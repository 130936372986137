export function validateEmail(email: any) {
  var re = /\S+@\S+\.\S+/;
  return re.test(String(email).toLowerCase());
}

export const validatePhone = (phone: any) => {
  const phone1 = phone + "";
  var cleanPhone = phone1
    .split(".")
    .join("")
    .split("-")
    .join("")
    .split(" ")
    .join("")
    .split("+")
    .join("");
  if (cleanPhone.length === 10) return true;
  else if (cleanPhone.length === 11 && cleanPhone.slice(0, 1) === "0")
    return true;
  else if (cleanPhone.length === 12 && cleanPhone.slice(0, 2) === "91")
    return true;
  else return false;
};

export const cleanPhone = (phone: any) => {
  const phone1 = phone + "";
  var cleanPhone = phone1
    .split(".")
    .join("")
    .split("-")
    .join("")
    .split(" ")
    .join("")
    .split("+")
    .join("");
  if (cleanPhone.length === 12) return cleanPhone.slice(2);
  if (cleanPhone.length === 10) return cleanPhone;
  if (cleanPhone.length === 11) return cleanPhone.slice(1);
  return cleanPhone;
};
