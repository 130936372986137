import { useEffect, useState } from "react";
import SideModal from "../SideModal/SideModal";
import { AdminService } from "../../services/admin.service";
import moment from "moment";
import EditEnrollment from "./EditEnrollment.modal";
import StringHelper from "../Helper/string.helper";

interface IViewEnrollmentModal {
    show: any,
    handleClose: any
}

export default function ViewEnrollmentModal(props: IViewEnrollmentModal) {

    const [paymentData, setPaymentData] = useState<any>();
    const [showEditEnrollment, setShowEditEnrollment] = useState<any>(undefined)

    const getEnrollmentByEnrollmentId = async () => {
        await AdminService.getEnrollmentDetail({ id: props.show }).then((res) => {
            if (res.status === 200) {
                setPaymentData(res.data.enrollmentDetails.enrollment);
                console.log(res.data, "payment")
            }
        })
    }

    useEffect(() => {
        if (props.show) {
            getEnrollmentByEnrollmentId();
        }
    }, [props.show])


    return (
        <SideModal
            show={props.show ? true : false}
            handleClose={() => props.handleClose(undefined)}
            title="View Enrollment"
            body={
                <>
                    <div>
                        <div className='mb-3'>Student: <strong className="ms-3">{paymentData?.student?.firstName + " " + paymentData?.student?.lastName}</strong></div>
                        <div className='mb-3'>Instructor: <strong className="ms-3">{paymentData?.instructor?.firstName + " " + paymentData?.instructor?.lastName}</strong></div>
                        <div className='mb-3'>Instrument: <strong className="ms-3">{paymentData?.course?.instrument}</strong></div>
                        <div className='mb-3'>Coordinator: <strong className="ms-3">{paymentData?.cordinator || "--"}</strong></div>
                    </div>
                    <div className="mt-3">
                        <h5>Payment Details</h5>
                    </div>
                    {paymentData && paymentData.payment_details && paymentData.payment_details.length > 0 ? paymentData.payment_details.filter((item: any) => item.status !== 'not-paid').map((item: any) =>
                        <>
                            <div className="bg-light p-3 rounded border border-1 mb-3">
                                <h6 className='mb-4 fw-bold'>Receipt Id: {item.receipt}</h6>
                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                    <span>Total Session</span>
                                    <strong>{item.num_classes}</strong>
                                </div>
                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                    <span>Session Bought</span>
                                    <strong>{item.classes_scheduled}</strong>
                                </div>
                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                    <span>Session Left</span>
                                    <strong>{item.classes_left}</strong>
                                </div>
                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                    <span>Session Completed</span>
                                    <strong>{item.classes_completed}</strong>
                                </div>
                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                    <span>Session Requested</span>
                                    <strong>{item.classes_requested}</strong>
                                </div>
                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                    <span>Session Cancelled</span>
                                    <strong>{item.classes_canceled}</strong>
                                </div>
                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                    <span>Session Late Charged</span>
                                    <strong>{item.classes_late_charged}</strong>
                                </div>
                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                    <span>Complimentary Classes</span>
                                    <strong>{item.extraClasses}</strong>
                                </div>
                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                    <span>Total Price</span>
                                    <strong>{item.total_price}</strong>
                                </div>
                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                    <span>Price per Class</span>
                                    <strong>{item.price}</strong>
                                </div>
                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                    <span>Status</span>
                                    <strong>{item.status}</strong>
                                </div>
                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                    <span>Pending Amount</span>
                                    <strong>{item.pending_amount}</strong>
                                </div>
                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                    <span>Payment Date</span>
                                    <strong>{moment(item.payment_date).format("DD MMM YYYY  hh:mm A")}</strong>
                                </div>
                            </div>
                        </>
                    ) : <h6 className="text-center mt-3">No Payments Done</h6>
                    }
                    {paymentData?.type === "paid" &&
                        <div className="btn btn-primary btn-block mb-4" onClick={() => setShowEditEnrollment(paymentData?._id)}>
                            Edit Enrollment
                        </div>
                    }

                    <EditEnrollment
                        enrollmentId={showEditEnrollment}
                        setEnrollmentId={setShowEditEnrollment}
                    />
                </>
            }
        />
    )
}