import { Badge, Container, Dropdown, Row, Table } from "react-bootstrap";

import { FontBold, NormalText } from "../Text/TableText.formats";
import "./table.css";
import { BiDotsVertical } from "react-icons/bi";
import CustomToggle from "../Menu/CustomMenu";
import SalesService from "../../services/sales.service";
import toast from "react-hot-toast";
import { useContext } from "react";
import { IsLoading } from "../../App.context";


interface IRazorPay {
    tableData: any;
    reloadData: any;
}


export default function SalesInvoicesTable({ tableData, reloadData }: IRazorPay) {
    const { loading, setLoading }: any = useContext(IsLoading);

    const handleDownload = (base64Data: any) => {
        // Convert base64 to Blob
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/octet-stream' });

        // Create a download link
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'file.txt'; // Set the desired file name
        document.body.appendChild(link);

        // Simulate a click to trigger the download
        link.click();

        // Cleanup
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }

    const createInvoicePdf = async (invoiceId: any) => {
        setLoading(true);
        await SalesService.createPdf(invoiceId)
            .then((res: any) => {
                if (res.status === 200) {
                    handleDownload(res?.data?.pdf)
                    toast.success("Pdf Downloaded successfully.");
                }
            })
            .catch((err: any) => {
                console.log(err);
                toast.error("unable to download PDF");
            })
        setLoading(false);
    }

    const cancelInvoice = async (invoiceId: any) => {
        await SalesService.cancelInvoice(invoiceId).then((res) => {
            if (res.status === 200) {
                toast.success("Invoice Canceled successfully");
                reloadData();
            }
        })
    }
    return (
        <>
            <Container>
                <Row>
                    <Table striped borderless hover responsive size="sm">
                        <thead>
                            <tr className="dhun-table-header text-secondary">
                                <th>#</th>
                                <th>Invoice Number</th>
                                <th>Buyer Name</th>
                                <th>Buyer Email</th>
                                <th>Buyer Phone</th>
                                <th>State</th>
                                <th>Date of Purchase</th>
                                {/* <th>Status</th> */}
                                <th>Amount Before GST</th>
                                <th>Amount After GST</th>
                                <th>CGST</th>
                                <th>IGST</th>
                                <th>SGST</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData && tableData.length > 0 ? (
                                tableData.map((data: any, index: any) => {
                                    return (
                                        <tr>
                                            <td>
                                                <NormalText value={index + 1} />
                                            </td>
                                            <td>
                                                <div className="d-flex">
                                                    <FontBold value={data.invoiceNumber} />
                                                </div>
                                            </td>

                                            <td>{data.buyerName ? data.buyerName : "N.A."}</td>
                                            <td>{data.buyerEmail ? data.buyerEmail : "N.A."}</td>

                                            <td>{data.buyerPhone ? data.buyerPhone : "N.A."}</td>
                                            <td>{data.buyerState ? data.buyerState : "N.A."}</td>
                                            <td>{data.dateOfPurchase ? data.dateOfPurchase : "N.A."}</td>

                                            {/* {data.paymentStatus === "pending" ? (
                                                <td>
                                                    <u className="fst-italic text-primary fs-12 cursor-pointer">
                                                        <Badge bg="danger" className="me-1">
                                                            {data.paymentStatus?.toUpperCase()}{" "}
                                                        </Badge>
                                                    </u>
                                                </td>
                                            ) : data.paymentStatus === "failed" ? (
                                                <td>
                                                    <u className="fst-italic text-primary fs-12 cursor-pointer">
                                                        <Badge bg="danger" className="me-1">
                                                            {data.paymentStatus?.toUpperCase()}{" "}
                                                        </Badge>
                                                    </u>
                                                </td>
                                            ) : (
                                                <td>
                                                    <u className="fst-italic text-primary fs-12 cursor-pointer">
                                                        <Badge bg="success" className="me-1">
                                                            {data.paymentStatus?.toUpperCase()}{" "}
                                                        </Badge>
                                                    </u>
                                                </td>
                                            )} */}

                                            <td>
                                                {data.totalAmountBeforeGST
                                                    ? data.totalAmountBeforeGST
                                                    : "N.A."}
                                            </td>
                                            <td>
                                                {data.totalAmountAfterGST
                                                    ? data.totalAmountAfterGST
                                                    : "N.A."}
                                            </td>
                                            <td>
                                                {
                                                    data?.gst?.cgst ?
                                                        data?.gst?.cgst : "N.A."
                                                }
                                            </td>
                                            <td>
                                                {
                                                    data?.gst?.igst ?
                                                        data?.gst?.igst : "N.A."
                                                }
                                            </td>
                                            <td>
                                                {
                                                    data?.gst?.sgst ?
                                                        data?.gst?.sgst : "N.A."
                                                }
                                            </td>
                                            <td>
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        as={CustomToggle}
                                                        id="dropdown-custom-components"
                                                    >
                                                        <BiDotsVertical />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            className="text-secondary fs-12 align-items-center d-flex fw-bold"
                                                            onClick={() => window.open(data.invoiceLink, "_blank")}
                                                        >
                                                            View PDF
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            className="text-secondary fs-12 align-items-center d-flex fw-bold"
                                                            onClick={() => cancelInvoice(data._id)}
                                                        >
                                                            Cancel Invoice
                                                        </Dropdown.Item>
                                                        {/* <Dropdown.Item
                                                            className="text-secondary fs-12 align-items-center d-flex fw-bold"
                                                            // onClick={() => setChangeStatus(data.renewal._id)}
                                                        >
                                                            Toggle Status
                                                        </Dropdown.Item> */}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr className="text-center">
                                    <td colSpan={16}>No Data Found</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Row>
            </Container>
        </>
    );
}
