import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Dropdown,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import toast from "react-hot-toast";
import { BiDotsVertical } from "react-icons/bi";
import { TbLockOpenOff, TbLockOpen } from "react-icons/tb";
import CustomToggle from "../../../components/Menu/CustomMenu";
import { AdminService } from "../../../services/admin.service";
import CardBasic from "../../../components/Card/card.basic";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { BookingService } from "../../../services/booking.service";
import UnblockUntillModal from "../../../components/Modals/UnblockUntill.modal";

interface IIncentiveTable {}

const BlockedEnrollmentTable = (props: IIncentiveTable) => {
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [blockedEnrollmentData, setBlockedEnrollmentData] = useState<any>([]);
  const [paidEnrollments, setPaidEnrollment] = useState<any>([]);
  const [enrollment, setEnrollment] = useState<any>({});
  const [showUnblockModal, setShowUnblockModal] = useState<any>(false);
  const [enrollnmentId, setEnrollnmentId] = useState<any>(null);

  const nextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  const getAllPaidSessions = async () => {
    await BookingService.getAllPaidEnrollments().then((res) => {
      if (res.status === 200) {
        const select_data = res?.data?.data?.map((data: any) => {
          return {
            label:
              data.student_name +
              " - " +
              data.instructor_name +
              " - " +
              data.instrument +
              " - " +
              data.enrollmentId,
            value: data._id,
          };
        });
        setPaidEnrollment(select_data);
      }
    });
  };

  const previousPage = () => {
    if (pageNumber <= 1) {
      toast.error("You are on the first page");
      return;
    }
    setPageNumber(pageNumber - 1);
  };

  const getAllBlockedEnrollments = async () => {
    let enrollmentId = (enrollment && enrollment.value) || undefined;
    await AdminService.getAllBlockedEnrollments(
      pageNumber,
      pageSize,
      enrollmentId
    ).then((res) => {
      if (res.status === 200) {
        setBlockedEnrollmentData(res.data);
      }
    });
  };

  useEffect(() => {
    getAllBlockedEnrollments();
  }, [pageNumber]);

  useEffect(() => {
    getAllPaidSessions();
  }, []);

  //   const handleUnblockEnrollments = async (enrollmentId: any) => {
  //     await AdminService.unblockEnrollment(enrollmentId).then((res) => {
  //       if (res.status === 200) {
  //         toast.success("Enrollment unblocked");
  //         getAllBlockedEnrollments();
  //       }
  //     });
  //   };

  return (
    <>
      <CardBasic
        body={
          <>
            <h6>Filters</h6>
            <Row>
              <Col md={4}>
                <Form.Label className="fs-12 fw-bold">Page Count</Form.Label>
                <Select
                  options={[
                    {
                      value: 10,
                      label: 10,
                    },
                    {
                      value: 20,
                      label: 20,
                    },
                    {
                      value: 50,
                      label: 50,
                    },
                  ]}
                  value={{
                    value: pageSize,
                    label: pageSize,
                  }}
                  onChange={(e: any) => {
                    setPageSize(e.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Form.Group className="mt-2 ">
                  <Form.Label className="fw-bold text-secondary fs-12">
                    Select Enrollment
                  </Form.Label>
                  <Select
                    options={paidEnrollments}
                    value={enrollment}
                    isSearchable={true}
                    placeholder="Select Enrollments"
                    onChange={(e: any) => {
                      setEnrollment(e);
                    }}
                    isClearable
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <Button
                  variant="primary"
                  onClick={getAllBlockedEnrollments}
                  size="sm"
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </>
        }
      />
      <div>
        <div className="d-flex justify-content-end align-items-center">
          <Button variant="primary" size="sm" onClick={previousPage}>
            <FontAwesomeIcon icon={faCaretLeft} /> Previous
          </Button>
          <Button
            variant="primary"
            size="sm"
            className="ms-2"
            onClick={nextPage}
          >
            Next <FontAwesomeIcon icon={faCaretRight} />
          </Button>
        </div>
        <div className="d-flex justify-content-end align-items-center fs-12 my-2">
          <span>Page {pageNumber}</span>
        </div>
      </div>
      <Table striped borderless hover responsive size="sm">
        <thead>
          <tr className="dhun-table-header text-secondary">
            <th>#</th>
            <th>Enrollment Id</th>
            <th>Name</th>
            <th>Instructor</th>
            <th>Classes Left</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {blockedEnrollmentData && blockedEnrollmentData.length > 0
            ? blockedEnrollmentData.map((data: any, index: number) => {
                console.log("table data", data)
                return (
                  <tr>
                    <td>{pageNumber * pageSize - pageSize + index + 1}</td>
                    <td>{data && data.enrollmentId && data.enrollmentId}</td>
                    <td>
                      {data &&
                        data.student &&
                        data.student.firstName + " " + data.student.lastName}
                    </td>
                    <td>
                      {data &&
                        data.instructor &&
                        data.instructor.firstName +
                          " " +
                          data.instructor.lastName}
                    </td>
                    <td>{data.classes_left}</td>
                    <td>
                      <Badge bg={data.isBlocked ? "danger" : "success"}>
                        {data.isBlocked ? "Blocked" : "Active"}
                      </Badge>
                    </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          as={CustomToggle}
                          id="dropdown-custom-components"
                        >
                          <BiDotsVertical className="cursor-pointer" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {setShowUnblockModal(true)
                            setEnrollnmentId(data._id)}
                            }
                          >
                            <TbLockOpen className="text-success" />{" "}
                            <span className="fw-bold text-success fs-12">
                              Unblock
                            </span>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                );
              })
            : "No data found"}
        </tbody>
      </Table>
      {showUnblockModal && (
        <UnblockUntillModal
          show={showUnblockModal}
          setShow={setShowUnblockModal}
            enrollnmentId={enrollnmentId}
            getAllBlockedEnrollments={getAllBlockedEnrollments}
        />
      )}
    </>
  );
};

export default BlockedEnrollmentTable;
