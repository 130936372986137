import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import CardBasic from "../../../../../components/Card/card.basic";
import CreateCourseModal from "../../../../../components/Modals/CreateCourse.Modal";
import EditCourseModal from "../../../../../components/Modals/EditCourse.Modal";
import CustomCard from "../../../../../global/CustomCard";
import { AdminService } from "../../../../../services/admin.service";
import Heading from "../../TableHeading/Heading";
interface IInstructorCourse {
  id: string;
}
export default function InstructorCourse(props: IInstructorCourse) {
  const [courseData, setCourseData] = useState<any>({});
  const [courseInstructor, setCourseInstructor] = useState<any>(undefined);
  const [editCourse, setEditCouse] = useState<any>(undefined);

  const getInstructorCourseData = async () => {
    if(!props.id) return;
    await AdminService.getInstructorCoursesbyId({ id: props.id })
      .then((res) => {
        if (res.status === 200) {
          setCourseData(res?.data?.data?.finalData);
        }
      })
      .catch((err) => {
        toast.error("Unable to fetch Course Details");
      });
  };

  useEffect(() => {
    getInstructorCourseData();
  }, [courseInstructor, editCourse]);

  return (
    <div>
      <CreateCourseModal
        instructorId={courseInstructor}
        setInstructorId={setCourseInstructor}
      />
      <EditCourseModal
        courseId={editCourse}
        setCourseId={setEditCouse}
      />
      <CardBasic
        body={
          <>
            <div className="d-flex justify-content-between">
              <Heading heading={"Courses (" + courseData.courseCount + ")"} />
              <div>
                <Button variant="secondary" size="sm" onClick={() => setCourseInstructor(props.id)}>
                  Add Course
                </Button>
              </div>
            </div>
            {courseData?.courseCount === 0 ? (
              "Course Not Available"
            ) : (
              <>
                <div className="d-flex justify-content-start overflow-scroll">
                  {courseData?.courseCount > 0 &&
                    courseData.courses.map((data: any) => {
                      return (
                        <CustomCard
                          img={data.headerImg}
                          title={data.title}
                          enrollments={courseData.enrollmentCount}
                          approve={data.approve}
                          level={data.level}
                          price_per_class={data.price_per_class}
                          discount={data.discount}
                          deleted={data.deleted}
                          isVisible={data.isVisible}
                          instrument={data.instrument}
                          onEditClick={() => setEditCouse(data._id)}
                        />
                      );
                    })}
                </div>
              </>
            )}
          </>
        }
      />
    </div>
  );
}
