import React, { useEffect, useState } from "react";
import Heading from "./Heading";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";

interface ITableHeading {
  isDynamic?: any;
  setSessionHeading?: any;
  titlesLength?: any;
  heading: string;
  title?: string;
  setSessionTitleIndex?: any;
}

const TableHeading = ({
  isDynamic,
  setSessionHeading,
  titlesLength,
  heading,
  title,
  setSessionTitleIndex,
}: ITableHeading) => {
  const [activeChild, setActiveChild] = useState(1);

  return isDynamic ? (
    <div style={{ display: "flex", gap: "1rem" }}>
      <Heading heading={heading} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <IoIosArrowUp
          onClick={() => {
            setActiveChild((a: any) => (a + 1 > titlesLength ? 0 : a + 1));
            setSessionHeading(title);
            setSessionTitleIndex(activeChild);
          }}
        />
        <IoIosArrowDown
          onClick={() => {
            setActiveChild((a) => (a - 1 < 0 ? titlesLength : a - 1));
            setSessionHeading(title);
            setSessionTitleIndex(activeChild);
          }}
        />
      </div>
    </div>
  ) : (
    <Heading heading={heading} />
  );
};

export default TableHeading;
