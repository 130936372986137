import React from "react";

const searchHighlight = {
  backgroundColor: "yellow",
  color: "black",
};

interface IHighlightSubstringProps {
  strTarget: string;
  subStr: string;
}

export default function HighlightString(props: IHighlightSubstringProps) {
  var strTarget = props.strTarget;
  var subStr = props.subStr;
  var reg = new RegExp(subStr, "gi");

  var marked_str = strTarget?.replace(reg, function (str) {
    return "|[" + str + "]|";
  });

  var arr = marked_str?.split("|");

  var result = arr?.map(function (s) {
    if (s.startsWith("[") && s.endsWith("]")) {
      s = s.replace(/\||\[|\]/g, "");
      return <span style={searchHighlight}>{s}</span>;
    } else {
      return <React.Fragment>{s}</React.Fragment>;
    }
  });
  if (result) return <span>{result}</span>;
  else return <span></span>;
}
