import { Badge } from "react-bootstrap"

enum InvoiceEnum {
    PER_CLASS = "per_class",
    INSTRUCTOR_FINE = "instructor_fine",
    INSTRUCTOR_INVOICE = "instructor_invoice"
}

interface IInvoiceBadge {
    type: InvoiceEnum
}

export default function InvoiceBadge (props: IInvoiceBadge) {
    const renderBadge = () => {
        switch(props.type) {
            case InvoiceEnum.PER_CLASS:
                return <Badge bg="primary">Per Class</Badge>
            case InvoiceEnum.INSTRUCTOR_FINE:
                return <Badge bg="danger">Instructor Fine</Badge>
            case InvoiceEnum.INSTRUCTOR_INVOICE:
                return <Badge bg="info">Instructor Invoice</Badge>
            default:
                return <Badge bg="warning">---</Badge>
        }
    }
    return (
        <>
            {renderBadge()}
        </>
    )
}