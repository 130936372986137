import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Container } from "react-bootstrap";
import CardBasic from "../../../components/Card/card.basic";
import DatePicker from "react-datepicker";
import AsyncSelect from "react-select/async";
import { AdminService } from "../../../services/admin.service";
import CallLogTable from "../../../components/Table/CallLog.table";

export default function CallRecordings() {
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [selOption, setSelOption] = useState<any>();

  const [callData, setCallData] = useState<any>();

  const fetchStudentData = (inputValue: any, callback: ([]) => void) => {
    if (!inputValue) {
      callback([]);
    } else {
      setTimeout(() => {
        AdminService.getAllStudents(inputValue).then((res) => {
          if (res.status === 200) {
            const students = res.data.data.map((data: any) => {
              return {
                value: data._id,
                label: `${data.firstName}  ${data.lastName} - ${data.email}`,
              };
            });
            callback(students);
          }
        });
      }, 1000);
    }
  };

  const fetchInstructorData = (inputValue: any, callback: ([]) => void) => {
    if (!inputValue) {
      callback([]);
    } else {
      setTimeout(() => {
        AdminService.getAllInstructor(inputValue).then((res) => {
          if (res.status === 200) {
            const instructors = res.data.data.map((data: any) => {
              return {
                value: data._id,
                label: `${data.firstName}  ${data.lastName} - ${data.email}`,
              };
            });
            callback(instructors);
          }
        });
      }, 1000);
    }
  };

  const handelInstructorChanges = (obj: any, name: string) => {
    setSelOption({ ...selOption, [name]: obj });
  };

  const getCallLogs = async () => {
    await AdminService.getAllCallLogs(
      startDate,
      endDate,
      selOption?.student?.value,
      selOption?.instructor?.value
    ).then((res) => {
      if (res.status === 200) {
        setCallData(res.data);
      }
    });
  };

  return (
    <>
      <Container>
        <Row className="mt-3">
          <Col>
            <CardBasic
              className="mt-3"
              body={
                <>
                  <h5 className="mb-3">Filters</h5>
                  <Row>
                    <Col md={3}>
                      <Form.Label className="fw-bold text-secondary fs-12">Start Date</Form.Label>
                      <div>
                        <DatePicker
                          selected={startDate}
                          onChange={(e: any) => setStartDate(e)}
                          className="p-1 text-muted form form-control"
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <Form.Label className="fw-bold text-secondary fs-12">End Date</Form.Label>
                      <div>
                        <DatePicker
                          selected={endDate}
                          onChange={(e: any) => setEndDate(e)}
                          className="p-1 text-muted form form-control"
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <Form.Label className="fw-bold text-secondary fs-12">
                        Instructor (Search)
                      </Form.Label>
                      <AsyncSelect
                        name="InstructorId"
                        loadOptions={fetchInstructorData}
                        onChange={(option: any) =>
                          handelInstructorChanges(option, "instructor")
                        }
                        isClearable={true}
                      />
                    </Col>

                    <Col md={3}>
                      <Form.Label className="fw-bold text-secondary fs-12">
                        Students (Search)
                      </Form.Label>
                      <AsyncSelect
                        name="StudentId"
                        loadOptions={fetchStudentData}
                        onChange={(option: any) =>
                          handelInstructorChanges(option, "student")
                        }
                        isClearable={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="my-4">
                      <div className="d-flex justify-content-end">
                        <Button
                          size="sm"
                          className="mx-2"
                          onClick={getCallLogs}
                        >
                          Get
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </>
              }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CardBasic
              className="mt-3"
              body={
                <>
                  <h4 className="mb-2">Call Logs</h4>
                  <CallLogTable tableData={callData} />
                </>
              }
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}
