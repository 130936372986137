import { useEffect, useState } from "react"
import { Button, Form } from "react-bootstrap"
import toast from "react-hot-toast"
import { AdminService } from "../../services/admin.service"
import SideModal from "../SideModal/SideModal"

interface IEditUser {
    user: {
        _id: string
        email: string,
        phone: number,
        firstName: string,
        lastName: string
    }
    setUser: any
}

export default function EditUser(props: IEditUser) {

    const [number, setNumber] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();


    const onUpdateUserClick = () => {
        if (!number || !email || !props.user._id || !firstName || !lastName) {
            toast.error("All Fields are mandatory")
            return false
        }
        AdminService.updateUserInfo(props.user._id, email, Number(number), firstName, lastName).then(res => {
            if (res.status === 200) {
                toast.success("User Updated");
                props.setUser({ _id: undefined });
                setNumber(undefined);
                setEmail(undefined);
                setFirstName(undefined);
                setLastName(undefined);
            }
        })
    }
    useEffect(() => {
        setNumber(String(props.user.phone))
        setEmail(props.user.email)
        setFirstName(props.user.firstName)
        setLastName(props.user.lastName)

    }, [props.user])
    return (
        <SideModal
            show={props.user._id ? true : false}
            handleClose={() => props.setUser({ _id: undefined })}
            title="Edit User"
            body={<>
                <Form.Group>
                    <Form.Label>
                        First Name
                    </Form.Label>
                    <Form.Control type="text" value={firstName} name="firstName" onChange={(e) => setFirstName(e.target.value)} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        LastName
                    </Form.Label>
                    <Form.Control type="text" value={lastName} name="lastName" onChange={(e) => setLastName(e.target.value)} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        Email
                    </Form.Label>
                    <Form.Control type="email" value={email} name="email" onChange={(e) => setEmail(e.target.value)} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        Phone
                    </Form.Label>
                    <Form.Control type="number" value={number} name="number" onChange={(e) => setNumber(e.target.value)} />
                </Form.Group>
                <Button variant="primary" size="sm" className="mt-3" onClick={onUpdateUserClick}>
                    Update User
                </Button>
            </>}
        />
    )
}