import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import toast from "react-hot-toast";
import SideModal from "../SideModal/SideModal";
import { instrument } from "../../constants/instruments";
import InstructorService from "../../services/instructor.service";
import { IoIosAddCircle, IoIosRemoveCircle } from "react-icons/io";

interface IEditCourseModal {
  courseId: string
  setCourseId: any
}

export default function EditCourseModal(props: IEditCourseModal) {

  const [loading, setLoading] = useState<boolean>(false);
  const [course, setCourse] = useState<any>({});

  const getCourse = async () => {
    await InstructorService.getCourse(props.courseId).then(res => {
      if (res.status === 200) {
        setCourse(res.data.userData);
      }
    })
  }

  const editCourse = async () => {
    setLoading(true)
    await InstructorService.editCourse(props.courseId, course.title, course.instrument, Number(course.price_per_class), course.isVisible, course.deleted, course.approve, course.discount).then(res => {
      if (res.status === 200) {
        toast.success("Course Updated")
        props.setCourseId(undefined)
      }
    }).catch(e => {
      setLoading(false)
      toast.error(e.response.data || "Something Went Wrong")
    })
    setLoading(false)
  }


  const handleUpdateChanges = (key: any, value: any) => {
    setCourse({ ...course, [key]: value })
  }


  const handleDiscountClasses = (e:any, index:number) => {
    let old_discount = course.discount;
    old_discount[index].num_classes = e.target.value;
    setCourse({...course, discount: old_discount});
  }

  const handleDiscountDiscount = (e:any, index:number) => {
    let old_discount = course.discount;
    old_discount[index].discount = e.target.value;
    setCourse({...course, discount: old_discount});
  }

  const removeDiscount = () => {
    let old_discount:any[] = course.discount;
    old_discount.pop();
    setCourse({...course, discount: old_discount});
  }

  const addDiscount = () => {
    let old_discount:any[] = course.discount;
    old_discount.push({
      num_classes: '0',
      discount: '0'
    });
    setCourse({...course, discount: old_discount});
  }


  useEffect(() => {
    if (props.courseId) {
      getCourse();
    }
  }, [props.courseId])

  return (
    <>
      <SideModal
        show={props.courseId ? true : false}
        handleClose={() => props.setCourseId(undefined)}
        title="Edit Course"
        body={
          <div className="h-100">
            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">
                Subject
              </Form.Label>
              <Select
                onChange={(e: any) => handleUpdateChanges("instrument", e.value)}
                options={instrument}
                value={instrument.find(inst => inst.value == course.instrument)}
              />
            </Form.Group>

            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">Title</Form.Label>
              <Form.Control type="text" name="title" onChange={(e) => handleUpdateChanges("title", e.target.value)} value={course.title} />
            </Form.Group>

            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">Price Per Class</Form.Label>
              <Form.Control type="number" name="price_per_class" onChange={(e) => handleUpdateChanges("price_per_class", e.target.value)} value={course.price_per_class} />
            </Form.Group>
            <Form.Group className="d-flex justify-content-start align-items-center">
              <Form.Group className="mt-3 d-flex justify-content-start align-items-center">
                <Form.Check checked={course.isVisible} label="Visible" className="fs-12" onChange={(e) => handleUpdateChanges("isVisible", e.target.checked)} />
              </Form.Group>
              <Form.Group className="mt-3 d-flex justify-content-start align-items-center ms-5">
                <Form.Check checked={course.deleted} label="Deleted" className="fs-12" onChange={(e) => handleUpdateChanges("deleted", e.target.checked)} />
              </Form.Group>
            </Form.Group>
            <Form.Group className="d-flex justify-content-start align-items-center">
              <Form.Group className="mt-3 d-flex justify-content-start align-items-center">
                <Form.Check checked={course.approve} label="Approved" className="fs-12" onChange={(e) => handleUpdateChanges("approve", e.target.checked)} />
              </Form.Group>
            </Form.Group>

            <Alert variant="warning" className="mt-3 fs-12">
              <b>Warning: </b>
              Make Sure you are altering discounts correctly
            </Alert>

            <div className="d-flex justify-content-between align-items-center">
              <h6 className="text-secondary">Discounts</h6>
              <div>
                <IoIosAddCircle className="cursor-pointer text-success me-2" style={{ fontSize: "25px" }} onClick={addDiscount} />
                <IoIosRemoveCircle className="cursor-pointer text-danger" style={{ fontSize: "25px" }} onClick={removeDiscount} />
              </div>
            </div>
            {course.discount && course.discount.length > 0 && course.discount.map((discount: any, index: number) => {
              return (
                <>
                  <Form.Group className="mt-2 fs-12 bg-muted p-3 rounded">
                    <Row className="d-flex align-items-center">
                      <Col md={2}>
                        Buy
                      </Col>
                      <Col md={3}>
                        <Form.Control type="number" value={discount.num_classes} onChange={(e: any)=>handleDiscountClasses(e, index)} />
                      </Col>
                      <Col md={2}>
                        get
                      </Col>
                      <Col md={3}>
                        <Form.Control type="number" value={discount.discount} onChange={(e: any)=>handleDiscountDiscount(e, index)} />
                      </Col>
                      <Col md={2}>
                        % off
                      </Col>
                    </Row>
                  </Form.Group>
                </>
              )
            })}

            <Button variant="secondary" disabled={loading} className="mt-3" onClick={editCourse}>Update Course</Button>
          </div>
        }
      />
    </>
  );
}
