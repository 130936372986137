import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import "./table.css";
import moment from "moment";
import { CSVLink } from "react-csv";
import toast from "react-hot-toast";
import InvoiceService from "../../services/invoice.service";
import AllInvoicesByInstructor from "../Modals/AllInvoicesByInstructor.modal";

interface IFinalInvoiceTable {
  pageCount: any
  pageNumber: any
  startDate: any
  endDate: any
  instructor: any;
}

export default function FinalInvoiceTable({
  pageCount,
  pageNumber,
  startDate,
  endDate,
  instructor
}: IFinalInvoiceTable) {

  const [tableData, setTableData] = useState<any>();

  const getInvoices = async () => {
    if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
      return;
    }
    const fromDate = moment(startDate).format("YYYY-MM-DD");
    const toDate = moment(endDate).format("YYYY-MM-DD");

    await
      InvoiceService.getInvoices(pageCount, pageNumber, "final_invoices", fromDate, toDate, instructor?.value).then((res: any) => {
        if (res.status === 200) {
          setTableData(res?.data.invoices);
        }
      })
        .catch((err: any) => {
          setTableData([]);
          // toast.error("Invoice Details not found.");
          console.log(err)
        })
  }

  const csvDataDownload =
    tableData && tableData?.length > 0
      ? tableData?.map((data: any) => {
        return {
          ...data
        };
      })
      : "";

  const vangheeDataDownload = tableData && tableData?.length > 0
    ? tableData?.map((data: any) => {
      return ({
        "Beneficiary Name": data?.bank_acc_name,
        "Beneficiary Account Number": "'" + data?.account,
        "IFSC Code": data?.ifsc,
        "Amount": data?.total_amount,
        "Description": "",
        "Payment Date": "",
        "Payment Model": "IMPS"
      })
    }) : "";


  useEffect(() => {
    getInvoices()
  }, [pageCount, startDate, endDate, instructor]);
  return (
    <>
      <Row>
        <Col className="my-4">
          <div className="d-flex justify-content-end">
            { }
            <CSVLink data={csvDataDownload} filename={"unpaid_invoices.csv"}>
              <Button size="sm" className="mx-2">
                Download CSV Data
              </Button>
            </CSVLink>
            <CSVLink data={vangheeDataDownload} filename={"vanghee_data.csv"}>
              <Button size="sm" className="mx-2">
                Download Vanghee Data
              </Button>
            </CSVLink>
          </div>
        </Col>
      </Row>
      <Table striped borderless hover responsive size="sm">

        <thead>
          <tr className="dhun-table-header text-secondary">
            <th>#</th>
            <th>Instructor</th>
            <th>Amount After TDS</th>
            <th>Fined</th>
            <th>Incentive</th>
            <th>TDS</th>
            <th>Amount</th>
            <th>Earnings</th>
          </tr>
        </thead>
        <tbody>
          {tableData && tableData?.length > 0
            ? tableData?.map((data: any, index: any) => {
              return (
                <tr >
                  <td>
                    {index + 1}
                  </td>
                  <td>
                    {data.name}
                  </td>
                  <td className="fw-bold">
                    {data.amount_after_tds}
                  </td>
                  <td>
                    {data.fined_amount}
                  </td>
                  <td>
                    {data.incentive}{"(" + data.totalIncentiveCount + ")"}
                  </td>
                  <td>
                    {data.tds}
                  </td>
                  <td>
                    {data.total_amount}
                  </td>
                  <td>
                    {data.total_dhunguru_earnings}
                  </td>
                </tr>
              );
            })
            : "No Data Available"}
        </tbody>
      </Table>


    </>
  );
}
