import React from "react";
import { Badge } from "react-bootstrap";
import CardBasic from "../../../../../components/Card/card.basic";
import "../../../../../assets/css/custom.css";
import "../../../../../assets/css/dashborad.css";

interface IApproval {
  instructor: any;
  courses: any;
  setCourses: any;
  setInstructor: any;
}

const Approvals = ({
  instructor,
  courses,
  setCourses,
  setInstructor,
}: IApproval) => {
  // const approveInstructor = async () => {
  //   try {
  //     const newValue = !instructor.approve;
  //     setInstructor((prev:any) => ({ ...prev, approve: newValue }));
  //     if (newValue) {
  //       await axios.get(
  //         `/api/admin/instructor/approve/${instructor.instructor_id}`
  //       );
  //     } else {
  //       await axios.get(
  //         `/api/admin/instructor/disapprove/${instructor.instructor_id}`
  //       );
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // const tagTeacher = async (tag: any) => {
  //   try {
  //     const newValue = !instructor[tag];
  //     setInstructor((prev:any: any) => ({ ...prev, [tag]: newValue }));
  //     await axios.get(
  //       `/api/admin/instructor/tag/${instructor.instructor_id}/${tag}/${newValue}`
  //     );
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // const approveInstrument = async (id: any) => {
  //   try {
  //     const newCourses = [...courses];
  //     const index = newCourses.findIndex((item) => item._id === id);
  //     const newValue = !courses[index].approve;
  //     newCourses[index].approve = newValue;
  //     setCourses(newCourses);
  //     if (newValue) {
  //       console.log("Approving");
  //       await axios.get(`/api/admin/instructor/lesson/approve/${id}`);
  //     } else {
  //       await axios.get(`/api/admin/instructor/lesson/disapprove/${id}`);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  return (
    <CardBasic
      body={
        <div>
          <span className="fw-bold fs-grey" style={{ fontSize: "20px" }}>
            Instructor Approval
          </span>
          <div className="row mb-4">
            <div className="col-12 col-lg-10">
              <div className="row align-items-center py-4">
                <div className="col-auto">
                  <div
                    className="custom-control custom-switch"
                    // onClick={() => approveInstructor()}
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      readOnly
                      checked={instructor.approve}
                    />
                    <label className="custom-control-label"></label>
                  </div>
                </div>
                <div className="col ms-n2">
                  <h6 className="mb-1">Approve Instructor Profile</h6>
                  <small>
                    Please check the profile and all the documents submitted
                    thoroughly.
                  </small>
                </div>
              </div>
            </div>
          </div>
          <span
            className="fw-bold fs-grey mb-3 mt-5 fs-25"
            style={{ fontSize: "20px" }}
          >
            Instructor Tagging
          </span>
          {/* <h5 className="h2 mb-3 mt-5"></h5> */}
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="card shadow-sm border">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <div
                        className="custom-control custom-switch"
                        // onClick={() => tagTeacher("isBackgroundChecked")}
                      >
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          readOnly
                          checked={instructor.isBackgroundChecked}
                        />
                        <label className="custom-control-label"></label>
                      </div>
                    </div>
                    <div className="col ms-n2">
                      <h6 className="mb-1">Background Check</h6>
                      <small>
                        Please check the profile and all the documents submitted
                        thoroughly.
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="card shadow-sm border">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <div
                        className="custom-control custom-switch"
                        // onClick={() => tagTeacher("isStarTeacher")}
                      >
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          readOnly
                          checked={instructor.isStarTeacher}
                        />
                        <label className="custom-control-label"></label>
                      </div>
                    </div>
                    <div className="col ms-n2">
                      <h6 className="mb-1">Star Teacher</h6>
                      <small>
                        Please check the profile and all the documents submitted
                        thoroughly.
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="card shadow-sm border">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <div
                        className="custom-control custom-switch"
                        // onClick={() => tagTeacher("isBulkDiscount")}
                      >
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          readOnly
                          checked={instructor.isBulkDiscount}
                        />
                        <label className="custom-control-label"></label>
                      </div>
                    </div>
                    <div className="col ms-n2">
                      <h6 className="mb-1">Bulk discount available?</h6>
                      <small>
                        Please check the profile and all the documents submitted
                        thoroughly.
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <span
              className="fw-bold fs-grey mb-3 mt-2 fs-25"
              style={{ fontSize: "20px" }}
            >
              Instrument Approvals
            </span>
            <div className="card shadow-sm border">
              <div className="list-group list-group-lg">
                {courses &&
                  courses?.map((item: any, index: any) => (
                    <div className="list-group-item">
                      <div className="row align-items-center">
                        <div className="col">
                          <h3 className="mb-2">{item.title}</h3>
                          <Badge bg="primary" className="mb-0">
                            <h3 className="badge badge-primary mb-0">
                              {item.instrument[0].toUpperCase() +
                                item.instrument.slice(1)}
                            </h3>
                          </Badge>
                        </div>
                        <div className="col-auto">
                          <div
                            className="custom-control custom-switch"
                            // onClick={() => approveInstrument(item._id)}
                          >
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              readOnly
                              checked={item.approve}
                            />
                            <label className="custom-control-label"></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default Approvals;
