import React, { useState } from "react";
import { Badge, Table } from "react-bootstrap";
import { FontBold, NormalText } from "../Text/TableText.formats";
import Button from "react-bootstrap/Button";
import moment from "moment";
import { useNavigate } from "react-router";
import Player from "../Modals/Player";
import { AdminService } from "../../services/admin.service";
import { toast } from "react-hot-toast";

interface IInstRecordingTable {
  tableData: any;
}

const btnStyle = { display: "flex", gap: "0.2rem", overflow: "scroll" };

const InstRecordingTable = ({ tableData }: IInstRecordingTable) => {
  const [selectedVideos, setSelectedVideos] = useState<any>();
  const [showPlayer, setShowPlayer] = useState<any>(false);

  const playVideo = (links: any) => {
    if (links.length > 0) {
      setSelectedVideos(links);
      setShowPlayer(true);
    }
  };

  const syncRecordingFromZoom = async(sessionId: string) => {
    await AdminService.syncRecordingFromZoom(sessionId).then(res => {
      if(res.status == 200){
        toast.success(res.data.message || "Sync in Progress Please Wait");
      }
    })
  }


  const navigate = useNavigate();
  return (
    <>
      <Table striped borderless hover responsive size="sm">
        <thead>
          <tr className="dhun-table-header text-secondary">
            <th>#</th>
            <th>Booking Id</th>
            <th>Student</th>
            <th>Instructor</th>
            <th>Course Name</th>
            <th>Enrollment Type</th>
            <th>Status</th>
            <th>Price</th>
            <th>Date Time</th>
            <th>Payment Id</th>
            <th>Recording Link</th>
          </tr>
        </thead>
        <tbody>
          {tableData && tableData.length > 0
            ? tableData.map((data: any, index: any) => {
                return (
                  <tr>
                    <td>
                      <NormalText value={index + 1} />
                    </td>
                    <td>
                      <FontBold value={data?.bookingId || "N/A"} />
                    </td>
                    <td
                      onClick={() => {
                        navigate(
                          "/admin/search/student/detail/" + data?.student?._id
                        );
                      }}
                    >
                      <u className="fst-italic text-primary fs-12 fs-underline cursor-pointer">
                        {data.student
                          ? data.student.firstName + " " + data.student.lastName
                          : "N/A"}
                      </u>
                    </td>
                    <td
                      onClick={() => {
                        navigate(
                          "/admin/search/student/detail/" +
                            data?.instructor?._id
                        );
                      }}
                    >
                      <u className="fst-italic text-primary fs-12 fs-underline cursor-pointer">
                        {data.instructor
                          ? data.instructor.firstName +
                            " " +
                            data.instructor.lastName
                          : "N/A"}
                      </u>
                    </td>
                    <td>
                      <span className="fs-12 cursor-pointer">
                        {data.title ? (
                          data.title
                        ) : (
                          <Badge
                            bg="danger"
                            className="me-1 fs-12 cursor-pointer"
                          >
                            N/A
                          </Badge>
                        )}
                      </span>
                    </td>
                    <td>
                      <span className="fs-12 cursor-pointer">
                        {data.type ? (
                          data.type
                        ) : (
                          <Badge
                            bg="danger"
                            className="me-1 fs-12 cursor-pointer"
                          >
                            N/A
                          </Badge>
                        )}
                      </span>
                    </td>
                    <td>
                      <div className="fs-12 cursor-pointer">
                        {data.status ? (
                          data?.status
                        ) : (
                          <Badge
                            bg="danger"
                            className="me-1 fs-12 cursor-pointer"
                          >
                            N/A
                          </Badge>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="fs-12 cursor-pointer">
                        {data?.price ? (
                          data?.price
                        ) : (
                          <Badge
                            bg="danger"
                            className="me-1 fs-12 cursor-pointer"
                          >
                            N/A
                          </Badge>
                        )}
                      </div>
                    </td>
                    <td>
                      <span className="font-monospace fs-12">
                        {moment(data.date_time).format("DD MMM YY hh:mm a")}
                      </span>
                    </td>
                    <td>
                      <div className="fs-12 cursor-pointer">
                        {data?.paymentId ? (
                          data?.paymentId
                        ) : (
                          <Badge
                            bg="danger"
                            className="me-1 fs-12 cursor-pointer"
                          >
                            N/A
                          </Badge>
                        )}
                      </div>
                    </td>
                    <td>
                      <div
                        className="cursor-pointer"
                        style={{
                          ...btnStyle,
                        }}
                      >
                        {data?.zoom?.recording_links.length > 0 ? (
                          <Button
                            variant="outline-primary"
                            size="sm"
                            onClick={() =>
                              playVideo(data?.zoom?.recording_links)
                            }
                          >
                            Recordings
                          </Button>
                        ) : (
                          <>
                          <Button variant="secondary" size="sm" onClick={() => syncRecordingFromZoom(data._id)}>
                            Sync Recording
                          </Button>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })
            : "No data found"}
        </tbody>
      </Table>
      {selectedVideos && (
        <Player
          show={showPlayer}
          setShow={setShowPlayer}
          data={selectedVideos}
        />
      )}
    </>
  );
};

export default InstRecordingTable;
