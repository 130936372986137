import { Badge, Button, Container, Row, Table } from "react-bootstrap";
import { FaExternalLinkAlt } from "react-icons/fa";
import { FontBold, NormalText } from "../Text/TableText.formats";
import "./table.css";
import toast from "react-hot-toast";
import SalesService from "../../services/sales.service";

interface IRazorPay {
  tableData: any;
  reloadData: any;
}

export default function SalesLinkTable({ tableData, reloadData }: IRazorPay) {

  const markComplete = async (_id: any) => {
    await SalesService.markComplete(_id).then((res) => {
      if (res.status === 200) {
        reloadData();
        toast.success("Marked as Complete");
      }
    })
  }

  return (
    <>
      <Container>
        <Row>
          <Table striped borderless hover responsive size="sm">
            <thead>
              <tr className="dhun-table-header text-secondary">
                <th>#</th>
                <th>Id/BuyerId</th>
                <th>Buyer Name</th>
                <th>Buyer Email</th>
                <th>Buyer Phone</th>
                <th>Coordinator</th>
                <th>Coupon Code</th>
                <th>Instructor</th>
                <th>Created By</th>
                <th>State</th>
                <th>Status</th>
                <th>Amount After GST</th>
                <th>Link Amount</th>
                <th>Payment Link</th>
              </tr>
            </thead>
            <tbody>
              {tableData && tableData.length > 0 ? (
                tableData.map((data: any, index: any) => {
                  return (
                    <tr>
                      <td>
                        <NormalText value={index + 1} />
                      </td>
                      <td>
                        <div className="d-flex">
                          <NormalText value={data._id} />
                        </div>
                      </td>

                      <td>{data.buyerName ? data.buyerName : "N.A."}</td>
                      <td>{data.buyerEmail ? data.buyerEmail : "N.A."}</td>

                      <td>{data.buyerPhone ? data.buyerPhone : "N.A."}</td>
                      
                      <td>{data.coordinator ? data.coordinator : "N.A."}</td>
                      <td>{data.couponId && data.couponId.couponName ? data.couponId.couponName : "N.A."}</td>
                      <td>{data.instructorId && data.instructorId.firstName ? data.instructorId.firstName : "N.A."}</td>
                      <td>{data.createdBy ? data.createdBy.firstName : "N.A."}</td>

                      <td>{data.buyerState ? data.buyerState : "N.A."}</td>

                      {data.paymentStatus === "Pending" ? (
                        <td>
                          <u className="fst-italic text-primary fs-12 cursor-pointer">
                            <Badge bg="danger" className="me-1">
                              {data.paymentStatus?.toUpperCase()}{" "}
                            </Badge>
                          </u>
                        </td>
                      ) : data.paymentStatus === "failed" ? (
                        <td>
                          <u className="fst-italic text-primary fs-12 cursor-pointer">
                            <Badge bg="danger" className="me-1">
                              {data.paymentStatus?.toUpperCase()}{" "}
                            </Badge>
                          </u>
                        </td>
                      ) : (
                        <td>
                          <u className="fst-italic text-primary fs-12 cursor-pointer">
                            <Badge bg="success" className="me-1">
                              {data.paymentStatus?.toUpperCase()}{" "}
                            </Badge>
                          </u>
                        </td>
                      )}

                    
                      <td>
                        {data.totalAmountAfterGST
                          ? (data.totalAmountAfterGST).toFixed(2)
                          : "N.A."}
                      </td>
                      <td>
                        {data.linkAmount
                          ? data.linkAmount
                          : "N.A."}
                      </td>
                      <td>
                        <Button
                        size="sm"
                          onClick={() => {
                            navigator.clipboard.writeText(data.paymentlink)
                            toast.success("Link Copied to Clipboard")
                          }}
                        >
                          Copy
                        </Button>
                        {/* <Button
                        size="sm"
                        variant="danger"
                        className="ms-2"
                          onClick={() => {
                                markComplete(data._id)
                          }}
                        >
                          Mark Complete
                        </Button> */}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="text-center">
                  <td colSpan={16}>No Data Found</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Row>
      </Container>
    </>
  );
}
