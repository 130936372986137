import moment from "moment";
import { useState } from "react";
import { Button, Dropdown, Table } from "react-bootstrap";
import PlayRecordingModal from "../Modals/PlayRecording.modal";
import CustomToggle from "../Menu/CustomMenu";
import { BiDotsVertical } from "react-icons/bi";
import CallService from "../../services/call.service";
import { toast } from "react-hot-toast";

interface ICallLogTable {
  tableData: any;
}

export default function CallLogTable({ tableData }: ICallLogTable) {
  const [index, setIndex] = useState<any>(undefined);

  const refreshCallLog = async (id: string) => {
    await CallService.refreshCallLog(id).then((res) => {
      if(res && res.status === 200) {
       toast.success("Refreshed Call Log Successfully")
      }
    }).catch((err) => {
      toast.error("Error Refreshing Call Log")
      console.error(err);
    })
  }

  return (
    <>
      <Table striped borderless hover responsive size="sm">
        <thead>
          <tr className="dhun-table-header text-secondary">
            <th>#</th>
            <th>Info</th>
            <th>From</th>
            <th>To</th>
            <th>Date</th>
            <th>Recording URL</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData && tableData.length > 0
            ? tableData.map((data: any, index: number) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{data && data.changes && data.changes.info}</td>
                    <th>{data?.from?.firstName + data?.from?.lastName + " (" +data?.from?.role+ ")"}</th>
                    <th>{data?.to?.firstName + data?.to?.lastName + " (" +data?.to?.role+ ")"}</th>   
                    <td>{moment(data.createdAt).format("DD MMM YYYY hh:mm a")}</td>
                    <td>
                      {data && data.recordingUrl ? (
                        <Button
                          size="sm"
                          onClick={() => setIndex(data.recordingUrl)}
                        >
                          Play
                        </Button>
                      ) : (
                        "--"
                      )}
                    </td>
                      <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        as={CustomToggle}
                        id="dropdown-custom-components"
                      >
                        <BiDotsVertical className="cursor-pointer" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                        onClick={() => refreshCallLog(data._id)}
                        >
                          Refresh Call Log
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    </td>
                  </tr>
                );
              })
            : "No Call Logs Found"}
        </tbody>
      </Table>
      <PlayRecordingModal show={index} setShow={() => setIndex(undefined)} />
    </>
  );
}
