import Select from "react-select";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Card,
  ButtonGroup,
  Button,
  Pagination,
} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import toast from "react-hot-toast";
import CardBasic from "../../../components/Card/card.basic";
import CreateSaleModal from "../../../components/Modals/CreateSale.modal";
import SalesLinkTable from "../../../components/Table/SalesLink.table";
import SalesService from "../../../services/sales.service";
import { CSVLink } from "react-csv";

interface IProps {
  activeKey: string
}

export default function SalesLinks({ activeKey }: IProps) {
  const [showCreateLink, setShowCreateLink] = useState(false);
  const [tableData, setTableData] = useState<any>();
  const [totalPages, setTotalPages] = useState(20);
  const [pageCount, setPageCount] = useState(1);
  const [status, setStatus] = useState<any>({
    value: "pending",
    label: "Pending",
  });

  const [dateRange, setDateRange] = useState({
    startDate: moment(new Date()).subtract("10", "days").toDate(),
    endDate: moment(new Date()).add("30", "days").toDate(),
  });

  const [csvData, setCsvData] = useState<any>([]);

  const getSalesLink = async () => {
    const fromDate = moment(dateRange.startDate).format("YYYY-MM-DD");
    const toDate = moment(dateRange.endDate).format("YYYY-MM-DD");

    if (!fromDate || !toDate) {
      return false;
    }

    await SalesService.getLink(fromDate, toDate, status.label)
      .then((res) => {
        if (res.status === 200) {
          setTableData(res?.data?.SalesLink);
          setCsvData(res?.data?.SalesLink.map((data: any, index: number) => {
            return {
              "#": index + 1,
              "Buyer name": data.buyerName || "--",
              "Buyer email": data.buyerEmail || "--",
              "Buyer phone": data.buyerPhone || "--",
              "Co-ordinator": data.coordinator || "--",
              "Coupon code": data.couponId && data.couponId.couponName && data.couponId.couponName || "--",
              "Instructor": data.instructorId && data.instructorId.firstName && data.instructorId.firstName || "--",
              "Created by": data.createdBy && data.createdBy.firstName || "--",
              "State": data.buyerState || "--",
              "Status": data.paymentStatus || "--",
              "Amount After GST": data.totalAmountAfterGST && (data.totalAmountAfterGST).toFixed(2) || "--",
              "Link amount": data.linkAmount || "--",
            }
          }))
        }
      })
      .catch((err) => {
        toast.error("Unable to fetch Payment Details");
      });
  };

  useEffect(() => {
    if (activeKey === "links") {
      getSalesLink();
    }
  }, [showCreateLink, totalPages, pageCount, status, dateRange, activeKey]);

  const PaymentStatus = [
    {
      value: "pending",
      label: "Pending",
    },
    {
      value: "completed",
      label: "Completed",
    },
    {
      value: "failed",
      label: "Failed",
    },
  ];

  return (
    <>
      <Container>
        <Row>
          <Col>
            <CardBasic
              className="mt-3"
              body={
                <>
                  <h6>Filter</h6>
                  <Row>
                    <Col md={3}>
                      <Form.Label className="fw-bold text-secondary fs-12">
                        Created At
                      </Form.Label>
                      <ReactDatePicker
                        onChange={(e: any) => {
                          setDateRange({
                            startDate: e[0],
                            endDate: e[1],
                          });
                        }}
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        selectsRange
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                      />
                    </Col>
                    <Col md={3}>
                      <Form.Label className="fw-bold text-secondary fs-12">
                        Payment Status
                      </Form.Label>
                      <Select
                        options={PaymentStatus}
                        value={status}
                        onChange={setStatus}
                      />
                    </Col>
                  </Row>
                </>
              }
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <Card className="mt-4">
          <Card.Body>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <Card.Title>Sales Link</Card.Title>
              <div className="d-flex gap-2 ">
                <ButtonGroup size="sm">
                  <Button onClick={() => setShowCreateLink(true)}>
                    Create New Sale
                  </Button>
                </ButtonGroup>
                <ButtonGroup size="sm">
                  <CSVLink
                    data={csvData}
                    className="btn btn-primary text-decoration-none"
                    filename={"Sales_links.csv"}
                    target="_blank"
                  >
                    Download CSV
                  </CSVLink>
                </ButtonGroup>
              </div>
            </div>
            <div className="m-4">
              <SalesLinkTable tableData={tableData} reloadData={getSalesLink} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pagination>
                <Pagination.First />
                <Pagination.Prev
                  onClick={() => {
                    setPageCount(pageCount - 1);
                  }}
                />
                <Pagination.Item>{pageCount}</Pagination.Item>
                <Pagination.Next
                  onClick={() => {
                    setPageCount(pageCount + 1);
                  }}
                />
                <Pagination.Last />
              </Pagination>
            </div>
          </Card.Body>
        </Card>
      </Container>
      <CreateSaleModal show={showCreateLink} setShow={setShowCreateLink} reloadData={getSalesLink} />
    </>
  );
}
