import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Form,
  Pagination,
  Row,
} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import toast from "react-hot-toast";
import CardBasic from "../../../components/Card/card.basic";
import CreateSessionModal from "../../../components/Modals/SessionModal";
import AllSessionTable from "../../../components/Table/Session";
import { AdminService } from "../../../services/admin.service";
import Select from "react-select";
import moment from "moment";

export default function Sessions() {
  const [showCreateLink, setShowCreateLink] = useState(false);
  const [tableData, setTableData] = useState<any>();
    const [pageCount, setPageCount] = useState<any>({ label: "20", value: 20 });
    const [status, setStatus] = useState<any>({
      label: "Scheduled",
      value: "scheduled",
    });
 const [dateRange, setDateRange] = useState({
   startDate: moment(new Date()).subtract("10", "days").toDate(),
   endDate: moment(new Date()).add("30", "days").toDate(),
 });
   
 console.log(status,'status')

  const getAllSessionData = async () => {
     const fromDate = moment(dateRange.startDate).format("YYYY-MM-DD");
     const toDate = moment(dateRange.endDate).format("YYYY-MM-DD");
    await AdminService.getAllSessions(
      pageCount.value,
      1,
      fromDate,
      toDate,
      status.value
    )
      .then((res: any) => {
        if (res.status === 200) {
          setTableData(res?.data);
        }
      })
      .catch((err: any) => {
        toast.error("Unable to fetch Session Details");
      });
  };

  useEffect(() => {
    getAllSessionData();
  }, [showCreateLink, pageCount,dateRange,status]);

  const [totalSessions, setTotalSessions] = useState<any>(tableData);

  const data = tableData?.map((date: any) => {
    return date?.date_time;
  });


  return (
    <>
      <Container>
        <Row>
          <Col>
            <CardBasic
              className="mt-3"
              body={
                <>
                  <h6>Filters</h6>
                  <Row>
                    <Col md={3}>
                      <Form.Label className="fw-bold text-secondary fs-12">
                        Date Range
                      </Form.Label>
                      <ReactDatePicker
                        onChange={(e: any) => {
                          setDateRange({
                            startDate: e[0],
                            endDate: e[1],
                          });
                        }}
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        selectsRange
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                      />
                    </Col>

                    <Col md={3}>
                      <Form.Label className="fw-bold text-secondary fs-12">
                        Page Count
                      </Form.Label>
                      <Select
                        options={[
                          { label: "20", value: 20 },
                          { label: "50", value: 50 },
                          { label: "100", value: 100 },
                          { label: "250", value: 250 },
                          { label: "500", value: 500 },
                          { label: "1000", value: 1000 },
                          { label: "1500", value: 1500 },
                        ]}
                        value={pageCount}
                        onChange={setPageCount}
                      />
                    </Col>
                    <Col md={3}>
                      <Form.Label className="fw-bold text-secondary fs-12">
                        Status
                      </Form.Label>
                      <Select
                        options={[
                          { label: "Scheduled", value: "scheduled" },
                          { label: "Completed", value: "completed" },
                          { label: "Flagged", value: "flagged" },
                          { label: "Requested", value: "requested" },
                          { label: "Cancelled", value: "cancelled" },
                        ]}
                        value={status}
                        onChange={setStatus}
                      />
                    </Col>
                  </Row>
                </>
              }
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <CardBasic
              body={
                <>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <Card.Title>Sessions</Card.Title>
                    <div>
                      <ButtonGroup size="sm">
                        <Button onClick={() => setShowCreateLink(true)}>
                          Create Session
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                  <AllSessionTable
                    tableData={tableData}
                    totalSessions={totalSessions ? totalSessions : tableData}
                    setTotalSessions={(sessions: any) =>
                      setTotalSessions(sessions)
                    }
                    reloadData={() => getAllSessionData()}
                    status={status?.value}
                  />
                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Pagination>
                      <Pagination.First />
                      <Pagination.Prev
                        onClick={() => {
                          setPageCount(pageCount - 1);
                        }}
                      />
                      <Pagination.Item>{pageCount}</Pagination.Item>
                      <Pagination.Next
                        onClick={() => {
                          setPageCount(pageCount + 1);
                        }}
                      />
                      <Pagination.Last />
                    </Pagination>
                  </div> */}
                </>
              }
            />
          </Col>
        </Row>
      </Container>
      <CreateSessionModal
        show={showCreateLink}
        setShow={setShowCreateLink}
        getAllSessionData={getAllSessionData}
        sessionType={"allSession"}
      />
    </>
  );
}
