import React from "react";
interface IHeading {
  heading: string
}
export default function Heading (props: IHeading) {
  return (
    <span
      className="fw-bold fs-grey"
      style={{ fontSize: "20px" }}
    >
      {props.heading}
    </span>
  );
};