import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";

interface IMyDropzone {
  onFileChange: any;
  files: any;
  height: any;
  isMulti: any;
  type: any;
  limit?: any;
}

const MyDropzone = ({
  onFileChange,
  files,
  height,
  isMulti,
  type,
  limit,
}: IMyDropzone) => {
  const [fileName, setFileName] = React.useState();

  const onDrop = React.useCallback(
    (acceptedFiles: any) => {
      if (acceptedFiles.length > 0) {
        if (acceptedFiles.some((item: any) => item.size > 100000000)) {
          toast.error(
            `Please upload a file which is less then 100MB. Files over that size are not allowed`
          );
          return;
        }
        if (isMulti) {
          setFileName(acceptedFiles.map((item: any) => item.path).join(", "));
          onFileChange([...files, ...acceptedFiles].flat());
        } else {
          setFileName(acceptedFiles[0].path);
          onFileChange([acceptedFiles[0]]);
        }
      } else {
        toast.error(
          `File submitted is not supported, please upload a file with following extensions: ${type}`
        );
      }
      // eslint-disable-next-line
    },
    [onFileChange]
  );

  useEffect(() => {
    setFileName(files.map((item: any) => item.path).join(", "));
  }, [files]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: type });

  const style: any = React.useMemo(() => {
    return {
      ...{ ...baseStyle, minHeight: height || "20vh" },
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    };
    // eslint-disable-next-line
  }, [isDragActive, isDragReject, isDragAccept]);

  return (
    <div className="form-group mb-0" {...getRootProps({ style })}>
      <input {...getInputProps()} className={`dropzonee`} />
      {isDragActive ? (
        <p className="mb-0">Drop the files here ...</p>
      ) : fileName ? (
        fileName
      ) : (
        <p className="mb-0">
          Drag 'n' drop some files here, or click to select files
        </p>
      )}
    </div>
  );
};

export default MyDropzone;

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  borderWidth: 1.4,
  borderRadius: 5,
  borderColor: "rgb(0, 0, 0, 0.4)",
  borderStyle: "dashed",
  backgroundColor: "#fff",
  color: "#12263F",
  outline: "none",
  transition: "border .24s ease-in-out",
  textAlign: "center",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};
