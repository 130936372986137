import SideModal from "../SideModal/SideModal";
import { UserRoleEnum } from "../../enum/role.enum";
import { useEffect, useState } from "react";
import { AdminService } from "../../services/admin.service";
import UserService from "../../services/user.service";
import { toast } from "react-hot-toast";
import StringHelper from "../Helper/string.helper";

interface IEditRoles {
  userId: string | undefined;
  setUserId: any;
}

export default function EditRoles(props: IEditRoles) {
  const [data, setData] = useState<any>([]);


  const getAdminById = async () => {
    const response = await UserService.getAdminById(props.userId);
    if (response.status === 200) {
      setData(response?.data);
    }
  };

  const handleRoleChange = async (
    roles: any,
    _id: any,
    e: any,
    chkrole: any
  ) => {
    const other_roles = roles.filter((role: any) => role.role != chkrole);
    let curr_role = roles.find((role: any) => role.role == chkrole);
    let new_current_role = {};
    if (curr_role) {
      new_current_role = { ...curr_role, allowed: e.target.checked };
    } else {
      new_current_role = {
        allowed: e.target.checked,
        role: chkrole,
      };
    }
    const new_roles = [...other_roles, new_current_role];
    await UserService.changeUserRole(new_roles, _id)

      .then((res: any) => {
        if (res.status === 200) {
          toast.success("Role Updated");
          getAdminById();
        }
      })
      .catch((err: any) => {
        toast.error("Something went wrong");
      });
  };


  useEffect(() => {
    if (props.userId) {
      getAdminById();
    }

  }, [props.userId]);

  return (
    <SideModal
      title="Edit Roles"
      show={props.userId ? true : false}
      handleClose={() => {
        props.setUserId(undefined);
      }}
      body={
        <>
          {Object.values(UserRoleEnum).map((role: any, index: any) => {
            return (
              <div className="form-check" key={index}>


                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={role}
                    checked={data?.adminRoles?.filter((data: any) => data?.role === role && data?.allowed === true).length > 0}
                    onChange={(e) => {
                      handleRoleChange(
                        data?.adminRoles,
                        data?._id,
                        e,
                        //small letter
                        role
                      );
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={role}
                  >
                    {StringHelper.capitalizeFirstLetter(role)}
                  </label>
                </div>
              </div>
            )
          })}
        </>
      }
    />
  );
}
