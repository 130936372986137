import { useState } from "react"
import { Button, Form } from "react-bootstrap"
import toast from "react-hot-toast"
import { AdminService } from "../../services/admin.service"
import SideModal from "../SideModal/SideModal"

interface IResetPassword {
    userId?: string
    setUserId: any
}

export default function ResetPassword(props: IResetPassword) {
    const [password, setPassword] = useState<string>();

    const onChangePasswordSubmit = async () => {
        if(!props.userId || !password) {
            toast.error("Password is a required field") 
            return false
        }

        await AdminService.changePassword(props.userId, password).then(res => {
            if(res.status === 200) {
                toast.success("Password Changed")
                props.setUserId(undefined);
                setPassword(undefined);
            }
        })
    }

    return (
        <SideModal
            show={props.userId ? true : false}
            handleClose={()=>props.setUserId(false)}
            title="Reset Password"
            body={<>
                <Form.Group>
                    <Form.Label className="fw-bold text-secondary fs-12">
                        New Password
                    </Form.Label>
                    <Form.Control type="password" name="password" onChange={(e) => setPassword(e.target.value)}/>
                </Form.Group>
                <Button variant="primary" size="sm" className="mt-3" onClick={onChangePasswordSubmit}>
                    Change Password
                </Button>
            </>}
        />
    )
}