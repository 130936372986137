import { Form, Image, Row } from "react-bootstrap";
import logo from "../../../assets/dhunguru_logo.svg";
import "./search.css";
import Courses from "../sections/Courses";
import Students from "../sections/Students";
import Instructors from "../sections/Instructors";
import Enrollments from "../sections/Enrollments";
import Invoices from "../sections/Invoices";
import Requests from "../sections/Requests";
import Sessions from "../sections/Sessions";
import Logs from "../sections/Logs";

interface ISearchResult {
  search: string;
  searchContent: any;
  searchKeyword: string;
}

export default function SearchResult(props: ISearchResult) {
  return (
    <>
      <div className="m-3 mt-5">
        {/* <Courses
                    courses={props.searchContent?.courses || []}
                    search={props.searchKeyword || ""}
                /> */}
        <Instructors
          instructors={props.searchContent?.instructors?.data || []}
          search={props.searchKeyword || ""}
        />
        <Students
          students={props.searchContent?.students || []}
          search={props.searchKeyword || ""}
        />
        <Enrollments
          enrollments={props.searchContent?.enrollments || []}
          search={props.searchKeyword || ""}
        />
        <Sessions
          sessions={props.searchContent?.sessions || []}
          search={props.searchKeyword || ""}
        />
        <Invoices
          invoices={props.searchContent?.invoices || []}
          search={props.searchKeyword || ""}
        />
        <Requests
          requests={props.searchContent?.requests || []}
          search={props.searchKeyword || ""}
        />

        {/* <Logs
                    logs={props.searchContent?.logs || []}
                    search={props.searchKeyword || ""}
                /> */}
      </div>
    </>
  );
}
