import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Tab, Tabs } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { AdminService } from "../../services/admin.service";
import Select from "react-select";
import { IcreateRazorpayLink } from "../../interfaces/razorpay.interface";
import toast from "react-hot-toast";
import { ArrayHelper } from "../../helpers/array.helper";
import DatePicker from "react-datepicker";
import moment from "moment";
import SideModal from "../SideModal/SideModal";


interface IUnblockUntillProps {
  show: boolean;
  setShow: any;
  enrollnmentId: any;
  getAllBlockedEnrollments: any;
  
}

export default function UnblockUntillModal(
  props: IUnblockUntillProps
) {

    const [date, setDate] = useState<any>(new Date());
    const [loading, setLoading] = useState(false);
  



   
    const handleClose =()=>{
        props.setShow(false);
    }

  const handleUnblockEnrollments = async (enrollmentId: any) => {
    setLoading(true);
   
    const payload={
      unblockTill: moment(date).toISOString()
    }

    await AdminService.unblockEnrollment(enrollmentId, payload).then((res) => {
      if (res.status === 200) {
        toast.success("Enrollment unblocked");
        props.getAllBlockedEnrollments();
        handleClose();
      }
    });
    setLoading(false);

  };
 



 
  return (
    <SideModal
      title={"Create Incentive"}
      show={props.show}
      handleClose={() => props.setShow(false)}
      body={
        <>
          
          
          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Unblock Untill
            </Form.Label>
            <DatePicker
              className="form-control"
              selected={date}
              onChange={(date: any) => setDate(date)}
              dateFormat="dd/MM/yyyy"
              placeholderText="Select Date"
            />
          </Form.Group>
          
          <Button className="mt-4" onClick={
                ()=>handleUnblockEnrollments(props.enrollnmentId)
          } disabled={loading}>
            Unblock
          </Button>
        </>
      }
    />
  );
}
