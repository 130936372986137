import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Outlet } from "react-router";
import { RolesContext } from "../../../../routes/private.context";
import UserService from "../../../../services/user.service";
import SideDrawer from "../../SideDrawer";
import classes from "./index.module.css";

const HomePage = () => {
  const [roles, setRoles] = useState<any>([]);
  const getUserRoles = async () => {
    await UserService.getUserAdminRoles()
      .then((res) => {
        if (res.status === 200) {
          setRoles(
            res?.data?.data
          );
        }
      })
      .catch((err) => {
        return toast.error(err);
      });
  };
  useEffect(() => {
    getUserRoles();
  }, []);

  return (
    <RolesContext.Provider value={{ roles, getUserRoles }}>
      <div className="d-flex justify-content-start ">
        <SideDrawer />
        <div className={classes.outlet}>
          <Outlet />
        </div>
      </div>
    </RolesContext.Provider>
  );
};

export default HomePage;
