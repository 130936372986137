import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Modal } from "react-bootstrap";

interface IPlayer {
  show: boolean;
  setShow: any;
  data: any;
}

const Player = ({ show, setShow, data }: IPlayer) => {
  const [url, setUrl] = useState<any>(undefined);

  useEffect(() => {
    if(data.length > 0){
      setUrl(data[0]._id);
    }
    else{
      setUrl(undefined);
    }
  },[show])
  console.log(data, "links");

  const getCurrentUrl = () => {
    const current_data = data.find((item: any) => item._id === url);
    if(current_data){
      let link = current_data.link;
      if(link.includes("https://")){
        return link;
      }
      else{
        return "https://"+link;
      }
    }
    else {
      return ""
    }
  }

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="model"
      fullscreen
    >
      <div className="modal-content">
        <div className="modal-body ">
          <div className="close pointer" onClick={() => setShow(false)}>
            &times;
          </div>
          <ReactPlayer
            config={{
              file: {
                attributes: {
                  disablepictureinpicture: "true",
                  // controlsList: "nodownload",
                },
              },
            }}
            onContextMenu={(e: any) => e.preventDefault()}
            url={
              getCurrentUrl()
            }
            controls={true}
            width={"100%"}
            height={"90%"}
            style={{ width: "100%", height: "100%" }}
          />

          {data.length > 1 && (
            <div className="text-center mt-4 mb-4" >
              <div className="btn-group btn-group-sm mt-3">
                {data.map((item: any, index: any) => (
                  <div
                    className={`btn btm-sm border btn-${
                      url === item._id ? "" : "outline-"
                    }primary`}
                    onClick={() => setUrl(item._id)}
                  >
                    {ordinal_suffix_of(index + 1)} video
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default Player;

function ordinal_suffix_of(i: any) {
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
}
