import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-hot-toast";
import CardBasic from "../../../../../components/Card/card.basic";
import JsonViewer from "../../../../../components/JsonViewer/Json.viewer";
import SideModal from "../../../../../components/SideModal/SideModal";
import { AdminService } from "../../../../../services/admin.service";
import InstructorService from "../../../../../services/instructor.service";

interface IInstructorAvailability {
  id: any;
  isEnrollment?: boolean;
}

const InstructorAvailability = ({
  id,
  isEnrollment,
}: IInstructorAvailability) => {
  const [cronsData, setCronsData] = useState<any>();
  const [kylasIdData, setKylasIdData] = useState<any>();
  const [showCronsModal, setShowCronsModal] = useState<boolean>(false);
  const [instructorProfile, setInstructorProfile] = useState<any>()

  const [editComissionShow, setEditComissionShow] = useState<boolean>(false);

  const getAllCrons = async () => {
    await AdminService.getAllCrons(id).then((res) => {
      if (res.status === 200) {
        setCronsData(res.data);
      }
    });
  };

  const getKylasId = async () => {
    await AdminService.getKylasId(id).then((res) => {
      if (res.status === 200) {
        setKylasIdData(res.data.data);
      }
    });
  };


  const getInstructorProfile = async () => {
    await InstructorService.getInstructorProfile(id).then((res) => {
      if(res.status === 200){
        setInstructorProfile(res.data.data);
      }
    })
  }

  const handleChange = (e:any) => {
    setInstructorProfile({...instructorProfile, [e.target.name]: e.target.value})
  }

  const saveCommission = async() => {
    await AdminService.updateCommssion(instructorProfile.user, instructorProfile.commission).then((res) => {
      if(res.status === 200){
        toast.success("Commission Updated Successfully");
        setEditComissionShow(false)
      }
    })
  }

  useEffect(() => {
    getAllCrons();
    getKylasId();
    getInstructorProfile()
  }, []);

  return (
    <>
      <CardBasic
        body={
          <>
            <div>
              <div className="fw-bold fs-grey fs-12">
                Next Session On: <span className="text-black">---</span>
              </div>
              {!isEnrollment && (
                <>
                  <div className="fw-bold fs-grey fs-12">
                    Kylas Id:{" "}
                    <span className="text-black">
                      {kylasIdData && kylasIdData[0]?.kylasId
                        ? kylasIdData[0]?.kylasId
                        : "NA"}
                    </span>
                  </div>
                  <div className="fw-bold fs-grey fs-12">
                    Cron(s) Active:{" "}
                    <span
                      className="fst-italic text-primary fs-12 fs-underline cursor-pointer"
                      onClick={() => setShowCronsModal(true)}
                    >
                      {cronsData &&
                        cronsData.data &&
                        cronsData.data.filter(
                          (cron: any) => cron.completed === false
                        ).length}
                    </span>
                  </div>
                  <div className="fw-bold fs-grey fs-12">
                    Sessions for Today: <span className="text-black">---</span>
                  </div>

                  <div className="fw-bold fs-grey fs-12">
                    Dhunguru Commission: <span className="text-black">{instructorProfile && instructorProfile.commission ? instructorProfile.commission :  "No Value Set - Default"}</span>
                    <FontAwesomeIcon icon={faPen} className="text-muted cursor-pointer ms-1" onClick={() => setEditComissionShow(true)}/>
                  </div>
                </>
              )}
            </div>
          </>
        }
      />

      <SideModal
          show={editComissionShow}
          handleClose={ () => setEditComissionShow(false)}
          body={
            <>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="fw-bold h6 text-secondary">Commission</Form.Label>
              <Form.Control type="number" placeholder="Enter Commission" min={0} max={100} name="commission" value={instructorProfile && instructorProfile.commission} onChange={handleChange}/>
            </Form.Group>
            <Button className="mt-2" onClick={saveCommission}>
              Save
            </Button>
            </>
          }
          title={"Edit Dhunguru Commission"}
      />

      <JsonViewer
        show={showCronsModal}
        setShow={setShowCronsModal}
        jsonData={
          cronsData &&
          cronsData.data &&
          cronsData.data.filter((cron: any) => cron.completed === false)
        }
      />
    </>
  );
};

export default InstructorAvailability;
