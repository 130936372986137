import { useLocation, useParams } from "react-router";
import Breadcrumbs from "../../../../global/Breadcrumbs";
import { Col, Container, Row } from "react-bootstrap";
import InstructorDetail from "../Instructor/InstructorDetail";
import InstructorAvailability from "../Instructor/InstructorAvailability";
import StudentDetail from "../Student/StudentDetail";
import ControlbarEnrollment from "../../../../components/ControlBar/ControlbarEnrollment";
import EnrollmentDetailCard from "./EnrollmentDetailCard";
import { AdminService } from "../../../../services/admin.service";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import EnrollmentDetailData from "./EnrollmentDetailTable";

export default function EnrollmentDetail() {
  const [data, setData] = useState<any>();
  const params: any = useParams();
  const { state }: any = useLocation();
  const { instId, studentId } = state || {};
  const id = params.id;

  const getEnrollmentDetail = async () => {
    await AdminService.getEnrollmentDetail({ id })
      .then((res) => setData(res?.data?.enrollmentDetails?.enrollment))
      .catch((err) => {
        toast.error("Unable to get Enrollment Detail");
      });
  };

  useEffect(() => {
    getEnrollmentDetail();
  }, []);

  return (
    <>
      <div className="m-4">
        <Container>
          <Breadcrumbs
            links={[
              {
                url: "/admin/search",
                name: "Home",
                active: false,
              },
              {
                url: "#",
                name: "Enrollment",
                active: true,
              },
            ]}
          />
          <Row className="mt-4">
            <Col md={8}>
              <InstructorDetail
                id={instId}
                title={"Instructor"}
                isEnrollment={true}
              />
            </Col>
            <Col md={4}>
              <InstructorAvailability id={instId} isEnrollment={true} />
              <div className="mt-4">
                <ControlbarEnrollment
                  instructorId={instId}
                  enrollmentId={data?.enrollmentId}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={12}>
              <StudentDetail
                isEnrollment={true}
                studentId={studentId}
                title={"Student"}
              />
            </Col>
          </Row>
          {data?.type === "paid" && (
            <Row className="mt-4">
              <Col md={12}>
                <EnrollmentDetailCard
                  enrollmentId={data.enrollmentId}
                  tableData={data}
                />
              </Col>
            </Row>
          )}
          <Row className="mt-4">
            <Col md={12}>
              <EnrollmentDetailData enrollmentId={id} />
            </Col>
          </Row>

          {/* <Row className="mt-4">
            <Col>
              <InstructorSessions id={params.id} />
            </Col>
          </Row> */}
        </Container>
      </div>
    </>
  );
}
