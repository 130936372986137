import React from "react";
import { Button, Modal } from "react-bootstrap";
import "./Modal.css";
interface IDeleteConfirmation {
  showModal: any;
  hideModal: any;
  confirmModal: any;
  id: any;
  type: any;
  message: any;
}

const modalStyle = {
  width: "600px",
  height: "250px",
  margin: "20% 34%",
};

const DeleteConfirmation = ({
  showModal,
  hideModal,
  confirmModal,
  id,
  type,
  message,
}: IDeleteConfirmation) => {
  return (
    <Modal
      show={showModal}
      onHide={hideModal}
      size="lg"
      style={{ ...modalStyle }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="alert alert-danger">{message}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={hideModal}>
          Cancel
        </Button>
        <Button variant="danger" onClick={() => confirmModal(type, id)}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirmation;
