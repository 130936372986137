import { Badge, Dropdown, Table } from "react-bootstrap";
import { BiDotsVertical } from "react-icons/bi";
import { IoSettingsOutline } from "react-icons/io5";
import { useNavigate } from "react-router";
import CustomToggle from "../Menu/CustomMenu";
import { NormalText } from "../Text/TableText.formats";
import "./table.css";
import { FaExchangeAlt } from "react-icons/fa";
import { AdminService } from "../../services/admin.service";
import toast from "react-hot-toast";
import CreateSaleItem from "../Modals/CreateSaleItem.modal";
import { useState } from "react";
import { log } from "console";

interface IRazorPay {
  tableData: any;
  reloadData: any;
}

export default function SalesItemsTable({ tableData, reloadData }: IRazorPay) {
  const navigate = useNavigate();
  console.log(tableData);

  const [updateData, setUpdateData] = useState<any>({});

  async function changeAvailability(data: any) {
    await AdminService.changeProductAvailability({
      itemCode: data.itemCode,
      isAvailable: !data.isAvailable,
    })
      .then((res) => {
        if (res.status === 200) {
          reloadData();
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error: Something Went Wrong");
      });
  }

  return (
    <>
      <Table striped borderless hover responsive size="sm">
        <thead>
          <tr className="dhun-table-header text-secondary">
            <th>#</th>
            <th>Item Code</th>
            <th>Item Name</th>
            <th>Item Type</th>
            <th>Item Descprition</th>
            <th>Item Price</th>
            <th>Availability</th>
            <th>HSN Code</th>
            <th>SGST</th>
            <th>CGST</th>
            <th>IGST</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tableData && tableData.length > 0 ? (
            tableData.map((data: any, index: any) => {
              return (
                <tr>
                  <td>
                    <NormalText value={index + 1} />
                  </td>
                  <td>{data.itemCode ? data.itemCode : "N.A."}</td>
                  <td>{data.itemName ? data.itemName : "N.A."}</td>
                  <td>{data.itemType ? data.itemType : "N.A."}</td>
                  <td>
                    {data.itemDescription ? data.itemDescription : "N.A."}
                  </td>
                  <td>{data.itemPrice ? data.itemPrice : "N.A."}</td>
                  <td>
                    {data.isAvailable ? (
                      <Badge
                        bg="success"
                        className="me-1 fst-italic fs-12 cursor-pointer"
                      >
                        Available
                      </Badge>
                    ) : (
                      <Badge
                        bg="danger"
                        className="me-1 fst-italic fs-12 cursor-pointer"
                      >
                        Unavailable
                      </Badge>
                    )}
                  </td>
                  <td>{data.hsnCode ? data.hsnCode : "N.A."}</td>
                  <td>{data?.gst?.sgst ? data.gst.sgst : "0"}</td>
                  <td>{data?.gst?.cgst ? data.gst.cgst : "0"}</td>
                  <td>{data?.gst?.igst ? data.gst.igst : "0"}</td>

                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        as={CustomToggle}
                        id="dropdown-custom-components"
                      >
                        <BiDotsVertical />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          className="fs-12 align-items-center d-flex fw-bold"
                          onClick={() => setUpdateData(data)}
                        >
                          <FaExchangeAlt className="text-success me-1" /> Update
                        </Dropdown.Item>

                        <Dropdown.Item
                          className="fs-12 align-items-center d-flex fw-bold"
                          onClick={() => {
                            changeAvailability(data);
                          }}
                        >
                          <IoSettingsOutline className="text-success me-1" />{" "}
                          Make {data.isAvailable ? "Unavailable" : "Available"}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr className="text-center">
              <td colSpan={16}>No Data Found</td>
            </tr>
          )}
        </tbody>
      </Table>
      <CreateSaleItem
        show={updateData.itemCode ? true : false}
        setShow={() => setUpdateData({})}
        data={updateData}
        reloadData={reloadData}
        isUpdate={true}
      />
    </>
  );
}
