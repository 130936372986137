import React, { useEffect } from "react";
import { useParams } from "react-router";
import Breadcrumbs from "../../../../global/Breadcrumbs";
import StudentDetail from "./StudentDetail";
import StudentTables from "./StudentTables";

const Student = () => {
  const params: any = useParams();

  useEffect(() => { }, []);

  return (
    <>
    <div className="m-4">
      <Breadcrumbs
        links={[
          {
            url: "/admin/search",
            name: "Home",
            active: false
          },
          {
            url: "#",
            name: "Student",
            active: true
          }
        ]}
      />

      <StudentDetail studentId={params.id} />
      <StudentTables id={params.id} />
      </div>
    </>
  );
};

export default Student;
