import React from "react";
import { useNavigate } from "react-router";
import CardBasic from "../../../components/Card/card.basic";
import { Image } from "react-bootstrap";
import { BiMailSend, BiPhone } from "react-icons/bi";

interface IStudents {
  search: string;
  students: any[]
}

export default function Students(props: IStudents) {
  const navigate = useNavigate();

  return (
    <>
      {props.students.length > 0 ?
        <>
          <div className="d-flex justify-content-between align-items-center my-4">
            <h5 className="fw-bold fs-grey">Students</h5>
          </div>

          <div className="d-flex justify-content-start overflow-x-scroll">
            {
              props.students && props.students.length > 0 && props.students.map((student: any) => {
                return (<>
                  <div className="mx-1 p-3 cursor-pointer">
                    <CardBasic
                      body={
                        <div onClick={() =>
                          navigate(
                            `/admin/search/student/detail/${student?._id}`
                          )
                        }>
                          <div className="d-flex justify-content-between">
                            <Image
                              src={student.avatar || "NA"}
                              className="img-small-circled"
                            />
                            <span className="fs-10 fs-dhunprimary fw-bold">
                              ID: {student?._id || "NA"}
                            </span>
                          </div>

                          <div className="mt-2">
                            <span className="fs-grey fw-bold">
                              {student?.firstName || "NA"} {""}
                              {student?.lastName || "NA"}
                            </span>
                          </div>

                          <div className="fs-10 text-muted">
                            <div>
                              <BiMailSend /> {student.email}
                            </div>
                            <div>
                              <BiPhone /> {student.phone}
                            </div>
                          </div>
                        </div>
                      }
                    />
                  </div>
                </>)
              })
            }
          </div>
        </> : " "
      }
    </>
  )

}