import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import toast from "react-hot-toast";
import SideModal from "../SideModal/SideModal";
import { AdminService } from "../../services/admin.service";
import { setSeconds } from "date-fns";

interface ICreateSaleModal {
  show: boolean;
  setShow: any;
  data?: any;
  isUpdate?: boolean;
  reloadData: any;
}

const IGST_OPTIONS = [
  { value: 0, label: "0%" },
  { value: 5, label: "5%" },
  { value: 12, label: "12%" },
  { value: 18, label: "18%" },
  { value: 28, label: "28%" },
];

const CGST_OPTIONS = [
  { value: 0, label: "0%" },
  { value: 2.5, label: "2.5%" },
  { value: 6, label: "6%" },
  { value: 9, label: "9%" },
  { value: 14, label: "14%" },
];

const SGST_OPTIONS = [
  { value: 0, label: "0%" },
  { value: 2.5, label: "2.5%" },
  { value: 6, label: "6%" },
  { value: 9, label: "9%" },
  { value: 14, label: "14%" },
];

export default function CreateSaleItem(props: ICreateSaleModal) {
  const [loading, setLoading] = useState<boolean>(false);
  const [igst, setIgst] = useState<any>(undefined);
  const [cgst, setCgst] = useState<any>(undefined);
  const [sgst, setSgst] = useState<any>(undefined);

  const itemTypes = [
    { value: "Guitar", label: "Guitar" },
    { value: "Piano", label: "Piano" },
    { value: "Drums", label: "Drums" },
    { value: "Keyboard", label: "Keyboard" },
    { value: "Violin", label: "Violin" },
    { value: "Flute", label: "Flute" },
    { value: "Vocal", label: "Vocal" },
  ];
  const [itemType, setItemType] = useState<any>();
  const [itemDetails, setItemDetails] = useState({
    itemCode: "",
    itemDescription: "",
    itemName: "",
    itemPrice: 0,
    itemType: "",
    hsnCode: "",
  });

  function handleChange(e: any) {
    const { name, value } = e.target;
    setItemDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  async function onSubmit() {
    itemDetails.itemType = itemType.value;

    if (
      itemDetails.itemCode === "" ||
      itemDetails.itemDescription === "" ||
      itemDetails.itemName === "" ||
      itemDetails.itemType === "" ||
      !cgst ||
      !sgst ||
      !igst
    ) {

      toast.error("Please fill all the fields");
      return;
    }
    if (itemDetails.itemPrice === 0) {
      toast.error("Price cannot be zero");
      return;
    }

    if (props.isUpdate) {
      handleUpdate();
      return;
    }

    setLoading(true);

    await AdminService.createSaleItem({...itemDetails, gst: {
      igst: igst?.value,
      cgst: cgst?.value,
      sgst: sgst?.value,
    }})
      .then((res: any) => {
        if (res.status === 200) {
          toast.success(res.data.message);
          handleModalClose();
          props.reloadData();
        }
      })
      .catch((err: any) => {
        console.log(err);
        toast.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function handleUpdate() {
    itemDetails.itemType = itemType.value;
    setLoading(true);
    await AdminService.updateSalesItem({...itemDetails,  gst: {
      igst: igst?.value,
      cgst: cgst?.value,
      sgst: sgst?.value,
    }})
      .then((res: any) => {
        if (res.status === 200) {
          toast.success(res.data.message);
          props.reloadData();
          handleModalClose();
        }
      })
      .catch((err: any) => {
        console.log(err);
        toast.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function handleModalClose() {
    setItemDetails({
      itemCode: "",
      itemDescription: "",
      itemName: "",
      itemPrice: 0,
      itemType: "",
      hsnCode: "",
    });
    setIgst(undefined);
    setCgst(undefined);
    setSgst(undefined);
    props.setShow();
  }
  useEffect(() => {
    if (props.isUpdate) {
      setItemDetails(props.data);
    }
  }, [props.data]);


  return (
    <SideModal
      title={"New Product"}
      show={props.show}
      handleClose={handleModalClose}
      body={
        <>
          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Item Code
            </Form.Label>
            <Form.Control
              type="text"
              name="itemCode"
              value={itemDetails.itemCode}
              onChange={(e: any) =>
                setItemDetails({
                  ...itemDetails,
                  itemCode: e.target?.value?.toUpperCase(),
                })
              }
            />
          </Form.Group>

          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Item Name
            </Form.Label>
            <Form.Control
              type="text"
              name="itemName"
              value={itemDetails.itemName}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Description
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="itemDescription"
              value={itemDetails.itemDescription}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Price (Excluding GST)
            </Form.Label>
            <Form.Control
              type="number"
              name="itemPrice"
              value={itemDetails.itemPrice}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              HSN Code
            </Form.Label>
            <Form.Control
              type="text"
              name="hsnCode"
              value={itemDetails.hsnCode}
              onChange={(e: any) =>
                setItemDetails({
                  ...itemDetails,
                  hsnCode: e.target?.value?.toUpperCase(),
                })
              }
            />
          </Form.Group>

          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              IGST
            </Form.Label>
            <Select
              options={IGST_OPTIONS}
              value={igst}
              onChange={(selectedOption) => setIgst(selectedOption)}
            />
          </Form.Group>

          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              CGST
            </Form.Label>
            <Select
              options={CGST_OPTIONS}
              value={cgst}
              onChange={(selectedOption) => setCgst(selectedOption)}
            />
          </Form.Group>

          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              SGST
            </Form.Label>
            <Select
              options={SGST_OPTIONS}
              value={sgst}
              onChange={(selectedOption) => setSgst(selectedOption)}
            />
          </Form.Group>

          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Item Type
            </Form.Label>
            <Select
              onChange={setItemType}
              options={itemTypes}
              value={itemType}
            />
          </Form.Group>

          {/* <Form.Group className="mt-2">
                        <Form.Label className="fw-bold text-secondary fs-12">
                            Select Product
                        </Form.Label>
                        <Select
                            // isMulti
                            isClearable
                        />
                    </Form.Group> */}

          <Button className="mt-3 w-100" onClick={onSubmit} disabled={loading}>
            {props.isUpdate ? "Save" : "Add New Item"}
          </Button>
        </>
      }
    />
  );
}
