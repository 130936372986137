import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal } from "react-bootstrap";
import CenterModal from "../CenterModal/CenterModal";
import CustomModal from "./CustomModal";
import { useRef } from "react";

interface IPlayRecordingModal {
  show: any;
  setShow: any;
}
interface AudioRefType {
  pause: () => void;
  currentTime: number;
}
export default function PlayRecordingModal({
  show,
  setShow,
}: IPlayRecordingModal) {
  console.log(show);
  const audioRef = useRef<any>();

  const handleClose = () => {
    setShow(false);
    // stop playing audio
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    };
  };



  return (
    <CenterModal
      title={"Recording"}
      show={show ? true : false}
      handleClose={handleClose}
      body={
        <>
          <div>

            <a href={show} target="_blank">
              Download Recording
            </a>

            <p className="p-3 text-muted fw-bold">
              Click to play recording
            </p>
            <audio ref={audioRef} controls>
              {show ?
                <>
                  <source src={show} type="audio/mp3" />
                </>
                : <>
                  <h6>
                    Nothing to play
                  </h6>
                </>}
            </audio>
          </div>
        </>
      }
    />
  );
}
