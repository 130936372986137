import { Box, Button, Card, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import DoneIcon from "@mui/icons-material/Done";
import CardBasic from "../../../components/Card/card.basic";
import { Image } from "react-bootstrap";
import { IoMailOpen, IoPhoneLandscape } from "react-icons/io5";
import { BiMailSend, BiPhone } from "react-icons/bi";
import SearchSectionHeader from "../../../components/Headers/SearchSectionHeader";

function Instructors(props: any) {
  const navigate = useNavigate();

  const instructors = props.instructors;
  const search = props.search;

  console.log(instructors);

  return (
    <>
      {instructors?.length > 0 ? (
        <>
          <SearchSectionHeader text="Instructors" />
          <div className="d-flex justify-content-start overflow-x-scroll">
            {instructors.map((inst: any, index: number) => {
              return (
                <div className="mx-1 p-3 cursor-pointer">
                  <CardBasic
                    body={
                      <div
                        onClick={() => {
                          navigate(
                            `/admin/search/instructor/detail/${
                              inst?.user?._id || "NA"
                            }`
                          );
                        }}
                      >
                        <div className="d-flex justify-content-between">
                          <Image
                            src={inst?.user?.avatar || "NA"}
                            className="img-small-circled"
                          />
                          <span className="fs-10 fs-dhunprimary fw-bold">
                            ID: {inst?.user?._id || "NA"}
                          </span>
                        </div>

                        <div className="mt-2">
                          <span className="fs-grey fw-bold">
                            {inst?.user?.firstName || "NA"} {""}
                            {inst?.user?.lastName || "NA"}
                          </span>
                        </div>
                        <div className="fs-10 text-muted">
                          <div>
                            <BiMailSend /> {inst?.user?.email}
                          </div>
                          <div>
                            <BiPhone /> {inst?.user?.phone}
                          </div>
                        </div>
                      </div>
                    }
                  />
                </div>
              );
            })}
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default Instructors;
