import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { orange } from "@mui/material/colors";
import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router";
import CardBasic from "../../../components/Card/card.basic";
import SearchSectionHeader from "../../../components/Headers/SearchSectionHeader";
import HighlightString from "../HighLighting";
import classes from "./index.module.css";

const textStyle = {
  display: "-webkit-box",
  maxWidth: "300px",
  webkitLineClamp: "1",
  webkitBoxOrient: "vertical",
  overflow: "hidden",
};

function Enrollments(props: any) {
  const enrollments = props.enrollments;
  const search = props.search;
  const navigate = useNavigate();

  return (
    <>
      {enrollments?.length > 0 ? (
        <>
          <SearchSectionHeader text="Enrollments" />
          <div className="d-flex justify-content-start overflow-x-scroll">
            {enrollments.map((enrolled: any, index: number) => {
              return (
                <div className="mx-1 p-3 cursor-pointer">
                  <CardBasic
                    body={
                      <div
                        onClick={() =>
                          navigate(
                            `/admin/search/enrollment/detail/${enrolled?._id}`,
                            {
                              state: {
                                instId: enrolled?.instructor?._id,
                                studentId: enrolled?.student?._id,
                              },
                            }
                          )
                        }
                      >
                        <div className="d-flex justify-content-between">
                          <div className="fw-bold fs-12">
                            {enrolled.type === "demo" ? (
                              <span className="text-muted">demo</span>
                            ) : (
                              <span className="fs-dhunprimary">paid</span>
                            )}
                          </div>
                          <div className="fw-bold fs-12 fs-primary">
                            {enrolled.enrollmentId}
                          </div>
                        </div>
                        <div className="mt-3">
                          <span
                            className="h5 fw-bold text-grey"
                            style={{ ...textStyle }}
                          >
                            {enrolled && enrolled?.course ? enrolled?.course.title : "--"}
                          </span>
                        </div>
                        <div>
                          <span className="fs-grey fs-12">
                            By{" "}
                            {enrolled?.instructor.firstName +
                              " " +
                              enrolled?.instructor.lastName || "--"}
                          </span>
                        </div>
                        <div>
                          <span className="fs-primary fs-12">
                            Student:{" "}
                            {enrolled?.student.firstName +
                              " " +
                              enrolled?.student.lastName || "--"}
                          </span>
                        </div>
                        <div className="fs-dhunprimary fs-10">
                          Sessions left : {enrolled?.classes_left || "0"}
                        </div>
                      </div>
                    }
                  />
                </div>
              );
            })}
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default Enrollments;
