import React, { useState, useEffect, useCallback } from "react";
import instructor_content from "../../../../../Content/instructor_content.json";
import { BsStarHalf, BsStarFill } from "react-icons/bs";
import { FaQuoteLeft } from "react-icons/fa";
import ImageViewer from "react-simple-image-viewer";
import { AiFillDelete, AiFillPlayCircle } from "react-icons/ai";
import ReactPlayer from "react-player";
import { Modal } from "react-bootstrap";
import Review from "./Review";
import Swal from "sweetalert2";
import MyDropzoneImage from "../../../../../components/Dropzone/MyDropzoneImage";
import MyDropzone from "../../../../../components/Dropzone/MyDropzone";
import "../../../../../assets/css/dashborad.css";
import "../../../../../assets/css/custom.css";

interface IBasics {
  setCourse: any;
  course: any;
  headerImg: any;
  setHeaderImg: any;
  saveCourse: any;
  loading: boolean;
  videos: any;
  setVideos: any;
  photos: any;
  setPhotos: any;
}

interface ICourseImage {
  headerImg: any;
  setHeaderImg: any;
  course: any;
  errors: any;
  setErrors: any;
}

interface ICourseDetails {
  course: any;
  setCourse: any;
  errors: any;
  setErrors: any;
}

const Basics = ({
  setCourse,
  course,
  headerImg,
  setHeaderImg,
  saveCourse,
  loading,
  videos,
  setVideos,
  photos,
  setPhotos,
}: IBasics) => {
  const [errors, setErrors] = useState(Array(6).fill(false));

  useEffect(() => {
    setErrors(Array(6).fill(false));
  }, [
    course.instrument,
    course.title,
    course.details,
    photos,
    videos,
    course.headerImg,
    course.photos,
    course.videos,
  ]);

  const validate = () => {
    const new_errors = Array(6).fill(false);
    if (course.details === "") new_errors[1] = true;
    if (course.title === "") new_errors[2] = true;
    if (!course.headerImg && (!headerImg || headerImg.length === 0))
      new_errors[3] = true;
    if (course.photos.length + photos.length > 5) new_errors[4] = true;
    if (course.videos.length + videos.length > 1) new_errors[5] = true;
    setErrors(new_errors);
    if (new_errors.includes(true)) return false;
    else return true;
  };

  const save = async (e: any) => {
    e.preventDefault();
    if (!validate()) return;
    saveCourse();
  };

  return (
    <div>
      <div className="mb-6">
        <CourseLevel
          course={course}
          setCourse={setCourse}
          errors={errors}
          setErrors={setErrors}
        />
        <CourseDetails
          course={course}
          setCourse={setCourse}
          errors={errors}
          setErrors={setErrors}
        />
        <CourseImage
          headerImg={headerImg}
          setHeaderImg={setHeaderImg}
          course={course}
          errors={errors}
          setErrors={setErrors}
        />
        <CourseMapping course={course} setCourse={setCourse} />
        <Images
          photos={photos}
          setPhotos={setPhotos}
          course={course}
          setCourse={setCourse}
          errors={errors}
          setErrors={setErrors}
        />
        <Videos
          videos={videos}
          setVideos={setVideos}
          course={course}
          setCourse={setCourse}
          errors={errors}
          setErrors={setErrors}
        />
        <Reviews data={course} setCourse={setCourse} />
      </div>

      <div className="row align-items-center mb-5">
        <div className="col-4">
          <button
            className="btn btn-block btn-lg btn-primary mb-6"
            disabled={loading}
            onClick={(e) => save(e)}
          >
            {loading && (
              <span className="spinner-border spinner-border-sm mr-2"></span>
            )}
            Save
          </button>
        </div>
        <div className="col-auto"></div>
      </div>
    </div>
  );
};

export default Basics;

interface ICourseLevel {
  course: any;
  setCourse: any;
  errors: any;
  setErrors: any;
}

const CourseLevel = ({
  course,
  setCourse,
  errors,
  setErrors,
}: ICourseLevel) => {
  return (
    <div className="">
      <div className="form-group mb-5 pb-2">
        <label className="bold mb-1">
          {instructor_content.course.basics.subject_title.title}
        </label>
        <div className="text-muted small mb-3">
          {instructor_content.course.basics.subject_title.description}
        </div>
        <input
          className={errors[2] ? "form-control is-invalid" : "form-control"}
          placeholder="Introduction to Piano"
          value={course.title}
          onChange={(e) => {
            setErrors((prev: any) => [prev[0], prev[1], false]);
            setCourse((prev: any) => ({ ...prev, title: e.target.value }));
          }}
        />
        <div className="invalid-feedback">
          {instructor_content.course.basics.subject_title.error}
        </div>
      </div>
      <div className="form-group mb-5 pb-2">
        <label className="bold mb-1">
          {instructor_content.course.basics.comfort_level.title}
        </label>
        <div className="text-muted small mb-3">
          {instructor_content.course.basics.comfort_level.description}
        </div>
        <div className="row align-items-center mb-3">
          <div className="col-4 col-lg-2">Beginner</div>
          <div className="col-5 col-lg-3">
            <select
              className="form-control form-control-sm"
              value={course.level.beginner}
              onChange={(e) => {
                setCourse((prev: any) => ({
                  ...prev,
                  level: { ...prev.level, beginner: e.target.value },
                }));
              }}
            >
              <option value="Very Comfortable">Very Comfortable</option>
              <option selected value="Comfortable">
                Comfortable
              </option>
              <option value="Not Comfortable">Not Comfortable</option>
            </select>
          </div>
        </div>
        <div className="row align-items-center mb-3">
          <div className="col-4 col-lg-2">Intermediate</div>
          <div className="col-5 col-lg-3">
            <select
              className="form-control form-control-sm"
              value={course.level.intermediate}
              onChange={(e) =>
                setCourse((prev: any) => ({
                  ...prev,
                  level: { ...prev.level, intermediate: e.target.value },
                }))
              }
            >
              <option value="Very Comfortable">Very Comfortable</option>
              <option selected value="Comfortable">
                Comfortable
              </option>
              <option value="Not Comfortable">Not Comfortable</option>
            </select>
          </div>
        </div>
        <div className="row align-items-center mb-3">
          <div className="col-4 col-lg-2">Advanced</div>
          <div className="col-5 col-lg-3">
            <select
              className="form-control form-control-sm"
              value={course.level.advance}
              onChange={(e) =>
                setCourse((prev: any) => ({
                  ...prev,
                  level: { ...prev.level, advance: e.target.value },
                }))
              }
            >
              <option value="Very Comfortable">Very Comfortable</option>
              <option selected value="Comfortable">
                Comfortable
              </option>
              <option value="Not Comfortable">Not Comfortable</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

const CourseDetails = ({
  course,
  setCourse,
  errors,
  setErrors,
}: ICourseDetails) => {
  return (
    <div className="">
      <div className="form-group mb-5 pb-2">
        <label className="bold mb-1">
          {instructor_content.course.basics.details.title}
        </label>
        <div className="text-muted small mb-3">
          {instructor_content.course.basics.details.description}
        </div>
        <textarea
          className={errors[1] ? "form-control is-invalid" : "form-control"}
          placeholder={instructor_content.course.basics.details.placeholder}
          style={{ minHeight: "150px" }}
          value={course.details}
          onChange={(e) => {
            setErrors((prev: any) => [prev[0], false]);
            setCourse((prev: any) => ({ ...prev, details: e.target.value }));
          }}
        ></textarea>
        <div className="invalid-feedback ">
          {instructor_content.course.basics.details.error}
        </div>
      </div>
    </div>
  );
};

const CourseImage = ({
  headerImg,
  setHeaderImg,
  course,
  errors,
  setErrors,
}: ICourseImage) => {
  return (
    <div className="">
      <div className="row mb-5 pb-2">
        <div className="col-8">
          <div className="form-group">
            <label className="bold mb-1">
              {instructor_content.course.basics.image.title}
            </label>
            <div className="text-muted small mb-3">
              {instructor_content.course.basics.image.description}
            </div>
            <img src={course.headerImg} className="img-fluid" alt="" />
          </div>
        </div>
        <div className="col-3 mt-5">
          <div style={{ width: "200px" }}>
            <MyDropzoneImage
              file={headerImg}
              onFileChange={(file: any) => setHeaderImg(file)}
              height="200px"
            />
          </div>
        </div>
        {errors[3] && (
          <div className="invalid-feedback d-block">
            {instructor_content.course.basics.image.error}
          </div>
        )}
      </div>
    </div>
  );
};

interface ICourseMapping {
  course: any;
  setCourse: any;
}
const CourseMapping = ({ course, setCourse }: ICourseMapping) => {
  return (
    <div>
      <div className="form-group mb-5 pb-2">
        <label className="bold mb-1">
          {instructor_content.course.basics.speciality.title}
        </label>
        <div className="text-muted small mb-3">
          {instructor_content.course.basics.speciality.description}
        </div>
        <input
          className="form-control"
          value={course.speciality}
          placeholder={instructor_content.course.basics.speciality.placeholder}
          onChange={(e) =>
            setCourse((prev: any) => ({ ...prev, speciality: e.target.value }))
          }
        />
      </div>
    </div>
  );
};

interface IImages {
  photos: any;
  setPhotos: any;
  course: any;
  setCourse: any;
  errors: any;
  setErrors: any;
}

const Images = ({
  photos,
  setPhotos,
  course,
  setCourse,
  errors,
  setErrors,
}: IImages) => {
  const deleteImg = (index: any) => {
    const newPhotos = [...photos];
    newPhotos.splice(index, 1);
    setPhotos(newPhotos);
  };

  const deleteSavedImage = (index: any) => {
    const newPhotos = [...course.photos];
    newPhotos.splice(index, 1);
    setCourse((prev: any) => ({ ...prev, photos: newPhotos }));
  };

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index: any) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <div className="form-group mb-6 mt-6 pb-2">
      <label className="bold mb-1">
        {instructor_content.course.basics.photos.title}
      </label>
      <div className="text-muted small mb-3">
        {instructor_content.course.basics.photos.description}
      </div>
      <MyDropzone
        files={photos}
        onFileChange={(data: any) => setPhotos(data)}
        height="100px"
        isMulti={true}
        type="image/jpeg, image/png"
      />
      <div className="row no-gutters mt-4">
        {course.photos &&
          course.photos.length > 0 &&
          course.photos.map((item: any, index: any) => (
            <div className="col-auto pr-3">
              <div className="d-flex align-items-start">
                <img
                  alt=""
                  className="rounded"
                  src={item.url}
                  style={{ height: "50px", width: "50px", objectFit: "cover" }}
                  onClick={() => openImageViewer(index)}
                />
                <div
                  className="close1 mt-n3"
                  onClick={() => deleteSavedImage(index)}
                >
                  &times;
                </div>
              </div>
            </div>
          ))}
        {photos.map((item: any, index: any) => (
          <div className="col-auto pr-2">
            <div className="d-flex align-items-start">
              <img
                alt=""
                className="rounded"
                src={URL.createObjectURL(item)}
                style={{ height: "50px", width: "50px", objectFit: "cover" }}
              />
              <div className="close1 mt-n3" onClick={() => deleteImg(index)}>
                &times;
              </div>
            </div>
          </div>
        ))}
      </div>
      {errors[4] && (
        <div className="invalid-feedback d-block mt-4">
          You cant add more than 5 photos
        </div>
      )}
      {isViewerOpen && (
        <ImageViewer
          src={course.photos.map((item: any) => item.url)}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
            zIndex: "1000122987349283",
          }}
        />
      )}
    </div>
  );
};

interface IVideos {
  videos: any;
  setVideos: any;
  course: any;
  setCourse: any;
  errors: any;
  setErrors: any;
}
const Videos = ({
  videos,
  setVideos,
  course,
  setCourse,
  errors,
  setErrors,
}: IVideos) => {
  const [isOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState();
  const playVideo = (id: any) => {
    setUrl(id);
    setIsOpen(true);
  };

  const deleteVideos = (index: any) => {
    const newVideos = [...videos];
    newVideos.splice(index, 1);
    setVideos(newVideos);
  };

  const deleteSavedVideos = (index: any) => {
    const newVideos = [...course.videos];
    newVideos.splice(index, 1);
    setCourse((prev: any) => ({ ...prev, videos: newVideos }));
  };

  return (
    <div className="form-group mb-5 pb-2">
      <label className="bold mb-1">
        {instructor_content.course.basics.videos.title}
      </label>
      <div className="text-muted small mb-3">
        {instructor_content.course.basics.videos.description}
      </div>
      <MyDropzone
        files={videos}
        onFileChange={(data: any) => setVideos(data)}
        height="100px"
        isMulti={false}
        type=".mp4"
      />
      <div className="row no-gutters mt-4">
        {course.videos &&
          course.videos.length > 0 &&
          course.videos.map((item: any, index: any) => (
            <div className="col-auto pr-2">
              <div className="d-flex align-items-start">
                <div
                  className="video-wrapper"
                  onClick={() => playVideo(item.url)}
                >
                  <video
                    className="rounded"
                    style={{
                      height: "50px",
                      width: "50px",
                      objectFit: "cover",
                    }}
                  >
                    <source src={item.url} />
                  </video>
                  <AiFillPlayCircle className="video-play-2 mt-n2 text-white h6 mb-0" />
                </div>
                <div
                  className="close1 mt-n3"
                  onClick={() => deleteSavedVideos(index)}
                >
                  &times;
                </div>
              </div>
            </div>
          ))}
        {videos.map((item: any, index: any) => (
          <div className="col-auto pr-2">
            <div className="d-flex align-items-start">
              <video
                className="img-fluid rounded video-overlay"
                style={{ height: "50px", width: "50px", objectFit: "cover" }}
              >
                <source src={URL.createObjectURL(item)} />
              </video>
              <div className="close1 mt-n3" onClick={() => deleteVideos(index)}>
                &times;
              </div>
            </div>
          </div>
        ))}
      </div>
      {errors[5] && (
        <div className="invalid-feedback d-block mt-4">
          You cant add more than 1 video
        </div>
      )}
      <Modal
        show={isOpen}
        onHide={() => setIsOpen(false)}
        centered
        contentClassName="bg-transparent"
      >
        <ReactPlayer
          config={{
            file: {
              attributes: {
                disablepictureinpicture: "true",
                controlsList: "nodownload",
              },
            },
          }}
          onContextMenu={(e: any) => e.preventDefault()}
          url={url}
          controls={true}
          width={"100%"}
          height={"100%"}
          style={{ width: "100%", height: "100%" }}
        />
      </Modal>
    </div>
  );
};

interface IReviews {
  data: any;
  setCourse: any;
}

const Reviews = ({ data, setCourse }: IReviews) => {
  const [showReview, setShowReview] = useState(false);
  const [loading, setLoading] = useState(false);

  const deleteReview = async (review_id: any) => {
    const response = await Swal.fire({
      title: "Are you sure you want to delete the review",
      text: "This action cannot be undone, once deleted",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (response.isConfirmed) {
      try {
        setLoading(true);
        // await axios.put(`/api/admin/course/review/delete/`, {
        //   review_id: review_id,
        //   course_id: data._id,
        // });
        const newCourse = { ...data };
        const index = newCourse.reviews.findIndex(
          (item: any) => item._id === review_id
        );
        newCourse.reviews.splice(index, 1);
        setCourse(newCourse);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    }
  };

  return (
    <div id="reviews" className="my-6">
      <div className="row pb-1 mt-5">
        <div className="col">
          <div className="h2 ">Feedback on the course</div>
        </div>
        <div className="col-auto">
          <div
            className="btn btn-sm btn-primary"
            onClick={() => setShowReview(true)}
          >
            Add Review
          </div>
        </div>
      </div>
      {data.reviews.length === 0 && <div>No reviews yet</div>}
      {data.reviews.map((item: any) => (
        <div className="row no-gutters">
          <div className="col">
            <div className="card rounded shadow mb-4 ">
              <div className="card-body pb-1">
                <div className="row no-gutters mb-0 pb-0">
                  <div className="col-auto mr-3">
                    <FaQuoteLeft />
                  </div>
                  <div className="col">
                    <blockquote className="blockquote px-0">
                      <Bio bio={item.review} />
                    </blockquote>
                  </div>
                </div>
                <footer className="row align-items-center no-gutters mb-4 mt-n2 ml-4">
                  <div className="col-auto">
                    <div className="avatar avatar-sm">
                      <img
                        alt=""
                        className="avatar-img rounded-circle"
                        src={item.avatar}
                      />
                    </div>
                  </div>
                  <div className="col pl-2">
                    <h3 className="h4 mb-0">{item.name}</h3>
                  </div>
                </footer>
              </div>
            </div>
          </div>
          <div
            className="col-auto pointer"
            onClick={() => deleteReview(item._id)}
          >
            {loading ? (
              <span className="spinner-border spinner-border-sm"></span>
            ) : (
              <AiFillDelete />
            )}
          </div>
        </div>
      ))}

      <Review
        show={showReview}
        setShow={setShowReview}
        courseId={data._id}
        course={data}
        setCourse={setCourse}
      />
    </div>
  );
};

interface IStars {
  numStars: any;
}

export const Stars = ({ numStars }: IStars) => {
  return (
    <div>
      {Array(parseInt(numStars))
        .fill(null)
        .map((item) => (
          <BsStarFill className="text-primary" />
        ))}
      {!Number.isInteger(numStars) ? (
        <BsStarHalf className="text-primary" />
      ) : (
        ""
      )}
      {/* {Array(Number.isInteger(numStars) ? 5-parseInt(numStars) : 4-parseInt(numStars)).fill(null).map(item => <BsStar className ='text-primary'/>)} */}
    </div>
  );
};

interface IBio {
  bio: any;
}
const Bio = ({ bio }: IBio) => {
  const [more, setMore] = useState(false);

  return (
    <p className="card-text text-muted">
      {more ? bio : bio.length > 60 ? bio.slice(0, 60) + "..." : bio}
      {bio.length > 60 && (
        <span
          className="btn-link pointer"
          onClick={() => setMore((prev: any) => !prev)}
        >
          {more ? "Read less" : "Read more"}
        </span>
      )}
    </p>
  );
};
