import { useEffect, useState } from "react";
import CardBasic from "../../../../../components/Card/card.basic";
import StudentService from "../../../../../services/student.service";
import { AiFillCheckCircle } from "react-icons/ai";
import { Badge, Col, Form, Image, Row } from "react-bootstrap";
import { FaPencilAlt } from "react-icons/fa";
import { AdminService } from "../../../../../services/admin.service";
import toast from "react-hot-toast";
import StudentDetailModal from "../../../../../components/Modals/StudentDetailModal";
import "./index.css";
interface IDetailStudent {
  studentId: string;
  title?: string;
  isEnrollment?: boolean;
}

interface IData {
  email: string;
  isBlocked: any;
  phone: string;
  firstName: string;
  lastName: string;
  countryCode: any;
}

export default function DetailStudent(props: IDetailStudent) {
  const [studentDetails, setStudentDetails] = useState<any>({});
  const [showModal, setShowModal] = useState<boolean>(false);
  const [data, setData] = useState<IData>({
    email: "",
    isBlocked: "",
    phone: "",
    firstName: "",
    lastName: "",
    countryCode: "",
  });
  const getStudentDetails = async () => {
    await StudentService.getStudentDetails(props.studentId).then((res) => {
      if (res.status === 200) {
        setStudentDetails(res.data.userData);
        setData({
          email: res.data.userData?.email,
          isBlocked: res.data.userData?.isBlocked,
          phone: res.data.userData?.phone,
          firstName: res.data.userData?.firstName,
          lastName: res.data.userData?.lastName,
          countryCode: res.data.userData?.countryCode,
        });
      }
    });
  };

  const onMount = async () => {
    getStudentDetails();
  };

  useEffect(() => {
    onMount();
  }, []);

  const handleToggle = async (toggle_id: any) => {
    await AdminService.toggleBlock(toggle_id)
      .then((res) => {
        if (res.status === 200) {
          console.log(res);

          toast.success("Updated");
          getStudentDetails();
        }
      })
      .catch((err: any) => {
        toast.error("Unable to Update Student Detail");
      });
  };

  const handleSubmit = async () => {
    await AdminService.updateStudentDetail({ id: props.studentId, data: data })
      .then((res) => {
        if (res.status === 200) {
          getStudentDetails();
          setShowModal(false);
          setData({
            email: res.data.email,
            isBlocked: res.data.isBlocked,
            phone: res.data.phone,
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            countryCode: res.data.countryCode,
          });
        }
      })
      .catch((err: any) => {
        toast.error("Unable to Update Student Detail");
      });
  };
  const handleChange = (e: any) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const onChange = (e: any) => {
    setData({
      ...data,
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <>
      <CardBasic
        body={
          <>
            {props.isEnrollment && (
              <div className="fw-bold fs-grey fs-14 d-flex justify-content-start mb-1">
                {props.title}
              </div>
            )}
            <div className="d-flex justify-content-start">
              <div>
                <Image
                  src={studentDetails.avatar || " "}
                  height="150px"
                  width="150px"
                />
              </div>
              <div className="ms-3 w-100">
                <div className="d-flex justify-content-between">
                  <span className="fw-bold fs-grey fs-14">
                    {studentDetails.firstName + " " + studentDetails.lastName}{" "}
                    <AiFillCheckCircle className="text-success" />
                    <span className="ms-2 upload-icon">
                      <FaPencilAlt
                        onClick={() => setShowModal(true)}
                        style={{ fontSize: "0.9rem" }}
                        className={showModal ? "text-secondary" : "font-upload"}
                      />
                    </span>
                    <div className="fs-12 fs-dhunprimary">
                      {studentDetails.email}
                    </div>
                  </span>
                  <span className="fs-grey fs-10 fw-bold">
                    {studentDetails._id}
                  </span>
                </div>
                <div className="mt-3 fs-14">
                  <h6 className="fs-grey"> Basic Details</h6>
                  <div className="bg-muted fs-12 w-100 rounded p-2">
                    <Row className="font-italic-imp">
                      <Col sm={6}>
                        Gender: {studentDetails.gender || "N.A."}
                        <br />
                        Email:{" "}
                        {studentDetails.isEmailConfirm
                          ? "Verified"
                          : "Not Verified"}
                        <br />
                        Source:{" "}
                        {studentDetails.utms
                          ? studentDetails.utms.utm_source +
                            " " +
                            studentDetails.utms.utm_campaign
                          : "N.A."}
                      </Col>
                      <Col sm={6}>
                        Demo Taken: {studentDetails.hasTakenDemo ? "Yes" : "No"}
                        <br />
                        <div className="d-flex flex-row gap-2">
                          Blocked:
                          <Form>
                            <Form.Check
                              type="switch"
                              id="toggle-switch"
                              checked={studentDetails.isBlocked}
                              onClick={() => handleToggle(studentDetails._id)}
                            />
                          </Form>
                          {studentDetails.isBlocked ? (
                            <Badge bg="danger">Blocked</Badge>
                          ) : (
                            <></>
                          )}
                        </div>
                        <br />
                        Phone :{" "}
                        {studentDetails.phone ? studentDetails.phone : "N.A."}
                      </Col>
                    </Row>
                    <br />
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </>
        }
      />
      <StudentDetailModal
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        hideModal={!showModal}
        show={showModal}
        hide={!showModal}
        data={data}
        setShowModal={setShowModal}
        onChange={onChange}
      />
    </>
  );
}
