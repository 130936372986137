import makeRequest, { RequestMethods } from "../api/makeRequest";
import url from "../api/urls";

export default class InstructorService {
  static async getDetails(_id: string) {
    return await makeRequest(
      url.instructor.getUserDetails + _id,
      RequestMethods.GET
    );
  }

  static async createCourse(
    instructor: string,
    title: string,
    instrument: string,
    price_per_class: number
  ) {
    return await makeRequest(url.instructor.createCourse, RequestMethods.POST, {
      instructor,
      title,
      instrument,
      price_per_class,
    });
  }

  static async getCourse(courseId: string) {
    return await makeRequest(
      url.courseDetail + "/" + courseId,
      RequestMethods.GET
    );
  }

  static async editCourse(
    courseId: string,
    title: string,
    instrument: string,
    price_per_class: number,
    isVisible: boolean,
    deleted: boolean,
    approve: boolean,
    discount: any
  ) {
    return await makeRequest(
      url.instructor.editCourse + "/" + courseId,
      RequestMethods.PUT,
      {
        title,
        instrument,
        price_per_class,
        isVisible,
        deleted,
        approve,
        discount,
      }
    );
  }
  static async updateUser(payload: any, instructorId: any) {
    return await makeRequest(
      url.instructor.updateUserInfo + "/" + instructorId,
      RequestMethods.PUT,
      payload
    );
  }
  static async getPaymentDetail(id: string) {
    return await makeRequest(
      url.instructor.getPaymentDetail + id,
      RequestMethods.GET
    );
  }

  static async getInstructorProfile(inst_id: any) {
    return await makeRequest(
      url.instructor.getInstructorProfile + "/" + inst_id,
      RequestMethods.GET
    );
  }

  static async getInstForSelect() {
    return await makeRequest(url.instructor.all, RequestMethods.GET);
  }
}
