import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { AdminService } from "../../services/admin.service";
import { onFetchInstructorCourse } from "../../store/actions/InstructorCourse";
import { useAppDispatch, useAppSelector } from "../../store/reduxHooks";
import Select from "react-select";
import { IcreateRazorpayLink } from "../../interfaces/razorpay.interface";
import toast from "react-hot-toast";
import { ArrayHelper } from "../../helpers/array.helper";
import DatePicker from "react-datepicker";
import moment from "moment";
import SideModal from "../SideModal/SideModal";
import InstructorService from "../../services/instructor.service";
import CalenderInput from "./DatePicker";
import { ICreateDemoSession } from "../../interfaces/createDemoSession";

interface ICreateDemoSessionLinkProps {
  show: boolean;
  setShow: any;
}

export default function CreateDemoSessionModal(
  props: ICreateDemoSessionLinkProps
) {
  const [finalSaveState, setFinalSaveState] = useState<any>({
    coordinator: "",
    dhunguruDiscount: 0,
    totalSession: 0,
    councellor: "",
  });

  const dispatch = useAppDispatch();
  const [inputStudentValue, setInputStudentValue] = useState<any>();
  const [inputCourseValue, setInputCourseValue] = useState<any>();
  const [date, setDate] = useState(moment().add("1", "hours").startOf("hour").toDate());
  const [demoTime, setDemoTime] = useState(moment().add("1", "hours").startOf("hour").toDate());
  const [instructorCourses, setInstructorCourses] = useState<any>();
  const [courses, setCourses] = useState<any[]>([]);

  const [selOption, setSelOption] = useState<any>({});

  const [loading, setLoading] = useState<boolean>(false);

  const handelInstructorChanges = (obj: any) => {
    setSelOption(obj);
  };

  const id = selOption?.value || "";

  const courseDetail = useAppSelector(
    (state) => state.instructorCourses.courseDetail
  );

  useEffect(() => {
    if(!id) return;
    dispatch(onFetchInstructorCourse({ id }));
  }, [id, dispatch]);

  const fetchInstructorData = (inputValue: any, callback: ([]) => void) => {
    if (!inputValue) {
      callback([]);
    } else {
      setTimeout(() => {
        AdminService.getAllInstructor(inputValue).then((res) => {
          if (res.status === 200) {
            const instructors = res.data.data.map((data: any) => {
              return {
                value: data._id,
                label: `${data.firstName}  ${data.lastName} - ${data.email}`,
              };
            });
            callback(instructors);
          }
        });
      }, 1000);
    }
  };

  const fetchCourseData = () => {
    if(!id) return;
    AdminService.getInstructorCourses({ id }).then((res) => {
      if (res.status === 200) {
        setInstructorCourses(
          res?.data?.data?.map((data: any) => {
            return {
              value: data._id,
              label: `${data.title}`,
            };
          })
        );
      }
    });
  };

  useEffect(() => {
    fetchCourseData();
  }, [id]);

  const getInsructorCourses = async (id: string) => {
    setCourses([]);
    if(!id) return;
    await AdminService.getInstructorCourses({ id }).then((res) => {
      if (res.status === 200) {
        setCourses(
          res.data.data.finalData.courses.map((course: any) => {
            return {
              value: course._id,
              label: (
                <span className="fs-12 fw-bold text-secondary">
                  {" "}
                  {course.instrument}
                </span>
              ),
            };
          })
        );
      }
    });
  };

  useEffect(() => {
    if (selOption && selOption.value) {
      getInsructorCourses(selOption.value);
    }
  }, [selOption]);

  const fetchStudentData = (inputValue: any, callback: ([]) => void) => {
    if (!inputValue) {
      callback([]);
    } else {
      setTimeout(() => {
        AdminService.getAllStudents(inputValue).then((res) => {
          if (res.status === 200) {
            const students = res.data.data.map((data: any) => {
              return {
                value: data._id,
                label: `${data.firstName}  ${data.lastName} - ${data.email}`,
              };
            });
            callback(students);
          }
        });
      }, 1000);
    }
  };

  const handleChange = (e: any) => {
    setFinalSaveState({ ...finalSaveState, [e.target.name]: e.target.value });
  };

  const onSubmit = async () => {
    setLoading(true);

    const inputData: ICreateDemoSession = {
      instructorId: selOption?.value || " ",
      studentId: inputStudentValue?.value || " ",
      courseId: inputCourseValue?.value || " ",
      coordinator: finalSaveState?.coordinator || " ",
      session_date_time: date,
      councellor: finalSaveState?.councellor || " ",
      actual_demo_time: demoTime
    };

    if (!inputData.councellor || !inputData.coordinator) {
      toast.error("Coordinator and Councellor are Mandatory")
      setLoading(false);
      return false
    }

    toast.promise(AdminService.createDemoSession(inputData)
      .then((res: any) => {
        if (res.status === 200) {
          props.setShow(false);
          setFinalSaveState({
            coordinator: "",
            councellor: ""
          });
          setSelOption({})
          setInputCourseValue({})
          setInputStudentValue({})
          setDate(moment().add("1", "hours").startOf("hour").toDate())
          toast.success("Success: Link Created!");
          setLoading(false);
        }
      })
      .then((e) => {
        setLoading(false);
        console.error(e);
      }), {
      loading: 'Creating Link',
      success: 'Link Created',
      error: 'Link Creation Failed',
    })

  }

  return (
    <SideModal
      show={props.show}
      handleClose={() => props.setShow(false)}
      title="Create Demo Enrollment"
      body={
        <>
          <Form.Group className="mt-2">
            <Form.Label className="fw-bold fs-12 text-secondary">Select Guru</Form.Label>

            <AsyncSelect
              name="InstructorId"
              loadOptions={fetchInstructorData}
              onChange={(option) => handelInstructorChanges(option)}
              isClearable={true}
              value={selOption}
            />
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Label className="fw-bold fs-12 text-secondary">Select the Student</Form.Label>

            <AsyncSelect
              name="studentId"
              loadOptions={fetchStudentData}
              onChange={setInputStudentValue}
              isClearable={true}
              value={inputStudentValue}

            />
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Label className="fw-bold fs-12 text-secondary">Select the Course</Form.Label>
            <Select
              name="courseId"
              options={instructorCourses}
              onChange={setInputCourseValue}
              value={inputCourseValue}
            />
            <Form.Group className="mt-2">
              <Form.Label className="fw-bold fs-12 text-secondary">Enrollment Type</Form.Label>
              <Form.Control
                type="text"
                name="enrollmentType"
                disabled={true}
                value="Demo"
              />
            </Form.Group>
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Label className="fw-bold fs-12 text-secondary">Date</Form.Label>
            <CalenderInput
              setValue={(date: any) => {
                setDate(date);
              }}
              selected={date}
            />
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Label className="fw-bold fs-12 text-secondary">Coordinator</Form.Label>
            <Form.Control
              type="text"
              name="coordinator"
              onChange={handleChange}
              value={finalSaveState.coordinator}
            />
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Label className="fw-bold fs-12 text-secondary">Councellor</Form.Label>
            <Form.Control
              type="text"
              name="councellor"
              onChange={handleChange}
              value={finalSaveState.councellor}
            />
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Label className="fw-bold fs-12 text-secondary">Actual Demo Time</Form.Label>
            <CalenderInput
              setValue={(date: any) => {
                setDemoTime(date);
              }}
              selected={demoTime}
              interval={30}
            />
          </Form.Group>
          <Button className="mt-4" onClick={onSubmit} size="sm" disabled={loading}>
            Book Session
          </Button>
        </>
      }
    />
  );
}
