import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import toast from "react-hot-toast";
import { AiFillDelete } from "react-icons/ai";
import instructor_content from "../../../../../Content/instructor_content.json";
import "../../../../../assets/css/dashborad.css";
import "../../../../../assets/css/custom.css";

interface IResources {
  course: any;
  setCourse: any;
  courseId: string;
  loading?: any;
}

interface IResourceItem {
  data: any;
  deleteResource: any;
}

const Resources = ({ course, setCourse, courseId }: IResources) => {
  const deleteResource = async (resource_id: any) => {
    try {
      const new_course = { ...course };
      const index = new_course.resources.findIndex(
        (item: any) => item._id === resource_id
      );
      new_course.resources.splice(index, 1);
      setCourse(new_course);
      //   await axios.delete(
      //     `/api/instructor/course/resource/delete/${courseId}/${resource_id}`
      //   );
      toast.success(course.resources.successDelete);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="">
      {course.resources.length > 0 && (
        <ul className="list-group list-group-active shadow-sm bg-white rounded mt-0">
          {course.resources.map((item: any) => (
            <ResourceItem data={item} deleteResource={deleteResource} />
          ))}
        </ul>
      )}
      {course.resources.length === 0 && <NoResource />}
    </div>
  );
};

export default Resources;

const ResourceItem = ({ data, deleteResource }: IResourceItem) => {
  return (
    <div className="list-group-item py-4">
      <div className="row align-items-center">
        <div className="col-12 col-md ml-n2 mb-4 mb-md-0 d-flex align-items-center">
          <div className="ml-3">
            <h4 className="text-body text-focus mb-2 name">{data.title}</h4>
            <p className=" text-muted mb-2">{data.description}</p>
            <a
              href={data.url}
              target="_blank"
              rel="noreferrer"
              className="small"
            >
              Resource Link
            </a>
          </div>
        </div>

        <div className="col-auto col-md-auto text-center">
          <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
            <div
              className="h3 mb-0 pointer"
              onClick={() => deleteResource(data._id)}
            >
              <AiFillDelete />
            </div>
          </OverlayTrigger>
        </div>
      </div>
    </div>
  );
};

const NoResource = () => {
  return (
    <div className="card mb-4">
      <div className="card-body d-flex align-items-center justify-content-center">
        <div className="h3 text-uppercase text-muted mb-0 py-5">
          {instructor_content.course.resources.no_resource}
        </div>
      </div>
    </div>
  );
};
