import React, { useState } from "react";
import instructor_content from "../../../../../Content/instructor_content.json";
import "../../../../../assets/css/dashborad.css";
import "../../../../../assets/css/custom.css";

interface IPricing {
  course: any;
  setCourse: any;
  saveCourse: any;
  loading: any;
}
const Pricing = ({ course, setCourse, saveCourse, loading }: IPricing) => {
  const [errors, setErrors] = useState(Array(2).fill(false));

  const validate = () => {
    const new_errors = Array(2).fill(false);
    if (course.price_per_class === "") new_errors[0] = true;
    for (let i = 0; i < 4; i++) {
      if (!course.discount[i].num_classes) new_errors[1] = true;
      if (!course.discount[i].discount) new_errors[1] = true;
      if (course.discount[i].num_classes < 1) new_errors[1] = true;
      if (course.discount[i].discount < 0) new_errors[1] = true;
      if (course.discount[i].discount > 90) new_errors[1] = true;
    }
    const temp = course.discount.map((item: any) => item.num_classes);
    if (temp.filter(onlyUnique).length !== temp.length) new_errors[1] = true;
    setErrors(new_errors);
    if (new_errors.includes(true)) return false;
    else return true;
  };

  const save = async (e: any) => {
    e.preventDefault();
    try {
      if (!validate()) return;
      saveCourse();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <div className="mb-6 ">
        <PricingPerClass
          course={course}
          setCourse={setCourse}
          errors={errors}
          setErrors={setErrors}
          errText="Please enter your pricing per class"
        />
        <Batches
          course={course}
          setCourse={setCourse}
          errors={errors}
          setErrors={setErrors}
          errText="Please fill all the inputs"
        />
      </div>

      <div className="row align-items-center mb-5">
        <div className="col-4">
          <button
            className="btn btn-block btn-lg btn-primary mb-6"
            disabled={loading}
            onClick={(e) => save(e)}
          >
            {loading && (
              <span className="spinner-border spinner-border-sm mr-2"></span>
            )}
            Save
          </button>
        </div>
        <div className="col-auto"></div>
      </div>
    </div>
  );
};

export default Pricing;

interface IPricingPerClass {
  course: any;
  setCourse: any;
  errors: any;
  setErrors: any;
  errText: any;
}
const PricingPerClass = ({
  course,
  setCourse,
  errors,
  setErrors,
  errText,
}: IPricingPerClass) => {
  return (
    <div className="mb-5 pb-2">
      <div className="form-group">
        <label className="bold mb-1">
          {instructor_content.course.pricing.price.title}
        </label>
        <div className="text-muted small mb-3">
          {instructor_content.course.pricing.price.description}
        </div>
        <InputItem
          icon="$"
          input={course.price_per_class}
          setValue={(value: any) =>
            setCourse((prev: any) => ({ ...prev, price_per_class: value }))
          }
        />
        {errors[0] && (
          <div className="invalid-feedback d-block ">{errText}</div>
        )}

        <div className="text-dark mt-3">
          Teachers earnings post Dhunguru's commission,{" "}
          <strong>Rs {course.price_per_class * 0.8}</strong>
        </div>
      </div>
    </div>
  );
};

interface IBatches {
  course: any;
  setCourse: any;
  errors: any;
  setErrors: any;
  errText: any;
}
const Batches = ({
  course,
  setCourse,
  errors,
  setErrors,
  errText,
}: IBatches) => {
  const editDiscount = (value: any, index: any, key: any) => {
    const new_course = { ...course };
    course.discount[index][key] = value;
    setCourse(new_course);
    const newErr = [...errors];
    newErr[0] = false;
    setErrors(newErr);
  };

  return (
    <div className="mb-5 pb-2">
      <div className="form-group">
        <label className="bold mb-1">
          {instructor_content.course.pricing.batches.title}
        </label>
        <div className="text-muted small mb-3">
          {instructor_content.course.pricing.batches.description}
        </div>
        <div className="row align-items-center mb-2">
          <div className="col-5 col-lg-3 bold small ml-5">
            Number of Sessions
          </div>
          <div className="col-5 col-lg-3 bold small">Discount</div>
        </div>
        <div className="row align-items-center mb-3">
          <div className="col-auto bold">1.</div>
          <div className="col-5 col-lg-3">
            <input
              className="form-control disabled-input"
              disabled={true}
              type="number"
              value={course.discount[0].num_classes}
            />
          </div>
          <div className="col-5 col-lg-3">
            <InputItemPost
              icon="%"
              input={course.discount[0].discount}
              setValue={(value: any) => editDiscount(value, 0, "discount")}
            />
          </div>
        </div>
        <div className="row align-items-center mb-3">
          <div className="col-auto bold">2.</div>
          <div className="col-5 col-lg-3">
            <input
              className="form-control"
              type="number"
              value={course.discount[1].num_classes}
              onChange={(e) => editDiscount(e.target.value, 1, "num_classes")}
            />
          </div>
          <div className="col-5 col-lg-3">
            <InputItemPost
              icon="%"
              input={course.discount[1].discount}
              setValue={(value: any) => editDiscount(value, 1, "discount")}
            />
          </div>
        </div>
        <div className="row align-items-center mb-3">
          <div className="col-auto bold">3.</div>
          <div className="col-5 col-lg-3">
            <input
              className="form-control"
              type="number"
              value={course.discount[2].num_classes}
              onChange={(e) => editDiscount(e.target.value, 2, "num_classes")}
            />
          </div>
          <div className="col-5 col-lg-3">
            <InputItemPost
              icon="%"
              input={course.discount[2].discount}
              setValue={(value: any) => editDiscount(value, 2, "discount")}
            />
          </div>
        </div>
        <div className="row align-items-center mb-3">
          <div className="col-auto bold">4.</div>
          <div className="col-5 col-lg-3">
            <input
              className="form-control"
              type="number"
              value={course.discount[3].num_classes}
              onChange={(e) => editDiscount(e.target.value, 3, "num_classes")}
            />
          </div>
          <div className="col-5 col-lg-3">
            <InputItemPost
              icon="%"
              input={course.discount[3].discount}
              setValue={(value: any) => editDiscount(value, 3, "discount")}
            />
          </div>
        </div>
        {errors[1] && (
          <div className="invalid-feedback d-block ">{errText}</div>
        )}
      </div>
    </div>
  );
};

interface IInputItems {
  icon: any;
  input: any;
  setValue: any;
}

const InputItem = ({ icon, input, setValue }: IInputItems) => {
  return (
    <div className="input-group input-group-merge">
      <input
        type="number"
        className="form-control form-control-prepended"
        placeholder=""
        value={input}
        onChange={(e) => setValue(e.target.value)}
      />
      <div className="input-group-prepend">
        <div className="input-group-text text-muted" style={{ height: "41px" }}>
          {icon}
        </div>
      </div>
    </div>
  );
};

interface IInputItemPost {
  icon: any;
  input: any;
  setValue: any;
}
const InputItemPost = ({ icon, input, setValue }: IInputItemPost) => {
  return (
    <div className="input-group input-group-merge">
      <input
        type="number"
        className="form-control form-control-appended"
        placeholder=""
        value={input}
        onChange={(e) => setValue(e.target.value)}
      />
      <div className="input-group-append">
        <div className="input-group-text text-muted" style={{ height: "41px" }}>
          {icon}
        </div>
      </div>
    </div>
  );
};

function onlyUnique(value: any, index: any, self: any) {
  return self.indexOf(value) === index;
}
