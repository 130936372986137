import React from 'react';
import { RingLoader } from 'react-spinners';
import "./loader.css";
import Lottie from "lottie-react";
import dhunguruLoader from '../../assets/lottie/dhunguru-loader.json';

export default function Loader(){
    return (
        <div className="dhun-loader-div">
            <div className='dhun-loader'>
            <Lottie animationData={dhunguruLoader} loop={true}  className='lottie-loader'/>
            </div>
            {/* <RingLoader color="#496DDB" className='dhun-loader' /> */}
        </div>
    )
}