import makeRequest from "../api/makeRequest";
import url from "../api/urls";

export default class UserService {
  static async getAllAdminUsers() {
    return await makeRequest(url.userRole.getAllAdminUsers, "get");
  }
  static async changeUserRole(adminRoles: any, _id: any) {
    return await makeRequest(url.userRole.changeUserRole + "/" + _id, "put", {
      adminRoles,
    });
  }

  static async createAdmin(
    firstName: string,
    lastName: string,
    email: string,
    phone: any,
    password: string,
    countryCode: string
  ) {
    return makeRequest(url.userRole.createAdmin, "post", {
      firstName,
      lastName,
      email,
      phone,
      password,
      countryCode,
    });
  }
  static async blockUnblockUser(_id: any) {
    return makeRequest(url.userRole.blockUnblockUser + "/" + _id, "put", {
      _id,
    });
  }

  static async getUserAdminRoles() {
    return await makeRequest(url.userRole.getUserAdminRoles, "get")
  }

  static async getAdminById(_id: any) {
    return await makeRequest(url.userRole.getAdminById + _id, "get")
  }
}
