import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import "./table.css";
import { AdminService } from "../../../../services/admin.service";
import CardBasic from "../../../../components/Card/card.basic";
import toast from "react-hot-toast";
import Select from "react-select";
import StudentService from "../../../../services/student.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { IsLoading } from "../../../../App.context";


interface IPaidTable {
  reloadRenewals: any
}

export default function PaidTable(props: IPaidTable) {
  const [paidTableData, setPaidTableData] = useState<any>([])
  const [students, setStudents] = useState<any>([]);

  const [selectedStudents, setSelectedStudents] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const {loading, setLoading}:any = React.useContext(IsLoading);

  const fetchPaidStudents = async () => {
    setLoading(true)
    await AdminService.getPaidStudents(selectedStudents.map((student: any) => {
      return student.value
    }), pageNumber, pageSize).then((res) => {
      if (res.status === 200) {
        setPaidTableData(res.data.studentEnrollments)
      }
      setLoading(false)
    }).catch(e => {
      setLoading(false)
    });
  };

  const createRenewal = async (studentId: any) => {
    await AdminService.createRenewal(studentId).then((res) => {
      if (res.status === 200) {
        toast.success("Renewal Created");
        props.reloadRenewals()
      }
    }).catch((err) => {
      console.log(err, "err")
      toast.error("Renewal already exists.");
    });
  };

  const allstudents = async () => {
    setLoading(true)
    await StudentService.all_paid().then((res) => {
      if (res.status === 200) {
        setStudents(res.data.map((data: any) => {
          return {
            value: data._id,
            label: data.firstName + " " + data.lastName + " - " + data.email,
          }
        }))
      }
      setLoading(false)
    }).then((err) => {
      setLoading(false)
      console.log(err, "err")
    })
  }

  const onNextClick = () => {
    setPageNumber(pageNumber + 1)
  }
  
  const onPreviousClick = () => {
    if(pageNumber === 1) return
    setPageNumber(pageNumber - 1)
  }

  useEffect(() => {
    fetchPaidStudents()
    allstudents()
  }, [pageNumber, pageSize])


  return (
    <>
      <Container>
        <Row>
          <Col>
            <CardBasic
              body={
                <>
                  <h6>Filters</h6>
                  <Row>
                    <Col>
                      <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label className="fs-12 fw-bold">Student</Form.Label>
                        <Select
                          options={students}
                          isMulti={true}
                          value={selectedStudents}
                          onChange={setSelectedStudents}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Label className="fs-12 fw-bold">Page Count</Form.Label>
                      <Select
                        options={[
                          {
                            value: 10,
                            label: 10,
                          }, {
                            value: 20,
                            label: 20,
                          }
                        ]}
                        value={{
                          value: pageSize,
                          label: pageSize,
                        }}
                        onChange={(e: any) => { setPageSize(e.value) }}
                      /></Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <Button variant="primary" onClick={fetchPaidStudents} size="sm">
                        Apply
                      </Button>
                    </Col>
                  </Row>
                </>
              }
            />
          </Col>
        </Row>
        <Row>
          <Col md={7}>
            <div className="d-flex justify-content-start align-items-center">
              <div>
                <div className="h5">
                  Paid Students
                </div>
                <div>

                </div>
              </div>
            </div>
          </Col>
          <Col md={5}>
            <div className="d-flex justify-content-end align-items-center">
              <Button size="sm" className="me-2" onClick={onPreviousClick} disabled={pageNumber<=1 ? true: false}><FontAwesomeIcon icon={faCaretLeft} />  Previous </Button>
              <Button size="sm" onClick={onNextClick}>Next <FontAwesomeIcon icon={faCaretRight}  /> </Button>
            </div>
            <div className="d-flex justify-content-end align-items-center mt-2">
              <p className="text-secondary fs-12">Page {pageNumber} - Showing {((pageNumber*pageSize) - pageSize) + 1} to {(pageNumber*pageSize)} entries</p>
            </div>

          </Col>
        </Row>
        <Row>
          <Col>
            <CardBasic
              className="mt-3"
              body={
                <>
                  <Table striped borderless hover responsive size="sm">
                    <thead>
                      <tr className="dhun-table-header text-secondary">
                        <th>S.No.</th>
                        <th>NAME</th>
                        <th>EMAIL</th>
                        <th>PHONE</th>
                        <th>TOTAL PAID</th>
                        <th>PAID ENROLLMENTS</th>
                        <th>TOTAL SESSIONS</th>
                        <th>SESSIONS COMPLETED</th>
                        <th>SESSIONS LEFT</th>
                        <th>REQUESTS LEFT</th>
                        <th>RENEWALS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paidTableData && paidTableData?.length > 0
                        ? paidTableData?.map((data: any, index: any) => {
                          return (
                            <tr key={data?._id}>
                              <td>
                                {(pageNumber*pageSize)-pageSize + index + 1}
                              </td>
                              <td>
                                {data.student.firstName} {data.student.lastName}
                              </td>
                              <td>
                                {data.student.email}
                              </td>
                              <td>
                                {data.student.phone}
                              </td>
                              <td>
                                {data.totalPaid}
                              </td>
                              <td>
                                {data.paidEnrollmentsCount}
                              </td>
                              <td>
                                {data.totalClasses}
                              </td>
                              <td>
                                {data.classesCompleted}
                              </td>
                              <td>
                                {data.classesLeft}
                              </td>
                              <td>
                                {data.classesLeftToRequest}
                              </td>
                              <td>
                                <Button size="sm" onClick={() => createRenewal(data.student._id)}>Renew</Button>
                              </td>
                            </tr>
                          );
                        })
                        : "No Data Available"}
                    </tbody>
                  </Table>
                </>
              }
            />
          </Col>
        </Row>
      </Container>
    </>

  );
}
