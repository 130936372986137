import React, { useEffect, useState } from "react";
import { AdminService } from "../../../services/admin.service";
import moment from "moment";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import CardBasic from "../../../components/Card/card.basic";
import DatePicker from "react-datepicker";
import Select from "react-select";
import toast from "react-hot-toast";
import IncentiveTable from "./IncentiveTable";
import { CSVLink } from "react-csv";
import CreateIncentiveLinkModal from "../../../components/Modals/CreateIncentive.modal";
import InstructorService from "../../../services/instructor.service";

const Incentive = () => {
  const [status, setStatus] = useState<any>();
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [showCreateLink, setShowCreateLink] = useState(false);

  const [incentiveData, setIncentiveData] = useState<any>();
  const [incentiveCsvData, setIncentiveCsvData] = useState<any>([]);
  const [instructors, setInstructors] = useState<any>([]);
  const [selected_instructor, setSelectedInstructor] = useState<any>();


  const statusData = [
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Transferred",
      value: "transferred",
    },
    {
      label: "Adjusted",
      value: "adjusted",
    },
    {
      label: "Cancelled",
      value: "cancelled",
    },
  ];

  const getInst = async () => {
    await InstructorService.getInstForSelect().then((res) => {
      if (res.status === 200) {
        setInstructors(
          res.data.map((data: any) => {
            return {
              value: data._id,
              label: data.firstName + " " + data.lastName + " - " + data.email,
            };
          })
        );
      }
    });
  };

  const getAllIncentives = async () => {
    let instructorId = selected_instructor && selected_instructor.value || undefined;
    await AdminService.getAllIncentives(status?.value, startDate, endDate, instructorId)
      .then((res) => {
        if (res.status === 200) {
          setIncentiveData(res.data.incentives);
          const csvDataFormat = res.data.incentives.map(
            (data: any, index: number) => {
              return {
                "#": index + 1,
                EnrollmentID: data.enrollmentId.enrollmentId,
                "Student Name":
                  data.studentId.firstName + " " + data.studentId.lastName,
                Instructor:
                  data.instructorId.firstName +
                  " " +
                  data.instructorId.lastName,
                Session_Count: data.sessionCount,
                Amount: data.amount,
                "Date Time": moment(data.date).format("DD-MM-YYYY hh:mm"),
                Status: data.transactionStatus,
              };
            }
          );
          // setIncentiveCsvData(csvDataFormat);
        }
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  };

  useEffect(() => {
    getInst();
  },[])


  return (
    <>
      <Container className="pt-3">
        <Row>
          <Col>
            <CardBasic
              className="mt-3"
              body={
                <>
                  <h5 className="mb-3">Filters</h5>
                  <Row>
                    <Col md={3}>
                      <Form.Label>Start Date</Form.Label>
                      <div>
                        <DatePicker
                          selected={startDate}
                          onChange={(e: any) => setStartDate(e)}
                          className="p-1 text-muted form form-control"
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <Form.Label>End Date</Form.Label>
                      <div>
                        <DatePicker
                          selected={endDate}
                          onChange={(e: any) => setEndDate(e)}
                          className="p-1 text-muted form form-control"
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Status</Form.Label>
                        <Select
                          options={statusData}
                          value={status}
                          onChange={(e: any) => setStatus(e)}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Label className="fw-bold text-secondary fs-12">
                        Instructors
                      </Form.Label>
                      <Select
                        options={instructors}
                        value={selected_instructor}
                        isSearchable={true}
                        placeholder="Select Instructor"
                        onChange={(e: any) => {
                          setSelectedInstructor(e);
                        }}
                        isClearable
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="my-4">
                      <div className="d-flex justify-content-end">
                        <Button
                          size="sm"
                          className="mx-2"
                          onClick={getAllIncentives}
                        >
                          Get
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </>
              }
            />

            <Row className="mt-2">
              <Col>
                <div className="d-flex justify-content-end">
                  <Button
                    className="mx-2 mt-3"
                    onClick={() => setShowCreateLink(true)}
                  >
                    Create Incentive
                  </Button>
                  {/* {incentiveCsvData && incentiveCsvData.length > 0 ? (
                    <CSVLink
                      data={incentiveCsvData}
                      filename="Incentive.csv"
                      className="mx-2 mt-3"
                    >
                      <Button size="sm" className="mx-2 mt-3">
                        Download CSV
                      </Button>
                    </CSVLink>
                  ) : (
                    <Button size="sm" className="mx-2 mt-3" disabled>
                      Download CSV
                    </Button>
                  )} */}
                </div>
                <CardBasic
                  className="mt-3"
                  body={
                    <>
                      <IncentiveTable
                        tableData={incentiveData}
                        reload={getAllIncentives}
                      />
                    </>
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <CreateIncentiveLinkModal
        show={showCreateLink}
        setShow={setShowCreateLink}
        reload={getAllIncentives}
      />
    </>
  );
};

export default Incentive;
