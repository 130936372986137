import { IAction } from "../../action";
import { ISearchState } from "./interface";
import * as actionTypes from "../../constants/searchPage";

const initialState: ISearchState = {
  loading: false,
  search: {},
};

const searchReducer = (state = initialState, action: IAction) => {
  if (action.type === actionTypes.FETCH_SEARCH_PAGE_INITIAL) {
    return {
      ...state,
      loading: true,
      search: {},
    };
  }

  if (action.type === actionTypes.FETCH_SEARCH_PAGE_SUCCESS) {
    return {
      ...state,
      loading: false,
      search: action.payload || {},
    };
  }

  if (action.type === actionTypes.FETCH_SEARCH_PAGE_FAILURE) {
    return {
      ...state,
      loading: false,
      search: {},
    };
  }

  return state;
};

export default searchReducer;
