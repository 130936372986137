import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Card, Table } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router';
import CardBasic from '../../../../../components/Card/card.basic';
import { FontBold, NormalText } from '../../../../../components/Text/TableText.formats';
import InstructorService from '../../../../../services/instructor.service';
import StudentService from '../../../../../services/student.service';
import Heading from '../../TableHeading/Heading';


interface IPayment{
    id:string
 
}
const PaymentTable = ({id}:IPayment) => {

    const [tableData,setTableData]=useState<any[]>();

  const navigate: any = useNavigate();


    const fetchPaymentDetail = async () => {
      await InstructorService.getPaymentDetail( id )
        .then((res) => {
          if (res.status === 200) {
            setTableData(res?.data);
          }
        })
        .catch((err) => {
          toast.error("Unable to fetch Student Payment Details");
        });
    };

    useEffect(() => {
      fetchPaymentDetail();
    }, [id]);


  return (
    <CardBasic
      body={
        <>
          <Heading heading="Payment Detail" />
          <div
            className="m-4"
            style={{ maxHeight: "500px", overflow: "scroll" }}
          >
            <Table striped borderless hover responsive size="sm">
              <thead>
                <tr className="dhun-table-header text-secondary">
                  <th>#</th>
                  <th>Id</th>
                  <th>Student Name</th>
                  <th>Payment status</th>
                  <th>Partial Payment(Rs)</th>
                  <th>Gateway</th>
                  <th>Amount(Rs)</th>
                  <th>total Session</th>
                  <th>price Per Class(Rs)</th>
                  <th>dhunguru Discount(%)</th>
                  <th>instructor Discount(%)</th>
                  <th>coordinator</th>
                  <th>state</th>
                  <th>Payment Date</th>
                </tr>
              </thead>
              <tbody>
                {tableData && tableData?.length > 0
                  ? tableData?.map((data: any, index: any) => {
                      return (
                        <tr key={data?._id}>
                          <td>
                            <NormalText value={index + 1} />
                          </td>
                          <td>
                            <FontBold value={data._id} />
                          </td>
                          <td
                            onClick={() => {
                              navigate(
                                "/admin/search/student/detail/" +
                                  data.studentId?._id
                              );
                            }}
                          >
                            <u className="fst-italic text-primary fs-12 fs-underline cursor-pointer">
                              {data?.studentId
                                ? data?.studentId?.firstName +
                                  " " +
                                  data?.studentId?.lastName
                                : "N.A."}
                            </u>
                          </td>
                          <td>
                            <FontBold value={data.paymentStatus || "--"} />
                          </td>
                          <td>
                            <FontBold value={data.partialPayment || "--"} />
                          </td>
                          <td>
                            <FontBold value={data.gateway || "--"} />
                          </td>
                          <td>
                            <FontBold value={data.amount || "--"} />
                          </td>
                          <td>
                            <FontBold value={data.totalSession || "--"} />
                          </td>{" "}
                          <td>
                            <FontBold value={data.pricePerClass || "--"} />
                          </td>{" "}
                          <td>
                            <FontBold value={data.dhunguruDiscount || "--"} />
                          </td>{" "}
                          <td>
                            <FontBold value={data.instructorDiscount || "--"} />
                          </td>
                          <td>
                            <FontBold value={data.coordinator || "--"} />
                          </td>
                          <td>
                            <FontBold value={data.state || "--"} />
                          </td>
                          <td>
                            <FontBold
                              value={
                                moment(data?.updated).format("DD MMM YY") ||
                                "--"
                              }
                            />
                          </td>
                        </tr>
                      );
                    })
                  : "No Data Available"}
              </tbody>
            </Table>
          </div>
        </>
      }
    />
  );
}

export default PaymentTable;
