import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import CardBasic from "../../../../../components/Card/card.basic";
import TableHeading from "../../TableHeading/index";
import StudentEnrollmentTable from "../StudentEnrollmentTable";

const StudentEnrollment = (props: any) => {
  return (
    <>
      <CardBasic
        body={
          <>
            <TableHeading heading="Enrollments" />
            <div className="m-4" style={{ maxHeight: "500px", overflow: "scroll" }}>
              <StudentEnrollmentTable
                id={props.id}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default StudentEnrollment;
