import { useState } from "react";
import CardBasic from "../../../../../components/Card/card.basic";
import Instrument from "./Instrument";
import "../../../../../assets/css/dashborad.css";
import "../../../../../assets/css/custom.css";

interface IInstruments {
  courses: any;
}

interface IAccountNav {
  selected: any;
  setSelected: any;
  courses: any;
}

const Instruments = ({ courses }: IInstruments) => {
  const [selected, setSelected] = useState(0);

  return (
    <CardBasic
      body={
        <div>
          <span className="fw-bold fs-grey mb-3" style={{ fontSize: "20px" }}>
            Instruments
          </span>
          <div className="header bg-white px-4 pt-2 rounded shadow-sm">
            <div className="header-body pt-0">
              <AccountNav
                selected={selected}
                setSelected={setSelected}
                courses={courses}
              />
            </div>
          </div>

          <Instrument data={courses[selected]} />
        </div>
      }
    />
  );
};

export default Instruments;

const AccountNav = ({ selected, setSelected, courses }: IAccountNav) => {
  return (
    <div className="row align-items-end mt-4">
      <div className="col-auto">
        <strong>Listed Instruments: </strong>
      </div>
      <div className="col">
        <ul className="nav nav-tabs header-tabs">
          {courses &&
            courses?.map((item: any, index: any) => (
              <li className="nav-item">
                <div
                  className={`nav-link pointer pt-0 ${
                    selected === index && "active"
                  }`}
                  onClick={() => setSelected(index)}
                >
                  {item.instrument[0].toUpperCase() + item.instrument.slice(1)}
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};
