import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Tab, Tabs } from "react-bootstrap";
import { AdminService } from "../../services/admin.service";
import Select from "react-select";
import toast from "react-hot-toast";
import SideModal from "../SideModal/SideModal";


interface ICreateVersionProps {
  show: boolean;
  setShow: any;
  reload: any;
}

export default function CreateVersionkModal(
  props: ICreateVersionProps
) {
  const [selectedProductType, setSelectedProductType] = useState<any>();
  const [selectedVersionType, setSelectedVersionType] = useState<any>();
  const [productType, setProductType] = useState([
    {label: "Web", value: "web"},
    {label: "Mobile", value: "mobile"}
  ]);
  const [versionType, setVersionType] = useState([
    {label: "Stable", value: "stable"},
    {label: "Obsolete", value: "obsolete"},
    {label: "Deprecated", value: "deprecated"},
    {label: "Beta", value: "beta"}

  ]);
  const [versionNo, setVersionNo] = useState<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {

  }, []);

  const onSubmit = async () => {
    if (!versionNo || !selectedProductType || !selectedVersionType ) {
      toast.error("Please fill all the fields");
      return;
    }

    setLoading(true);

    const data = {
      versionType: selectedVersionType?.value,
      productType: selectedProductType?.value,
      version: versionNo
    };
    await AdminService.createVersion(data).then((res) => {
      if (res.status === 200) {
        toast.success("Version Created Successfully");
        setLoading(false);
        props.setShow(false);
        props.reload();
      } else {
        toast.error("Something went wrong");
        setLoading(false);
      }
    });
  };

  return (
    <SideModal
      title={"Create Version"}
      show={props.show}
      handleClose={() => props.setShow(false)}
      body={
        <>
          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Select Product Type
            </Form.Label>
            <Select
              options={productType}
              value={selectedProductType}
              isSearchable={true}
              onChange={(e: any) => {
                setSelectedProductType(e);
              }}
              isClearable={true}
            />
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Select Version Type
            </Form.Label>
            <Select
              options={versionType}
              value={selectedVersionType}
              isSearchable={true}
              onChange={(e: any) => {
                setSelectedVersionType(e);
              }}
              isClearable={true}
            />
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Version Number
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Version No."
              value={versionNo}
              onChange={(e) => setVersionNo(e.target.value)}
            />
          </Form.Group>
          <Button className="mt-4" onClick={onSubmit} disabled={loading}>
            Create
          </Button>
        </>
      }
    />
  );
}
