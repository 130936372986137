import React from "react";
import { Alert, Badge, Card } from "react-bootstrap";
import { IoPencil } from "react-icons/io5";
import CardBasic from "../../components/Card/card.basic";
import { instrument } from "../../constants/instruments";

interface IInstructorCourse {
  title?: string;
  enrollments?: any;
  img?: any;
  approve: boolean;
  level: any
  price_per_class: number
  discount: any
  instrument: any;
  deleted: boolean
  isVisible: boolean
  onEditClick: any
}

const CustomCard = (props: IInstructorCourse) => {
  return (
    <div className="m-3">
      <Card style={{ width: "300px", minHeight: "325px" }}>
        <Card.Body>
          <div>
            <img src={props.img} width="100%" height="100px" className="rounded" />
          </div>
          <div className="mt-3">
            <div className="text-secondary d-flex align-items-center">
              <span className="fw-bold xcn-text-18">{props.title}</span>
              <span className="ms-2"> <IoPencil className="cursor-pointer" onClick={props.onEditClick}/> </span>
            </div>
            <div>
              {props.instrument && <span className="fs-12 fw-bold">Instrument: {props.instrument}</span>}
            </div>
            <div>
              {props.price_per_class && <span className="fs-12">Price Per Class: {props.price_per_class}</span>}
            </div>

          </div>
          <div>
            {props.discount && props.discount.length > 0 && <Alert className="fs-12" variant="warning"><b className="fs-14">Offers</b> {(props.discount).map((dis: any) => {
              return (
                <div>{dis.num_classes} get {dis.discount}% off</div>
              )
            })}</Alert>}
          </div>

          <div className="d-flex justify-content-start align-items-between">
            <div>
              <span className="fs-12">
                {props.approve ? <Badge bg="success">Approved</Badge> : <Badge bg="danger">Not Approved</Badge>}
              </span>
            </div>
            <div className="ms-2">
              <span className="fs-12">
                {props.isVisible ? <Badge bg="primary">Visible</Badge> : <Badge bg="danger">Not Visible</Badge>}
              </span>
            </div>
            <div className="ms-2">
              <span className="fs-12">
                {props.deleted && <Badge bg="danger">Deleted</Badge>}
              </span>
            </div>
          </div>
        </Card.Body>

      </Card>
    </div>
  );
};

export default CustomCard;
