import { useEffect, useState } from "react"
import { AdminService } from "../../services/admin.service"
import SideModal from "../SideModal/SideModal"
import { Badge } from "react-bootstrap"
import TruncateHelper from "../Helper/truncate.helper"
import moment from "moment"
import { Timeline } from 'rsuite';
import StringHelper from "../Helper/string.helper"

interface IViewSessionModal {
    show: any,
    setShow: any
}

export default function ViewSessionModal({ show, setShow }: IViewSessionModal) {

    const [sessionData, setSessionData] = useState<any>()

    const getSessionDetails = async () => {
        await AdminService.getSessionDetailsById(show).then((res) => {
            if (res.status === 200) {
                setSessionData(res.data)
            }
        })
    }

    useEffect(() => {
        if (show) {
            getSessionDetails();
        }
    }, [show])


    return (
        <SideModal
            show={show ? true : false}
            handleClose={() => setShow(undefined)}
            body={
                <>
                    <div>
                        <div style={{ marginBottom: 50 }}>
                            <h5>Session - {sessionData?.bookingId}</h5>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                            <h6 className="text-muted" style={{ width: "60%" }}>Student:</h6>
                            <h6>{sessionData?.student?.firstName}{" "}{sessionData?.student?.lastName}</h6>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                            <h6 className="text-muted" style={{ width: "60%" }}>Instructor:</h6>
                            <h6>{sessionData?.instructor?.firstName}{" "}{sessionData?.instructor?.lastName}</h6>
                        </div>

                        <div className="d-flex align-items-center mb-4">
                            <h6 className="text-muted" style={{ width: "60%" }}>Instrument:</h6>
                            <h6>{sessionData?.course?.instrument}</h6>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                            <h6 className="text-muted" style={{ width: "60%" }}>Enrollement ID:</h6>
                            <h6>{sessionData?.enrollment?.enrollmentId}</h6>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                            <h6 className="text-muted" style={{ width: "60%" }}>Enrollment Type:</h6>
                            <Badge bg="warning" className="me-1">
                                {sessionData?.enrollment?.type}
                            </Badge>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                            <h6 className="text-muted" style={{ width: "60%" }}>Status:</h6>
                            {sessionData?.status === "completed" ? <Badge bg="success" className="me-1">
                                {sessionData?.status}
                            </Badge> : sessionData?.status === "cancelled" ? <Badge bg="danger" className="me-1">
                                {sessionData?.status}
                            </Badge> : <Badge bg="secondary" className="me-1">
                                {sessionData?.status}
                            </Badge>}
                        </div>
                        <div className="d-flex align-items-center mb-4">
                            <h6 className="text-muted" style={{ width: "60%" }}>Price:</h6>
                            <h6>{sessionData?.price}</h6>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                            <h6 className="text-muted" style={{ width: "60%" }}>Payment ID:</h6>
                            <h6>{sessionData?.paymentId}</h6>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                            <h6 className="text-muted" style={{ width: "60%" }}>Schedule Time:</h6>
                            <h6>No response yet</h6>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                            <h6 className="text-muted" style={{ width: "60%" }}>Student Joined:</h6>
                            <h6>{sessionData?.joined_student ? "Joined" : "Not Joined"}</h6>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                            <h6 className="text-muted" style={{ width: "60%" }}>Instructor Joined:</h6>
                            <h6>{sessionData?.joined_instructor ? "Joined" : "Not Joined"}</h6>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                            <h6 className=" text-muted" style={{ width: "60%" }}>Dhunguru Meet ID:</h6>
                            <h6>{sessionData?.dhunguruMeetId}</h6>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                            <h6 className=" text-muted" style={{ width: "60%" }}>Schedule Time:</h6>
                            <h6>{moment(sessionData?.date_time).format("DD MMM YYYY, hh:mm A")}</h6>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                            <h6 className="text-muted" style={{ width: "60%" }}>Zoom Join Link:</h6>
                            <a href={sessionData?.zoom?.join_url} className="text-decoration-none text-center fw-bold mb-2">Link</a>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                            <h6 className=" text-muted" style={{ width: "60%" }}>Zoom Meet ID:</h6>
                            <h6>{sessionData?.zoom?.meeting_id}</h6>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                            <h6 className="text-muted" style={{ width: "60%" }}>Zoom Meet Password:</h6>
                            <h6>{sessionData?.zoom?.meeting_password}</h6>
                        </div>
                        {sessionData && sessionData?.joinLogs?.length > 0 &&
                            <>
                                <div className="mt-3 mb-4">
                                    <h5>Meet Logs</h5>
                                </div>
                                <Timeline>
                                    {sessionData && sessionData?.joinLogs?.map((data: any) => {
                                        return (
                                            <Timeline.Item>Joined by <b>{StringHelper.capitalizeFirstLetter(data?.joinedBy)} </b> at <b>{moment(data?.joinedAt).format("DD MMM YYYY, hh:mm A")}</b></Timeline.Item>
                                        )
                                    })}
                                </Timeline>
                            </>
                        }

                    </div>
                </>
            }
            title="View Session"
        />
    )
}