import {
  Container,
  Card,
  ButtonGroup,
  Button,
  Pagination,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import CreateSaleCoupnCode from "../../../components/Modals/CreateSaleCoupnCode";
import SalesCoupnCodesTable from "../../../components/Table/SalesCoupnCodes.table";
import SalesService from "../../../services/sales.service";
import CardBasic from "../../../components/Card/card.basic";
import Select from "react-select";

interface IProps {
  activeKey: string;
}

export default function SalesCoupnCodes({ activeKey }: IProps) {
  const [showCreateProduct, setShowCreateProduct] = useState<boolean>(false);
  const [pageCount, setPageCount] = useState(1);
  const [status, setStatus] = useState<any>("");
  const [coupncodes, setCoupncodes] = useState<any[]>([]);

  async function getAllCounpnCodes() {
    await SalesService.getSaleCouponCodes("", status.value)
      .then((res) => {
        if (res.status === 200) {
          setCoupncodes(res.data.data?.coupon);
        }
      })
      .catch((err) => console.log(err));
  }
  const statusOptions = [
    { label: "All", value: "all" },
    { label: "Active", value: "active" },
    { label: "Canceled", value: "inactive" },
  ];

  useEffect(() => {
    if (activeKey === "couponcodes") {
      getAllCounpnCodes();
    }
  }, [activeKey, status]);
  return (
    <>
      <Container>
        <CardBasic
          className="mt-3"
          body={
            <>
              <h6>Filter</h6>
              <Row>
                <Col md={3}>
                  <Form.Label className="fw-bold text-secondary fs-12">
                    Status
                  </Form.Label>
                  <Select
                    options={statusOptions}
                    value={status}
                    onChange={setStatus}
                  />
                </Col>
              </Row>
            </>
          }
        />
      </Container>
      <Container>
        <Card className="mt-4">
          <Card.Body>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <Card.Title>Coupon Codes</Card.Title>
              <div className="d-flex gap-2 ">
                <ButtonGroup size="sm">
                  <Button onClick={() => setShowCreateProduct(true)}>
                    Create New Coupon Code
                  </Button>
                </ButtonGroup>
              </div>
            </div>
            <div className="m-4">
              <SalesCoupnCodesTable
                tableData={coupncodes}
                reloadData={getAllCounpnCodes}
              />
            </div>
          </Card.Body>
        </Card>
      </Container>

      <CreateSaleCoupnCode
        show={showCreateProduct}
        setShow={setShowCreateProduct}
        reloadData={getAllCounpnCodes}
      />
    </>
  );
}
