import makeRequest, { makeParams, RequestMethods } from "../api/makeRequest";
import url from "../api/urls";

export default class InvoiceService {
  static async getLastStudentPayment(studentId: string) {
    return await makeRequest(
      url.invoice.getLastStudentPayment + "/" + studentId,
      RequestMethods.GET
    );
  }

  static async getAllInvoices(
    fromDate: string,
    toDate: string,
    fromInvoiceNumber: any,
    toInvoiceNumber: any
  ) {
    const params = makeParams([
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
      {
        index: "fromInvoiceNumber",
        value: fromInvoiceNumber,
      },
      {
        index: "toInvoiceNumber",
        value: toInvoiceNumber,
      },
    ]);
    return await makeRequest(
      `${url.invoice.getAllInvoices + params}`,
      RequestMethods.GET
    );
  }
  static async getCancelledIndiaInvoices(
    fromDate: string,
    toDate: string,
    fromInvoiceNumber: any,
    toInvoiceNumber: any
  ) {
    const params = makeParams([
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
      {
        index: "fromInvoiceNumber",
        value: fromInvoiceNumber,
      },
      {
        index: "toInvoiceNumber",
        value: toInvoiceNumber,
      },
    ]);
    return await makeRequest(
      `${url.invoice.getCancelledIndiaInvoices + params}`,
      RequestMethods.GET
    );
  }
  static async getCancelledInternationalInvoices(
    fromDate: string,
    toDate: string,
    fromInvoiceNumber: any,
    toInvoiceNumber: any
  ) {
    const params = makeParams([
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
      {
        index: "fromInvoiceNumber",
        value: fromInvoiceNumber,
      },
      {
        index: "toInvoiceNumber",
        value: toInvoiceNumber,
      },
    ]);
    return await makeRequest(
      `${url.invoice.getCancelledInternationalInvoices + params}`,
      RequestMethods.GET
    );
  }
  static async getCancelledMusicIndiaInvoices(
    fromDate: string,
    toDate: string,
    fromInvoiceNumber: any,
    toInvoiceNumber: any
  ) {
    const params = makeParams([
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
      {
        index: "fromInvoiceNumber",
        value: fromInvoiceNumber,
      },
      {
        index: "toInvoiceNumber",
        value: toInvoiceNumber,
      },
    ]);
    return await makeRequest(
      `${url.invoice.getCancelledMusicIndiaInvoices + params}`,
      RequestMethods.GET
    );
  }
  static async getCancelledMusicInternationalInvoices(
    fromDate: string,
    toDate: string,
    fromInvoiceNumber: any,
    toInvoiceNumber: any
  ) {
    const params = makeParams([
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
      {
        index: "fromInvoiceNumber",
        value: fromInvoiceNumber,
      },
      {
        index: "toInvoiceNumber",
        value: toInvoiceNumber,
      },
    ]);
    return await makeRequest(
      `${url.invoice.getCancelledMusicInternationalInvoices + params}`,
      RequestMethods.GET
    );
  }
  static async getAllMusicInvoices(
    fromDate: string,
    toDate: string,
    fromInvoiceNumber: any,
    toInvoiceNumber: any
  ) {
    const params = makeParams([
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
      {
        index: "fromInvoiceNumber",
        value: fromInvoiceNumber,
      },
      {
        index: "toInvoiceNumber",
        value: toInvoiceNumber,
      },
    ]);
    return await makeRequest(
      `${url.invoice.getAllMusicInvoices + params}`,
      RequestMethods.GET
    );
  }

  static async getAllInternationalInvoices(
    fromDate: string,
    toDate: string,
    fromInvoiceNumber: any,
    toInvoiceNumber: any
  ) {
    const params = makeParams([
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
      {
        index: "fromInvoiceNumber",
        value: fromInvoiceNumber,
      },
      {
        index: "toInvoiceNumber",
        value: toInvoiceNumber,
      },
    ]);
    return await makeRequest(
      `${url.invoice.getAllInvoicesInternational + params}`,
      RequestMethods.GET
    );
  }
  static async getAllMusicInternationalInvoices(
    fromDate: string,
    toDate: string,
    fromInvoiceNumber: any,
    toInvoiceNumber: any
  ) {
    const params = makeParams([
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
      {
        index: "fromInvoiceNumber",
        value: fromInvoiceNumber,
      },
      {
        index: "toInvoiceNumber",
        value: toInvoiceNumber,
      },
    ]);
    return await makeRequest(
      `${url.invoice.getAllMusicInternationalInvoices + params}`,
      RequestMethods.GET
    );
  }

  static async getInvoicebyId(payload: any) {
    return await makeRequest(
      `${url.invoice.getAllInvoices}${payload?.id}`,
      RequestMethods.GET
    );
  }
  static async getInvoiceData(instructorId: any, fromDate: any, toDate: any) {
    const params = makeParams([
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
    ]);

    return await makeRequest(
      `${url.invoice.getInvoiceData}${"/" + instructorId + params}`,
      RequestMethods.GET
    );
  }

  static async getInternationalInvoicebyId(payload: any) {
    return await makeRequest(
      `${url.invoice.getAllInvoicesInternational}${payload?.id}`,
      RequestMethods.GET
    );
  }

  static async getInvoices(
    pageCount: any,
    pageNumber: any,
    invoiceType: any,
    from: any,
    to: any,
    instructor?: any
  ) {
    const params = makeParams([
      {
        index: "count",
        value: pageCount,
      },
      {
        index: "page",
        value: pageNumber,
      },
      {
        index: "invoiceType",
        value: invoiceType,
      },
      {
        index: "fromDate",
        value: from,
      },
      {
        index: "toDate",
        value: to,
      },
      {
        index: "instructor",
        value: instructor,
      },
    ]);
    return await makeRequest(
      `${url.invoice.getInvoices + params}`,
      RequestMethods.GET
    );
  }

  static async fetchAllInvoices(instId: any, fromDate: any, toDate: any) {
    const params = makeParams([
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
    ]);
    return await makeRequest(
      `${url.invoice.fetchAllInvoices + "/" + instId + params}`,
      RequestMethods.GET
    );
  }

  static async recordInvoices(
    instructorId: any,
    commission: any,
    invoiceIds: any[],
    incentiveIds: any[]
  ) {
    return await makeRequest(
      `${url.invoice.recordInvoices + "/" + instructorId}`,
      RequestMethods.POST,
      {
        commission,
        invoiceIds,
        incentiveIds,
      }
    );
  }
}
