import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import SideModal from "../SideModal/SideModal";
import { AdminService } from "../../services/admin.service";
import AsyncSelect from "react-select/async";
import moment from "moment";
import SalesService from "../../services/sales.service";

interface ICreateSaleModal {
  show: boolean;
  setShow: any;
  reloadData: any;
  updateId?: string;
}

export default function CreateSaleCoupnCode(props: ICreateSaleModal) {
  const [loading, setLoading] = useState<boolean>(false);
  const [itemDetails, setItemDetails] = useState({
    couponName: "",
    discountPercent: 0,
    maxDiscount: 0,
    isCancelled: false,
    validTill: moment().format("YYYY-MM-DD"),
  });
  const [selectedInstructor, setSelectedInstructor] = useState<any>({});
  let timeoutId: any;

  async function onSubmit() {
    if (
      !itemDetails.couponName ||
      !itemDetails.discountPercent
    ) {
      toast.error("Please fill all the fields");
      console.log(selectedInstructor);
      return;
    }
    if (props.updateId) {
      handleUpdate();
      return;
    }

    let payload: any = {
      ...itemDetails,

    };
    if (selectedInstructor && selectedInstructor.value) {
      payload = {
        ...payload,
        instructorId: selectedInstructor?.value,
      };
    }

    setLoading(true);
    await SalesService.createSaleCouponCode(payload)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data?.message);
          props.reloadData();
          handleModalClose();
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.messages);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function handleUpdate() {
    let payload = {
      ...itemDetails,
      instructorId: selectedInstructor?.value,
    };
    setLoading(true);
    if (props.updateId) {
      await SalesService.updateSaleCouponCode(props.updateId, payload)
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data);
            toast.success(res.data?.message);
            props.reloadData();
            handleModalClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.response?.messages);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  function handleModalClose() {
    setItemDetails({
      couponName: "",
      discountPercent: 0,
      maxDiscount: 0,
      isCancelled: false,
      validTill: moment().format("YYYY-MM-DD"),
    });
    setSelectedInstructor({});
    props.setShow();
  }

  function fetchAllInstructor(inputValue: any, callback: ([]) => void) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    if (inputValue.length > 3) {
      timeoutId = setTimeout(() => {
        AdminService.getAllInstructor(inputValue).then((res) => {
          if (res.status === 200) {
            const instructors = res.data.data.map((data: any) => {
              return {
                value: data._id,
                label: `${data.firstName}  ${data.lastName} - ${data.email}`,
              };
            });
            callback(instructors);
          }
        });
      }, 500);
    }
  }

  async function getCoupnData() {
    await SalesService.getSaleCouponCodes(props.updateId)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.data?.coupon.length > 0) {
            setItemDetails(res.data.data?.coupon[0]);
            setSelectedInstructor({
              value: res.data.data?.coupon[0]?.instructorId?._id,
              label: `${res.data.data?.coupon[0]?.instructorId?.user?.firstName}  ${res.data.data?.coupon[0]?.instructorId?.user?.lastName} - ${res.data.data?.coupon[0]?.instructorId?.user?.email}`,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.messages);
        handleModalClose();
      });
  }
  useEffect(() => {
    if (props.show && props.updateId) {
      getCoupnData();
    }
  }, [props.show]);
  return (
    <SideModal
      title={props.updateId ? "Update Coupon Code" : "Create Coupon Code"}
      show={props.show}
      handleClose={handleModalClose}
      body={
        <>
          <Form.Group>
            <Form.Label className="fw-bold text-secondary fs-12  mt-4">
              Instructor
            </Form.Label>
            <AsyncSelect
              loadOptions={fetchAllInstructor}
              onChange={(e: any) => {
                setSelectedInstructor(e);
              }}
              value={selectedInstructor}
              isClearable={true}
            />
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Coupon Code
            </Form.Label>
            <Form.Control
              type="text"
              name="couponName"
              value={itemDetails.couponName}
              onChange={(e: any) =>
                setItemDetails({ ...itemDetails, couponName: e.target?.value })
              }
            />
          </Form.Group>

          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Discount Percentage
            </Form.Label>
            <Form.Control
              type="number"
              name="discountPercent"
              max={100}
              min={0}
              value={itemDetails.discountPercent}
              onChange={(e: any) =>
                setItemDetails({
                  ...itemDetails,
                  discountPercent: e.target?.value,
                })
              }
            />
          </Form.Group>

          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Max Discount
            </Form.Label>
            <Form.Control
              type="number"
              name="maxDiscount"
              min={0}
              value={itemDetails.maxDiscount}
              onChange={(e: any) =>
                setItemDetails({ ...itemDetails, maxDiscount: e.target?.value })
              }
            />
          </Form.Group>

          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Valid Till
            </Form.Label>
            <Form.Control
              type="date"
              name="validTill"
              value={moment(itemDetails.validTill).format("YYYY-MM-DD")}
              onChange={(e: any) =>
                setItemDetails({ ...itemDetails, validTill: e.target?.value })
              }
            />
          </Form.Group>
          <Form.Switch
            className="mt-2"
            label={itemDetails.isCancelled ? "Cancelled" : "Active"}
            checked={!itemDetails.isCancelled}
            onChange={(e: any) =>
              setItemDetails({
                ...itemDetails,
                isCancelled: !itemDetails.isCancelled,
              })
            }
          />

          <Button className="mt-3 w-100" onClick={onSubmit} disabled={loading}>
            {!props.updateId ? "Create New Coupn Code" : "Update"}
          </Button>
        </>
      }
    />
  );
}
