import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Container,
  Nav,
  Pagination,
} from "react-bootstrap";
import toast from "react-hot-toast";
import CreateAdminsModal from "../../../components/Modals/CreateAdminsModal";
import AllUsersTable from "../../../components/Table/AllUsersTable";
import { AdminService } from "../../../services/admin.service";
import UserService from "../../../services/user.service";
import RoleTable from "../../../components/Table/RoleTable";

export default function AllUsers() {
  const [showCreateLink, setShowCreateLink] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [totalPages, setTotalPages] = useState(20);
  const [pageCount, setPageCount] = useState(1);
  const [selectedPage, setSelectedPage] = useState(1);

  const getAllAdminUsers = async () => {
    await UserService.getAllAdminUsers()
      .then((res: any) => {
        if (res.status === 200) {
          if (res?.data?.length > 0) {
            setTableData(res?.data);
            console.log(res?.data, "Admin Data");
          }
        }
      })
      .catch((err: any) => {
        toast.error("Unable to fetch Admins Details");
      });
  };

  useEffect(() => {
    getAllAdminUsers();
  }, [showCreateLink]);

  const checkIfRoleAllowed = (roles: any[], checkRole: any, index: any) => {
    const exist = roles
      ?.filter((data: any) => data?.role === checkRole && data?.allowed === true)
      .map((data: any) => {
        return data?.allowed;
      });

    return exist[0];
  };
  const handleRoleChange = async (
    roles: any,
    _id: any,
    e: any,
    chkrole: any
  ) => {
    const other_roles = roles.filter((role: any) => role.role !== chkrole);
    let curr_role = roles.find((role: any) => role.role === chkrole);
    let new_current_role = {};
    if (curr_role) {
      new_current_role = { ...curr_role, allowed: e.target.checked };
    } else {
      new_current_role = {
        allowed: e.target.checked,
        role: chkrole,
      };
    }
    const new_roles = [...other_roles, new_current_role];
    await UserService.changeUserRole(new_roles, _id)
      .then((res: any) => {
        if (res.status === 200) {
          toast.success("Role Updated");
          getAllAdminUsers();
        }
      })
      .catch((e: any) => {
        console.error(e);
        toast.error("Something went wrong while updating roles");
      });
  };

  return (
    <>
      <Container className="pt-4">
        <Card className="mt-4">
          <Card.Body>
            <div className="d-flex justify-content-between align-items-center">
              <Card.Title>All Accounts</Card.Title>
              <div className="d-flex justify-content-between align-items-center">
                <Pagination>
                  <Pagination.First />
                  <Pagination.Prev
                    onClick={() => {
                      setPageCount(pageCount - 1);
                    }}
                  />
                  <Pagination.Item>{pageCount}</Pagination.Item>
                  <Pagination.Next
                    onClick={() => {
                      setPageCount(pageCount + 1);
                    }}
                  />
                  <Pagination.Last />
                </Pagination>
                <Button size="sm" className="ms-3 mb-3" onClick={() => setShowCreateLink(true)}>
                  Create New Admin
                </Button>
              </div>
            </div>

            <div className="m-4">
              <RoleTable
                tableData={tableData}
                checkIfRoleAllowed={checkIfRoleAllowed}
                handleRoleChange={handleRoleChange}
                reloadData={getAllAdminUsers}
              // checkedItems={checkedItems}
              />
            </div>
          </Card.Body>
        </Card>
        <CreateAdminsModal
          show={showCreateLink}
          setShowModal={setShowCreateLink}
          reloadData={() => getAllAdminUsers()}
        />
      </Container>
    </>
  );
}
