import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import DetailStudent from "./Detail.Student";
import StatsStudents from "./Stats.student";
import ControlbarStudent from "../../../../../components/ControlBar/ControlBar.student";

interface IStudentDetail {
  studentId: string;
  isEnrollment?: boolean;
  title?: string;
}

export default function StudentDetail(props: IStudentDetail) {
  return props.isEnrollment ? (
    <Container>
      <Row className="mt-3">
        <Col md={12}>
          <DetailStudent
            studentId={props.studentId}
            isEnrollment={props.isEnrollment}
            title={props.title}
          />
        </Col>
      </Row>
    </Container>
  ) : (
    <Container>
      <Row className="mt-3">
        <Col md={8}>
          <DetailStudent studentId={props.studentId} />
        </Col>
        <Col md={4}>
          <Row>
            <StatsStudents studentId={props.studentId} />
          </Row>
          <Row className="mt-3">
            <ControlbarStudent studentId={props.studentId} />
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
