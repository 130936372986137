import {
  Alert,
  Badge,
  Button,
  Col,
  Form,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";

import SideModal from "../SideModal/SideModal";
import { useEffect, useState } from "react";

import ReactDatePicker from "react-datepicker";

import CenterModal from "../CenterModal/CenterModal";
import moment from "moment";
import InvoiceService from "../../services/invoice.service";
import { AdminService } from "../../services/admin.service";
import { toast } from "react-hot-toast";
import TabsIndex from "../Tabs/Tabs.index";

interface IRecordInvoiceModal {
  startDate: any;
  endDate: any;
  instId: any;
  onHide: any;
}

export default function RecordInvoiceModal(props: IRecordInvoiceModal) {
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [invoiceData, setInvoiceData] = useState<any>();
  const [dhunguruCommission, setDhunguruCommission] = useState<any>(20);

  const getInvoiceData = async () => {
    const fromDate = moment(dateRange.startDate).format("YYYY-MM-DD");
    const toDate = moment(dateRange.endDate).format("YYYY-MM-DD");
    if (!props.instId) {
      return;
    }
    await InvoiceService.getInvoiceData(props.instId, fromDate, toDate)
      .then((res: any) => {
        if (res.status === 200) {
          setInvoiceData(res?.data);

          setDhunguruCommission(res?.data?.dhunguruCommission);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const recordInvoices = async () => {
    const invoiceIds = invoiceData?.invoices?.map(
      (invoice: any) => invoice._id
    );
    const incentiveIds = invoiceData?.incentives?.map(
      (incentive: any) => incentive._id
    );

    await InvoiceService.recordInvoices(
      props.instId,
      Number(dhunguruCommission),
      invoiceIds,
      incentiveIds
    ).then((res) => {
      if (res.status == 200) {
        props.onHide();
        toast.success("Invoices recorded successfully");
      }
    });
  };

  useEffect(() => {
    if (props.startDate && props.endDate) {
      setDateRange({
        startDate: new Date(props.startDate),
        endDate: new Date(props.endDate),
      });
    }
  }, [props.startDate, props.endDate, props.instId]);

  useEffect(() => {
    getInvoiceData();
  }, [dateRange]);


  const tabs=[{
    name: "Invoices",
   icon: "",
    key: "invoices",
  },
  {
    name: "Incentives",
icon:"",
    key: "incentives",
  }
]

const [selectedTab, setSelectedTab] = useState<any>(tabs[0].key);

const renderTabContent = () => {
  switch (selectedTab) {
    case "invoices":
      return (
        <>
          <Table striped borderless hover responsive size="sm">
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Receipt Id</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Enrollnment Id</th>
                </tr>
              </thead>
              <tbody>
                {invoiceData?.invoices?.map((invoice: any, index: number) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{invoice.receipt_id}</td>
                    <td>{moment(invoice.date).format("DD-MM-YYYY")}</td>
                    <td>{invoice.amount}</td>
                    <td>{invoice.enrollment?.enrollmentId}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
        </>
      );
    case "incentives":
      return(
        <>
            <Table striped borderless hover responsive size="sm">
              <thead>
                <tr style={{textAlign:"center"}}>
                  <th>S. No.</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Enrollnment Id</th>
                  <th>Session Count</th>
                  
                  <th>Transaction Status</th>
                </tr>
              </thead>
              <tbody>
                {invoiceData?.incentives?.map((incentive: any, index: number) => (
                  <tr key={index} style={{textAlign:"center"}}>
                    <td>{index + 1}</td>
                    <td>{moment(incentive.date).format("DD-MM-YYYY")}</td>
                    <td>{incentive.amount}</td>
                    <td>{incentive.enrollmentId.enrollmentId}</td>
                    <td>{incentive.sessionCount}</td>
                 
                    <td>
                       <Badge bg="primary"> {incentive.transactionStatus}</Badge>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>       
        </>
      )
  }
}


console.log(invoiceData)

  return (
    <CenterModal
      title={"Record Invoice"}
      show={props.instId ? true : false}
      handleClose={() => props.onHide()}
      body={
        <>
          <Form.Label className="fw-bold text-secondary fs-12">
            Date Range
          </Form.Label>
          <ReactDatePicker
            onChange={(e: any) => {
              setDateRange({
                startDate: e[0],
                endDate: e[1],
              });
            }}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            selectsRange
            dateFormat="dd/MM/yyyy"
            className="form-control"
          />
          <div className="d-flex flex-column border border-black bg-light rounded  w-100 h-100 p-3 mt-3 mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="fw-bold fs-12">
                  <span>{props.instId}</span>{" "}
                </div>
                <div className="fw-bold fs-12">
                  <span className="text-muted me-1">Instructor Name:</span>
                  <span>
                    {invoiceData?.instructorDetails?.firstName +
                      " " +
                      invoiceData?.instructorDetails?.lastName}
                  </span>{" "}
                </div>
                <div className="fw-bold fs-12">
                  <span className="text-muted me-1">Email:</span>
                  <span>{invoiceData?.instructorDetails?.email}</span>{" "}
                </div>
              </div>
              <div className="fw-bold fs-12">
                <div>
                  Total Amount:{" "}
                  <span className="text-primary">
                    {invoiceData?.totalAmount}
                  </span>{" "}
                </div>
                <div>
                  Total Invoices: <span>{invoiceData?.totalInvoices}</span>{" "}
                </div>
                <div>
                  Commission:{" "}
                  <span>
                    {invoiceData?.dhunguruCommission
                      ? invoiceData.dhunguruCommission + "%"
                      : "Not Set (Default: 20%)"}
                  </span>{" "}
                </div>
              </div>
              <div className="fw-bold fs-12">
                <div>
                  Incentive Amount:{" "}
                  <span> {invoiceData?.totalIncentivesAmount}</span>{" "}
                </div>
                <div>
                  Incentive Count:{" "}
                  <span>{invoiceData?.incentives?.length}</span>{" "}
                </div>
              </div>
              <div className="fw-bold fs-12">
                <div>
                  Fines: <span>{invoiceData?.totalInstructorFineAmount}</span>{" "}
                </div>
                <div>
                  Classwise: <span>{invoiceData?.per_classes_invoices}</span>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="border border-black bg-light rounded  w-100 h-100 p-3 mt-3 mb-3">
            <Form.Group>
              <Form.Label className="fw-bold fs-12">
                Dhunguru Commission
              </Form.Label>
              <Row>
                <Col>
                  <Form.Control
                    type="number"
                    value={dhunguruCommission}
                    onChange={(e) => setDhunguruCommission(e.target.value)}
                  />
                </Col>
                <Col>
                  <Button variant="danger" onClick={() => recordInvoices()}>
                    Record Invoices and Incentives
                  </Button>
                </Col>
              </Row>
            </Form.Group>
          </div>
          <div>
            <div>
            <TabsIndex
              selectedTabIndex={tabs.findIndex(tab => tab.key === selectedTab)}
              setSelectedTabIndex={(index: number) => setSelectedTab(tabs[index].key)}
              tabs={tabs}
            />
            <div className="mt-3">
              {renderTabContent()}
            </div>
            </div>
           
           
          </div>
        </>
      }
    />
  );
}
