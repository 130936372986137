import { ExportToCsv } from 'export-to-csv-fix-source-map';
import React, { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap';
import { AdminService } from '../../services/admin.service';

interface ICsvDetailBookedSessionTable{
    tableData:any;
}
interface IOptions {
  fieldSeparator: any;
  quoteStrings: any;
  decimalSeparator: any;
  showLabels: any;
  showTitle: any;
  title: any;
  useTextFile: any;
  useBom: boolean;
  useKeysAsHeaders: boolean;
}
const CsvDetailBookedSessionTable = ({tableData}:ICsvDetailBookedSessionTable) => {
 

let csvOptions: IOptions = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  showTitle: true,
  title: "CSV Data",
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
};

  const csvExporter = new ExportToCsv(csvOptions);

const handleDownload=()=>{
 if (tableData?.length > 0) {
   csvExporter.generateCsv(tableData);
 }
}
 


  return (
    <Table striped responsive size="sm">
      <thead>
        <tr>
          {tableData &&
            Object.keys(tableData[0]).map((header) => {
              return <td className="fw-bold text-secondary fs-12">{header}</td>;
            })}
        </tr>
      </thead>
      <tbody>
        {tableData?.map((row: any) => {
          return (
            <tr>
              {Object.values(row).map((column: any) => {
                return (
                  <td className="fw-bold text-secondary fs-12">
                    {column.length !== 0 ? column : "--"}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
      <div className="mt-3">
        <Button size="sm" onClick={handleDownload}> Download</Button>
      </div>
    </Table>
  );
}

export default CsvDetailBookedSessionTable
