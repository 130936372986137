import React, { useEffect, useState } from "react";
import { Badge, Button, Col, Form, Image, Row } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";
import CardBasic from "../../../../../components/Card/card.basic";
import InstructorService from "../../../../../services/instructor.service";
import { AdminService } from "../../../../../services/admin.service";
import toast from "react-hot-toast";
import InstructorDetailModal from "./InstructorDetail.modal";

interface IInstructorDetails {
  id: string;
  title?: string;
  isEnrollment?: boolean;
}

const InstructorDetail = (props: IInstructorDetails) => {
  const [instructor, setInstructor] = useState<any>({});
  const [show, setShow] = useState<boolean>(false);

  const getDetails = async () => {
    await InstructorService.getDetails(props.id).then((res) => {
      if (res.status === 200) {
        setInstructor(res.data.userData);
      }
    });
  };

  const handleToggle = async (toggle_id: any) => {
    await AdminService.toggleBlock(toggle_id)
      .then((res) => {
        if (res.status === 200) {
          console.log(res);

          toast.success("Updated ");
          getDetails();
        }
      })
      .catch((err: any) => {
        toast.error("Unable to Update Student Detail");
      });
  };
  useEffect(() => {
    getDetails();
  }, []);

  return (
    <>
      <CardBasic
        body={
          <>
            {props.isEnrollment && (
              <div className="d-flex justify-content-start fw-bold fs-grey fs-14 mb-2">
                {props.title}
              </div>
            )}
            <div className="d-flex justify-content-start">
              <div>
                <Image
                  src={instructor.avatar}
                  height="150px"
                  width="150px"
                  className="rounded"
                />
              </div>
              <div className="ms-3 w-100">
                <div className="d-flex justify-content-between">
                  <span className="fw-bold fs-grey fs-14">
                    {instructor.firstName + " " + instructor.lastName}{" "}
                    <AiFillCheckCircle className="text-success" />
                    <div className="fs-12 fs-dhunprimary">
                      {instructor.email}
                    </div>
                  </span>
                  <span className="fs-grey fs-10 fw-bold">
                    {instructor._id}
                  </span>
                </div>
                <div className="mt-3 fs-14">
                  <h6 className="fs-grey"> Basic Details</h6>
                  <div className="bg-muted fs-12 w-100 rounded p-2">
                    <Row className="font-italic-imp">
                      <Col sm={6}>
                        Gender: {instructor.gender || "N.A."}
                        <br />
                        Email:{" "}
                        {instructor.isEmailConfirm
                          ? "Verified"
                          : "Not Verified"}
                        <br />
                        Source:{" "}
                        {instructor.utms
                          ? instructor.utms.utm_source +
                            " " +
                            instructor.utms.utm_campaign
                          : "N.A."}
                      </Col>
                      <Col sm={6}>
                        Star Teacher: {instructor.hasTakenDemo ? "Yes" : "No"}
                        <br />
                        <div className="d-flex flex-row gap-2">
                          Blocked:
                          <Form>
                            <Form.Check
                              type="switch"
                              id="toggle-switch"
                              checked={instructor.isBlocked}
                              onClick={() => handleToggle(instructor._id)}
                            />
                          </Form>
                          {instructor.isBlocked ? (
                            <Badge bg="danger">Blocked</Badge>
                          ) : (
                            <></>
                          )}
                        </div>
                        <br />
                        Phone: {instructor?.countryCode}{" "}
                        {instructor.phone || "N.A."}
                      </Col>
                      <Col>
                        <Button onClick={() => setShow(true)}>Edit</Button>
                      </Col>
                    </Row>
                    <br />
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </>
        }
      />

      <InstructorDetailModal
        show={show}
        handleClose={() => setShow(false)}
        reload={getDetails}
        instructor={instructor}
      />
    </>
  );
};

export default InstructorDetail;
