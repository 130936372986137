import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import UserService from "../services/user.service";

import { onSetToken } from "../store/actions/auth";
import { useAppDispatch, useAppSelector } from "../store/reduxHooks";
import HomePage from "../views/private/Components/HomePage";
import { privateConfig, publicConfig } from "./config";

const PrivateRouter = () => {
  const { pathname } = useLocation();
  const isLoggedIn = useAppSelector((state) => state.auth.loggedIn);
  const authToken = sessionStorage.getItem("authKey") || "";
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isLoggedIn && authToken) {
      dispatch(onSetToken({ token: authToken }));
    }
  }, [isLoggedIn, authToken, dispatch]);

  return (
    <>
      {isLoggedIn && <HomePage />}
      {!authToken && <Navigate state={{ redirectTo: pathname }} to="/" />}
    </>
  );
};
const MainRoutes = () => {
  return (
    <Routes>
      {publicConfig.map((config) => (
        <Route
          key={config.path}
          path={config.path}
          element={<config.element />}
        />
      ))}
      <Route path="/admin" element={<PrivateRouter />}>
        {privateConfig.map((config) => (
          <Route
            key={config.path}
            path={config.path}
            element={<config.element />}
          />
        ))}
      </Route>
      <Route path="*" element={<Navigate to="/admin/login" />} />
    </Routes>
  );
};

export default MainRoutes;
