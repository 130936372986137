import React, { useEffect, useState } from "react";
import { Button, Col, Container, Dropdown, Form, Row, Table } from "react-bootstrap";
import "./table.css";
import { AdminService } from "../../services/admin.service";
import CardBasic from "../Card/card.basic";
import { BiDotsVertical } from "react-icons/bi";
import CustomToggle from "../Menu/CustomMenu";
import toast from "react-hot-toast";
import RenewalBadge from "../Labels/Renewal.badge";
import RenewalEnumModal from "../Modals/RenewalEnumModal";
import Select from "react-select";
import StudentService from "../../services/student.service";
import { RenewalStatusEnum, renewalSelectMenu } from "../../enum/renewal.enum";
import RenewalSummaryModal from "../Modals/RenewalSummaryModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { IsLoading } from "../../App.context";

interface IRenewalsTable {
  reload: any
}

export default function RenewalsTable(props: IRenewalsTable) {
  const [renewalsTableData, setRenewalsTableData] = useState<any>([]);
  // const [shiftIndex, setShiftIndex] = useState<any>(-1);

  const [enumsData, setEnumsData] = useState<any>();
  const [pageSize, setPageSize] = useState(20);

  const [changeStatus, setChangeStatus] = useState<any>(undefined);

  const [students, setStudents] = useState<any>([]);

  const [selectedStudents, setSelectedStudents] = useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState(1);

  const [selectedRenewal, setSelectedRenewal] = useState<any>(undefined)

  const {loading, setLoading}:any = React.useContext(IsLoading);

  const getNotesEnums = async () => {
    await AdminService.getNotesEnums().then((res) => {
      if (res.status === 200) {
        setEnumsData(res.data.map((data: any) => {
          return {
            label: data,
            value: data
          }
        }))
      }
    })
  }



  const fetchRenewals = async () => {
    setLoading(true);
    await AdminService.getRenewals(selectedStudents.map((data:any) => {
      return data.value
    }), selectedStatus.map((data:any) => {
      return data.value
    }), pageNumber, pageSize).then((res) => {
      if (res.status === 200) {
        setRenewalsTableData(res.data.studentsFinalData)
        setLoading(false);
      }
    }).catch(e =>{
      setLoading(false);
    });
  };

  const deleteRenewal = async (renewalId: any) => {
    await AdminService.deleteRenewal(renewalId).then((res) => {
      if (res.status === 200) {
        fetchRenewals();
      }
    });
  };

  const renewalStudents = async () => {
    await StudentService.renewalStudents().then((res) => {
      if (res.status === 200) {
        setStudents(res.data.map((data:any) => {
          return {
            label: data.firstName + " " + data.lastName + " - " + data.email,
            value: data._id
          }
        }));
      }
    });
  }

  const onNextClick = () => {
    setPageNumber(pageNumber + 1)
  }
  
  const onPreviousClick = () => {
    if(pageNumber === 1) return
    setPageNumber(pageNumber - 1)
  }


  useEffect(() => {
    getNotesEnums();
  }, [changeStatus])

  useEffect(() => {
    renewalStudents();
    fetchRenewals();
  },[changeStatus, props.reload, pageNumber])

  return (
    <>
      <Container>
        <Row>
          <Col>
            <CardBasic
              className="mt-3"
              body={
                <>
                  <div>
                    <h6>
                      Filters
                    </h6>
                    <div className="mt-3">
                        <Row>
                          <Col md={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label className="fw-bold fs-12">Student</Form.Label>
                              <Select
                                options={students}
                                value={selectedStudents}
                                onChange={(e: any) => setSelectedStudents(e)}
                                isMulti={true}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label className="fw-bold fs-12">Status</Form.Label>
                              <Select
                                options={renewalSelectMenu}
                                isMulti={true}
                                value={selectedStatus}
                                onChange={(e: any) => setSelectedStatus(e)}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                      <Form.Label className="fs-12 fw-bold">Page Count</Form.Label>
                      <Select
                        options={[
                          {
                            value: 10,
                            label: 10,
                          }, {
                            value: 20,
                            label: 20,
                          },
                          {
                            value: 30,
                            label: 30,
                          }
                        ]}
                        value={{
                          value: pageSize,
                          label: pageSize,
                        }}
                        onChange={(e: any) => { setPageSize(e.value) }}
                      /></Col>
                        </Row>
                        <Row className="mt-1">
                          <Col>
                            <Button variant="primary" className="btn-sm" onClick={fetchRenewals}>
                              Apply
                              </Button>
                          </Col>
                          </Row>
                    </div>
                  </div>
                </>
              }/>
          </Col>
        </Row>
        <Row>
          <Col md={7}>
            <div className="d-flex justify-content-start align-items-center">
              <div>
                <div className="h5">
                  Renewal Students
                </div>
                <div>

                </div>
              </div>
            </div>
          </Col>
          <Col md={5}>
            <div className="d-flex justify-content-end align-items-center">
              <Button size="sm" className="me-2" onClick={onPreviousClick} disabled={pageNumber<=1 ? true: false}><FontAwesomeIcon icon={faCaretLeft} />  Previous </Button>
              <Button size="sm" onClick={onNextClick}>Next <FontAwesomeIcon icon={faCaretRight}  /> </Button>
            </div>
            <div className="d-flex justify-content-end align-items-center mt-2">
              <p className="text-secondary fs-12">Page {pageNumber} - Showing {((pageNumber*pageSize) - pageSize) + 1} to {(pageNumber*pageSize)} entries</p>
            </div>

          </Col>
        </Row>
        <Row>
          <Col>
            <CardBasic
              body={
                <>
                  <Table striped hover responsive size="sm">
                    <thead>
                      <tr className="dhun-table-header text-secondary">
                        <th>S.No.</th>
                        <th>NAME</th>
                        <th>EMAIL</th>
                        <th>PHONE</th>
                        <th>Status</th>
                        <th>TOTAL PAID</th>
                        <th>PAID ENROLLMENTS</th>
                        <th>TOTAL SESSIONS</th>
                        <th>SESSIONS COMPLETED</th>
                        <th>SESSIONS LEFT</th>
                        <th>REQUESTS LEFT</th>
                        <th>ACTIONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renewalsTableData && renewalsTableData?.length > 0
                        ? renewalsTableData?.map((data: any, index: any) => {
                          return (
                            <tr key={data?._id}>
                              <td>
                                {(pageNumber*pageSize)-pageSize + index + 1}
                              </td>
                              <td className="fw-bold text-primary cursor-pointer"  onClick={() => setSelectedRenewal(data.renewal._id)}>
                                {data.renewal.student.firstName} {data.renewal.student.lastName}
                              </td>
                              <td>
                                {data.renewal.student.email}
                              </td>
                              <td>
                                {data.renewal.student.phone}
                              </td>
                              <td onClick={() => setChangeStatus(data.renewal._id) }>
                                <RenewalBadge renewal={data.renewal.status} />
                              </td>
                              <td>
                                {data.enrollmentData.totalPaid}
                              </td>
                              <td>
                                {data.enrollmentData.paidEnrollmentsCount}
                              </td>
                              <td>
                                {data.enrollmentData.totalClasses}
                              </td>
                              <td>
                                {data.enrollmentData.classesCompleted}
                              </td>
                              <td>
                                {data.enrollmentData.classesLeft}
                              </td>
                              <td>
                                {data.enrollmentData.classesLeftToRequest}
                              </td>

                              <td>
                                <Dropdown>
                                  <Dropdown.Toggle
                                    as={CustomToggle}
                                    id="dropdown-custom-components"
                                  >
                                    <BiDotsVertical />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      className="text-secondary fs-12 align-items-center d-flex fw-bold"
                                      onClick={() => deleteRenewal(data.renewal._id)}
                                    >
                                      Delete
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      className="text-secondary fs-12 align-items-center d-flex fw-bold"
                                      onClick={() => setChangeStatus(data.renewal._id)}
                                    >
                                      Toggle Status
                                    </Dropdown.Item>
                                    {/* <Dropdown.Item
                                      className="text-secondary fs-12 align-items-center d-flex fw-bold"
                                      onClick={() => { setShiftIndex(index) }}
                                    >
                                      Update
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      className="text-secondary fs-12 align-items-center d-flex fw-bold"
                                      onClick={() => { setShiftIndex(index) }}
                                    >
                                      View Details
                                    </Dropdown.Item> */}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          );
                        })
                        : "No Data Available"}
                    </tbody>
                  </Table>
                </>
              }
            />
          </Col>
        </Row>
      </Container>


      <RenewalEnumModal
        renewalId={changeStatus}
        onHide={() => setChangeStatus(undefined)}
      />

    <RenewalSummaryModal
        renewalId={selectedRenewal}
        onHide={() => setSelectedRenewal(undefined)}
      />

      {/* <RenewalModal
        show={shiftIndex >= 0 ? true : false}
        onClose={() => setShiftIndex(-1)}
        data={renewalsTableData && renewalsTableData[shiftIndex]}
        handleNotesChange={handleNotesDtata}
        enumsData={enumsData}
        selectEnums={selectEnums}
        setSelectEnums={setSelectEnums}
        handleNotesUpdate={updateRenewal}
      /> */}
    </>
  );
}
