import moment from "moment";
import { useState } from "react";
import { Badge, Dropdown, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { BiDotsVertical } from "react-icons/bi";
import { IoTrashBinSharp, IoVideocam } from "react-icons/io5";
import { useNavigate } from "react-router";
import CustomToggle from "../../../../../components/Menu/CustomMenu";
import DeleteConfirmation from "../../../../../components/Modals/DeleteConfirmation";
import {
  FontBold,
  NormalText,
} from "../../../../../components/Text/TableText.formats";
import { AdminService } from "../../../../../services/admin.service";

interface IEnrollmentTable {
  totalEnrollment: any;
  reloadData: any;
  deleteAllowed: boolean;
  zoomLinkShow: boolean;
}

const EnrollmentDetailTable = (props: IEnrollmentTable) => {
  const [sessionId, setSessionId] = useState<any>();
  const [type, setType] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [deleteMessage, setDeleteMessage] = useState<any>("");
  const navigate: any = useNavigate();

  const showDeleteModal = (type: any, id: any) => {
    setType(type);
    setSessionId(id);
    if (type) {
      setDeleteMessage(
        `Are you sure you want to delete enrollment with Id : ${type}`
      );
    }
    setDisplayConfirmationModal(true);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const submitDelete = async (type: any, _id: any) => {
    setDisplayConfirmationModal(false);
    if (displayConfirmationModal === true) {
      await AdminService.deleteSession(_id)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Session Deleted Successfully");
            props.reloadData();
          }
        })
        .catch((e) => {
          console.error(e);
          toast.error(e.response.data);
        });
    }
  };

  return (
    <>
      <Table striped borderless hover responsive size="sm">
        <thead>
          <tr className="dhun-table-header text-secondary">
            <th>#</th>
            <th>Session Id</th>
            <th>Enrollment Id</th>
            <th>Date Time</th>
            <th>Instrument</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.totalEnrollment && props.totalEnrollment?.length > 0
            ? props.totalEnrollment?.map((data: any, index: any) => {
                return (
                  <tr key={data?._id}>
                    <td>
                      <NormalText value={index + 1} />
                    </td>
                    <td>
                      <FontBold value={data.bookingId} />
                      <span className="fs-10 ms-2">
                        {data.type === "demo" && (
                          <Badge bg="secondary">Demo</Badge>
                        )}
                      </span>
                    </td>

                    <td
                      onClick={() => {
                        navigate(
                          "/admin/search/enrollment/detail/" +
                            data?.enrollment?._id
                        );
                      }}
                    >
                      <u className="fst-italic text-primary fs-12 fs-underline cursor-pointer">
                        {data?.enrollment?.enrollmentId}
                      </u>
                    </td>

                    <td>
                      <span className="font-monospace fs-12">
                        {moment(data.date_time).format("DD MMM YY hh:mm a")}
                      </span>
                    </td>
                    <td>
                      <div className="text">
                        <FontBold
                          value={
                            data?.title ? data?.title.toUpperCase() : "N.A."
                          }
                        />
                      </div>
                    </td>
                    <td className="fs-12">
                      {data.confirm_by_instructor === "student_no_show" ||
                        (data.confirm_by_student === "student_no_show" && (
                          <Badge bg="warning" className="me-1">
                            Student No Show
                          </Badge>
                        ))}
                      {data.confirm_by_instructor === "instructor_no_show" ||
                        (data.confirm_by_student === "instructor_no_show" && (
                          <Badge bg="warning" className="me-1">
                            Teacher No Show
                          </Badge>
                        ))}
                      {data.confirm_by_instructor &&
                        data.confirm_by_student &&
                        data.confirm_by_student != data.instructor_no_show && (
                          <Badge bg="danger" className="me-1">
                            Disputed Session
                          </Badge>
                        )}
                    </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          as={CustomToggle}
                          id="dropdown-custom-components"
                        >
                          <BiDotsVertical />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {props.zoomLinkShow && (
                            <Dropdown.Item
                              className="text-secondary fs-12 align-items-center d-flex fw-bold"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  data.zoom.join_url
                                );
                                toast.success("Link Copied");
                              }}
                            >
                              <IoVideocam className="text-primary me-1" /> Copy
                              Link
                            </Dropdown.Item>
                          )}
                          {props.deleteAllowed && (
                            <Dropdown.Item
                              className="text-danger fs-12 align-items-center d-flex fw-bold"
                              onClick={() => {
                                submitDelete(data.bookingId, data._id);
                                showDeleteModal(data.bookingId, data._id);
                              }}
                            >
                              <IoTrashBinSharp className="text-danger me-1" />{" "}
                              Delete Session
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                );
              })
            : "No Data Available"}
        </tbody>
      </Table>
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        hideModal={hideConfirmationModal}
        confirmModal={submitDelete}
        id={sessionId}
        type={type}
        message={deleteMessage}
      />
    </>
  );
};

export default EnrollmentDetailTable;
