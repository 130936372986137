import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import Select from "react-select";
import toast from "react-hot-toast";
import SideModal from "../SideModal/SideModal";
import { instrument } from "../../constants/instruments";
import InstructorService from "../../services/instructor.service";

interface ICreateCourseModal {
  instructorId: string
  setInstructorId: any
}

export default function CreateCourseModal(props: ICreateCourseModal) {

  const [title, setTitle] = useState<string>();
  const [selInstrument, setSelInstrument] = useState<any>();
  const [price_per_class, setPricePerClass] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const createCourse = async() => {
    setLoading(true)
    if(!title || !selInstrument || !price_per_class){
      toast.error("All Fields are Mandatory!")
      return false
    }
    await InstructorService.createCourse(props.instructorId, title, selInstrument.value, Number(price_per_class)).then(res=>{
      if(res.status === 200){
        toast.success("Course Created")
        props.setInstructorId(undefined)
      }
    }).catch(e => {
      setLoading(false)
      toast.error(e.response.data || "Something Went Wrong")
    })
    setLoading(false)
  }

  return (
    <>
      <SideModal
        show={props.instructorId ? true : false}
        handleClose={() => props.setInstructorId(undefined)}
        title="Add a Course"
        body={
          <div className="h-100">
            <Form.Group className="mt-2">

              <Form.Label className="fw-bold text-secondary fs-12">
                Subject
              </Form.Label>
              <Select
                onChange={setSelInstrument}
                options={instrument}
                value={selInstrument}
              />
            </Form.Group>

            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">Title</Form.Label>
              <Form.Control type="text" name="title" onChange={(e) => setTitle(e.target.value)} value={title} />
            </Form.Group>

            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">Price Per Class</Form.Label>
              <Form.Control type="number" name="price_per_class" onChange={(e) => setPricePerClass(e.target.value)} value={price_per_class} />
            </Form.Group>

            <Alert variant="warning" className="mt-3">Note: Discount and Level would be set as <b>default</b><br/> <br/> Discounts would be as follows <b><br/> 8 classes 15% <br/> 16 classes 25% <br/> 24 classes 40% <br/></b><br/> Level would be set as <b>Comfortable</b></Alert>

            <Button variant="secondary" disabled={loading} className="mt-2" onClick={createCourse}>Create Course</Button>
          </div>
        }
      />
    </>
  );
}
