import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { AdminService } from '../../services/admin.service'
import Select from 'react-select'
import { RenewalStatusEnum, renewalSelectMenu } from '../../enum/renewal.enum'
import SideModal from '../SideModal/SideModal'
import RenewalBadge from '../Labels/Renewal.badge'
import { toast } from 'react-hot-toast'

interface IRenewalEnumModal {
    renewalId: any,
    onHide: any,
}

const RenewalEnumModal = (props: IRenewalEnumModal) => {
    const [renewal, setRenewal] = React.useState<any>({})

    const [selectedStatus, setSelectedStatus] = React.useState<any>()

    const getRenewalById = async() => {
        if(!props.renewalId) return false
        await AdminService.getRenewalById(props.renewalId).then((res) => {
            if (res.status === 200) {
                setRenewal(res.data.renewal)
                setSelectedStatus({value: res.data.renewal.status, label: res.data.renewal.status})
            }
        })
    }

    const updateRenewal = async() => {
        await AdminService.updateRenewal(props.renewalId, {
            status: selectedStatus?.value
        }).then((res) => {
            if(res.status === 200){
                props.onHide()
                toast.success('Renewal Updated Successfully')
            }
        }).catch(e => {
            toast.error('Something went wrong')
        })
    }

    useEffect(( ) => {
        getRenewalById()
    },[props.renewalId])

    return (

        <SideModal
        show={props.renewalId ? true : false}
        handleClose={props.onHide}
        title="Change Status"
        body={
            <div className='h-100'>
               <span className="fw-bold me-2">Current Status: </span> <RenewalBadge renewal={renewal?.status}/>
            <Select
                    options={renewalSelectMenu}
                    value={selectedStatus}
                    className='mt-3'
                    onChange={setSelectedStatus}
                />
                <div className='mt-3 d-flex justify-content-start align-items-center'>
                <Button variant="outline-secondary" onClick={props.onHide}>
                    Cancel
                </Button>
                <Button variant="primary" className='ms-2' onClick={updateRenewal}>
                    Update
                </Button>
                </div>
            </div>
        }/>
    )
}

export default RenewalEnumModal