import React, { useState } from "react";
import { Badge, Table } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router";
import { FontBold, NormalText } from "../Text/TableText.formats";
import "./table.css";

interface ISearchInst {
  tableData: any;
  //   deleteAllowed: boolean;
  //   reloadData: any;
  //   zoomLinkShow: boolean;
}

export default function SearchInstTable({
  tableData,
  //   deleteAllowed,
  //   reloadData,
  //   zoomLinkShow,
}: ISearchInst) {

  const navigate = useNavigate();

  return (
    <>
      <Table striped borderless hover responsive size="sm">
        <thead>
          <tr className="dhun-table-header text-secondary">
            <th>#</th>
            <th>Teacher Name</th>
            <th>PPC</th>
            <th>Demo En.</th>
            <th>Paid En.</th>
            <th>Conversion</th>
            <th>Phone</th>
            <th>Language</th>
            <th>Subject</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tableData && tableData?.length > 0
            ? tableData?.map((data: any, index: any) => {
              return (
                <tr>
                  <td>
                    <NormalText value={index + 1} />
                  </td>

                  <td>
                    {
                      data.name
                    }
                  </td>
                  <td>
                    {
                      data.price_per_class
                    }
                  </td>
                  <td className="font-monospace fs-12">
                    {
                      data.demos
                    }
                  </td>
                  <td className="font-monospace fs-12">
                    {
                      data.paid
                    }
                  </td>
                  <td className="font-monospace fs-12">
                    {
                      data.conversion + "%"
                    }
                  </td>
                  <td className="fw-bold">
                    {
                      data.phone
                    }
                  </td>
                  <td className="fs-12">
                    {
                      data.languages.map((lang: string) => {
                        return <Badge bg="primary" className="me-1">{lang}</Badge>
                      })
                    }
                  </td>
                  <td>
                    <div className="fs-12 cursor-pointer">
                      {
                        data.subject.toUpperCase()
                      }
                    </div>
                  </td>
                  <td onClick={() => {
                    navigate(
                      "/admin/search/instructor/detail/" +
                      data?.instructor_id
                    );
                  }}>
                    <FaEye className="fs-12 cursor-pointer text-secondary" />
                  </td>
                </tr>
              );
            })
            : " ---- "}
        </tbody>
      </Table>
    </>
  );
}
