import { IAction } from "../../action";
import { IInstructorState } from "./interface";
import * as actionTypes from "../../constants/instructor";

const initialState: IInstructorState = {
  loading: false,
  instructorDetail: {},
};

const instructorReducer = (state = initialState, action: IAction) => {
  if (action.type === actionTypes.FETCH_INSTRUCTOR_INITIAL) {
    return {
      ...state,
      loading: true,
      instructorDetail: {},
    };
  }

  if (action.type === actionTypes.FETCH_INSTRUCTOR_SUCCESS) {
    return {
      ...state,
      loading: false,
      instructorDetail: action.payload?.userData || {},
    };
  }

  if (action.type === actionTypes.FETCH_INSTRUCTOR_FAILURE) {
    return {
      ...state,
      loading: false,
      instructorDetail: {},
    };
  }

  return state;
};

export default instructorReducer;
