import CardBasic from "../Card/card.basic";
import { TfiBlackboard } from "react-icons/tfi";
import { RiSecurePaymentFill, RiShoppingCart2Line } from "react-icons/ri";
import { useNavigate } from "react-router";
import CalenderInput from "../Modals/DatePicker";
import Select from "react-select";
import SideModal from "../SideModal/SideModal";
import { AdminService } from "../../services/admin.service";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import moment from 'moment';

interface IControlbarInstructor {
  instructorId: string;
  enrollmentId: string;
}

export default function ControlbarEnrollment(props: IControlbarInstructor) {
  const [showSessionModal, setShowSessionModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(moment().add("1","hours").startOf("hour").toDate());
  const [errors, setErrors] = useState(Array(3).fill(false));
  const [selectValue, setSelectValue] = useState<any>();

  const navigate = useNavigate();

  const createSchedule = async (e: any) => {
    setLoading(true);
    await AdminService.createSession({
      enrollmentId: props.enrollmentId,
      date: [date.toISOString()],
    })
      .then((res: any) => {
        if (res.status === 200) {
          toast.success("Sessions Booked");
        }
        setShowSessionModal(false);
      })
      .catch((err: any) => {
      setLoading(false);
        toast.error("Unable to create Session");
      });
    setLoading(false);
  };

  return (
    <>
      <CardBasic
        body={
          <>
            <div className="d-flex justify-content-end align-items-center">
              <div className="text-center mx-2 cursor-pointer">
                <TfiBlackboard onClick={() => setShowSessionModal(true)} />
                <div className="fs-10 fw-bold">New Session</div>
              </div>
            </div>
          </>
        }
      />
      <SideModal
        show={showSessionModal}
        handleClose={() => setShowSessionModal(false)}
        body={
          <div className="w-100  mt-1" style={{ height: "90vh" }}>
            <div className="form-group my-4">
              <label className="fw-bold text-secondary fs-12">Date</label>
              <CalenderInput
                setValue={(date: any) => {
                  setDate(date);
                }}
                selected={date}
              />
              {errors[1] && (
                <div className="invalid-feedback d-block">
                  Please select Date
                </div>
              )}
            </div>
            <button
              className="btn btn-primary btn-block mt-6"
              onClick={(e) => {
                createSchedule(e);
              }}
              disabled={loading}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm mr-2"></span>
              )}
              Create Schedule
            </button>
          </div>
        }
        title="Add Session"
      />
    </>
  );
}
