import moment from "moment";
import React, { useState } from "react";
import { Badge, Dropdown, Table } from "react-bootstrap";
import { BiDotsVertical, BiPencil } from "react-icons/bi";
import { IoTrashBinSharp } from "react-icons/io5";
import CustomToggle from "../../../components/Menu/CustomMenu";
import { AdminService } from "../../../services/admin.service";
import EditVersion from "../../../components/Modals/EditVersion.modal";

interface IVersionTable {
  tableData: any;
  reload: any;
}

const VersionTable = ({ tableData, reload }: IVersionTable) => {
  const [index, setIndex] = useState<any>();
  const [showEditVersionModal, setShowEditVersionModal] = useState<boolean>(false);
  const handleVersionDelete = async (versionId: any) => {
    await AdminService.deleteVersion(versionId).then((res) => {
      if (res.status === 200) {
        reload();
      }
    });
  };
  return (
    <>
      <Table striped borderless hover responsive size="sm">
        <thead>
          <tr className="dhun-table-header text-secondary">
            <th>#</th>
            <th>Id</th>
            <th>Version</th>
            <th>Version Type</th>
            <th>Product Type</th>
            <th>Date Time</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData && tableData.length > 0
            ? tableData.map((data: any, index: number) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      {data &&
                        data._id}
                    </td>
                    <td>
                      {data &&
                        data.version}
                    </td>
                    <td>
                      {data &&
                        data.versionType}
                    </td>
                    <td>{data && data.productType}</td>
                    <td>{moment(data.date).format("DD-MM-YYYY hh:mm a")}</td>
                    <td>
                      {(
                        <Dropdown>
                          <Dropdown.Toggle
                            as={CustomToggle}
                            id="dropdown-custom-components"
                          >
                            <BiDotsVertical className="cursor-pointer" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                handleVersionDelete(data?._id);
                              }}
                            >
                              <IoTrashBinSharp className="text-danger" />{" "}
                              <span className="fw-bold text-danger fs-12">
                                Delete
                              </span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setIndex(index);
                                setShowEditVersionModal(true)
                              }}
                            >
                              <BiPencil className="text-success" />{" "}
                              <span className="fw-bold text-success fs-12">
                                Update
                              </span>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </td>
                  </tr>
                );
              })
            : "No data found"}
        </tbody>
      </Table>
      <EditVersion
        setShow={setShowEditVersionModal}
        show={showEditVersionModal}
        data={tableData && tableData[index]}
        reload={reload}
      />
    </>
  );
};

export default VersionTable;
