import React, { useEffect, useState } from "react";
import { Button, Form, Tab, Tabs } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { AdminService } from "../../services/admin.service";
import { onFetchInstructorCourse } from "../../store/actions/InstructorCourse";
import { useAppDispatch, useAppSelector } from "../../store/reduxHooks";
import Select from "react-select";
import { IcreateRazorpayLink } from "../../interfaces/razorpay.interface";
import toast from "react-hot-toast";
import { ArrayHelper } from "../../helpers/array.helper";
import DatePicker from "react-datepicker";
import moment from "moment";
import SideModal from "../SideModal/SideModal";
import { states } from "../../utilis/states.utils";
import { countries } from "../../utilis/country.utiils";
import { part_pay_items } from "../../utilis/partpay.utils";

interface ICreateRazorpayLinkProps {
  show: boolean;
  setShow: any;
}

let paymentGatewayConfigIndia = [
  // {
  //   value: "rzp",
  //   label: "Razorpay",
  // },
  {
    value: "stripe",
    label: "Stripe",
  }
  // {
  //   value: "paytm",
  //   label: "Paytm",
  // },
  // {
  //   value: "cashfree",
  //   label: "Cashfree",
  // },
];
const paymentTypes = [
  { label: "New Student", value: "New Student" },
  { label: "Renewal", value: "Renewal" },
  { label: "Referral", value: "Referral" },
  { label: "Additional Subject", value: "Additional Subject" },
];
let paymentGatewayConfigInternational = [
  // {
  //   value: "paytm",
  //   label: "Paytm",
  // },
  // {
  //   value: "cashfree",
  //   label: "Cashfree",
  // },
  // {
  //   value: "rzp",
  //   label: "Razorpay",
  // },
  {
    value: "stripe",
    label: "Stripe",
  }
];

export default function CreateRazorpayLinkModal(
  props: ICreateRazorpayLinkProps
) {
  const [finalSaveState, setFinalSaveState] = useState<any>({
    dhunguruDiscount: 0,
    totalSession: 0,
    fromHaryana: false,
  });

  const dispatch = useAppDispatch();
  const [inputStudentValue, setInputStudentValue] = useState<any>();
  const [priceCalculationType, setPriceCalculationType] =
    useState<string>("calculate");
  const [inputCourseValue, setInputCourseValue] = useState<any>();
  const [country, setCountry] = useState<any>({});
  // const [isCountryIndia, setIsCountryIndia] = useState<boolean>(false);

  const [totalSessions, setTotalSessions] = useState<any>();
  const [pricePerClass, setPricePerClass] = useState<number>(0);
  const [instructorDiscount, setInstructorDiscount] = useState<any>(undefined);
  const [expiryTime, setExpiryTime] = useState<any>();
  const [partialPayDueDate, setPartialPayDueDate] = useState<any>();

  const [extraClassesAllowed, setExtraClassesAllowed] =
    useState<boolean>(false);

  const [pricePerClassType, setPricePerClassType] = useState<string>("fixed");

  const [instructorCourses, setInstructorCourses] = useState<any>({});
  // const [extraClasses, setExtraClasses] = useState<number>(0);
  const [reqExtraClasses, setReqExtraClasses] = useState<any>();
  const [extraClasses, setExtraClasses] = useState<any>();
  const [selOption, setSelOption] = useState<any>({});

  const [state, setState] = useState<any>({});
  const [paymentGateway, setPaymentGateway] = useState<any>(
    paymentGatewayConfigInternational[0]
  );

  const [paymentType, setPaymentType] = useState<any>();

  const [acceptPartial, setAcceptPartial] = useState<boolean>(false);
  const [partialPayment, setPartialPayment] = useState<number>(0);
  const [coordinators, setCoordinators] = useState<any>();

  const handelInstructorChanges = (obj: any) => {
    setSelOption(obj);
  };

  const [loading, setLoading] = useState<boolean>(false);

  const id = selOption?.value || "";
  const courseDetail = useAppSelector(
    (state) => state.instructorCourses.courseDetail
  );

  const totalSession1 = [
    {
      num_classes: "1",
      discount: "0",
    },
    {
      num_classes: "2",
      discount: "0",
    },
    {
      num_classes: "3",
      discount: "0",
    },
    {
      num_classes: "4",
      discount: "0",
    },
    {
      num_classes: "5",
      discount: "0",
    },
    {
      num_classes: "6",
      discount: "0",
    },
    {
      num_classes: "7",
      discount: "0",
    },
    {
      num_classes: "8",
      discount: "15",
    },
    {
      num_classes: "16",
      discount: "25",
    },
  ];

  const totalSession2 = [
    {
      num_classes: "32",
      discount: "35",
    },
    {
      num_classes: "40",
      discount: "35",
    },
    {
      num_classes: "48",
      discount: "35",
    },
    {
      num_classes: "72",
      discount: "35"
    },
    {
      num_classes: "96",
      discount: "35",
    }

  ];

  useEffect(() => {
    if (inputCourseValue) {
      const current_course = courseDetail.find(
        (course: any) => course._id == inputCourseValue.value
      );
      const newTotalSessions = totalSession1.concat(
        current_course?.discount,
        totalSession2
      );

      const uniqueElements = ArrayHelper.uniqueObjects(newTotalSessions);

      if (current_course) {
        // if (isCountryIndia === true) {
        //   setPricePerClass(current_course.price_per_class);
        // } else {

        // }
        setPricePerClass(
          current_course.price_per_class +
          current_course.price_per_class * 0.18
        );
        setTotalSessions(
          uniqueElements.map((data: any) => {
            return {
              value: Number(data.num_classes),
              label:
                "Buy " +
                data.num_classes +
                " classes and  get " +
                data.discount +
                " % off",
            };
          })
        );
      }
    }
  }, [inputCourseValue]);

  useEffect(() => {
    if (!id) return;
    dispatch(onFetchInstructorCourse({ id }));
  }, [id, dispatch]);

  const fetchInstructorData = (inputValue: any, callback: ([]) => void) => {
    if (!inputValue) {
      callback([]);
    } else {
      setTimeout(() => {
        AdminService.getAllInstructor(inputValue).then((res) => {
          if (res.status === 200) {
            const instructors = res.data.data.map((data: any) => {
              return {
                value: data._id,
                label: `${data.firstName}  ${data.lastName} - ${data.email}`,
              };
            });
            callback(instructors);
          }
        });
      }, 1000);
    }
  };

  const fetchCoordinators = () => {
    AdminService.getAllCoordinators().then((res) => {
      if (res.status === 200) {
        setCoordinators(
          res?.data?.map((data: any) => {
            return {
              value: data._id,
              label: `${data.firstName}  ${data.lastName}`,
            };
          })
        );
      }
    });
  };

  const fetchCourseData = async () => {
    if (!id) return;
    await AdminService.getInstructorCourses({ id }).then((res) => {
      if (res.status === 200) {
        setInstructorCourses(
          res?.data?.data?.map((data: any) => {
            return {
              value: data._id,
              label: `${data.title}`,
            };
          })
        );
      }
    });
  };

  useEffect(() => {
    fetchCourseData();
    fetchCoordinators();
  }, [id]);

  const fetchStudentData = (inputValue: any, callback: ([]) => void) => {
    if (!inputValue) {
      callback([]);
    } else {
      setTimeout(() => {
        AdminService.getAllStudents(inputValue).then((res) => {
          if (res.status === 200) {
            const students = res.data.data.map((data: any) => {
              return {
                value: data._id,
                label: `${data.firstName}  ${data.lastName} - ${data.email}`,
              };
            });
            callback(students);
          }
        });
      }, 1000);
    }
  };

  const handleChange = (e: any) => {
    setFinalSaveState({ ...finalSaveState, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (e: any, name: string) => {
    setFinalSaveState({ ...finalSaveState, [name]: e.value });
  };

  const handleCoordinatorChange = (e: any, name: string) => {
    setFinalSaveState({ ...finalSaveState, [name]: e.label });
  };

  const getInstructorDiscount = () => {
    if (instructorDiscount) {
      return instructorDiscount;
    }

    if (
      courseDetail &&
      courseDetail.length > 0 &&
      inputCourseValue &&
      inputCourseValue.value
    ) {
      const current_course = courseDetail.find(
        (course: any) => course._id == inputCourseValue.value
      );

      const newTotalSessions = totalSession1.concat(
        current_course?.discount,
        totalSession2
      );

      // For manual discount

      if (current_course && newTotalSessions) {
        const current_discount = newTotalSessions.find(
          (data: any) => data.num_classes == finalSaveState.totalSession
        );
        return current_discount ? current_discount.discount : 0;
      }
      return 0;
    }
    return 0;
  };

  const getPriceAfterInstructorDiscount = () => {
    if (!pricePerClass) {
      return 0;
    } else {
      return (
        pricePerClass - (pricePerClass * Number(getInstructorDiscount())) / 100
      );
    }
  };

  const getFinalPrice = () => {
    if (finalSaveState.totalSession && pricePerClass) {
      const total_temp =
        Number(pricePerClass) * Number(finalSaveState.totalSession);
      const instru_after_discount =
        total_temp - (Number(getInstructorDiscount()) / 100) * total_temp;

      const dhunguru_after_discount =
        instru_after_discount - Number(finalSaveState.dhunguruDiscount);

      const after_gst =
        dhunguru_after_discount + Number((0.18 * dhunguru_after_discount).toFixed(2));

      return Number(after_gst.toFixed(2));
    } else {
      return 0;
    }
  };

  const getBeforeGstPrice = () => {
    if (finalSaveState.totalSession && pricePerClass) {
      const total_temp =
        Number(pricePerClass) * Number(finalSaveState.totalSession);
      const instru_after_discount =
        total_temp - (Number(getInstructorDiscount()) / 100) * total_temp;

      const dhunguru_after_discount =
        instru_after_discount - Number(finalSaveState.dhunguruDiscount);

      return Number(dhunguru_after_discount.toFixed(2));
    } else {
      return 0;
    }
  };

  const onSubmit = async () => {
    if (country?.value === "India") {
      if (!state || !state.value) {
        toast.error("State is required");
        return false;
      }
    }

    if (
      !selOption?.value ||
      !inputStudentValue?.value ||
      !inputCourseValue?.value ||
      // !expiryTime ||
      !country.value ||
      // !partialPayDueDate ||
      !paymentGateway.value
    ) {
      toast.error("Please fill all the fields");
      return false;
    }
    setLoading(true);
    const inputPaymentData: IcreateRazorpayLink = {
      instructorId: selOption?.value || " ",
      studentId: inputStudentValue?.value || " ",
      courseId: inputCourseValue?.value || " ",
      totalSession: finalSaveState?.totalSession || 0,
      pricePerClass: pricePerClass || 0,
      dhunguruDiscount: finalSaveState?.dhunguruDiscount >= 0 ? finalSaveState.dhunguruDiscount : 0,
      coordinator: finalSaveState?.coordinator || " ",
      // paymentType: paymentType.value,
      // platformFee: finalSaveState?.dhunguruDiscount < 0 ? Math.abs(finalSaveState.dhunguruDiscount) : 0,
      // linkExpireDate: moment(expiryTime).unix(),
      instructorDiscount: Number(getInstructorDiscount()),
      extraClasses:
        extraClassesAllowed && reqExtraClasses
          ? Number(reqExtraClasses?.value)
          : 0,
      // state: state.value,
      country: country.value,
      // partialPayment: acceptPartial ? partialPayment : 0,
      gateway: paymentGateway.value,
      // partialPayDueDate: acceptPartial ? partialPayDueDate : undefined,
    };
    console.log(inputPaymentData);

    await AdminService.createRazorpayLink(inputPaymentData)
      .then((res) => {
        if (res.status === 200) {
          props.setShow(false);
          setFinalSaveState({
            coordinator: "",
            dhunguruDiscount: 0,
            totalSession: 0,
          });
          toast.success("Success: Link Created!");
          window.location.reload();
        }
        setLoading(false);
      })
      .then((e) => {
        setLoading(false);
        console.error(e);
      });

    setLoading(false);
  };

  const calculateGstInclusiveAmount = (e: any) => {
    const amt_rec = e.target.value;
    const gst_amount: any = (amt_rec - amt_rec * (100 / (100 + 18))).toFixed(2);
    const amt_without_gst = amt_rec - gst_amount;
    const total_temp =
      Number(pricePerClass) * Number(finalSaveState.totalSession);
    const instru_after_discount =
      total_temp - (Number(getInstructorDiscount()) / 100) * total_temp;
    const left_amount = instru_after_discount - amt_without_gst;
    // console.log(amt_rec, gst_amount, amt_without_gst, total_temp, instru_after_discount, left_amount)

    setFinalSaveState({
      ...finalSaveState,
      dhunguruDiscount: left_amount.toFixed(2),
    });
  };

  const calculateGstInclusiveAmountInt = (e: any) => {
    const amt_rec = e.target.value;
    const amt_without_gst = amt_rec;
    const total_temp =
      Number(pricePerClass) * Number(finalSaveState.totalSession);
    const instru_after_discount =
      total_temp - (Number(getInstructorDiscount()) / 100) * total_temp;
    const left_amount = instru_after_discount - amt_without_gst;
    // console.log(amt_rec, gst_amount, amt_without_gst, total_temp, instru_after_discount, left_amount)

    setFinalSaveState({
      ...finalSaveState,
      dhunguruDiscount: left_amount.toFixed(2),
    });
  };

  const handleExtraClassesChange = (selectedOption: any) => {
    setReqExtraClasses(selectedOption);
  };

  useEffect(() => {
    let extra_classes = 0;
    if (extraClassesAllowed && finalSaveState.totalSession >= 8) {
      extra_classes = 1;
      if (finalSaveState.totalSession >= 16) extra_classes = 2;
      if (finalSaveState.totalSession >= 24) extra_classes = 4;
      if (finalSaveState.totalSession >= 32) extra_classes = 5;
      if (finalSaveState.totalSession >= 40) extra_classes = 6;
      if (finalSaveState.totalSession >= 48) extra_classes = 8;
      if (finalSaveState.totalSession >= 72) extra_classes = 14;
      if (finalSaveState.totalSession >= 96) extra_classes = 20;
    }
    let requiredExtraClasses = [];
    for (let i = 0; i <= extra_classes; i++) {
      requiredExtraClasses.push({ value: i, label: i.toString() });
    }
    setExtraClasses(requiredExtraClasses);
    setReqExtraClasses(requiredExtraClasses[0]);
  }, [extraClassesAllowed, finalSaveState.totalSession]);

  return (
    <SideModal
      title={"Create New Enrollment"}
      show={props.show}
      handleClose={() => props.setShow(false)}
      body={
        <>
          <div>
            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">
                Payment Type
              </Form.Label>
              <Select
                options={paymentTypes}
                value={paymentType}
                onChange={setPaymentType}
              />
            </Form.Group>
            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">
                Select Guru
              </Form.Label>
              <AsyncSelect
                name="InstructorId"
                loadOptions={fetchInstructorData}
                onChange={(option) => handelInstructorChanges(option)}
                isClearable={true}
              />
            </Form.Group>
            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">
                Select the Student
              </Form.Label>

              <AsyncSelect
                name="studentId"
                loadOptions={fetchStudentData}
                onChange={setInputStudentValue}
                isClearable={true}
              />
            </Form.Group>
            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">
                Select the Course
              </Form.Label>
              <Select
                name="courseId"
                options={instructorCourses}
                isClearable={true}
                onChange={setInputCourseValue}
              />
            </Form.Group>
            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">
                Country
              </Form.Label>
              <Select
                options={countries.map((country) => {
                  return {
                    label: country,
                    value: country,
                  };
                })}
                value={country}
                onChange={(e) => {
                  setCountry(e);
                  // setIsCountryIndia(e?.value === "India" ? true : false);
                }}
              />
            </Form.Group>
            {totalSessions && totalSessions.length > 0 && (
              <>
                <Form.Group className="mt-2 mb-4">
                  <Form.Label className="fw-bold text-secondary fs-12">
                    Total Sessions
                  </Form.Label>

                  <Select
                    options={totalSessions}
                    onChange={(e: any) => handleSelectChange(e, "totalSession")}
                  />
                </Form.Group>

                <Form.Group className="mt-2">
                  <Form.Check
                    inline
                    className="mt-4 fw-bold text-secondary fs-12"
                    reverse
                    label="Extra Classes?"
                    name="extraClassesAllowed"
                    checked={extraClassesAllowed}
                    onChange={(e) => setExtraClassesAllowed(e.target.checked)}
                    type="checkbox"
                  />
                </Form.Group>

                {extraClassesAllowed && (
                  <Form.Group className="mt-2 mb-4">
                    <Form.Label className="fw-bold text-secondary fs-12">
                      Extra Classes
                    </Form.Label>

                    {/* <Form.Control
                    type="number"
                    name="extraClasses"
                    // value={extraClasses}
                    min={0}
                    max={extraClasses}
                  /> */}
                    <Select
                      name="extraClasses"
                      options={extraClasses}
                      onChange={handleExtraClassesChange}
                      value={reqExtraClasses}
                      isClearable={true}
                    />
                  </Form.Group>
                )}
                <hr />

                <Form.Label className="fw-bold text-secondary fs-12">
                  Price Per Class (By Instructor)
                </Form.Label>
                <div>
                  <Tabs
                    activeKey={pricePerClassType}
                    onSelect={(k: any) => setPricePerClassType(k)}
                  >
                    <Tab
                      eventKey="fixed"
                      title="Fixed"
                      tabClassName="px-3 py-2 fw-bold"
                      className="razorpay-box"
                    >
                      <Form.Group>
                        <Form.Label className="fw-bold text-secondary fs-12">
                          Fixed Price (By Instructor)
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="pricePerClass"
                          disabled={true}
                          value={pricePerClass}
                        />
                      </Form.Group>
                      <Form.Group className="mt-4">
                        <Form.Label className="fw-bold text-secondary fs-12  mt-2">
                          Instructor Discount
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="instructorDiscount"
                          disabled={true}
                          value={getInstructorDiscount()}
                        />
                      </Form.Group>
                    </Tab>
                    <Tab
                      eventKey="manual"
                      title="Discounted"
                      tabClassName="px-3 py-2 fw-bold"
                      className="razorpay-box"
                    >
                      <Form.Group>
                        <Form.Label className="fw-bold text-secondary fs-12">
                          Discounted Price (By Instructor)
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="pricePerClass"
                          value={pricePerClass}
                          onChange={(e: any) => setPricePerClass(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group className="mt-2">
                        <Form.Label className="fw-bold text-secondary fs-12 mt-2">
                          Instructor Discount
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="instructorDiscount"
                          value={getInstructorDiscount()}
                          onChange={(e: any) =>
                            setInstructorDiscount(Number(e.target.value))
                          }
                        />
                      </Form.Group>
                    </Tab>
                  </Tabs>
                </div>

                <hr />

                <div>
                  <Tabs
                    activeKey={priceCalculationType}
                    onSelect={(k: any) => setPriceCalculationType(k)}
                    className="mt-3"
                  >
                    <Tab
                      eventKey="calculate"
                      title="Calculate"
                      tabClassName="px-3 py-2 fw-bold"
                      className="razorpay-box"
                    >
                      <Form.Group>
                        <Form.Label className="fw-bold text-secondary fs-12">
                          Dhunguru Discount
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="dhunguruDiscount"
                          onChange={handleChange}
                          value={finalSaveState.dhunguruDiscount}
                        />
                      </Form.Group>
                    </Tab>
                    <Tab
                      eventKey="fixed"
                      title="Fixed"
                      tabClassName="px-3 py-2 fw-bold"
                      className="razorpay-box"
                    >
                      {/* {isCountryIndia ? (
                      <Form.Group>
                        <Form.Label className="fw-bold text-secondary fs-12">
                          Amount (GST Inclusive)
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="gstIncAmount"
                          onChange={calculateGstInclusiveAmount}
                          // value={getFinalPrice()}
                        />
                      </Form.Group>
                    ) : (
                      <Form.Group>
                        <Form.Label className="fw-bold text-secondary fs-12">
                          Amount
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="gstIncAmount"
                          onChange={calculateGstInclusiveAmountInt}
                          // value={getFinalPrice()}
                        />
                      </Form.Group>
                    )} */}
                    </Tab>
                  </Tabs>
                </div>

                <hr />

                {/* <Form.Group className="mt-2">
                  <Form.Label className="fw-bold text-secondary fs-12">
                    Link Expiry{" "}
                  </Form.Label>
                  <DatePicker
                    selected={expiryTime}
                    onChange={(date: Date) => setExpiryTime(date)}
                    className="form-control input"
                    showTimeSelect
                    dateFormat="MMMM d, yyyy h:mm aa"
                    minDate={moment().add("days", 1).toDate()}
                  />
                </Form.Group> */}
              </>
            )}

            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">
                Coordinator
              </Form.Label>
              <Select
                name="coordinatorId"
                options={coordinators}
                isClearable={true}
                onChange={(e: any) => {
                  handleCoordinatorChange(e, "coordinator");
                }}
              />
            </Form.Group>

            {/* {isCountryIndia && (
            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">
                State(Mandatory)
              </Form.Label>
              <Select
                options={states.map((state) => {
                  return {
                    label: state,
                    value: state,
                  };
                })}
                value={state}
                onChange={setState}
              />
            </Form.Group>
          )} */}
            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">
                Payment Gateway
              </Form.Label>
              <Select
                // options={
                //   country.value === "India"
                //     ? paymentGatewayConfigIndia
                //     : paymentGatewayConfigInternational
                // }
                options={paymentGatewayConfigInternational}
                value={paymentGateway}
                onChange={setPaymentGateway}
              />
            </Form.Group>
          </div>
          {/* {paymentGateway.value != "paytm" && (
            <>
              <Form.Group className="mt-2">
                <Form.Check
                  inline
                  className="mt-4 fw-bold text-secondary fs-12"
                  reverse
                  label="Partial Payment?"
                  name="partialPayment"
                  checked={acceptPartial}
                  onChange={(e) => setAcceptPartial(e.target.checked)}
                  type="checkbox"
                />
              </Form.Group>
              {acceptPartial && (
                <Form.Group className="mt-2">
                  <Form.Label className="fw-bold text-secondary fs-12">
                    Partial Payment Amount
                  </Form.Label>
                  <Select
                    options={part_pay_items}
                    value={part_pay_items.find(
                      (itm) => itm.value == partialPayment
                    )}
                    onChange={(e: any) => setPartialPayment(e.value)}
                  />
                  <Form.Group className="mt-2">
                    <Form.Label className="fw-bold text-secondary fs-12">
                      Partial Payment Due Date
                    </Form.Label>
                    <DatePicker
                      selected={partialPayDueDate}
                      onChange={(date: Date) => setPartialPayDueDate(date)}
                      className="form-control input"
                      showTimeSelect
                      dateFormat="MMMM d, yyyy h:mm aa"
                      minDate={moment().add("days", 1).toDate()}
                    />
                  </Form.Group>
                  <hr />
                </Form.Group>
              )}
            </>
          )} */}
          {/* { (
            <div
              className="bg-muted rounded-3 mt-3 p-3 fs-12"
              style={{ backgroundColor: "#f1f1f1" }}
            >
              <div className="fw-bold text-secondary">
                <div>
                  Price per Class:{" "}
                  <span className="text-black">{pricePerClass}</span>
                </div>

                <div>
                  {" "}
                  Instructor Discount:{" "}
                  <span className="text-danger">
                    {" "}
                    - {getInstructorDiscount()} %
                  </span>
                </div>
                <div>
                  {" "}
                  PPC (after Inst. Discount):{" "}
                  <span className="text-primary">
                    {" "}
                    {getPriceAfterInstructorDiscount()}
                  </span>
                </div>
                <div>
                  {" "}
                  Total Classes:{" "}
                  <span className="text-black">
                    {" "}
                    x {finalSaveState.totalSession}
                  </span>
                </div>
                <div>
                  {" "}
                  Total Price:{" "}
                  <span className="text-success">
                    {" "}
                    ={" "}
                    {getPriceAfterInstructorDiscount() *
                      finalSaveState.totalSession}
                  </span>
                </div>
                <div>
                  {" "}
                  Dhunguru Discount:{" "}
                  <span className="text-danger">
                    {" "}
                    - {finalSaveState.dhunguruDiscount >= 0 ? finalSaveState.dhunguruDiscount : 0}
                  </span>
                </div>

                <div>
                  {" "}
                  Platform Fee:{" "}
                  <span className="text-danger">
                    {" "}
                    {finalSaveState.dhunguruDiscount < 0 ? Math.abs(finalSaveState.dhunguruDiscount) : 0}
                  </span>
                </div>


                <div>
                  {" "}
                  Total Price:{" "}
                  <span className="text-black">
                    {" "}
                    {getBeforeGstPrice() > 0
                      ? getBeforeGstPrice()
                      : " --- All Fields Are Required --- "}
                  </span>
                </div>
              </div>
            </div>
          )} */}
          {/* {isCountryIndia && (
            <div
              className="bg-muted rounded-3 mt-3 p-3 fs-12"
              style={{ backgroundColor: "#f1f1f1" }}
            >
              <div className="fw-bold text-secondary">
                <div>
                  Price per Class:{" "}
                  <span className="text-black">{pricePerClass}</span>
                </div>

                <div>
                  {" "}
                  Instructor Discount:{" "}
                  <span className="text-danger">
                    {" "}
                    - {getInstructorDiscount()} %
                  </span>
                </div>
                <div>
                  {" "}
                  PPC (after Inst. Discount):{" "}
                  <span className="text-primary">
                    {" "}
                    {getPriceAfterInstructorDiscount()}
                  </span>
                </div>
                <div>
                  {" "}
                  Total Classes:{" "}
                  <span className="text-black">
                    {" "}
                    x {finalSaveState.totalSession}
                  </span>
                </div>
                <div>
                  {" "}
                  Total Price:{" "}
                  <span className="text-success">
                    {" "}
                    ={" "}
                    {getPriceAfterInstructorDiscount() *
                      finalSaveState.totalSession}
                  </span>
                </div>
                <hr />
                <div>
                  {" "}
                  Dhunguru Discount:{" "}
                  <span className="text-danger">
                    {" "}
                    - {finalSaveState.dhunguruDiscount >= 0 ? finalSaveState.dhunguruDiscount : 0}
                  </span>
                </div>

                <div>
                  {" "}
                  Platform Fee:{" "}
                  <span className="text-danger">
                    {" "}
                    {finalSaveState.dhunguruDiscount < 0 ? Math.abs(finalSaveState.dhunguruDiscount) : 0}
                  </span>
                </div>
                <div>
                  {" "}
                  Total Price:{" "}
                  <span className="text-black">
                    {" "}
                    {getBeforeGstPrice() > 0
                      ? getBeforeGstPrice()
                      : " --- All Fields Are Required --- "}
                  </span>
                </div>

                <div>
                  {" "}
                  GST:{" "}
                  <span className="text-success">
                    {" "}
                    {getBeforeGstPrice() > 0 ? (
                      <> +{(getBeforeGstPrice() * 0.18).toFixed(2)}</>
                    ) : (
                      " --- All Fields Are Required --- "
                    )}
                  </span>
                </div>
                <div>
                  {" "}
                  Total Price with GST:{" "}
                  <span className="text-primary">
                    {" "}
                    {getFinalPrice() > 0
                      ? getFinalPrice()
                      : " --- All Fields Are Required --- "}
                  </span>
                </div>

                <div>
                  {" "}
                  Total Price (Rounded):{" "}
                  <span className="text-primary">
                    {" "}
                    {getFinalPrice() > 0
                      ? Math.round(getFinalPrice())
                      : " --- All Fields Are Required --- "}
                  </span>
                </div>
              </div>
            </div>
          )} */}

          <Button className="mt-2 w-100" onClick={onSubmit} disabled={loading}>
            Create
          </Button>
        </>
      }
    />
  );
}
