import React from "react";
import { Box, Paper } from "@mui/material";

const LogDetail = () => {


  
  return (
    <>
      <>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "6rem",
            "& > :not(style)": {
              m: 1,
              width: "28rem",
              height: "28rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
            },
          }}
        >
          <Paper elevation={3}>
            {
              <div
                style={{
                  display: "flex",
                  gap: "3rem",
                  //   backgroundColor: "aqua",
                  alignContent: "center",
                  //   justifyContent: "center",
                  marginLeft: "1rem",
                  marginTop: "1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    gap: "2.3rem",
                    // backgroundColor: "yellow",
                  }}
                >
                  <div>
                    <b>Title</b>
                  </div>
                  <div>
                    <b>Classes Canceled</b>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignContent: "flex",
                    gap: "2.3rem",
                    // backgroundColor: "red",
                    marginLeft: "auto",
                    marginRight: "1rem",
                  }}
                ></div>
              </div>
            }
          </Paper>
          <Paper elevation={3}>
            {
              <div
                style={{
                  display: "flex",
                  //   gap: "rem",
                  //   backgroundColor: "aqua",
                  alignContent: "center",
                  //   justifyContent: "center",
                  marginLeft: "1rem",
                  marginTop: "1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    gap: "2.3rem",
                    // backgroundColor: "yellow",
                  }}
                >
                  <div>
                    <b>Title</b>
                  </div>
                  <div>
                    <b>Approved</b>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignContent: "flex",
                    gap: "2.3rem",
                    // backgroundColor: "red",
                    marginLeft: "auto",
                    marginRight: "1rem",
                  }}
                ></div>
              </div>
            }
          </Paper>
        </Box>
      </>
    </>
  );
};

export default LogDetail;
