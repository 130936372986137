import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";

import ReactJson from 'react-json-view'

interface IJsonViewer {
    show: boolean,
    setShow: any,
    jsonData: any
}

export default function JsonViewer(props: IJsonViewer) {
    return (
        <Modal show={props.show} onHide={()=>props.setShow(false)} fullscreen>
            <Modal.Body>
                <div>
                    <div className="d-flex justify-content-end align-items-center">
                        <IoClose className="text-secondary" onClick={() => props.setShow(false)}/>
                    </div>
                    <div className="fs-12">
                        <ReactJson src={props.jsonData} collapsed={true}/>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}