import { Badge, Container, Dropdown, Row, Table } from "react-bootstrap";

import { FontBold, NormalText } from "../Text/TableText.formats";
import "./table.css";
import { BiDotsVertical } from "react-icons/bi";
import CustomToggle from "../Menu/CustomMenu";
import SalesService from "../../services/sales.service";
import toast from "react-hot-toast";
import { useContext } from "react";
import { IsLoading } from "../../App.context";


interface IRazorPay {
    tableData: any;
    reloadData: any;
}


export default function CancelledSalesInvoicesTable({ tableData, reloadData }: IRazorPay) {
    const { loading, setLoading }: any = useContext(IsLoading);

   

    return (
        <>
            <Container>
                <Row>
                    <Table striped borderless hover responsive size="sm">
                        <thead>
                            <tr className="dhun-table-header text-secondary">
                                <th>#</th>
                                <th>Invoice Number</th>
                                <th>Buyer Name</th>
                                <th>Buyer Email</th>
                                <th>Buyer Phone</th>
                                <th>State</th>
                                <th>Date of Purchase</th>
                                {/* <th>Status</th> */}
                                <th>Amount Before GST</th>
                                <th>Amount After GST</th>
                                <th>CGST</th>
                                <th>IGST</th>
                                <th>SGST</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData && tableData.length > 0 ? (
                                tableData.map((data: any, index: any) => {
                                    return (
                                        <tr>
                                            <td>
                                                <NormalText value={index + 1} />
                                            </td>
                                            <td>
                                                <div className="d-flex">
                                                    <FontBold value={data.invoiceNumber} />
                                                </div>
                                            </td>

                                            <td>{data.buyerName ? data.buyerName : "N.A."}</td>
                                            <td>{data.buyerEmail ? data.buyerEmail : "N.A."}</td>

                                            <td>{data.buyerPhone ? data.buyerPhone : "N.A."}</td>
                                            <td>{data.buyerState ? data.buyerState : "N.A."}</td>
                                            <td>{data.dateOfPurchase ? data.dateOfPurchase : "N.A."}</td>

                                            <td>
                                                {data.totalAmountBeforeGST
                                                    ? data.totalAmountBeforeGST
                                                    : "N.A."}
                                            </td>
                                            <td>
                                                {data.totalAmountAfterGST
                                                    ? data.totalAmountAfterGST
                                                    : "N.A."}
                                            </td>
                                            <td>
                                                {
                                                    data?.gst?.cgst ?
                                                        data?.gst?.cgst : "N.A."
                                                }
                                            </td>
                                            <td>
                                                {
                                                    data?.gst?.igst ?
                                                        data?.gst?.igst : "N.A."
                                                }
                                            </td>
                                            <td>
                                                {
                                                    data?.gst?.sgst ?
                                                        data?.gst?.sgst : "N.A."
                                                }
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr className="text-center">
                                    <td colSpan={16}>No Data Found</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Row>
            </Container>
        </>
    );
}
