import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import toast from "react-hot-toast";
import Select from "react-select";
import CardBasic from "../../../components/Card/card.basic";
import CreateDemoSessionModal from "../../../components/Modals/CreateDemoSession.modal";
import DemoSessionTable from "../../../components/Table/DemoSessionTable";
import { AdminService } from "../../../services/admin.service";

export default function DemoSessionIndex() {
  const [showCreateLink, setShowCreateLink] = useState(false);
  const [tableData, setTableData] = useState<any>();
  const [state, setState] = useState<any>([{
    key: "selection"
  }
  ]);

  const [dateRange, setDateRange] = useState({
    startDate: moment(new Date()).subtract("10", "days").toDate(),
    endDate: moment(new Date()).add("30", "days").toDate(),
  })

  const [sort, setSort] = useState<any>({label: "Descending", value: -1})
  const [pageCount, setPageCount] = useState<any>({label: "20", value: 20})
  const [status, setStatus] = useState<any>({label: "Scheduled", value: "scheduled"})


  const getDemoSessionData = async () => {
    const fromDate = moment(dateRange.startDate).format("YYYY-MM-DD");
    const toDate = moment(dateRange.endDate).format("YYYY-MM-DD");

    await AdminService.getDemoSessions(pageCount.value, 1, fromDate, toDate, sort.value, status.value)
      .then((res) => {
        if (res.status === 200) {
          setTableData(res?.data?.data.demoSessions);
        }
      })
      .catch((err) => {
        toast.error("Unable to fetch  Demo Session Details");
      });
  };

  useEffect(() => {
    getDemoSessionData();
  }, [sort, pageCount, dateRange, status]);

  return (
    <>
      <Container>
        <Row>
          <Col>
            <CardBasic
            className="mt-3"
              body={
                <>
                  <h6>Filters</h6>
                  <Row>
                    <Col md={3}>
                      <Form.Label className="fw-bold text-secondary fs-12">Date Range</Form.Label>
                    <ReactDatePicker
                      onChange={(e: any) => {
                        setDateRange({
                          startDate: e[0],
                          endDate: e[1]
                        })
                      }}
                      startDate={dateRange.startDate}
                      endDate={dateRange.endDate}
                      selectsRange
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                    />
                  </Col>
                  <Col md={3}>
                      <Form.Label className="fw-bold text-secondary fs-12">Date Sort</Form.Label>
                    <Select
                      options={[
                        {label: "Ascending", value: 1},
                        {label: "Descending", value: -1}
                      ]}
                      value={
                        sort
                      }
                      onChange={setSort}

                    />
                  </Col>

                  <Col md={3}>
                      <Form.Label className="fw-bold text-secondary fs-12">Page Count</Form.Label>
                    <Select
                      options={[
                        {label: "20", value: 20},
                        {label: "50", value: 50},
                        {label: "100", value: 100},
                        {label: "250", value: 250},
                        {label: "500", value: 500},
                        {label: "1000", value: 1000},
                        {label: "1500", value: 1500}
                      ]}
                      value={
                        pageCount
                      }
                      onChange={setPageCount}
                    />
                  </Col>
                  <Col md={3}>
                      <Form.Label className="fw-bold text-secondary fs-12">Status</Form.Label>
                    <Select
                      options={[
                        {label: "Scheduled", value: "scheduled"},
                        {label: "Completed", value: "completed"},
                        {label: "Re-Scheduled", value: "rescheduled"},
                        {label: "Cancelled", value: "cancelled"}
                      ]}
                      value={
                        status
                      }
                      onChange={setStatus}
                    />
                    </Col>
                  </Row>
                </>
              }
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <CardBasic
              body={
                <>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <Card.Title>Demo Sessions</Card.Title>
                    <div>
                      <ButtonGroup size="sm">
                        <Button onClick={() => setShowCreateLink(true)}>
                          Create Demo Session
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                  <DemoSessionTable
                    tableData={tableData}
                    reloadData={() => getDemoSessionData()}
                    deleteAllowed={true}
                    zoomLinkShow={true}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <Pagination>
                      <Pagination.First />
                      <Pagination.Prev
                        onClick={() => {
                          setPageCount(pageCount - 1);
                        }}
                      />
                      <Pagination.Item>{pageCount}</Pagination.Item>
                      <Pagination.Next
                        onClick={() => {
                          setPageCount(pageCount + 1);
                        }}
                      />
                      <Pagination.Last />
                    </Pagination> */}
                  </div>
                </>
              }
            />
          </Col>
        </Row>
      </Container>
      <CreateDemoSessionModal
        show={showCreateLink}
        setShow={setShowCreateLink}
      />
    </>
  );
}
