import React, { useState, useEffect, Fragment } from "react";
import { useDropzone } from "react-dropzone";

interface IMyDropzoneImage {
  onFileChange: any;
  height: any;
  file: any;
  link?: any;
}
const MyDropzoneImage = ({
  onFileChange,
  height,
  file,
  link,
}: IMyDropzoneImage) => {
  const [fileName, setFileName] = useState<any>();
  const [files, setFiles] = useState<any>(file);
  const [baseStyle, setBaseStyle] = useState<any>({
    ...initBaseStyle,
    minHeight: height || "20vh",
  });

  useEffect(() => {
    setFiles(file);
  }, [file]);

  useEffect(() => {
    if (files && files.length > 0) {
      setBaseStyle((prev: any) => ({
        ...prev,
        backgroundImage: link ? `url("${link}")` : "",
        color: link ? "white" : "black",
        backgroundSize: "cover",
      }));
    }
    // eslint-disable-next-line
  }, [link]);

  useEffect(() => {
    if (files && files.length > 0) {
      setBaseStyle((prev: any) => ({
        ...prev,
        backgroundImage: "url('" + URL.createObjectURL(files[0]) + "')",
        color: "white",
        backgroundSize: "cover",
      }));
      setFileName(files.map((item: any) => item.name).join(", "));
    } else {
      setBaseStyle((prev: any) => ({
        ...prev,
        backgroundImage: link ? `url("${link}")` : "",
        color: link ? "white" : "black",
        backgroundSize: "cover",
      }));
      setFileName("");
    }
    // eslint-disable-next-line
  }, [files]);

  const onDrop = React.useCallback(
    (acceptedFiles: any) => {
      setFileName(acceptedFiles.map((item: any) => item.name).join(", "));
      onFileChange(acceptedFiles);
      setFiles(acceptedFiles);
    },
    [onFileChange]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });
  // accept: ".jpg, .png, .jpeg";
  const style = React.useMemo(() => {
    return {
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    };
    // eslint-disable-next-line
  }, [isDragActive, isDragReject, isDragAccept, baseStyle]);

  return (
    <Fragment>
      <div className="form-group mb-1 pointer" {...getRootProps({ style })}>
        <input {...getInputProps()} className={`dropzonee pointer`} />
        {isDragActive ? (
          <p className="mb-0">Drop the files here ...</p>
        ) : fileName ? (
          ""
        ) : (
          <p className="mb-0 pointer">
            Drag 'n' drop some files here, or click to select files
          </p>
        )}
      </div>
      {fileName && (
        <small className="text-center w-100 small">{fileName}</small>
      )}
    </Fragment>
  );
};

export default MyDropzoneImage;

const initBaseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  borderWidth: 1.4,
  borderRadius: 5,
  borderColor: "rgb(0, 0, 0, 0.4)",
  borderStyle: "dashed",
  backgroundColor: "#fff",
  color: "#12263F",
  outline: "none",
  transition: "border .24s ease-in-out",
  textAlign: "center",
  backgroundSize: "cover",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};
