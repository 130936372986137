import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import CardBasic from "../../../components/Card/card.basic";
import Select from "react-select";
import UnpaidInvoicesTableForAll from "../../../components/Table/UnpaidInvoiceTable";
import UnpaidInvoicesTableForPerClass from "../../../components/Table/UnpaidInvoiceTableForPerClass";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import RecordFine from "../../../components/recordFine/recordFine";
import UnpaidInvoicesTableForInstFine from "../../../components/Table/UnpaidInvoiceTableForInstFine";
import InstructorService from "../../../services/instructor.service";
import UnpaidInvoicesTableForRecordInvoices from "../../../components/Table/UnpaidInvoiceTableForRecordInvoice";
import TabsIndex from "../../../components/Tabs/Tabs.index";
import {
  faBookReader,
  faFilePen,
  faList,
  faReceipt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import FinalInvoiceTable from "../../../components/Table/FinalInvoice.table";
import InvoicesSummaryTable from "../../../components/Table/InvoicesSummary.table";

export default function UnpaidInstructorInvoices() {
  const [pageCount, setPageCount] = useState<any>({ label: "20", value: 20 });
  const [dateRange, setDateRange] = useState({
    startDate: moment(new Date()).subtract("15", "days").toDate(),
    endDate: moment(new Date()).toDate(),
  });
  const [showFine, setShowFine] = useState(false);
  const [instructors, setInstructors] = useState<any>([]);
  const [selected_instructor, setSelectedInstructor] = useState<any>();

  const tabs = [
    {
      name: "Global Invoice",
      key: "all",
      faIcon: faList,
    },
    {
      name: "Per Class",
      key: "per_class",
      faIcon: faBookReader,
    },
    // {
    //   name: "Record Invoice",
    //   key: "record_invoice",
    //   faIcon: faReceipt,
    // },
    {
      name: "Record Fine",
      key: "record_fine",
      faIcon: faFilePen,
    },
    {
      name: "Final Invoice",
      key: "final_invoice",
      faIcon: faFilePen,
    },
    {
      name: "Invoices Summary",
      key: "invoices_summary",
      faIcon: faFilePen,
    },
  ];

  const [selectedTab, setSelectedTab] = useState<any>(tabs[0].key);

  const getInst = async () => {
    await InstructorService.getInstForSelect().then((res) => {
      if (res.status === 200) {
        setInstructors(
          res.data.map((data: any) => {
            return {
              value: data._id,
              label: data.firstName + " " + data.lastName + " - " + data.email,
            };
          })
        );
      }
    });
  };

  const renderTable = () => {
    switch (selectedTab) {
      case "all": {
        return (
          <UnpaidInvoicesTableForAll
            pageCount={pageCount.value}
            pageNumber={1}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            instructor={selected_instructor}
          />
        );
      }

      case "per_class": {
        return (
          <UnpaidInvoicesTableForPerClass
            pageCount={pageCount.value}
            pageNumber={1}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            instructor={selected_instructor}
          />
        );
      }
      case "record_invoice": {
        return (
          <UnpaidInvoicesTableForRecordInvoices
            pageCount={pageCount.value}
            pageNumber={1}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            instructor={selected_instructor}
          />
        );
      }
      case "record_fine": {
        return (
          <UnpaidInvoicesTableForInstFine
            pageCount={pageCount.value}
            pageNumber={1}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            instructor={selected_instructor}
          />
        );
      }

      case "final_invoice": {
        return (
          <FinalInvoiceTable
            pageCount={pageCount.value}
            pageNumber={1}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            instructor={selected_instructor}
          />
        );
      }
      case "invoices_summary": {
        return (
          <InvoicesSummaryTable
            pageCount={pageCount.value}
            pageNumber={1}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            instructor={selected_instructor}
          />
        );
      }
    }
  };

  useEffect(() => {
    getInst();
  }, [pageCount, dateRange, selected_instructor]);

  return (
    <>
      <Container>
        <Row>
          <Col>
            <CardBasic
              className="mt-3"
              body={
                <>
                  <h6>Filters</h6>
                  <Row>
                    <Col md={3}>
                      <Form.Label className="fw-bold text-secondary fs-12">
                        Date Range
                      </Form.Label>
                      <ReactDatePicker
                        onChange={(e: any) => {
                          setDateRange({
                            startDate: e[0],
                            endDate: e[1],
                          });
                        }}
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        selectsRange
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                      />
                    </Col>
                    <Col md={3}>
                      <Form.Label className="fw-bold text-secondary fs-12">
                        Page Count
                      </Form.Label>
                      <Select
                        options={[
                          { label: "20", value: 20 },
                          { label: "50", value: 50 },
                          { label: "100", value: 100 },
                          { label: "250", value: 250 },
                          { label: "500", value: 500 },
                          { label: "1000", value: 1000 },
                          { label: "1500", value: 1500 },
                        ]}
                        value={pageCount}
                        onChange={setPageCount}
                      />
                    </Col>
                    <Col md={3}>
                      <Form.Label className="fw-bold text-secondary fs-12">
                        Instructors
                      </Form.Label>
                      <Select
                        options={instructors}
                        value={selected_instructor}
                        isSearchable={true}
                        placeholder="Select Instructor"
                        onChange={(e: any) => {
                          setSelectedInstructor(e);
                        }}
                        isClearable
                      />
                    </Col>
                  </Row>
                </>
              }
            />
          </Col>
        </Row>
        <Row>
          <Col className="my-4">
            <div className="d-flex justify-content-end">
              <Button
                size="sm"
                className="mx-2"
                onClick={() => setShowFine(true)}
              >
                Create Fine
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <TabsIndex
              selectedTabIndex={tabs.findIndex(
                (tab) => tab.key === selectedTab
              )}
              setSelectedTabIndex={(index: number) =>
                setSelectedTab(tabs[index].key)
              }
              tabs={tabs}
            />
            <div className="mt-3">{renderTable()}</div>
          </Col>
        </Row>
      </Container>
      <RecordFine show={showFine} setShow={setShowFine} />
    </>
  );
}
