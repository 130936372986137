import React, { useState, useEffect } from "react";
import Select from "react-select";
import toast from "react-hot-toast";
import { AdminService } from "../../services/admin.service";
import CalenderInput from "./DatePicker";
import "./Modal.css";
import { BookingService } from "../../services/booking.service";
import SideModal from "../SideModal/SideModal";
import moment from "moment";

interface ISessionModal {
  show: any;
  setShow: any;
  getAllSessionData?: any;
  sessionType: any;
  getStudentSessionData?: any;
  getInstructorSessionData?: any;
}

const SessionModal = ({ show, setShow }: ISessionModal) => {
  const [loading, setLoading] = useState(false);

  const [enrollment, setEnrollment] = useState<any>({});
  const [date, setDate] = useState(moment().add("1", "hour").startOf("hour").toDate());
  const [errors, setErrors] = useState(Array(3).fill(false));
  const [paidEnrollments, setPaidEnrollment] = useState<any>([]);

  const getAllPaidSessions = async () => {
    await BookingService.getAllPaidEnrollments().then((res) => {
      if (res.status === 200) {
        const select_data = res?.data?.data?.map((data: any) => {
          return {
            label:
              data.student_name +
              " - " +
              data.instructor_name +
              " - " +
              data.instrument +
              " - " +
              data.enrollmentId +
              " (" +
              data.sessions_left +
              "/" +
              data.total_classes +
              ")",
            value: data.enrollmentId,
          };
        });
        setPaidEnrollment(select_data);
      }
    });
  };

  useEffect(() => {
    setErrors(Array(3).fill(false));
  }, [enrollment, date]);

  useEffect(() => {
    getAllPaidSessions();
  }, []);

  useEffect(() => {
    const validate = () => {
      const newErrors = Array(3).fill(false);

      if (enrollment === "") newErrors[0] = true;
      if (date === null) newErrors[1] = true;

      setErrors(newErrors);
      if (newErrors.includes(true)) {
        return true;
      } else {
        return false;
      }
    };
    validate();
  }, [enrollment, date]);

  const createSchedule = async (e: any) => {
    setLoading(true);
    await AdminService.createSession({
      enrollmentId: enrollment.value,
      date: [date.toISOString()],
    })
      .then((res: any) => {
        if (res.status === 200) {
          toast.success("Sessions Booked");
          setShow(false);
          setEnrollment("");
        }
      })
      .catch((err: any) => {
        setLoading(false);
        toast.error("Unable to create Session");
      });
    setLoading(false);
  };

  return (
    <SideModal
      show={show}
      handleClose={() => setShow(false)}
      body={
        <div className="w-100  mt-1">
          <div className="form-group ">
            <label className="fw-bold text-secondary fs-12">
              Select Enrollment
            </label>
            <div className="selector">
              <Select
                options={paidEnrollments}
                value={enrollment}
                isSearchable={true}
                placeholder="Select Enrollments"
                onChange={(e: any) => {
                  setEnrollment(e);
                }}
                isClearable
              />
              {errors[0] && (
                <div className="invalid-feedback d-block">
                  Please select a valid enrollment id
                </div>
              )}
            </div>
          </div>

          <div className="form-group my-4 ">
            <label className="fw-bold text-secondary fs-12">Date</label>
            <CalenderInput
              setValue={(date: any) => {
                setDate(date);
              }}
              selected={date}
            />
            {errors[2] && (
              <div className="invalid-feedback d-block">Please select Date</div>
            )}
          </div>
          <button
            className="btn btn-primary btn-block mt-6"
            onClick={(e) => {
              createSchedule(e);
            }}
            disabled={loading}
          >
            {loading && (
              <span className="spinner-border spinner-border-sm mr-2"></span>
            )}
            Create Schedule
          </button>
        </div>
      }
      title="Add Session"
    />
  );
};

export default SessionModal;
