import { Badge, Dropdown, Table } from "react-bootstrap";
import { BiDotsVertical } from "react-icons/bi";
import CustomToggle from "../Menu/CustomMenu";
import { NormalText } from "../Text/TableText.formats";
import "./table.css";
import { FaExchangeAlt } from "react-icons/fa";
import { useState } from "react";
import CreateSaleCoupnCode from "../Modals/CreateSaleCoupnCode";

interface IRazorPay {
  tableData: any;
  reloadData: any;
}

export default function SalesCoupnCodesTable({
  tableData,
  reloadData,
}: IRazorPay) {
  const [updateId, setUpdateId] = useState<string>("");

  return (
    <>
      <Table striped borderless hover responsive size="sm">
        <thead>
          <tr className="dhun-table-header text-secondary">
            <th>#</th>
            <th>Coupon Code</th>
            <th>Discount Percent</th>
            <th>Max Discount</th>
            <th>Instructor</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tableData && tableData.length > 0 ? (
            tableData.map((data: any, index: any) => {
              return (
                <tr key={index}>
                  <td>
                    <NormalText value={index + 1} />
                  </td>
                  <td>{data.couponName ? data.couponName : "N.A."}</td>
                  <td>
                    {data.discountPercent ? data.discountPercent : "N.A."}
                  </td>
                  <td>{data.maxDiscount ? data.maxDiscount : "N.A."}</td>
                  <td>
                    {data.instructorId
                      ? data.instructorId?.firstName +
                        " - " +
                        data.instructorId?.lastName +
                        " - " +
                        data.instructorId?.email
                      : "N.A."}
                  </td>
                  <td>
                    {data.isCancelled ? (
                      <Badge
                        bg="danger"
                        className="me-1 fst-italic fs-12 cursor-pointer"
                      >
                        Cancelled
                      </Badge>
                    ) : (
                      <Badge
                        bg="success"
                        className="me-1 fst-italic fs-12 cursor-pointer"
                      >
                        Available
                      </Badge>
                    )}
                  </td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        as={CustomToggle}
                        id="dropdown-custom-components"
                      >
                        <BiDotsVertical />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          className="fs-12 align-items-center d-flex fw-bold"
                          onClick={() => setUpdateId(data?._id)}
                        >
                          <FaExchangeAlt className="text-success me-1" /> Update
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr className="text-center">
              <td colSpan={16}>No Data Found</td>
            </tr>
          )}
        </tbody>
      </Table>
      <CreateSaleCoupnCode
        show={updateId !== ""}
        setShow={() => setUpdateId("")}
        reloadData={reloadData}
        updateId={updateId}
      />
    </>
  );
}
