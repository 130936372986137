import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import CardBasic from "../../../../components/Card/card.basic";

interface IEnrollmentDetailCard {
  enrollmentId: string;
  tableData: any;
}

const EnrollmentDetailCard = (props: IEnrollmentDetailCard) => {
  return (
    <div>
      <CardBasic
        body={
          <>
            <div className="fw-bold fs-grey fs-17 d-flex justify-content-start mb-3">
              Sessions Detail
            </div>

            <div className="mt-3 fs-14">
              <div className="bg-muted fs-12 w-100 rounded p-2">
                <Row className="font-italic-imp">
                  <Col sm={6}>
                    Total: {props.tableData?.num_classes || "0"}
                    <br />
                    Requested: {props.tableData?.classes_requested || "0"}
                    <br />
                    Scheduled: {props.tableData?.classes_scheduled || "0"}
                  </Col>
                  <Col sm={6}>
                    Left: {props.tableData?.left || "0"}
                    <br />
                    Late: {props.tableData?.classes_late_charged || "0"}
                    <br />
                    Cancelled: {props.tableData?.classes_cancelled || "0"}
                  </Col>
                </Row>
                <br />
              </div>
            </div>
          </>
        }
      />
    </div>
  );
};

export default EnrollmentDetailCard;
