import moment from "moment";
import { useEffect, useState } from "react"
import { Button, Form } from "react-bootstrap"
import toast from "react-hot-toast"
import { AdminService } from "../../services/admin.service"
import AnnouncementService from "../../services/announcements.service";
import SideModal from "../SideModal/SideModal"
import CalenderInput from "./DatePicker";
import Select from "react-select";

interface IEdit {
  data:any;
  show:boolean;
  setShow:any
}

export default function EditAnnouncement(props: IEdit) {
  const [announcement, setAnnouncement] = useState({
    bannerImg: "",
    title: "",
    header: "",
    line1: "",
    line2: "",
    line3: "",
    link: "",
    validUpto: "",
    target: "",
    isVisible:false,
    approve:false,
    deleted:false,
  });


  const [date, setDate] = useState(
    moment().add("1", "hours").startOf("hour").toDate()
  );
  const [target, setTarget] = useState<any>({ value: "all", label: "All" });
  

    const onUpdateUserClick = async () => {
      const payload= {...announcement};
      payload.target = target.value;
      payload.validUpto = date.toISOString();
      console.log(payload,'data payload')
      await AnnouncementService.update(
          props?.data?._id,
          payload
        ).then((res) => {
          console.log(res?.data?.data,'check')
          if (res.status === 200) {
            toast.success("Updated");
            window.location.reload();
          }
        }).catch((err) => {toast.error("Error updating")});
    }

    console.log(props.data,'data')

    useEffect(()=>{
        setAnnouncement({
            bannerImg: props.data?.bannerImg,
    title: props.data?.title,
    header: props.data?.header,
    line1: props.data?.line1,
    line2: props.data?.line2,
    line3: props.data?.line3,
    link: props.data?.link,
    validUpto: props.data?.validUpto,
    target: props.data?.target,
    isVisible:props.data?.isVisible,
    approve:props.data?.approve,
    deleted:props.data?.deleted,
        });
    },[props.data])
    return (
      <SideModal
        show={props.show}
        handleClose={() => props.setShow(false)}
        title="Edit Announcement"
        body={
          <div className="w-100 mt-3">
            <div className="form-group">
              <label className="fw-bold text-secondary fs-12">
                Banner Image
              </label>
              <input
                className={`form-control`}
                value={announcement.bannerImg}
                onChange={(e) =>
                  setAnnouncement((prev) => ({
                    ...prev,
                    bannerImg: e.target.value,
                  }))
                }
              />
              <div className="invalid-feedback">Should not be empty</div>
            </div>
            <div className="form-group">
              <label className="fw-bold text-secondary fs-12">Title</label>
              <input
                className={`form-control`}
                value={announcement.title}
                onChange={(e) =>
                  setAnnouncement((prev) => ({
                    ...prev,
                    title: e.target.value,
                  }))
                }
              />
              <div className="invalid-feedback">Should not be empty</div>
            </div>
            <div className="form-group">
              <label className="fw-bold text-secondary fs-12">Header</label>
              <input
                className={`form-control`}
                value={announcement.header}
                onChange={(e) =>
                  setAnnouncement((prev) => ({
                    ...prev,
                    header: e.target.value,
                  }))
                }
              />

              <div className="invalid-feedback">Should not be empty</div>
            </div>
            <div className="form-group">
              <label className="fw-bold text-secondary fs-12">line1</label>
              <input
                className={`form-control`}
                value={announcement.line1}
                onChange={(e) =>
                  setAnnouncement((prev) => ({
                    ...prev,
                    line1: e.target.value,
                  }))
                }
              />
              <div className="invalid-feedback">Should not be empty</div>
            </div>
            <div className="form-group">
              <label className="fw-bold text-secondary fs-12">line2</label>
              <input
                className={`form-control`}
                value={announcement.line2}
                onChange={(e) =>
                  setAnnouncement((prev) => ({
                    ...prev,
                    line2: e.target.value,
                  }))
                }
              />
              <div className="invalid-feedback">Should not be empty</div>
            </div>
            <div className="form-group">
              <label className="fw-bold text-secondary fs-12">line3</label>
              <input
                className={`form-control`}
                value={announcement.line3}
                onChange={(e) =>
                  setAnnouncement((prev) => ({
                    ...prev,
                    line3: e.target.value,
                  }))
                }
              />
              <div className="invalid-feedback">Should not be empty</div>
            </div>
            <div className="form-group">
              <label className="fw-bold text-secondary fs-12">Link</label>
              <input
                className={`form-control`}
                value={announcement.link}
                onChange={(e) =>
                  setAnnouncement((prev) => ({
                    ...prev,
                    link: e.target.value,
                  }))
                }
              />
              <div className="invalid-feedback">Should not be empty</div>
            </div>
            <div className="form-group">
              <label className="fw-bold text-secondary fs-12">Valid Upto</label>
              <CalenderInput
                setValue={(date: any) => {
                  setDate(date);
                }}
                selected={date}
              />
              <div className="invalid-feedback">Should not be empty</div>
            </div>
            <div className="form-group">
              <label className="fw-bold text-secondary fs-12">Target</label>
              <Select
                options={[{ label: "All", value: "all" }]}
                value={target}
                onChange={setTarget}
              />
            </div>
            <div className="form-group d-flex justify-content-between">
              <label className="fw-bold text-secondary fs-12">Approve</label>
              <input
                checked={announcement.approve}
                onChange={(e) =>
                  setAnnouncement((prev) => ({
                    ...prev,
                    approve: e.target.checked,
                  }))
                }
                type="checkbox"
              />
            </div>{" "}
            <div className="form-group d-flex justify-content-between">
              <label className="fw-bold text-secondary fs-12">Delete</label>
              <input
                checked={announcement.deleted}
                onChange={(e) =>
                  setAnnouncement((prev) => ({
                    ...prev,
                    deleted: e.target.checked,
                  }))
                }
                type="checkbox"
              />
            </div>
            <div className="form-group d-flex justify-content-between">
              <label className="fw-bold text-secondary fs-12">Visibility</label>
              <input
                checked={announcement.isVisible}
                onChange={(e) =>
                  setAnnouncement((prev) => ({
                    ...prev,
                    isVisible: e.target.checked,
                  }))
                }
                type="checkbox"
              />
            </div>
            <button
              className="btn btn-primary btn-block mt-6"
              onClick={() => onUpdateUserClick()}
            >
              Edit
            </button>
          </div>
        }
      />
    );
}