import React, { useEffect, useState } from 'react'
import { Alert, Button, Modal } from 'react-bootstrap'
import { AdminService } from '../../services/admin.service'
import Select from 'react-select'
import { RenewalStatusEnum } from '../../enum/renewal.enum'
import SideModal from '../SideModal/SideModal'
import RenewalBadge from '../Labels/Renewal.badge'
import { toast } from 'react-hot-toast'
import moment from 'moment'

interface IRenewalSummaryModal {
    renewalId: any,
    onHide: any,
}

const RenewalSummaryModal = (props: IRenewalSummaryModal) => {
    const [renewal, setRenewal] = React.useState<any>({})

    const [selectedStatus, setSelectedStatus] = React.useState<any>()
    const [summary, setSummary] = React.useState<any>(undefined)

    const [note, setNote] = useState<any>(undefined)

    const getRenewalById = async () => {
        if (!props.renewalId) return false

        await AdminService.getRenewalById(props.renewalId).then((res) => {
            if (res.status === 200) {
                setRenewal(res.data.renewal)
                setSelectedStatus({ value: res.data.renewal.status, label: res.data.renewal.status })
            }
        })
    }

    const addNote = async () => {
        if(!note) return false;

        await AdminService.addNote(props.renewalId, note).then((res) => {
            if(res.status === 200){
                toast.success('Note Added Successfully')
                setNote("")
                getRenewalById()
            }
        }).catch(e => {
            toast.error('Something went wrong')
        })
    }


    const getRenewalSummary = async () => {
        if (!props.renewalId) return false
        await AdminService.getRenewalSummary(props.renewalId).then((res) => {
            setSummary(res.data.last_purchase)
        }).catch(e => {
            setSummary(undefined)
        })
    }
    useEffect(() => {
        getRenewalById()
        getRenewalSummary()
    }, [props.renewalId])



    return (

        <SideModal
            show={props.renewalId ? true : false}
            handleClose={props.onHide}
            title="Student Renewal Summary"
            body={
                <>
                    <div className='d-flex justify-content-end'>
                        <span className='fs-14 fw-bold text-secondary me-2'>Status: </span><RenewalBadge renewal={renewal?.status} />
                    </div>
                    {summary ? 
                    <Alert variant='primary' className='mt-3  fw-bold'>
                        <div>
                            <span className="me-1 text-secondary">Last Purchase Date:</span> {moment(summary?.payment_date).format('DD/MM/YYYY hh:mm A')}
                        </div>
                        <div>
                            <span className="me-1 text-secondary">Last Purchase Amount:  </span> {summary?.total_price}
                        </div>
                        <div>
                            <span className="me-1 text-secondary">Classes Purchased:  </span> {summary?.num_classes}
                        </div>
                        <div>
                            <span className="me-1 text-secondary">Extra Classes:  </span> {renewal?.extraClasses || "--"}
                        </div>
                    </Alert>
                       : 
                    <Alert variant='danger' className='mt-3'>
                        No Renewal Summary Found
                    </Alert>
                       }
                       <hr/>
                       <div className='mt-3'>
                        <div className='d-flex justify-content-start align-items-center'>
                            <h5>Notes</h5>
                        </div>
                        <div className='bg-muted p-3 rounded'>
                            <textarea className='form-control' rows={5} value={note} placeholder='Write note here' onChange={(e) => {setNote(e.target.value)}} />
                            <Button variant='primary' size="sm" className='mt-2' onClick={addNote} disabled={!note}>Add Note</Button>
                        </div>
                       <hr/>

                        <div className='mt-3'>
                            {renewal && renewal.notes ? renewal?.notes.slice(0).reverse().map((note: any) => {
                                return (
                                    <>
                                        <Alert variant='dhunprimary'>
                                            <div className='text-wrap text-break'>
                                                {note?.data}
                                            </div>
                                            <div className=" mt-2 text-right font-italic fs-10">By {(note?.addedBy?.firstName || " ") + " " + (note?.addedBy?.lastName || " ")}</div>   
                                            <div className="text-right text-muted font-italic fs-10">{moment(note?.date).format('DD/MM/YYYY hh:mm A')}</div>   
                                        </Alert>
                                    </>
                                )
                            }) : "--"}
                        </div>
                       </div>
                </>
            } />
    )
}

export default RenewalSummaryModal