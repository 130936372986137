import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import CardBasic from '../../../components/Card/card.basic'
import { AdminService } from '../../../services/admin.service'
import BlockedEnrollmentTable from './BlockedEnrollment.table'

const BlockedEnrollments = () => {

  

    return (
        <>
            <Container>
                <Row className="mt-5">
                    <Col>
                        <CardBasic
                            className="mt-2"
                            body={
                                <>
                                    <BlockedEnrollmentTable />
                                </>
                            }
                        />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default BlockedEnrollments