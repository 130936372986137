import makeRequest from "../../api/makeRequest";
import url from "../../api/urls";
import { MethodsEnum } from "../../enum/methods.enum";
import {
  FETCH_INSTRUCTOR_COURSE_DETAIL_FAILURE,
  FETCH_INSTRUCTOR_COURSE_DETAIL_INITIAL,
  FETCH_INSTRUCTOR_COURSE_DETAIL_SUCCESS,
} from "../constants/instructorCourse";

const onFetchInstructorCourseInitial = (payload?: object) => ({
  type: FETCH_INSTRUCTOR_COURSE_DETAIL_INITIAL,
  payload,
});
const onFetchInstructorCourseSuccess = (payload?: object) => ({
  type: FETCH_INSTRUCTOR_COURSE_DETAIL_SUCCESS,
  payload,
});
const onFetchInstructorCourseFailure = (payload?: object) => ({
  type: FETCH_INSTRUCTOR_COURSE_DETAIL_FAILURE,
  payload,
});

export const onFetchInstructorCourse = (payload?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(onFetchInstructorCourseInitial(payload));

      const { data } = await makeRequest(
        `${url?.getInstructorCourses}${payload?.id}`,
        MethodsEnum.GET
      );
      dispatch(onFetchInstructorCourseSuccess(data));
    } catch (error: any) {
      dispatch(onFetchInstructorCourseFailure(error));
    }
  };
};
