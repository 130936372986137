import React, { useEffect, useState } from "react";
import SessionsTable from "../SessionsTable";
import TableHeading from "../../TableHeading";
import { AdminService } from "../../../../../services/admin.service";
import toast from "react-hot-toast";
import CardBasic from "../../../../../components/Card/card.basic";

const InstructorSessions = (props: any) => {

  const [sessionTitleIndex, setSessionTitleIndex] = useState<any>(0);

  const titles = [
    "Completed Sessions",
    "Scheduled Sessions",
    "Cancelled Sessions",
    "Rescheduled Sessions",
  ];

  const sessionTitle = titles[sessionTitleIndex];

  const id = props.id;

  const [tableData, setTableData] = useState<any>();
  const fetchInstructorSession = async () => {
    await AdminService.getInstructorSession({ id })
      .then((res) => {
        if (res.status === 200) {
          if (sessionTitle === "Completed Sessions")
            setTableData(res?.data?.completed);
          if (sessionTitle === "Scheduled Sessions") {
            setTableData(res?.data?.scheduled);
          }
          if (sessionTitle === "Cancelled Sessions") {
            setTableData(res?.data?.cancelled);
          }
          if (sessionTitle === "Rescheduled Sessions") {
            setTableData(res?.data?.rescheduled);
          }
        }
      })
      .catch((err: any) => {
        toast.error("Unable to fetch Instructor Session");
      });
  };

  useEffect(() => {
    fetchInstructorSession();
  }, [id, sessionTitle]);

  const [totalSessions, setTotalSessions] = useState<any>(tableData);

  return (
    <div className="mt-3">
      <CardBasic
        body={
          <>
            <TableHeading
              isDynamic="true"
              heading={sessionTitle}
              setSessionHeading={(title: any) => { }}
              titlesLength={titles.length - 1}
              setSessionTitleIndex={(index: any) => setSessionTitleIndex(index)}
            />

            <div className="m-4" style={{ maxHeight: "500px", overflow: "scroll" }}>
              <SessionsTable
                totalSessions={totalSessions ? totalSessions : tableData}
                reloadData={() => fetchInstructorSession()}
                deleteAllowed={sessionTitle === "Scheduled Sessions" ? true : false}
                zoomLinkShow={true}
              />

            </div>
          </>
        } />
    </div>
  );
};

export default InstructorSessions;
