import { IAction } from "../../action";
import { IEnrollmentState } from "./interface";
import * as actionTypes from "../../constants/enrollment";

const initialState: IEnrollmentState = {
  loading: false,
  enrollment: {},
};

const enrollmentReducer = (state = initialState, action: IAction) => {
  if ((action.type === actionTypes.FETCH_ENROLLMENT_INITIAL)) {
    return {
      ...state,
      enrollment: {},
      loading: true,
    };
  }
  if ((action.type === actionTypes.FETCH_ENROLLMENT_SUCCESS)) {
    return {
      ...state,
      enrollment: action.payload || {},
      loading: false,
    };
  }

  if ((action.type === actionTypes.FETCH_ENROLLMENT_FAILURE)) {
    return {
      ...state,
      enrollment: {},
      loading: false,
    };
  }

  return state;
};

export default enrollmentReducer;
