import makeRequest, { makeParams, RequestMethods } from "../api/makeRequest";
import url from "../api/urls";

export default class SalesService {
  static async getLink(fromDate: string, toDate: string, status: any) {
    const params = makeParams([
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
      {
        index: "paymentStatus",
        value: status,
      },
    ]);

    return await makeRequest(
      `${url.sales.getLink + params}`,

      RequestMethods.GET
    );
  }
  static async createLink(payload: any) {
    return await makeRequest(
      url.sales.createLink,
      RequestMethods.POST,
      payload
    );
  }

  static async getSalesInvoices(fromDate: string,toDate: string,fromInvoiceNumber: any,toInvoiceNumber: any) {
    const params = makeParams([
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
      {
        index: "fromInvoiceNumber",
        value: fromInvoiceNumber,
      },
      {
        index: "toInvoiceNumber",
        value: toInvoiceNumber,
      },
    ]);
    return await makeRequest(url.sales.getAllInvoices + params, RequestMethods.GET);
  }


  static async getCancelledInvoice(fromDate: string,toDate: string,fromInvoiceNumber: any,toInvoiceNumber: any) {
    const params = makeParams([
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
      {
        index: "fromInvoiceNumber",
        value: fromInvoiceNumber,
      },
      {
        index: "toInvoiceNumber",
        value: toInvoiceNumber,
      },
    ]);
    return await makeRequest(url.sales.getCancelledInvoice + params, RequestMethods.GET);
  }

  static async createSalesInvoice(payload: any) {
    return await makeRequest(url.sales.createInvoice, RequestMethods.POST, payload);
  }


  static async createPdf(invoiceId: any) {
    return await makeRequest(
      `${url.sales.createPdf}/${invoiceId}`,
      RequestMethods.GET
    );
  }

  static async markComplete(_id: any) {
    return await makeRequest(`${url.sales.markComplete}/${_id}`,RequestMethods.POST);
  }

  static async createSaleCouponCode(payload:any){
    return await makeRequest(url.sales.createSaleCoupon, RequestMethods.POST, payload);
  }

  static async getSaleCouponCodes(id:string = '', status:string='All'){
    let reqUrl = id ? `${url.sales.getAllSaleCoupon}/${id}` : url.sales.getAllSaleCoupon;
    if(status === 'active'){
      reqUrl = `${reqUrl}?isCancelled=false`;
    }else if(status === 'inactive'){
      reqUrl = `${reqUrl}?isCancelled=true`;
    }
    return await makeRequest(reqUrl, RequestMethods.GET);
  }

  static async updateSaleCouponCode(id:string, payload:any){
    return await makeRequest(url.sales.updateSaleCoupon+'/'+id, RequestMethods.PUT, payload);
  }

  static async checkCoupon(code:string){
    return await makeRequest(url.sales.checkCoupon, RequestMethods.POST, {code});
  }

  static async cancelInvoice(invoiceId:any) {
    return await makeRequest(url.sales.cancleInvoice + "/" + invoiceId , RequestMethods.PUT);
  }

}
