import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import CardBasic from "../../../../../components/Card/card.basic";
import { AdminService } from "../../../../../services/admin.service";
import TableHeading from "../../TableHeading";
import StudentSessionsTable from "../StudentSessionsTable";

const StudentSessions = (props: any) => {

  const [sessionTitleIndex, setSessionTitleIndex] = useState<any>(0);

  const titles = [
    "Completed Sessions",
    "Scheduled Sessions",
    "Cancelled Sessions",
  ];

  const sessionTitle = titles[sessionTitleIndex];

  const id = props.id;
  const [tableData, setTableData] = useState<any>();

  const getStudentSessionData = async () => {
    if (sessionTitle === "Completed Sessions") {
      await AdminService.getStudentSession({ id }, "completed")
        .then((res: any) => {
          if (res.status === 200) {
            setTableData(res?.data);
          }
        })
        .catch((err: any) => {
          toast.error("Unable to fetch Student Session");
        });
    }
    if (sessionTitle === "Scheduled Sessions") {
      await AdminService.getStudentSession({ id }, "scheduled")
        .then((res: any) => {
          if (res.status === 200) {
            setTableData(res?.data);
          }
        })
        .catch((err: any) => {
          toast.error("Unable to fetch Student Session");
        });
    }

    if (sessionTitle === "Cancelled Sessions") {
      await AdminService.getStudentSession({ id }, "cancelled")
        .then((res: any) => {
          if (res.status === 200) {
            setTableData(res?.data);
          }
        })
        .catch((err: any) => {
          toast.error("Unable to fetch Student Session");
        });
    }
  };

  useEffect(() => {
    getStudentSessionData();
  }, [id, sessionTitle]);

  const [totalSessions, setTotalSessions] = useState<any>(tableData);

  return (
    <>
      <CardBasic
        body={
          <>
         
            <TableHeading
              isDynamic="true"
              heading={sessionTitle}
              setSessionHeading={(title: any) => { }}
              titlesLength={titles.length - 1}
              setSessionTitleIndex={(index: any) => setSessionTitleIndex(index)}
            />
            
            <div className="m-4" style={{maxHeight:"500px", overflow: "scroll"}}>
                <StudentSessionsTable
                  totalSessions={totalSessions ? totalSessions : tableData}
                  reloadData={()=>getStudentSessionData()}
                  deleteAllowed={sessionTitle === "Scheduled Sessions" ? true :false}
                  zoomLinkShow={true}
                />
                </div>
          </>
        }
      />
    </>
  );
};

export default StudentSessions;
