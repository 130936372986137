import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./CenterModal.css";

interface ICenterModal {
  show: boolean;
  handleClose: any;
  body: any;
  title: string;
  footer?: any;
}

export default function CenterModal(props: ICenterModal) {
  return (
    <>
      <div className={props.show ? "dhun-centermodal-parent" : "d-none"}>
        <div className="d-flex justify-content-end">
          <div className="w-100 h-100"></div>
          <div>
            <div className="dhun-centermodal-main">
              <div className="dhun-centermodal-top">
                <div className="d-flex justify-content-between align-items-center mx-1">
                  <span className="text-black text-capitalize fs-5 fw-bold">
                    {props.title}
                  </span>
                  <FontAwesomeIcon
                    icon={faClose}
                    className="text-black dhun-link-pointer"
                    onClick={props.handleClose}
                  />
                </div>
                <hr className="text-primary mx-1" />
                <div className="mt-2 mx-1 h-100" style={{minHeight: '100vh'}}>{props.body}</div>
              </div>
              {props.footer && (
                <div className="dhun-centermodal-footer mx-1">{props.footer}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
