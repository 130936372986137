import makeRequest, { makeParams, RequestMethods } from "../api/makeRequest";
import url from "../api/urls";

export default class AnnouncementService {
  static async get(
  ) {
    return await makeRequest(url.announcement.get, RequestMethods.GET);
  }
  static async create(payload: any) {
    return await makeRequest(
      url.announcement.create,
      RequestMethods.POST,
      payload
    );
  }

  static async update(id: string, payload: any) {
    return await makeRequest(
      url.announcement.update + "/" + id,
      RequestMethods.PUT,
      payload
    );
  }

  static async delete(id: string) {
    return await makeRequest(
      url.announcement.delete + "/" + id,
      RequestMethods.DELETE,
    );
  }
}
