import React, { useEffect, useState } from "react";
import { Badge, Dropdown, Pagination, Table } from "react-bootstrap";
import { IoCheckbox, IoCheckmark, IoFlag, IoTrashBinSharp } from "react-icons/io5";
import { FontBold } from "../Text/TableText.formats";
import "./table.css";
import moment from "moment";
import { BiDotsVertical, BiPencil } from "react-icons/bi";
import CustomToggle from "../Menu/CustomMenu";
import { AdminService } from "../../services/admin.service";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import FlaggedSessionModel from "../Modals/FlaggedSessionModel";
import AnnouncementService from "../../services/announcements.service";
import EditAnnouncement from "../Modals/EditAnnouncementModal";
import { ImCheckmark, ImCross } from "react-icons/im";

interface IAnnoucement {
  tableData: any;
  reload:any;
}

export default function AllAnnouncementTable({
  tableData,
  reload
}: IAnnoucement) {
  const navigate = useNavigate();

  const [showAnnoucementModal, setShowAnnoucementModal] =
  useState<boolean>(false);
  const [index, setIndex] = useState<any>();

  const handleAnnoucementDelete = async (_id: any) => {
    await AnnouncementService.delete(_id)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Annoucement Deleted Successfully");
          reload();
        }
      })
      .catch((e) => {
        console.error(e);
        toast.error(e.response.data);
      });
  };


  return (
    <>
      <Table striped borderless hover responsive size="sm">
        <thead>
          <tr className="dhun-table-header text-secondary">
            <th>#</th>
            <th>target</th>
            <th>bannerImg</th>
            <th>title</th>
            <th>header</th>
            <th>line 1</th>
            <th>line 2</th>
            <th>line 3</th>
            <th>Valid Till</th>
            <th>link</th>
            <th>Approved</th>
            <th>Deleted</th>
            <th>Visibility</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {tableData && tableData?.length > 0
            ? tableData?.map((data: any, index: any) => {
                return (
                  <tr key={data?._id}>
                    <td>
                      <FontBold value={index + 1} />
                    </td>
                    <td>
                      <span className="fw-bold fs-10 text-secondary">
                        {data?.target}
                      </span>
                    </td>
                    <td>
                      <span className="fw-bold fs-10 text-secondary">
                        {data?.title}
                      </span>
                    </td>
                    <td>
                      <span className="fw-bold fs-10 text-secondary">
                        {data?.line1}
                      </span>
                    </td>{" "}
                    <td>
                      <span className="fw-bold fs-10 text-secondary">
                        {data?.line2}
                      </span>
                    </td>{" "}
                    <td>
                      <span className="fw-bold fs-10 text-secondary">
                        {data?.line3}
                      </span>
                    </td>
                    <td>
                      <span className="font-monospace fs-12 text-secondary">
                        {moment(data?.data?.validUpto).format(
                          "DD MM YYYY hh:mm a")}
                      </span>
                    </td>
                    <td>
                      <span className="fw-bold fs-10 text-secondary">
                        {data?.link}
                      </span>
                    </td>
                    <td>
                      <span className="fw-bold fs-10 text-secondary">
                        {data?.approve == true ? (
                          <ImCheckmark className="text-success fs-6" />
                        ) : (
                          <ImCross className="text-danger" />
                        )}
                      </span>
                    </td>{" "}
                    <td>
                      <span className="fw-bold fs-10 text-secondary">
                        {data?.deleted == true ? (
                          <ImCheckmark className="text-success fs-6" />
                        ) : (
                          <ImCross className="text-danger" />
                        )}
                      </span>
                    </td>{" "}
                    <td>
                      <span className="fw-bold fs-10 text-secondary">
                        {data?.isVisible == true ? (
                          <ImCheckmark className="text-success fs-6" />
                        ) : (
                          <ImCross className="text-danger" />
                        )}
                      </span>
                    </td>{" "}
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          as={CustomToggle}
                          id="dropdown-custom-components"
                        >
                          <BiDotsVertical className="cursor-pointer" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              handleAnnoucementDelete(data?._id);
                            }}
                          >
                            <IoTrashBinSharp className="text-danger" />{" "}
                            <span className="fw-bold text-danger fs-12">
                              Delete
                            </span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setIndex(index);
                              setShowAnnoucementModal(true);
                            }}
                          >
                            <BiPencil className="text-success" />{" "}
                            <span className="fw-bold text-success fs-12">
                              Edit
                            </span>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                );
              })
            : "No Data Available"}
        </tbody>
      </Table>
      <EditAnnouncement
        setShow={setShowAnnoucementModal}
        show={showAnnoucementModal}
        data={tableData && tableData[index]}
      />
    </>
  );
}
