import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import "./table.css";
import moment from "moment";
import { CSVLink } from "react-csv";
import toast from "react-hot-toast";
import InvoiceService from "../../services/invoice.service";
import AllInvoicesByInstructor from "../Modals/AllInvoicesByInstructor.modal";
import RecordInvoiceModal from "../Modals/RecordInvoices.modal";

interface IUnpaidInvoice {
  pageCount: any
  pageNumber: any
  startDate: any
  endDate: any 
  instructor: any;
}

export default function UnpaidInvoicesTableForRecordInvoices({
  pageCount,
  pageNumber,
  startDate,
  endDate,
  instructor
}: IUnpaidInvoice) {

  const [tableData, setTableData] = useState<any>();
  const [show, setShow] = useState(false);

  const getInvoices = async () => {
    if(!moment(startDate).isValid() || !moment(endDate).isValid()){
      return;
    }
    const fromDate = moment(startDate).format("YYYY-MM-DD");
     const toDate = moment(endDate).format("YYYY-MM-DD");
     
    await
      InvoiceService.getInvoices(pageCount, pageNumber, "instructor_fine", fromDate, toDate, instructor?.value).then((res: any) => {
        if (res.status === 200) {
          setTableData(res?.data.invoices);
        }
      })
        .catch((err: any) => {
          // toast.error("Invoice Details not found.");
          console.log(err)
        })
  }

  useEffect(() => {
    getInvoices()
  }, [pageCount, startDate, endDate, instructor]);
  return (
    <>
      <Table striped borderless hover responsive size="sm">
      
        <thead>
          <tr className="dhun-table-header text-secondary">
            <th>Receipt Id</th>
            <th>Instructor</th>
            <th>Enrollment Id</th>
            <th>Schedule Id</th>
            <th>Amount</th>
            <th>Date</th>
            <th>Comments</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {tableData && tableData?.length > 0
            ? tableData?.map((data: any, index: any) => {
                return (
                  <tr >
                    <td>
                      {data.receipt_id}
                    </td>
                    <td>
                      {data.instructor_name}
                    </td>
                    <td>
                     {data.enrollment_id}
                    </td>
                    <td>
                     {data.schedule_id}
                    </td>
                    <td>
                      {data.amount}
                    </td>
                    <td>
                      {moment(data.date1).format("DD/MM/YYYY hh:mm a")}
                    </td>
                    <td>
                      {data.comments}
                    </td>
                    <td>
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => setShow(true)}
                      >
                        Record Invoice
                      </Button>
                      

                    </td>

                  </tr>
                );
              })
            : "No Data Available"}
           
        </tbody>
      </Table>
      {/* <RecordInvoiceModal
                        show={show}
                        setShow={setShow}
                        
                      /> */}
      
      
    </>
  );
}
