import { useEffect, useRef, useState } from "react";
import { Button, Form, Image, Offcanvas } from "react-bootstrap";
import Select from "react-select";
import InstructorService from "../../../../../services/instructor.service";
import toast from "react-hot-toast";

interface IAddProductDetails {
  show: any;
  handleClose: any;
  reload: any;
  instructor: any;
}

export default function InstructorDetailModal({
  show,
  handleClose,
  reload,
  instructor,
}: IAddProductDetails) {
  const [data, setData] = useState<any>({
    email: instructor?.email,
    firstName: instructor?.firstName,
    lastName: instructor?.lastName,
    phone: instructor?.phone,
    countryCode: instructor?.countryCode,
  });

  const handleChangeValue = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const updateUser = async () => {
    console.log(data, instructor);
    await InstructorService.updateUser(data, instructor._id)
      .then((res) => {
        if (res.status === 200) {
          toast.success("user details updated successfully");
          handleClose();
          reload();
        }
      })
      .catch((err: any) => {
        console.error(err);
        toast.error("cannot update the user details");
      });
  };

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      backdrop="static"
      placement="end"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Update User Details</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form.Group className="mb-3">
          <Form.Label className="form-label product-label">
            First Name
          </Form.Label>
          <Form.Control
            type="text"
            value={data.firstName}
            name="firstName"
            onChange={(e: any) => handleChangeValue(e)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="form-label product-label">
            Last Name
          </Form.Label>
          <Form.Control
            type="text"
            name="lastName"
            value={data.lastName}
            onChange={(e: any) => handleChangeValue(e)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="form-label product-label">Email</Form.Label>
          <Form.Control
            type="text"
            name="email"
            value={data.email}
            onChange={(e: any) => handleChangeValue(e)}
          />
        </Form.Group>
        <div className="d-flex flex-row gap-3 ">
          <Form.Group className="mb-3 w-25">
            <Form.Label className="form-label product-label">
              Country
            </Form.Label>
            <Form.Control
              type="text"
              name="countryCode"
              value={data.countryCode}
              onChange={(e: any) => handleChangeValue(e)}
            />
          </Form.Group>
          <Form.Group className="mb-3 w-75">
            <Form.Label className="form-label product-label">Phone</Form.Label>
            <Form.Control
              type="number"
              name="phone"
              max={10}
              min={10}
              value={data.phone}
              onChange={(e: any) => handleChangeValue(e)}
            />
          </Form.Group>
        </div>

        <Button
          type="submit"
          className="w-100 btn btn-secondary mt-3"
          onClick={updateUser}
        >
          Add
        </Button>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
