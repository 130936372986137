import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import "./table.css";
import { CSVLink } from "react-csv";
import moment from "moment";
import toast from "react-hot-toast";
import InvoiceService from "../../services/invoice.service";
import AllInvoicesByInstructor from "../Modals/AllInvoicesByInstructor.modal";
import RecordInvoiceModal from "../Modals/RecordInvoices.modal";

interface IUnpaidInvoice {
  pageCount: any;
  pageNumber: any;
  startDate: any;
  endDate: any;
  instructor: any;
}

export default function UnpaidInvoicesTableForAll({
  pageCount,
  pageNumber,
  startDate,
  endDate,
  instructor,
}: IUnpaidInvoice) {
  const [tableData, setTableData] = useState<any>();
  const [allInvoicesData, setAllInvoicesData] = useState<any>();
  const [instId, setInstId] = useState<any>();
  const [showRecInv, setShowRecInv] = useState<any>(undefined);
  const [displayAllInvoicesModal, setDisplayAllInvoicesModal] = useState(false);

  const hideAllInvoicesModal = () => {
    setDisplayAllInvoicesModal(false);
  };
  // const [csvData, setCsvData] = useState<any>();

  const getInvoices = async () => {

    if(!moment(startDate).isValid() || !moment(endDate).isValid()){
      return;
    }
    const fromDate = moment(startDate).format("YYYY-MM-DD");
    const toDate = moment(endDate).format("YYYY-MM-DD");
    

    await InvoiceService.getInvoices(
      pageCount,
      pageNumber,
      "all",
      fromDate,
      toDate,
      instructor?.value
    )
      .then((res: any) => {
        if (res.status === 200) {
          setTableData(res?.data.invoices);
        }
      })
      .catch((err: any) => {
        // toast.error("Invoice Details not found.");
        console.log(err);
      });
  };

  const fetchAllInvoices = async (instId: any) => {

    // remove  invalid time value error
    if(!moment(startDate).isValid() && !moment(endDate).isValid()){
      return;
    }
    const fromDate = moment(startDate).format("YYYY-MM-DD");
    const toDate = moment(endDate).format("YYYY-MM-DD");
    
    await InvoiceService.fetchAllInvoices(instId, fromDate, toDate)
      .then((res: any) => {
        if (res.status === 200) {
          setInstId(instId);
          setAllInvoicesData(res?.data);
          setDisplayAllInvoicesModal(true);
        }
      })
      .catch((err: any) => {
        // toast.error("Invoice Details not found.");
        console.log(err);
      });
  };



  useEffect(() => {
    getInvoices();
  }, [pageCount, startDate, endDate, instructor]);

  return (
    <>
      <Container>
       
        <Table striped borderless hover responsive size="sm">
          <thead>
            <tr className="dhun-table-header text-secondary">
              <th>Name</th>
              <th>Total Amount</th>
              <th>Dhunguru Commission</th>
              <th>Dhunguru Earnings</th>
              <th>Amount Paid</th>
              <th>Total Fine</th>
              <th>Incentive(Total)</th>
              <th>TDS Deduced</th>
              <th>Amount to be Paid</th>
              <th>Record Payment</th>
              <th>All Invoices</th>
            </tr>
          </thead>
          <tbody>
            {tableData && tableData?.length > 0
              ? tableData?.map((data: any, index: any) => {
                  return (
                    <tr>
                      <td>{data.name}</td>
                      <td>{data.total_amount}</td>
                      <td>{data.commission + "%"}</td>
                      <td>{data.total_dhunguru_earnings}</td>
                      <td>{data.amount_paid}</td>
                      <td>{data.fined_amount}</td>
                      <td>{data.incentive + "("+ data.totalIncentiveCount +")"}</td>
                      <td>{data.tds}</td>

                      <td>{data.amount_after_tds}</td>
                      <td>
                        <Button
                          size="sm"
                          onClick={() => setShowRecInv(data.instructor_id)}
                        >
                          Record Invoices
                        </Button>
                      </td>
                      <td>
                        <Button
                          size="sm"
                          onClick={() => fetchAllInvoices(data.instructor_id)}
                        >
                          All Invoices
                        </Button>
                      </td>
                    </tr>
                  );
                })
              : "No Data Available"}
          </tbody>
        </Table>
      </Container>

      <AllInvoicesByInstructor
        showModal={displayAllInvoicesModal}
        hideModal={hideAllInvoicesModal}
        id={instId}
        allInvoicesData={allInvoicesData}
      />

      <RecordInvoiceModal
        instId={showRecInv}
        onHide={() => setShowRecInv(undefined)}
        startDate={startDate}
        endDate={endDate}
      />
    </>
  );
}
