import React from "react";
import EnrollmentTable from "../EnrollmentTable";
import TableHeading from "../../TableHeading/index";
import CardBasic from "../../../../../components/Card/card.basic";

export default function InstructorEnrollment (props: any) {
  return (
    <>
      <CardBasic
        body={
          <>
            <TableHeading heading="Enrollments" />
            <div className="m-4" style={{ maxHeight: "500px", overflow: "scroll" }}>
            <EnrollmentTable
              id={props.id}
              />
            </div>
          </>
        }
      />
    </>
   
  );
};
