import {
  Container,
  Card,
  ButtonGroup,
  Button,
  Pagination,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import CreateSaleItem from "../../../components/Modals/CreateSaleItem.modal";
import { AdminService } from "../../../services/admin.service";
import SalesItemsTable from "../../../components/Table/SalesItems.table";

interface IProps {
  activeKey: string;
}

export default function SalesItems({ activeKey }: IProps) {
  const [showCreateProduct, setShowCreateProduct] = useState<boolean>(false);
  const [pageCount, setPageCount] = useState(1);
  const [salesItems, setSalesItems] = useState<any[]>([]);

  async function getAllSalesItems() {
    await AdminService.getAllSalesItems()
      .then((res) => {
        setSalesItems(res.data.data?.items);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (activeKey === "items") {
      getAllSalesItems();
    }
  }, [activeKey]);
  return (
    <>
      <Container>
        <Card className="mt-4">
          <Card.Body>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <Card.Title>Items</Card.Title>
              <div className="d-flex gap-2 ">
                <ButtonGroup size="sm">
                  <Button onClick={() => setShowCreateProduct(true)}>
                    Add New Item
                  </Button>
                </ButtonGroup>
              </div>
            </div>
            <div className="m-4">
              <SalesItemsTable
                tableData={salesItems}
                reloadData={getAllSalesItems}
              />
            </div>
          </Card.Body>
        </Card>
      </Container>

      <CreateSaleItem
        show={showCreateProduct}
        setShow={setShowCreateProduct}
        reloadData={getAllSalesItems}
      />
    </>
  );
}
