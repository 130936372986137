import moment from "moment";
import React, { useEffect, useState } from "react";
import { Badge, Dropdown, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { BiDotsVertical } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoTrashBinSharp, IoVideocam } from "react-icons/io5";
import { useNavigate } from "react-router";
import CustomToggle from "../../../../../components/Menu/CustomMenu";
import {
  FontBold,
  ItalicText,
  NormalText,
} from "../../../../../components/Text/TableText.formats";
import { AdminService } from "../../../../../services/admin.service";
import CommonTable from "../../Table/CommonTable";

interface IInstructorSessions {
  totalSessions: any;
  reloadData: any;
  deleteAllowed: boolean;
  zoomLinkShow: boolean;
}

const SessionTable = (props: IInstructorSessions) => {
  const navigate: any = useNavigate();

  const handleSessionDelete = async (_id: any) => {
    await AdminService.deleteSession(_id)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Session Deleted Successfully");
          props.reloadData();
        }
      })
      .catch((e) => {
        console.error(e);
        toast.error(e.response.data);
      });
  };
  return (
    <Table striped borderless hover responsive size="sm">
      <thead>
        <tr className="dhun-table-header text-secondary">
          <th>#</th>
          <th>Session Id</th>
          <th>Student Name</th>
          <th>Enrollment Id</th>
          <th>Dhunguru Meet ID</th>
          <th>Date Time</th>
          <th>Instrument</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.totalSessions && props.totalSessions?.length > 0
          ? props.totalSessions?.map((data: any, index: any) => {
            return (
              <tr key={data?._id}>
                <td>
                  <NormalText value={index + 1} />
                </td>
                <td>
                  <FontBold value={data.bookingId} />
                  <span className="fs-10 ms-2">
                    {data.type === "demo" && (
                      <Badge bg="secondary">Demo</Badge>
                    )}
                  </span>
                </td>

                <td
                  onClick={() => {
                    navigate(
                      "/admin/search/student/detail/" + data?.student?._id
                    );
                  }}
                >
                  <u className="fst-italic text-primary fs-12 fs-underline cursor-pointer">
                    {data?.student?._id
                      ? data?.student?.firstName +
                      " " +
                      data?.student?.lastName
                      : "N.A."}
                  </u>
                </td>

                <td
                  onClick={() => {
                    navigate(
                      "/admin/search/enrollment/detail/" +
                      data?.enrollment?._id,
                      {
                        state: {
                          instId: data?.instructor?._id,
                          studentId: data?.student?._id,
                        },
                      }
                    );
                  }}
                >
                  <u className="fst-italic text-primary fs-12 fs-underline cursor-pointer">
                    {data?.enrollment?.enrollmentId}
                  </u>
                </td>
                <td>
                  <span className="font-monospace fs-12">
                    {data?.dhunguruMeetId || "--"}
                  </span>
                </td>
                <td>
                  <span className="font-monospace fs-12">
                    {moment(data?.date_time).format("DD MMM YY hh:mm a")}
                  </span>
                </td>
                <td>
                  <div className="text">
                    <FontBold
                      value={
                        data?.course && data?.course.instrument
                          ? data?.course.instrument.toUpperCase()
                          : "N.A."
                      }
                    />
                  </div>
                </td>
                <td className="fs-12">
                  {data.confirm_by_instructor === "student_no_show" ||
                    (data.confirm_by_student === "student_no_show" && (
                      <Badge bg="warning" className="me-1">
                        Student No Show
                      </Badge>
                    ))}
                  {data.confirm_by_instructor === "instructor_no_show" ||
                    (data.confirm_by_student === "instructor_no_show" && (
                      <Badge bg="warning" className="me-1">
                        Teacher No Show
                      </Badge>
                    ))}
                  {data.confirm_by_instructor &&
                    data.confirm_by_student &&
                    data.confirm_by_student != data.instructor_no_show && (
                      <Badge bg="danger" className="me-1">
                        Disputed Session
                      </Badge>
                    )}
                </td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle
                      as={CustomToggle}
                      id="dropdown-custom-components"
                    >
                      <BiDotsVertical />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {props.zoomLinkShow && (
                        <Dropdown.Item
                          className="text-secondary fs-12 align-items-center d-flex fw-bold"
                          onClick={() => {
                            navigator.clipboard.writeText(data.zoom.join_url);
                            toast.success("Link Copied");
                          }}
                        >
                          <IoVideocam className="text-primary me-1" /> Copy
                          Link
                        </Dropdown.Item>
                      )}
                      {props.deleteAllowed && (
                        <Dropdown.Item
                          className="text-danger fs-12 align-items-center d-flex fw-bold"
                          onClick={() => {
                            handleSessionDelete(data._id);
                          }}
                        >
                          <IoTrashBinSharp className="text-danger me-1" />{" "}
                          Delete Session
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            );
          })
          : "No Data Available"}
      </tbody>
    </Table>
  );
};

export default SessionTable;
