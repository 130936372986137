import React, { useCallback, useContext, useEffect, useState } from "react";
import Select from "react-select";
import classes from "./index.module.css";
import SessionBookingDetail from "./SessionBookingDetail";
import { BookingService } from "../../../services/booking.service";
import { Form, Card, Container, Button, Row, Col } from "react-bootstrap";
import toast from "react-hot-toast";
import { IsLoading } from "../../../App.context";
import Dropzone from "react-dropzone";
import { AdminService } from "../../../services/admin.service";
import { parse } from "papaparse";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CsvDetailBookedSessionTable from "../../../components/Table/CsvDetailBookedSessionTable";

function BulkSessionBooking() {

  const csvFile = require("../../../assets/bookSession.csv");


  const [selectedEnrollments, setSelectedEnrollments] = useState<any>();
  const [selectedSession, setSelectedSession] = useState<any[]>([]);
  const [error, setError] = useState<any>(undefined);
  const { loading, setLoading } = useContext<any>(IsLoading);
  const [session, setSession] = useState<any[]>([]);

  const [isSessionBooked, setIsSessionBooked] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [bookingCsv, setBookingCsv] = useState<any>();
  const [tableData, setTableData] = useState<any>();
  const [paidEnrollments, setPaidEnrollment] = useState<any>();




  const getAllPaidSessions = async () => {
    await BookingService.getAllPaidEnrollments().then((res) => {
      if (res.status === 200) {
        const select_data = res.data.data.map((data: any) => {
          return {
            label:
              data.student_name +
              " - " +
              data.instructor_name +
              " - " +
              data.instrument +
              " - " +
              data.enrollmentId +
              " (" +
              data.sessions_left +
              "/" +
              data.total_classes +
              ")",
            value: data.enrollmentId,
          };
        });
        setPaidEnrollment(select_data);
      }
    });
  };

  const bookBulkSessions = async () => {
    setLoading(true);
    const enrollmentId = selectedEnrollments.value;
    const selectedDates = selectedSession.map((date) => {
      return new Date(date).toISOString();
    });

    await BookingService.bulkBookSessions(enrollmentId, selectedDates)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Sessions Booked");
          setError(undefined);
          setSelectedEnrollments(undefined);
          setSelectedSession([]);
          setSession([]);
        } else {
          toast.error("Something Went Wrong");
          setError(res.data);
        }
      })
      .catch((e) => {
        setLoading(false);
        setError(e.response.data);
        toast.error("Error: " + e.response.data.message);
      });
    setLoading(false);
  };

  const handleUploadedFile = async (files: any) => {
    let formData = new FormData();
    formData.append("uploadfile", files[0]);

    // postData();
    await AdminService.csvOfBookedSession(formData)
      .then((res: any) => {
        setLoading(true);
        if (res.status === 200) {
          toast.success("Session booked Uploaded");
        }
      })
      .catch((e: any) => {
        toast.error(e?.message);
      });
    // setLoading(false);
  };


  const bulkBookingSubmission = async () => {
    let formData = new FormData();
    formData.append("file", bookingCsv);
    formData.append("fromDate", startDate.toISOString());
    formData.append("toDate", endDate.toISOString());
    toast.promise(AdminService.sessionBulkBooking(formData).then((res) => {
      if (res.status === 200) {
        setTableData(res?.data?.data?.enrollmentsThatCanNotBeBooked);
        toast.success("Session booked successfully");
      }
    }), {
      loading: 'Loading...',
      success: 'Upload Completed',
      error: 'Unable to upload file',
    })
  }


  useEffect(() => {
    getAllPaidSessions();
  }, [selectedSession]);


  return (
    <Row className="m-4">
      <Col md={8}>
        <Container>
          <Card style={{ minHeight: "300px" }} className="p-2">
            <div className="h5 fw-bold ms-3 mt-3 ">Bulk Booking</div>

            <div className="m-3">
              <Form.Label>Select Enrollment</Form.Label>
              <div className={classes.selector}>
                <Select
                  options={paidEnrollments}
                  value={selectedEnrollments}
                  isSearchable={true}
                  placeholder="Select Enrollments"
                  onChange={(e: any) => {
                    setSelectedEnrollments(e);
                  }}
                  isClearable={true}
                />
              </div>
            </div>
            <hr />
            <SessionBookingDetail
              selectedSessions={selectedSession}
              setSelectedSessions={setSelectedSession}
              session={session}
              setSession={setSession}
            />
            <div className="d-flex justify-content-center mb-2">
              <Button
                variant="secondary"
                size="sm"
                disabled={
                  selectedSession.length === 0 || !selectedEnrollments
                    ? true
                    : false
                }
                onClick={bookBulkSessions}
              >
                {" "}
                Book Sessions{" "}
              </Button>
            </div>

            {error && (
              <div className="mt-2 text-white">
                <div className="border border-1 border-muted p-2 rounded-2 d-flex justify-content-between mb-2 bg-dark">
                  Error: {JSON.stringify(error.message)} <br />
                  Data: {JSON.stringify(error.data)}
                  <br />
                  Status: {error.status}
                </div>
              </div>
            )}
          </Card>
        </Container>
      </Col>
      <Col md={4}>
        <Container>
          <div className="border border-4 border-dashed rounded-2">
            <Dropzone onDrop={handleUploadedFile}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div
                    {...getRootProps()}
                    style={{ minWidth: "200px", minHeight: "200px" }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <input {...getInputProps()} />
                    <p className="text-muted fw-bold">
                      Drag 'n' drop CSV File here
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        </Container>
      </Col>
      <Col md={8}>
        <Container>
          <Card style={{ minHeight: "300px" }} className="p-2">
            <div className="h5 fw-bold ms-3 mt-3 ">Bulk Booking(days)</div>
            <div className="ms-3">
              <Row>
                <Col md={3}>
                  <Form.Label>Start Date</Form.Label>
                  <div className={classes.selector}>
                    <DatePicker
                      selected={startDate}
                      onChange={(e: any) => setStartDate(e)}
                    />
                  </div>
                </Col>
                <Col md={3}>
                  <Form.Label>End Date</Form.Label>
                  <div className={classes.selector}>
                    <DatePicker
                      selected={endDate}
                      onChange={(e: any) => setEndDate(e)}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="ms-3 mt-3">
              <Form.Group controlId="formFileMultiple" className="mb-3">
                <div className="d-flex flex-column align-item-center">
                  <Form.Label>Select CSV here</Form.Label>
                  <a href={csvFile} download className="mb-2">
                    Download sample file <FontAwesomeIcon icon={faDownload} />
                  </a>
                </div>
                <Form.Control
                  type="file"
                  accept=".csv"
                  onChange={(e: any) => {
                    setBookingCsv(e.target.files[0]);
                    console.log(e.target.files[0], "e");
                  }}
                />
              </Form.Group>
            </div>{" "}
            <hr />
            <div className="d-flex justify-content-center mb-2">
              <Button
                variant="secondary"
                size="sm"
                // onClick={bookBulkSessions}
                onClick={() => {
                  bulkBookingSubmission();
                  setIsSessionBooked(true);
                }}
              >
                {" "}
                Book Sessions{" "}
              </Button>
            </div>
            {error && (
              <div className="mt-2 text-white">
                <div className="border border-1 border-muted p-2 rounded-2 d-flex justify-content-between mb-2 bg-dark">
                  Error: {JSON.stringify(error.message)} <br />
                  Data: {JSON.stringify(error.data)}
                  <br />
                  Status: {error.status}
                </div>
              </div>
            )}
          </Card>
          {isSessionBooked && (
            <Card style={{ minHeight: "300px" }} className="p-2">
              <div className="h5 fw-bold ms-3 mt-3 ">Csv Download</div>
              <div className="ms-3">
                <CsvDetailBookedSessionTable tableData={tableData} />
              </div>
            </Card>
          )}
        </Container>
      </Col>
    </Row>
  );
}

export default BulkSessionBooking;
