import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import AnnouncementService from "../../services/announcements.service";
import UserService from "../../services/user.service";
import { validateEmail, validatePhone } from "../../utilis/validations";
import SideModal from "../SideModal/SideModal";
import Select from "react-select";
import CalenderInput from "./DatePicker";
import moment from "moment";

interface ICreateAnnouncement {
  show: boolean;
  setShowModal: any;
  reloadData?: any;
}

const CreateAnnouncementModal = ({
  show,
  setShowModal,
}: ICreateAnnouncement) => {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(
    moment().add("1", "hours").startOf("hour").toDate()
  );

  const [user, setUser] = useState({
    bannerImg: "",
    title: "",
    header: "",
    line1: "",
    line2: "",
    line3: "",
    link: "",
    target: "",
    validUpto:Date(),
  });
  const [errors, setErrors] = useState(Array(9).fill(false));
  const [target,setTarget]=useState<any>({ value: "all", label: "All" });
  
  useEffect(() => {
    setErrors(Array(4).fill(false));
  }, [user]);

  useEffect(() => {
    setUser({
      bannerImg: "",
      title: "",
      header: "",
      line1: "",
      line2: "",
      line3: "",
      link: "",
      target: "",
      validUpto:Date(),
    });
  }, [show]);

  const validate = () => {
    let new_errors = Array(9).fill(false);
    if (user.bannerImg=="") new_errors[0] = true;
    if (user.title.length <3 ) new_errors[1] = true;
    if (user.header.length < 3) new_errors[2] = true;
    if (user.link == "") new_errors[3] = true;
    if (user.line1 == "") new_errors[5] = true;
    if (user.line2== "") new_errors[6] = true;
    if (user.line3 == "") new_errors[7] = true;
    setErrors(new_errors);
    if (new_errors.includes(true)) return false;
    else return true;
  };
  

  const createAnnouncement = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (!validate()) return;
    const payload={...user};
    payload.target=target.value;
    payload.validUpto=date.toISOString();
    await AnnouncementService.create(
     payload
    ).then((res) => {
      if (res.status === 200) {
        setShowModal(false);
        toast.success("Announcement Created");
      }
    });
    setLoading(false);
  };
  const handleClose = () => setShowModal(false);
  return (
    <SideModal
      show={show}
      handleClose={handleClose}
      title={"Create Annoucement"}
      body={
        <div className="w-100  mt-1">
          <form className="mt-3 ">
            <div className="form-group">
              <label className="fw-bold text-secondary fs-12">
                Banner Image
              </label>
              <input
                className={`form-control ${errors[0] && "is-invalid"} `}
                value={user.bannerImg}
                onChange={(e) =>
                  setUser((prev) => ({ ...prev, bannerImg: e.target.value }))
                }
              />
              <div className="invalid-feedback">Should not be empty</div>
            </div>

            <div className="form-group">
              <label className="fw-bold text-secondary fs-12">Title</label>
              <input
                className={`form-control ${errors[1] && "is-invalid"} `}
                value={user.title}
                onChange={(e) =>
                  setUser((prev) => ({ ...prev, title: e.target.value }))
                }
              />
              <div className="invalid-feedback">Should not be empty</div>
            </div>
            <div className="form-group">
              <label className="fw-bold text-secondary fs-12">Header</label>
              <input
                className={`form-control`}
                value={user.header}
                onChange={(e) =>
                  setUser((prev) => ({ ...prev, header: e.target.value }))
                }
              />
              <div className="invalid-feedback">Should not be empty</div>
            </div>
            <div className="form-group">
              <label className="fw-bold text-secondary fs-12">line1</label>
              <input
                className={`form-control`}
                value={user.line1}
                onChange={(e) =>
                  setUser((prev) => ({
                    ...prev,
                    line1: e.target.value,
                  }))
                }
              />
              <div className="invalid-feedback">Should not be empty</div>
            </div>
            <div className="form-group">
              <label className="fw-bold text-secondary fs-12">line2</label>
              <input
                className={`form-control`}
                value={user.line2}
                onChange={(e) =>
                  setUser((prev) => ({
                    ...prev,
                    line2: e.target.value,
                  }))
                }
              />
              <div className="invalid-feedback">Should not be empty</div>
            </div>
            <div className="form-group">
              <label className="fw-bold text-secondary fs-12">line3</label>
              <input
                className={`form-control`}
                value={user.line3}
                onChange={(e) =>
                  setUser((prev) => ({
                    ...prev,
                    line3: e.target.value,
                  }))
                }
              />
              <div className="invalid-feedback">Should not be empty</div>
            </div>
            <div className="form-group">
              <label className="fw-bold text-secondary fs-12">Link</label>
              <input
                className={`form-control ${errors[2] && "is-invalid"} `}
                value={user.link}
                onChange={(e) =>
                  setUser((prev) => ({ ...prev, link: e.target.value }))
                }
              />
              <div className="invalid-feedback">Should not be empty</div>
            </div>

            <div className="form-group">
              <label className="fw-bold text-secondary fs-12">Valid Upto</label>
              <CalenderInput
                setValue={(date: any) => {
                  setDate(date);
                }}
                selected={date}
              />
              <div className="invalid-feedback">Should not be empty</div>
            </div>
            <label className="fw-bold text-secondary fs-12">Target</label>
            <Select
              options={[
                { label: "All", value: "all" },
              ]}
              value={target}
              onChange={setTarget}
              className={`${errors[3] && "is-invalid"} `}
            />
            <div className="invalid-feedback">Should not be empty</div>

            <button
              className="btn btn-primary btn-block mt-6"
              onClick={(e) => createAnnouncement(e)}
              disabled={loading}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm mr-2"></span>
              )}
              Create Annoucement
            </button>
          </form>
        </div>
      }
    />
  );
};

export default CreateAnnouncementModal;
