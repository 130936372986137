import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './tabs.css';
interface Itab {
    name: string
    faIcon?: any
    key: string
}
interface ITabsIndex {
    selectedTabIndex: number
    setSelectedTabIndex?: any
    tabs: Itab[]
}

export default function TabsIndex(props: ITabsIndex) {
    console.log(props)
    return (
        <>
            <div>
                <div className="d-flex justify-content-start">
                    {
                        props.tabs.map((tab, index) => {
                            if (index === props.selectedTabIndex) {
                                return (
                                    <>
                                        <div className="dhun-tab active">
                                            <FontAwesomeIcon icon={tab?.faIcon} className='fs-12 me-2'/> {tab.name}
                                        </div>
                                    </>
                                )
                            }
                            else {
                                return (
                                    <>
                                        <div className="dhun-tab" onClick={() => props.setSelectedTabIndex(index)}>
                                            <FontAwesomeIcon icon={tab.faIcon} className='fs-12 me-2'/> {tab.name}
                                        </div>
                                    </>
                                )
                            }
                        })
                    }
                </div>
            </div>
        </>
    )
}