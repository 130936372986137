export const instrument = [
    { value: 'guitar', label: "Guitar" },
    { value: 'piano', label: "Piano" },
    { value: 'drums', label: "Drums" },
    { value: 'keyboard', label: "Keyboard" },
    { value: 'violin', label: "Violin" },
    { value: 'ukulele', label: "Ukulele" },
    { value: 'cajon', label: "Cajon" },
    { value: 'harmonium', label: "Harmonium" },
    { value: 'flute', label: "Flute" },
    { value: 'tabla', label: "Tabla" },
    { value: 'veena', label: "Veena" },
    { value: 'trumpet', label: "Trumpet" },
    { value: 'mridangam', label: "Mridangam" },
    { value: 'sitar', label: "Sitar" },
    { value: 'singing', label: "Singing" },
    { value: 'songWriting', label: "Song Writing" },
    { value: 'musicComposition', label: "Music Production" },
    { value: 'musicTheory', label: "Music Theory" },
    { value: 'musicTherapy', label: "Music Therapy" },
    { value: 'rap', label: "Rap" },
    { value: 'dJing', label: "DJing" },
    { value: 'dhol', label: "Dhol" },
    { value: 'harmonica', label: "Harmonica" },
    { value: 'mandolin', label: "Mandolin" },
    { value: 'doubleBass', label: "Double Bass" },
    { value: 'cello', label: "Cello" },
  ]
