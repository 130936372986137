export class ArrayHelper {
    static uniqueObjects (arrOfObj: any[]){
        let newArrOfObj:any[] = [];
        for(const obj of arrOfObj){
            const exist = newArrOfObj.find((data:any)=> obj_equals(data, obj));
            if(!exist){
                newArrOfObj.push(obj)
            }
        }
       return newArrOfObj;
    }

    
}

export const obj_equals:any = (a:any, b:any) => {
    if (a === b) return true;
    if (a instanceof Date && b instanceof Date)
      return a.getTime() === b.getTime();
    if (!a || !b || (typeof a !== 'object' && typeof b !== 'object'))
      return a === b;
    if (a.prototype !== b.prototype) return false;
    const keys = Object.keys(a);
    if (keys.length !== Object.keys(b).length) return false;
    return keys.every(k => obj_equals(a[k], b[k]));
  };
