import { Tab, Tabs } from "react-bootstrap";
import SalesLinks from "./Sales.links";
import SalesItems from "./Sales.items";
import SalesInvoices from "./Sales.invoices";
import { useState } from "react";
import SalesCoupnCodes from "./Sales.coupncodes";
import CancelledSalesInvoices from "./CancelledSales.invoices";

export default function SalesIndex() {
  const [activeKey, setActiveKey] = useState("items");
  return (
    <>
      <Tabs
        defaultActiveKey="links"
        id="uncontrolled-tab-example"
        className="mt-2 px-2 fw-bold ml-3"
        onSelect={(eventKey) => setActiveKey(eventKey as string)}
      >
        <Tab
          eventKey="links"
          title={
            <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
              Links
            </div>
          }
        >
          <SalesLinks activeKey={activeKey} />
        </Tab>
        <Tab
          eventKey="items"
          title={
            <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
              Items
            </div>
          }
        >
          <SalesItems activeKey={activeKey} />
        </Tab>
        <Tab
          eventKey="invoices"
          title={
            <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
              Invoices
            </div>
          }
        >
          <SalesInvoices activeKey={activeKey} />
        </Tab>
        <Tab
          eventKey="cancelled-invoices"
          title={
            <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
              Cancelled Invoices
            </div>
          }
        >
          <CancelledSalesInvoices activeKey={activeKey} />
        </Tab>
        <Tab
          eventKey="couponcodes"
          title={
            <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
              Coupon Codes
            </div>
          }
        >
          <SalesCoupnCodes activeKey={activeKey} />
        </Tab>
      </Tabs>
    </>
  );
}
