import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import toast from "react-hot-toast";
import CardBasic from "../../../components/Card/card.basic";
import AnnouncementService from "../../../services/announcements.service";
import CreateAnnouncementModal from "../../../components/Modals/CreateAnnouncementModal";
import AllAnnouncementTable from "../../../components/Table/AnnouncementTable";

export default function Announcement() {
  const [showCreateLink, setShowCreateLink] = useState(false);
  const [tableData, setTableData] = useState<any>();
  const getAllAnnouncementData = async () => {
    await AnnouncementService.get().then((res) => {
        if (res.status === 200) {
          console.log(res.data.data,'data')
          setTableData(res?.data?.data);
        }
      })
      .catch((err: any) => {
        toast.error("Unable to fetch Announcement Details");
      });
  };



  useEffect(() => {
    getAllAnnouncementData();
  }, [showCreateLink]);

 

  return (
    <>
      <Container>
        <Row className="mt-4">
          <Col>
            <CardBasic
              body={
                <>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <Card.Title>Announcements</Card.Title>
                    <div>
                      <ButtonGroup size="sm">
                        <Button onClick={() => setShowCreateLink(true)}>
                          Create Announcement
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                  <AllAnnouncementTable
                    tableData={tableData}
                    reload={()=>getAllAnnouncementData()}
                  />
                </>
              }
            />
          </Col>
        </Row>
      </Container>
      <CreateAnnouncementModal
        show={showCreateLink}
        setShowModal={setShowCreateLink}
        reloadData={()=>getAllAnnouncementData()}
      />
    </>
  );
}
