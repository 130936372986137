import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { AdminService } from "../../services/admin.service";
import SideModal from "../SideModal/SideModal";

interface IEditCouncellor {
 enrollmentId: string;
 show:boolean;
 setShow:any;
 reload:any
}

export default function EditCouncellorModel(props: IEditCouncellor) {
  const [councellor, setCouncellor] = useState<string>();

  const onUpdateCouncellorClick = () => {
    if ( !councellor) {
      toast.error("This Fields is mandatory");
      return false;
    }
    AdminService.updateCouncellor(props.enrollmentId,councellor).then(
      (res) => {
        if (res.status === 200) {
          toast.success("Councellor Updated");
          console.log(res?.data,"updatedData");
          props.setShow(false);
          props.reload();
          window.location.reload();
        }
      }
    );
  };
  
  return (
    <SideModal
      show={props.show}
      handleClose={() => props.setShow(false)}
      title="Edit"
      body={
        <>
          <Form.Group>
            <Form.Label>Councellor</Form.Label>
            <Form.Control
              type="text"
              value={councellor}
              name="councellor"
              onChange={(e) => setCouncellor(e.target.value)}
            />
          </Form.Group>
          <Button
            variant="primary"
            size="sm"
            className="mt-3"
            onClick={onUpdateCouncellorClick}
          >
            Update
          </Button>
        </>
      }
    />
  );
}
