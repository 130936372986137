import Index from ".";

const CSVIndex = () => {
    return (
        <>
            <Index />
        </>
    )
}

export default CSVIndex;