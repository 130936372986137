import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FormInput from "../FormInput";
import { PhoneInput } from "../PhoneInput";
import SideModal from "../SideModal/SideModal";

interface IStudentDetail {
  show: boolean;
  hide: any;
  handleChange: any;
  handleSubmit: any;
  hideModal: any;
  data: any;
  setShowModal: any;
  onChange: any;
}
const inputStyle = {
  paddingTop: "8px",
  height: "45px",
};
const StudentDetailModal = ({
  show,
  handleChange,
  handleSubmit,
  data,
  setShowModal,
  onChange,
}: IStudentDetail) => {
  const handleClose = () => setShowModal(false);

  return (
    <>
      <SideModal
        show={show}
        handleClose={handleClose}
        title={"Edit Student Detail"}
        body={
          <Form>
            <FormInput
              classes="mb-3"
              onChange={(e: HTMLElement) => handleChange(e)}
              value={data.firstName}
              identifier="firstName"
              label="First Name"
            />{" "}
            <FormInput
              classes="mb-3"
              onChange={(e: HTMLElement) => handleChange(e)}
              value={data.lastName}
              identifier="lastName"
              label="Last Name"
            />
            <FormInput
              classes="mb-3"
              onChange={(e: HTMLElement) => handleChange(e)}
              value={data.email}
              identifier="email"
              label="Email"
              type="email"
            />
            <FormInput
              classes="mb-3"
              onChange={(e: HTMLElement) => handleChange(e)}
              value={data.countryCode}
              identifier="countryCode"
              label="Country Code"
            />
            <FormInput
              classes="mb-3"
              onChange={(e: HTMLElement) => handleChange(e)}
              value={data.phone}
              identifier="phone"
              label="Phone"
            />{" "}
            {/* <Form.Group as={Row} className="mb-4">
              <Form.Label column>Phone</Form.Label>
              <Col>
                <PhoneInput
                  name="phone"
                  onChange={(e: HTMLElement) => handleChange(e)}
                  disabled={false}
                  value={data.phone}
                />
              </Col>
            </Form.Group> */}
            {/* <Form.Group as={Row} className="mb-3">
              <Form.Label column>Gender</Form.Label>
              <Col>
                <Form.Select
                  value={data.gender}
                  onChange={(e: any) => onChange(e)}
                  style={{ width: "15rem" }}
                  name="gender"
                >
                  {items.map((option: any) => {
                    return <option value={option.value}>{option.label}</option>;
                  })}
                </Form.Select>
              </Col>
            </Form.Group> */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column>Blocked</Form.Label>
              <Col>
                <input
                  type="checkbox"
                  name="isBlocked"
                  checked={data.isBlocked ? true : false}
                  onChange={(e: any) => onChange(e)}
                  style={{ ...inputStyle }}
                />
              </Col>
            </Form.Group>
            {/* <Form.Group as={Row} className="mb-3">
              <Form.Label column>Demo Taken</Form.Label>
              <Col>
                <input
                  type="checkbox"
                  name="hasTakenDemo"
                  checked={data.hasTakenDemo}
                  onChange={(e: any) => handleChange(e)}
                  style={{ ...inputStyle }}
                />
              </Col>
            </Form.Group> */}
          </Form>
        }
        footer={
          <div className="d-flex justify-content-between">
            <Button
              onClick={() => {
                setShowModal(false);
              }}
            >
              Cancel
            </Button>
            <Button onClick={handleSubmit}>Save</Button>
          </div>
        }
      />
    </>
  );
};

export default StudentDetailModal;
