import MainRoutes from "./routes";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { Toaster } from "react-hot-toast";
import "./App.scss";
import Loader from "./components/Loader/Loader.index";
import React, { useState } from "react";
import { IsLoading } from "./App.context";
import "./assets/css/global.css";
// import 'rc-slider/assets/index.css';


function App() {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <>
      <IsLoading.Provider value={{ loading, setLoading }}>
        {loading && <Loader />}
        <MainRoutes />
        <Toaster
          position="bottom-right"
          reverseOrder={false}
        />
      </IsLoading.Provider>
    </>
  );
}

export default App;
