import React, { useEffect, useState } from "react";
import { Badge, Dropdown, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { BiDotsVertical } from "react-icons/bi";
import { useNavigate } from "react-router";
import CustomToggle from "../../../../../components/Menu/CustomMenu";
import {
  FontBold,
  NormalText,
} from "../../../../../components/Text/TableText.formats";
import { AdminService } from "../../../../../services/admin.service";

const EnrollmentTable = (props: any) => {
  const [tableData, setTableData] = useState([]);
  const id = props.id;
  const navigate: any = useNavigate();

  const fetchInstructorEnrollment = async () => {
    await AdminService.getInstructorEnrollment({ id })
      .then((res) => {
        if (res.status === 200) {
          setTableData(res?.data);
        }
      })
      .catch((err) => {
        toast.error("Unable to fetch Instructor Enrollment");
      });
  };

  useEffect(() => {
    fetchInstructorEnrollment();
  }, [id]);

  return (
    <div>
      <Table striped borderless hover responsive size="sm">
        <thead>
          <tr className="dhun-table-header text-secondary">
            <th>#</th>
            <th>Id</th>
            <th>Student Name</th>
            <th>Instrument</th>
            <th colSpan={6} className="text-center">
              Sessions
            </th>
            <th>PPC</th>
            <th></th>
          </tr>
          <tr>
            <td colSpan={4}></td>
            <td className="fs-10">Tot.</td>
            <td className="fs-10">Req.</td>
            <td className="fs-10">Sch.</td>
            <td className="fs-10">Left</td>
            <td className="fs-10">Late</td>
            <td className="fs-10">Can.</td>
          </tr>
        </thead>
        <tbody>
          {tableData && tableData?.length > 0
            ? tableData?.map((data: any, index: any) => {
                return (
                  <tr key={data?._id}>
                    <td>
                      <NormalText value={index + 1} />
                    </td>
                    <td>
                      <FontBold value={data.enrollmentId} />
                      <span className="fs-10 ms-2">
                        {data.type === "demo" && (
                          <Badge bg="secondary">Demo</Badge>
                        )}
                      </span>
                    </td>

                    <td
                      onClick={() => {
                        navigate(
                          "/admin/search/student/detail/" + data.student._id
                        );
                      }}
                    >
                      <u className="fst-italic text-primary fs-12 fs-underline cursor-pointer">
                        {data?.student?._id
                          ? data?.student?.firstName +
                            " " +
                            data?.student?.lastName
                          : "N.A."}
                      </u>
                    </td>
                    <td>
                      <span className="fw-bold fs-12">
                        {data.course && data.course.instrument && data.course.instrument.toUpperCase()}
                      </span>
                    </td>

                    <td>
                      <span className="font-monospace fs-12">
                        {data.num_classes || "---"}
                      </span>
                    </td>
                    <td>
                      <span className="font-monospace fs-12">
                        {data.classes_requested || "---"}
                      </span>
                    </td>
                    <td>
                      <span className="font-monospace fs-12">
                        {data.classes_scheduled || "---"}
                      </span>
                    </td>
                    <td>
                      <span className="font-monospace fs-12">
                        {data.classes_left || "---"}
                      </span>
                    </td>
                    <td>
                      <span className="font-monospace fs-12">
                        {data.classes_late_charged || "---"}
                      </span>
                    </td>
                    <td>
                      <span className="font-monospace fs-12">
                        {data.classes_canceled || "---"}
                      </span>
                    </td>

                    <td>
                      <NormalText
                        value={
                          data.price_per_class
                            ? "USD " + data.price_per_class
                            : 0
                        }
                      />
                    </td>

                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          as={CustomToggle}
                          id="dropdown-custom-components"
                        >
                          <BiDotsVertical />
                        </Dropdown.Toggle>

                        <Dropdown.Menu></Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                );
              })
            : "No Data Available"}
        </tbody>
      </Table>
    </div>
  );
};

export default EnrollmentTable;
