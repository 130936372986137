import React, { useState } from 'react'
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import CardBasic from '../../../components/Card/card.basic';
import Select from "react-select";
import './SerachInst.css'
import SearchInstTable from '../../../components/Table/SearchInstTable';
import { AdminService } from '../../../services/admin.service';
import { toast } from 'react-hot-toast';
import { instrument } from '../../../constants/instruments';
import { languages } from '../../../constants/languages';
import { gender } from '../../../constants/gender';

const SearchInst = () => {

  const inst_search_values = [
    { label: "New teacher", value: "new" },
    {
      label: "Experienced teacher",
      value: "exp",
    },
  ]

  const [inst_type, setInst_type] = useState<any>(inst_search_values[0]);
  const [sel_gender, setSel_gen] = useState<any>({});
  const [sel_lang, setSel_lang] = useState<any>({});
  const [sel_instrumentType, selSet_instrumentType] = useState<any>(instrument[0]);
  const [start_budget, setStart_budget] = useState<any>(300)
  const [end_budget, setEnd_budget] = useState<any>(2000)

  const [instructors, setInstructors] = useState<any>([]);

  const onFetchData = async () => {
    await AdminService.getInstructors(
      inst_type.value,
      sel_instrumentType.value,
      sel_lang.value,
      sel_gender.value,
      start_budget,
      end_budget
    ).then(res => {
      if (res.status === 200) {
        if (res.data.length <= 0) {
          toast.error("No Instructors Available!")
        }
        setInstructors(res.data);
      }
    }).catch(e => {
      toast.error("No Instructors Available!")
    });
  }

  return (
    <>
      <Container>
        <Row className="mt-4">
          <Col>
            <CardBasic
              className="mt-3"
              body={
                <>
                  <h6>Filters</h6>

                  <Row>
                    <Col md={3}>
                      <Form.Label className="fw-bold text-secondary fs-12">
                        Select Instructor (Mandatory)
                      </Form.Label>
                      <Select
                        options={inst_search_values}
                        value={inst_type}
                        onChange={setInst_type}
                      />
                    </Col>

                    <Col md={3}>
                      <Form.Label className="fw-bold text-secondary fs-12">
                        Subject (Mandatory)
                      </Form.Label>
                      <Select
                        onChange={selSet_instrumentType}
                        options={instrument}
                        value={sel_instrumentType}
                      />
                    </Col>
                    <Col md={3}>
                      <Form.Label className="fw-bold text-secondary fs-12">
                        Gender of Teacher
                      </Form.Label>
                      <Select
                        options={gender}
                        className="genderSelect"
                        value={sel_gender}
                        onChange={setSel_gen}
                        isClearable={true}
                      />
                    </Col>
                    <Col md={3}>
                      <Form.Label className="fw-bold text-secondary fs-12">
                        Language preference
                      </Form.Label>
                      <Select
                        options={languages}
                        value={sel_lang}
                        onChange={setSel_lang}
                        isClearable={true}
                      // onChange={setStatus}
                      />
                    </Col>

                  </Row>

                  <Row className="mt-4">



                    <Col md={4}>
                      <Form.Label className="fw-bold text-secondary fs-12">
                        Budget
                      </Form.Label>
                      <Form.Group className='d-flex justify-content-between'>
                        <div>
                          <Form.Label>
                            Min
                          </Form.Label>
                          <Form.Control type="number" min={300} value={start_budget} onChange={(e)=>setStart_budget(e.target.value)}/>
                        </div>
                        <div className='ms-2'>
                          <Form.Label>
                            Max
                          </Form.Label>
                          <Form.Control type="number" value={end_budget} onChange={(e)=>setEnd_budget(e.target.value)} />
                        </div>
                      </Form.Group>



                      {/* <MultiRangeSlider
                        min={300}
                        max={2000}
                        
                        onChange={({ min, max }: any) => {
                          setStart_budget(min)
                          setEnd_budget(max)
                        }
                        }
                      /> */}
                    </Col>
                    <Row className="mt-4">
                      <Col>
                        <Button onClick={onFetchData}>Get Instructors</Button>
                      </Col>
                    </Row>
                  </Row>
                </>
              }
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <CardBasic
              body={
                <>
                  <h6>Instructor List</h6>
                  <SearchInstTable
                    tableData={instructors}
                  />
                </>
              }
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SearchInst
