import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import CardBasic from "../../../../../components/Card/card.basic";
import { AdminService } from "../../../../../services/admin.service";
import TableHeading from "../../TableHeading";
import EnrollmentTableHeading from "../EnrollmentTableHeading";
import EnrollmentDetailTable from "./EnrollmentDetailTable";

const EnrollmentDetailData = (props: any) => {
  const [enrollmentTitleIndex, setEnrollmentTitleIndex] = useState<any>(0);

  const titles = [
    "Completed Enrollment",
    "Scheduled Enrollment",
    "Cancelled Enrollment",
  ];

  const enrollmentTitle = titles[enrollmentTitleIndex];

  const id = props?.enrollmentId;
  const [tableData, setTableData] = useState<any>();

  const getEnrollmentDetailData = async () => {
    if (enrollmentTitle === "Completed Enrollment") {
      await AdminService.getSessionByEnrollment({ id }, "completed")
        .then((res: any) => {
          if (res.status === 200) {
            setTableData(res?.data);
          }
        })
        .catch((err: any) => {
          toast.error("Unable to fetch Student Session");
        });
    }
    if (enrollmentTitle === "Scheduled Enrollment") {
      await AdminService.getSessionByEnrollment({ id }, "scheduled")
        .then((res: any) => {
          if (res.status === 200) {
            setTableData(res?.data);
          }
        })
        .catch((err: any) => {
          toast.error("Unable to fetch Student Session");
        });
    }

    if (enrollmentTitle === "Cancelled Enrollment") {
      await AdminService.getSessionByEnrollment({ id }, "cancelled")
        .then((res: any) => {
          if (res.status === 200) {
            setTableData(res?.data);
          }
        })
        .catch((err: any) => {
          toast.error("Unable to fetch Student Session");
        });
    }
  };

  useEffect(() => {
    getEnrollmentDetailData();
  }, [id, enrollmentTitle]);

  const [totalEnrollment, setTotalEnrollment] = useState<any>(tableData);

  return (
    <>
      <CardBasic
        body={
          <>
            <EnrollmentTableHeading
              isDynamic="true"
              heading={enrollmentTitle}
              setEnrollmentHeading={(title: any) => {}}
              titlesLength={titles.length - 1}
              setEnrollmentTitleIndex={(index: any) =>
                setEnrollmentTitleIndex(index)
              }
            />

            <div
              className="m-4"
              style={{ maxHeight: "500px", overflow: "scroll" }}
            >
              <EnrollmentDetailTable
                totalEnrollment={totalEnrollment ? totalEnrollment : tableData}
                reloadData={() => getEnrollmentDetailData()}
                deleteAllowed={
                  enrollmentTitle === "Scheduled Enrollment" ? true : false
                }
                zoomLinkShow={true}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default EnrollmentDetailData;
