import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import CardBasic from "../../../components/Card/card.basic";
import Select from "react-select";
import { AdminService } from "../../../services/admin.service";
import InstRecordingTable from "../../../components/Table/InstRecordingTable";
import { addDays, subDays } from "date-fns";
import moment from "moment";
import { DateRange } from "react-date-range";
import toast from "react-hot-toast";
import AsyncSelect from "react-select/async";
import ReactDatePicker from "react-datepicker";

const InstRecording = () => {
  const [selectValueInst, setSelectValueInst] = useState<any>();
  const [selectValueStudent, setSelectValueStudent] = useState<any>();
  const [instructorId, setInstructorId] = useState<any>();
  const [studentId, setStudentId] = useState<any>();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [data, setData] = useState();
  const [type, setType] = useState<any>({});

  const handelInstructorSelectChange = (obj: any) => {
    setSelectValueInst(obj);
  };

  const handelStudentSelectChange = (obj: any) => {
    setSelectValueStudent(obj);
  };

  const fetchAllInstructor = (inputValue: any, callback: ([]) => void) => {
    if (!inputValue) {
      callback([]);
    } else if (inputValue.length > 2) {
      setTimeout(() => {
        AdminService.instRecordingUserSearch(inputValue)
          .then((res) => {
            if (res.status === 200) {
              const instructor = res?.data
                ?.filter((data: any) => data.role === "instructor")
                .map((data: any) => {
                  return {
                    value: data._id,
                    label: `${data.firstName} ${data.lastName}`,
                  };
                });
              callback(instructor);
            }
          })
          .catch((err) => toast.error("Unable to get instructor detail"));
      }, 1000);
    }
  };

  const fetchAllStudent = (inputValue: any, callback: ([]) => void) => {
    if (!inputValue) {
      callback([]);
    } else if (inputValue.length > 2) {
      setTimeout(() => {
        AdminService.instRecordingUserSearch(inputValue)
          .then((res) => {
            if (res.status === 200) {
              const student = res?.data
                ?.filter((data: any) => data.role === "student")
                .map((data: any) => {
                  return {
                    value: data._id,
                    label: `${data.firstName} ${data.lastName}`,
                  };
                });
              callback(student);
            }
          })
          .catch((err) => toast.error("Unable to get student detail"));
      }, 1000);
    }
  };

  const instruId = selectValueInst?.value;
  const stuId = selectValueStudent?.value;

  useEffect(() => {
    setInstructorId(instruId);
  }, [instruId]);

  useEffect(() => {
    setStudentId(stuId);
  }, [stuId]);

  const onClickGet = async () => {
    const fromDate = moment(startDate).format("YYYY-MM-DD");
    const toDate = moment(endDate).format("YYYY-MM-DD");

    await AdminService.getAllSessionsForStudentOrInstructor(
      studentId,
      instructorId,
      fromDate,
      toDate,
      type.value
    )
      .then((res) => {
        setData(res?.data);
      })
      .catch((err) => {
        toast.error("Unable to get session for student and instructor");
      });
  };

  const getRecordingByBookingId = async() => {
    await AdminService.getRecordingByBookingId(bookingId).then((res) => {
      setData(res?.data.data);
    })
    .catch((err) => {
      toast.error("Unable to get session for Booking Id");
    });
  }

  const [bookingId, setBookingId] = useState<any>("");

  return (
    <>
      <Container>
        <Row className="mt-4">
          <Col>
            <CardBasic
              className={{ height: "100%" }}
              body={
                <>
                  <div>
                    <Form.Group>
                      <Form.Label className="fw-bold text-secondary fs-12  mt-4">
                        1. Select Instructor
                      </Form.Label>
                      <AsyncSelect
                        loadOptions={fetchAllInstructor}
                        onChange={(e: any) => {
                          handelInstructorSelectChange(e);
                        }}
                        isClearable={true}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label className="fw-bold text-secondary fs-12  mt-4">
                        2. Select Student
                      </Form.Label>
                      <AsyncSelect
                        loadOptions={fetchAllStudent}
                        onChange={(e: any) => {
                          handelStudentSelectChange(e);
                        }}
                        isClearable={true}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label className="fw-bold text-secondary fs-12 mt-4">
                        3. Select Date Range
                      </Form.Label>
                      <div className="d-flex justify-content-center">
                        <ReactDatePicker
                          onChange={(e:any) => { 
                            setStartDate(e[0])
                            setEndDate(e[1]) 
                          }}
                          startDate={startDate}
                          endDate={endDate}
                          selectsRange
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                        />
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label className="fw-bold text-secondary fs-12 mt-4">
                        4. Enrollment Type
                      </Form.Label>
                       <Select
                        options={[
                          {label: "Paid", value: 'paid'},
                          {label: "Demo", value: "demo"}
                        ]}
                        onChange={(e:any) => setType(e)}
                        value={type}
                       />
                    </Form.Group>
                  </div>
                  <div className="d-flex justify-content-end mt-2">
                    <Button onClick={onClickGet} variant="primary">
                      Get
                    </Button>
                  </div>
                </>
              }
            />
          </Col>
          <Col>
            <CardBasic
              body={
                <>
                  <div>
                    <Form.Group>
                      <Form.Label className="fw-bold text-secondary fs-12  mt-4">
                        Select Booking Id
                      </Form.Label>
                      <Form.Control
                        name="bookingId"
                        onChange={e =>
                          setBookingId(e.target.value)
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="d-flex justify-content-end mt-2">
                    <Button onClick={getRecordingByBookingId} variant="primary">
                      Get
                    </Button>
                  </div>
                </>
              }
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <CardBasic body={<InstRecordingTable tableData={data} />} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default InstRecording;
