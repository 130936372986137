import { IAction } from "../interface";
import { IAuthState } from "./interace";

export const loginInitial = (state: IAuthState, action: IAction) => {
  return { ...state, loading: true, loggedIn: false, token: null };
};

export const loginSuccess = (state: IAuthState, action: IAction) => {
  return {
    ...state,
    loading: false,
    loggedIn: !!action?.payload?.token,
    token: action?.payload?.token,
  };
};

export const loginFailure = (state: IAuthState, action: IAction) => {
  return { ...state, loading: false, loggedIn: false, token: null };
};

export const onSetToken = (state: IAuthState, action: IAction) => {
  return {
    ...state,
    loggedIn: !!action?.payload?.token,
    token: action?.payload?.token,
  };
};

export const inclustiveGSTCalculation = (amount: number): number => {
  return (amount / 118)*100
}


export const decimalValue = (value: number) => {
  return Number(value.toFixed(2)) 
}