export enum RenewalStatusEnum {
    DNP = "dnp",
    NOT_INTERESTED = "not_interested",
    WILL_RENEW = "will_renew",
    FOLLOW_UP = "follow_up",
    LINK_SHARED = "link_shared",
    RENEWAL_DONE = "renewal_done",
    DID_NOT_RENEW = "did_not_renew",
    OPEN = "open"
}

export const renewalSelectMenu = [
    {
        label: "DNP",
        value: RenewalStatusEnum.DNP
    },
    {
        label: "Not right now",
        value: RenewalStatusEnum.NOT_INTERESTED
    },
    {
        label: "Renewal follow up",
        value: RenewalStatusEnum.WILL_RENEW
    },
    {
        label: "Follow Up",
        value: RenewalStatusEnum.FOLLOW_UP
    },
    {
        label: "Link Shared",
        value: RenewalStatusEnum.LINK_SHARED
    },
    {
        label: "Renewal Done",
        value: RenewalStatusEnum.RENEWAL_DONE
    },
    {
        label: "Did Not Renew",
        value: RenewalStatusEnum.DID_NOT_RENEW

    }, {
        label: "Open",
        value: RenewalStatusEnum.OPEN
    }
]

