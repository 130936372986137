import AsyncSelect from "react-select/async";
import Select from "react-select";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import "./Renewals.css";
import CardBasic from "../../../components/Card/card.basic";
import { AdminService } from "../../../services/admin.service";
import PaidTable from "../Components/Table/PaidTable";
import RenewalsTable from "../../../components/Table/RenewalsTable";

const Renewals = () => {

  const [reloadRenewals, setReloadRenewals] = useState<boolean>(false);


  return (
    <>
      <Tabs
        defaultActiveKey="paid"
        id="uncontrolled-tab-example"
        className="mb-3 p-2 fw-bold"
      >
        <Tab eventKey="paid" title="Paid Students" >
          <PaidTable reloadRenewals={() => setReloadRenewals(!reloadRenewals)} />
        </Tab>
        <Tab eventKey="renewals" title="Renewals" >
          <RenewalsTable reload={reloadRenewals} />
        </Tab>
      </Tabs>
    </>
  );
};

export default Renewals;
