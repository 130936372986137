import makeRequest, { makeParams } from "../api/makeRequest";
import urls from "../api/urls";
import { MethodsEnum } from "../enum/methods.enum";

export class BookingService {
  static async getAllPaidEnrollments() {
    return await makeRequest(
      `${urls.booking.getAllPaidEnrollments}`,
      MethodsEnum.GET
    );
  }

  static async getAllPaidMusicEnrollments() {
    return await makeRequest(
      `${urls.booking.getAllPaidMusicEnrollments}`,
      MethodsEnum.GET
    );
  }

  static async bulkBookSessions(enrollmentId: string, date: string[]) {
    return await makeRequest(urls.booking.bulkbookSessions, MethodsEnum.POST, {
      enrollmentId,
      date,
    });
  }

  static async getAllEnrollments(type: any, page?: any, count?: any) {
    const params = makeParams([
      {
        index: "type",
        value: type,
      },
      {
        index: "page",
        value: page,
      },
      {
        index: "count",
        value: count,
      },
    ]);
    return await makeRequest(
      `${urls.booking.getAllEnrollments + params}`,
      MethodsEnum.GET
    );
  }

  
}
