
interface IText {
    value: any
}

export function MonospaceText(props: IText) {
    return <span className="font-monospace fs-12">{props.value}</span>
}

export function FontBold(props: IText) {
    return <span className="fw-bold fs-12">{props.value}</span>
}

export function NormalText(props: IText) {
    return <span className="fs-12">{props.value}</span>
}

export function LightText(props: IText) {
    return <span className=" fw-light fs-12">{props.value}</span>
}

export function ItalicText(props: IText) {
    return <span className=" fst-italic fs-12">{props.value}</span>
}