import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useQueryParams from "../../../Hooks/useQueryParams";
import SearchResult from "./SearchResult";
import { AdminService } from "../../../services/admin.service";
import { Form, Image } from "react-bootstrap";
import logo from "../../../assets/logo_dark.png";
import "./search.css";

const SearchPage = (props: any) => {
  const [searchContent, setSearchContent] = useState<any>(undefined);
  const { search }: any = useQueryParams("search");
  const navigate: any = useNavigate();

  const setSearchParams = (searchParams: string) => {
    navigate(`/admin/search?search=${searchParams}`);
  };

  const getSearchData = async () => {
    if (search?.length > 2) {
      await AdminService.search(search).then((res) => {
        if (res.status === 200) {
          setSearchContent(res.data);
        }
      });
    } else {
      setSearchContent(undefined);
    }
  };

  useEffect(() => {
    getSearchData();
  }, [search]);

  return (
    <>
      <div className="d-flex justify-content-start align-items-center m-4">
        <Image src={logo} height="25px" />
        <Form.Control
          type="text"
          value={props.search}
          onChange={(e) => {
            setSearchParams(e.target.value);
          }}
          className="ms-4 dhun-search-bar-top"
          placeholder="Type Here to Search..."
        />
      </div>
      <hr className="text-muted" />
      {searchContent ? (
        <SearchResult
          search={search}
          searchContent={searchContent}
          searchKeyword={search}
        />
      ) : (
        <>
          <div className="p-4 fst-italic text-secondary">
            Type text to search
          </div>
        </>
      )}
    </>
  );
};

export default SearchPage;
