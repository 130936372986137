import Select from "react-select";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Form,
    Card,
    ButtonGroup,
    Button,
    Pagination,
} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import toast from "react-hot-toast";
import CardBasic from "../../../components/Card/card.basic";
import SalesService from "../../../services/sales.service";
import CreateSaleInvoiceModal from "../../../components/Modals/CreateSaleInvoice.model";
import SalesInvoicesTable from "../../../components/Table/SalesInvoices.table";
import { CSVLink } from "react-csv";

interface IProps {
    activeKey: string;
}
export default function SalesInvoices({ activeKey }: IProps) {
    const [showCreateLink, setShowCreateLink] = useState(false);
    const [tableData, setTableData] = useState<any>();
    const [pageCount, setPageCount] = useState(1);
    const [invoiceNumber, setInvoiceNumber] = useState({
        fromInvoiceNumber: '',
        toInvoiceNumber: ''
    })
    const [status, setStatus] = useState<any>({
        value: "pending",
        label: "Pending",
    });

    const [dateRange, setDateRange] = useState({
        startDate: moment(new Date()).subtract("10", "days").toDate(),
        endDate: moment(new Date()).add("30", "days").toDate(),
    });

    const [csvData, setCsvData] = useState<any>([]);


    const handleInvoiceDataChange = (e: any) => {
        setInvoiceNumber({ ...invoiceNumber, [e.target.name]: e.target.value });
    };

    const getSalesInvoices = async () => {
        const fromDate = moment(dateRange.startDate).format("YYYY-MM-DD");
        const toDate = moment(dateRange.endDate).format("YYYY-MM-DD");
        if (!fromDate || !toDate || fromDate === 'Invalid date' || toDate === 'Invalid date') {
            return false;
        }
        await SalesService.getSalesInvoices(fromDate, toDate, invoiceNumber.fromInvoiceNumber, invoiceNumber.toInvoiceNumber)
            .then((res) => {
                if (res.status === 200) {
                    setTableData(res?.data?.data);
                    setCsvData(res?.data?.data.map((data: any, index: number) => {
                        return {
                            "#": index + 1,
                            "Buyer name": data.buyerName || "--",
                            "Buyer email": data.buyerEmail || "--",
                            "Buyer phone": data.buyerPhone || "--",
                            "Date of Purchase": data.dateOfPurchase && moment(data.dateOfPurchase).format("DD-MM-YY hh:mm a") || "--",
                            "State": data.buyerState || "--",
                            "Amount Before GST": data.totalAmountBeforeGST && (data.totalAmountBeforeGST).toFixed(2) || "--",
                            "Amount After GST": data.totalAmountAfterGST && (data.totalAmountAfterGST).toFixed(2) || "--",
                            "CGST": data?.gst?.cgst && (data?.gst?.cgst).toFixed(2) || "--",
                            "SGST": data?.gst?.sgst && (data?.gst?.sgst).toFixed(2) || "--",
                            "IGST": data?.gst?.igst && (data?.gst?.igst).toFixed(2) || "--",
                        }
                    }))
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error("Unable to fetch Sales Invoices");
            });
    };

    useEffect(() => {
        if (activeKey === 'invoices') {
            getSalesInvoices();
        }
    }, [activeKey, pageCount, status, dateRange]);

    const PaymentStatus = [
        {
            value: "pending",
            label: "Pending",
        },
        {
            value: "completed",
            label: "Completed",
        },
        {
            value: "failed",
            label: "Failed",
        },
    ];

    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <CardBasic
                            className="mt-3"
                            body={
                                <>
                                    <h6>Filters</h6>
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label className="fw-bold text-secondary fs-12">
                                                Date Range
                                            </Form.Label>
                                            <ReactDatePicker
                                                onChange={(e: any) => {
                                                    setDateRange({
                                                        startDate: e[0],
                                                        endDate: e[1],
                                                    });
                                                }}
                                                startDate={dateRange.startDate}
                                                endDate={dateRange.endDate}
                                                selectsRange
                                                dateFormat="dd/MM/yyyy"
                                                className="form-control"
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label className="fw-bold text-secondary fs-12">
                                                    Starting Invoice Number
                                                </Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="fromInvoiceNumber"
                                                    onChange={handleInvoiceDataChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label className="fw-bold text-secondary fs-12">
                                                    Ending Invoice Number
                                                </Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="toInvoiceNumber"
                                                    onChange={handleInvoiceDataChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                        {/* <Col md={3}>
                                            <Form.Label className="fw-bold text-secondary fs-12">
                                                Payment Status
                                            </Form.Label>
                                            <Select
                                                options={PaymentStatus}
                                                value={status}
                                                onChange={setStatus}
                                            />
                                        </Col> */}
                                    </Row>
                                </>
                            }
                        />
                    </Col>
                </Row>
            </Container>
            <Container>
                <Card className="mt-4">
                    <Card.Body>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <Card.Title>Sales Invoices</Card.Title>
                            <div className="d-flex gap-2 ">
                                <ButtonGroup size="sm">
                                    <Button onClick={() => setShowCreateLink(true)}>
                                        Create New Invoice
                                    </Button>
                                </ButtonGroup>
                                <ButtonGroup size="sm">
                                    <CSVLink
                                        data={csvData}
                                        className="btn btn-primary text-decoration-none"
                                        filename={"Sales_invoice.csv"}
                                        target="_blank"
                                    >
                                        Download CSV
                                    </CSVLink>
                                </ButtonGroup>
                            </div>
                        </div>
                        <div className="m-4">
                            <SalesInvoicesTable tableData={tableData} reloadData={getSalesInvoices} />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Pagination>
                                <Pagination.First />
                                <Pagination.Prev
                                    onClick={() => {
                                        setPageCount(pageCount - 1);
                                    }}
                                />
                                <Pagination.Item>{pageCount}</Pagination.Item>
                                <Pagination.Next
                                    onClick={() => {
                                        setPageCount(pageCount + 1);
                                    }}
                                />
                                <Pagination.Last />
                            </Pagination>
                        </div>
                    </Card.Body>
                </Card>
            </Container>
            <CreateSaleInvoiceModal show={showCreateLink} setShow={setShowCreateLink} reloadData={getSalesInvoices} />
        </>
    );
}
