import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Validation from "../../../helpers/Validations";
import { onLogin } from "../../../store/actions/auth";
import { useAppDispatch, useAppSelector } from "../../../store/reduxHooks";
import classes from "./index.module.css";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="#">
        Dhunguru
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const Login = () => {
  const isLoggedIn = useAppSelector((state) => state?.auth?.loggedIn);
  const dispatch = useAppDispatch();
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });

  const { state }: any = useLocation();
  const navigate = useNavigate();
  const redirect = state?.redirectTo;
  console.log(redirect, "redirect");
  useEffect(() => {
    if (isLoggedIn) {
      if (!redirect) {
        navigate("/admin/search", { replace: true });
      } else {
        // state clear
        window.history.replaceState({}, "");

        // navigate to redirect
        navigate(redirect, { replace: true });
      }
    }
  }, [isLoggedIn, navigate, redirect]);

  const loginSubmit = () => {
    const emailValidation = Validation.isEmail(email);
    const isValid = password === "" || !password ? false : true;
    if (!emailValidation.isValid || !isValid) {
      setErrors({
        email: !emailValidation.isValid,
        password: !isValid,
      });
    } else {
      setErrors({
        email: false,
        password: false,
      });
      dispatch(onLogin(loginData));
    }
  };

  const { email, password } = loginData;

  const paperStyle = {
    display: "flex",
    flexDirection: "column",
    padding: "2rem",
    height: "70vh",
    width: 500,
    margin: "20px auto",
    gap: "2rem",
    marginTop: "10rem",
  };
  const avatarStyle = { backgroundColor: "orange" };
  const btnstyle = { margin: "8px 0" };
  const linkStyle = { textDecoration: "none" };

  const onChangeValue = (e: any) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  return (
    <>
      {!isLoggedIn && (
        <Paper elevation={5} sx={paperStyle}>
          <div className={classes.Icon_heading_wrapper}>
            <Avatar style={avatarStyle}>
              <LockIcon />
            </Avatar>
            <Typography variant="h6">Admin Login</Typography>
          </div>
          <TextField
            label="Email"
            placeholder="Enter email"
            name="email"
            value={email}
            error={errors?.email}
            fullWidth
            required
            onChange={onChangeValue}
            helperText={errors.email && "Invalid Email."}
          />
          <TextField
            label="Password"
            placeholder="Enter password"
            type="password"
            fullWidth
            required
            name="password"
            value={password}
            error={errors.password}
            onChange={onChangeValue}
            helperText={errors.password && "Please choose a valid password."}
          />
          <FormControlLabel
            control={<Checkbox name="checkedB" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={btnstyle}
            fullWidth
            onClick={loginSubmit}
          >
            Login
          </Button>
          <Typography>
            <Link href="#" style={linkStyle}>
              Forgot password?
            </Link>
          </Typography>
          <Copyright />
        </Paper>
      )}
    </>
  );
};

export default Login;
