import moment from "moment";
import makeRequest, { RequestMethods, makeParams } from "../api/makeRequest";
import urls from "../api/urls";
import { MethodsEnum } from "../enum/methods.enum";
import { IcreateEnrollment } from "../interfaces/createEnrollment";
import { ICreateSession } from "../interfaces/createSession";
import {
  ICreateManualInvoice,
  IcreateRazorpayLink,
} from "../interfaces/razorpay.interface";
import url from "../api/urls";

export class AdminService {
  static async search(term: string) {
    return await makeRequest(`${urls.search.search}${term}`, MethodsEnum.GET);
  }

  static async getPaidStudentStats(fromDate: string, toDate: string) {
    const params = makeParams([
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
    ]);
    return await makeRequest(urls.paid_students_csv + params, MethodsEnum.GET);
  }

  static async getUserEnums() {
    return await makeRequest(`${urls.getUserEnums}`, MethodsEnum.GET);
  }
  static async getAllBookingIds() {
    return await makeRequest(`${urls.getAllBookingIds}`, MethodsEnum.GET);
  }
  static async getInstructorCourses(payload: any) {
    return await makeRequest(
      `${urls.getInstructorCourses}${payload?.id}`,
      MethodsEnum.GET
    );
  }

  static async getInstructorCoursesbyId(payload: any) {
    return await makeRequest(
      `${urls.getInstructorCoursesbyId}${payload?.id}`,
      MethodsEnum.GET
    );
  }
  static async getStudentEnrollment(payload: any) {
    return await makeRequest(
      `${urls.getStudentEnrollment}${payload?.id}`,
      MethodsEnum.GET
    );
  }
  static async getEnrollmentDetail(payload: any) {
    return await makeRequest(
      `${urls.getEnrollmentDetail}${payload?.id}`,
      MethodsEnum.GET
    );
  }

  static async getInstructorSession(payload: any) {
    return await makeRequest(
      `${urls.getInstructorSession}${payload?.id}`,
      MethodsEnum.GET
    );
  }

  static async createIncentive(payload: any) {
    return await makeRequest(
      `${urls.incentive.createIncentive}`,
      MethodsEnum.POST,
      payload
    );
  }

  static async deleteIncentive(incentiveId: any) {
    return await makeRequest(
      `${urls.incentive.deleteIncentive}/${incentiveId}`,
      MethodsEnum.DELETE
    );
  }

  static async getStudentSession(payload: any, status: any) {
    const params = makeParams([
      {
        index: "status",
        value: status,
      },
    ]);
    return await makeRequest(
      `${urls.getStudentSessionData + payload?.id + params}`,
      MethodsEnum.GET
    );
  }
  static async getSessionByEnrollment(payload: any, status: any) {
    const params = makeParams([
      {
        index: "status",
        value: status,
      },
    ]);
    return await makeRequest(
      `${urls.getSessionByEnrollment + payload?.id + params}`,
      MethodsEnum.GET
    );
  }

  static async getAllCSVTypes() {
    return await makeRequest(`${urls.getAllCSVTypes}`, MethodsEnum.GET);
  }

  static async getAllSessions(
    pageCount: number,
    pageNumber: any,
    fromDate: string,
    toDate: string,
    status: string
  ) {
    const params = makeParams([
      {
        index: "count",
        value: pageCount,
      },
      {
        index: "page",
        value: pageNumber,
      },
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
      {
        index: "status",
        value: status,
      },
    ]);
    return await makeRequest(
      `${urls.getAllSessions + params}`,
      MethodsEnum.GET
    );
  }

  static async getUserRoles() {
    return await makeRequest(`${urls.getUserRoles}`, MethodsEnum.GET);
  }
  static async getDemoSessions(
    pageCount: number,
    pageNumber: any,
    fromDate: string,
    toDate: string,
    sort: number,
    status: string
  ) {
    const params = makeParams([
      {
        index: "count",
        value: pageCount,
      },
      {
        index: "page",
        value: pageNumber,
      },
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
      {
        index: "sort",
        value: sort,
      },
      {
        index: "status",
        value: status,
      },
    ]);
    return await makeRequest(
      `${urls.getDemoSessions + params}`,
      MethodsEnum.GET
    );
  }

  static async getInstructorStatus(payload: any) {
    return await makeRequest(
      `${urls.getInstructorStatus}${payload?.id}`,
      MethodsEnum.GET
    );
  }

  static async getAllStudents(search?: any) {
    const params = makeParams([
      {
        index: "search",
        value: search,
      },
    ]);
    return await makeRequest(
      `${urls.getAllStudents + params}`,
      MethodsEnum.GET
    );
  }

  static async getActiveStudentCsv(fromDate: string, toDate: string) {
    const params = makeParams([
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
    ]);
    return await makeRequest(
      `${urls.getActiveStudentCsv + params}`,
      MethodsEnum.GET
    );
  }

  static async getExtraClassesCSV(fromDate: string, toDate: string) {
    const params = makeParams([
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
    ]);
    return await makeRequest(
      `${urls.getExtraClassesCSV + params}`,
      MethodsEnum.GET
    );
  }

  static async getPaymentEnrollments(fromDate: string, toDate: string) {
    const params = makeParams([
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
    ]);
    return await makeRequest(
      `${urls.getPaymentzEnrollments + params}`,
      MethodsEnum.GET
    );
  }

  static async getAdminLogs(fromDate: string, toDate: string) {
    const params = makeParams([
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
    ]);
    return await makeRequest(
      `${urls.getAdminLogsCsv + params}`,
      MethodsEnum.GET
    );
  }

  static async getDhunguruDiscountCSV(fromDate: string, toDate: string) {
    const params = makeParams([
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
    ]);
    return await makeRequest(
      `${urls.getDhunguruDiscountCSV + params}`,
      MethodsEnum.GET
    );
  }

  static async getBulkDataCsv(fromDate: string, toDate: string) {
    const params = makeParams([
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
    ]);
    return await makeRequest(`${urls.getBulkData + params}`, MethodsEnum.GET);
  }
  static async getDemoSessionsCsv(fromDate: string, toDate: string) {
    const params = makeParams([
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
    ]);
    return await makeRequest(
      `${urls.getDemoSessionsCsv + params}`,
      MethodsEnum.GET
    );
  }

  static async getInvoicesCsv(fromDate: string, toDate: string) {
    const params = makeParams([
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
    ]);
    return await makeRequest(
      `${urls.getInvoicesCsv + params}`,
      MethodsEnum.GET
    );
  }

  static async getInstructorConversionCSV(fromDate: string, toDate: string) {
    const params = makeParams([
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
    ]);
    return await makeRequest(
      `${urls.getInstructorConversionCSV + params}`,
      MethodsEnum.GET
    );
  }

  static async getdownloadRecordingForDateRangeCsv(
    fromDate: string,
    toDate: string
  ) {
    const params = makeParams([
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
    ]);
    return await makeRequest(
      `${urls.downloadRecordingForDateRangeCsv + params}`,
      MethodsEnum.GET
    );
  }

  static async getStudentCsv(fromDate: string, toDate: string) {
    const params = makeParams([
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
    ]);
    return await makeRequest(`${urls.getStudentCsv + params}`, MethodsEnum.GET);
  }
  static async getEnrollmentCsv(fromDate: string, toDate: string) {
    const params = makeParams([
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
    ]);
    return await makeRequest(
      `${urls.getEnrollmentCsv + params}`,
      MethodsEnum.GET
    );
  }
  static async getInstructorCsv(fromDate: string, toDate: string) {
    const params = makeParams([
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
    ]);
    return await makeRequest(
      `${urls.getInstructorCsv + params}`,
      MethodsEnum.GET
    );
  }

  static async getAllInstructor(search: any) {
    const params = makeParams([
      {
        index: "search",
        value: search,
      },
    ]);
    return await makeRequest(
      `${urls.getAllInstructor + params}`,
      MethodsEnum.GET
    );
  }
  static async getUnapprovedInstructors() {
    return await makeRequest(
      `${urls.getUnapprovedInstructors}`,
      MethodsEnum.GET
    );
  }
  static async csvOfBookedSession(payload: any) {
    return await makeRequest(
      `${urls.csvOfBookedSession}`,
      MethodsEnum.POST,
      payload
    );
  }
  static async createDemoSession(payload: any) {
    return await makeRequest(
      `${urls.createDemoSession}`,
      MethodsEnum.POST,
      payload
    );
  }

  static async postFlaggedResponse(payload: any) {
    return await makeRequest(
      `${urls.postFlaggedResponse}`,
      MethodsEnum.POST,
      payload
    );
  }

  static async updateInstructorStatus(payload: any) {
    return await makeRequest(
      `${urls.updateInstructorStatus}${payload?.id}`,
      MethodsEnum.PUT,
      payload.data
    );
  }

  static async updateStudentDetail(payload: any) {
    return await makeRequest(
      `${urls.updateStudentDetail}${payload?.id}`,
      MethodsEnum.PUT,
      payload.data
    );
  }

  static async getAllPaymentDetails(
    fromDate: string,
    toDate: string,
    sortBy: string,
    pageCount?: any,
    pageNumber?: any,
    status?: string,
    gateway?: string,
    studentId?: any
  ) {
    const params = makeParams([
      {
        index: "count",
        value: pageCount,
      },
      {
        index: "page",
        value: pageNumber,
      },
      {
        index: "toDate",
        value: toDate,
      },
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "status",
        value: status,
      },
      {
        index: "gateway",
        value: gateway,
      },
      {
        index: "studentId",
        value: studentId,
      },
      {
        index: "sortBy",
        value: sortBy,
      },
    ]);
    return await makeRequest(
      `${urls.getAllPaymentDetails + params}`,
      MethodsEnum.GET
    );
  }

  static async instRecordingUserSearch(searchText?: any, pageNumber?: any) {
    const params = makeParams([
      {
        index: "searchText",
        value: searchText,
      },
    ]);
    return await makeRequest(
      `${urls.instRecordingUserSearch + params}`,
      MethodsEnum.GET
    );
  }

  static async getAllSessionsForStudentOrInstructor(
    studentId: string,
    instructorId: string,
    fromDate?: string,
    toDate?: string,
    type?: string
  ) {
    const params = makeParams([
      {
        index: "studentId",
        value: studentId,
      },
      {
        index: "instructorId",
        value: instructorId,
      },
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
      {
        index: "type",
        value: type,
      },
    ]);
    return await makeRequest(
      `${urls.getAllSessionsForStudentOrInstructor + params}`,
      MethodsEnum.GET
    );
  }

  static async getInstructors(
    teacher: "exp" | "new",
    subject: string,
    language?: string,
    gender?: "Male" | "Female",
    start_budget?: number,
    end_budget?: number
  ) {
    const params = makeParams([
      {
        index: "teacher",
        value: teacher,
      },
      {
        index: "subject",
        value: subject,
      },
      {
        index: "language",
        value: language,
      },
      {
        index: "gender",
        value: gender,
      },
      {
        index: "start_budget",
        value: start_budget,
      },
      {
        index: "end_budget",
        value: end_budget,
      },
    ]);
    return await makeRequest(`${urls.getInstructor + params}`, MethodsEnum.GET);
  }

  static async createRazorpayLink(payload: IcreateRazorpayLink) {
    return await makeRequest(
      `${urls.createPaymentLink}`,
      MethodsEnum.POST,
      payload
    );
  }

  static async createManualInvoice(payload: ICreateManualInvoice) {
    return await makeRequest(
      `${urls.invoice.createManualInvoice}`,
      MethodsEnum.POST,
      payload
    );
  }
  static async createMusicManualInvoice(payload: ICreateManualInvoice) {
    return await makeRequest(
      `${urls.invoice.createMusicManualInvoice}`,
      MethodsEnum.POST,
      payload
    );
  }

  static async createPartialInvoice(payload: any) {
    return await makeRequest(
      urls.invoice.createPartialInvoice,
      MethodsEnum.POST,
      payload
    );
  }
  static async createManualInternationalInvoice(payload: ICreateManualInvoice) {
    return await makeRequest(
      `${urls.invoice.createManualInternationalInvoice}`,
      MethodsEnum.POST,
      payload
    );
  }
  static async createMusicManualInternationalInvoice(
    payload: ICreateManualInvoice
  ) {
    return await makeRequest(
      `${urls.invoice.createMusicManualInternationalInvoice}`,
      MethodsEnum.POST,
      payload
    );
  }

  static async createManualEnrollment(payload: IcreateEnrollment) {
    return await makeRequest(
      `${urls.createManualEnrollment}`,
      MethodsEnum.POST,
      payload
    );
  }
  static async createSession(payload: ICreateSession) {
    return await makeRequest(
      `${urls.createSession}`,
      MethodsEnum.POST,
      payload
    );
  }
  static async getInstructorEnrollment(payload: any) {
    return await makeRequest(
      `${urls.getInstructorEnrollment}${payload.id}`,
      MethodsEnum.GET,
      payload
    );
  }
  static async deleteSession(sessionId: string) {
    return await makeRequest(
      urls.sessions.deleteSession + "/" + sessionId,
      "delete"
    );
  }

  static async getAllCrons(userId: any) {
    return await makeRequest(urls.getAllCrons + "/" + userId, MethodsEnum.GET);
  }

  static async getSessionsByDateRange(startDate: string, endDate: string) {
    return await makeRequest(
      urls.sessions.getByDateRange +
      "?fromDate=" +
      startDate +
      "&toDate=" +
      endDate,
      "get"
    );
  }

  static async getIncentivesCSVByDateRange(startDate: string, endDate: string) {
    return await makeRequest(
      urls.incentive.getIncentivesCSVByDateRange +
      "?fromDate=" +
      startDate +
      "&toDate=" +
      endDate,
      "get"
    );
  }

  static async getAllStudentsCSV(startDate: string, endDate: string) {
    return await makeRequest(
      urls.getAllStudentsCSV + "?fromDate=" + startDate + "&toDate=" + endDate,
      "get"
    );
  }
  static async getGlobalEnrollment() {
    return await makeRequest(urls.getGlobalEnrollment, "get");
  }
  static async getInternalEnrollment() {
    return await makeRequest(urls.getInternalEnrollment, "get");
  }

  static async getKylasId(userId: any) {
    return await makeRequest(urls.getKylasId + "/" + userId, MethodsEnum.GET);
  }

  static async getRecordingByBookingId(bookingId: string) {
    return await makeRequest(
      urls.getRecordingByBookingId + "?bookingId=" + bookingId,
      MethodsEnum.GET
    );
  }

  static async changePassword(userId: string, password: string) {
    return await makeRequest(
      urls.changeAdminPassword + "/" + userId,
      MethodsEnum.PUT,
      { password: password }
    );
  }

  static async updateUserInfo(
    userId: string,
    email: string,
    phone: number,
    firstName: any,
    lastName: any
  ) {
    return await makeRequest(
      urls.updateUserInfo + "/" + userId,
      MethodsEnum.PUT,
      { email, phone, firstName, lastName }
    );
  }

  static async updateCouncellor(enrollmentId: string, councellor: string) {
    return await makeRequest(
      urls.updateCouncellor + "/" + enrollmentId,
      MethodsEnum.PUT,
      { councellor: councellor }
    );
  }

  static async deleteRazorPayLink(linkId: string) {
    return await makeRequest(
      urls.deleteRazorPayLink + "/" + linkId,
      MethodsEnum.DELETE
    );
  }

  static async paymentSync(linkId: string) {
    return await makeRequest(urls.paymentSync + "/" + linkId, MethodsEnum.GET);
  }

  static async paytmSync(linkId: string) {
    return await makeRequest(urls.paytmSync + "/" + linkId, MethodsEnum.GET);
  }

  static async cashfreeSync(linkId: string) {
    return await makeRequest(urls.cashfreeSync + "/" + linkId, MethodsEnum.GET);
  }

  static async sessionBulkBooking(payload: any) {
    return await makeRequest(
      urls.sessionBulkBooking,
      MethodsEnum.POST,
      payload
    );
  }

  static async getAllCoordinators() {
    return await makeRequest(urls.getAllCoordinators, MethodsEnum.GET);
  }

  static async deleteEnrollmentInvoice(id: any) {
    return await makeRequest(
      urls.deleteEnrollmentInvoice + "/" + id,
      MethodsEnum.DELETE
    );
  }

  static async getRenewalCsv(classesFrom: any, classesTo: any) {
    let params = makeParams([
      {
        index: "numberOfClassesFrom",
        value: classesFrom,
      },
      {
        index: "numberOfClassesTo",
        value: classesTo,
      },
    ]);

    return await makeRequest(urls.getRenewalCsv + params, MethodsEnum.GET);
  }

  static async syncRecordingFromZoom(session_id: string) {
    return await makeRequest(
      urls.syncRecordingFromZoom + "/" + session_id,
      MethodsEnum.GET
    );
  }

  static async updateCommssion(inst_id: string, commission: number) {
    return await makeRequest(
      urls.updateCommssion + "/" + inst_id,
      MethodsEnum.PUT,
      { commission: commission }
    );
  }
  static async getPaidStudents(
    studentIds: any[],
    pageNumber?: number,
    pageSize?: number
  ) {
    const params = makeParams([
      {
        index: "pageNumber",
        value: pageNumber,
      },
      {
        index: "pageSize",
        value: pageSize,
      },
    ]);
    return await makeRequest(
      `${urls.getPaidStudents}${params}`,
      MethodsEnum.POST,
      studentIds
    );
  }

  static async getRenewals(
    students: any,
    status: any,
    pageNumber: number,
    pageSize: number
  ) {
    return await makeRequest(`${urls.getRenewals}`, MethodsEnum.POST, {
      students,
      status,
      pageNumber,
      pageSize,
    });
  }

  static async createRenewal(studentId: any) {
    return await makeRequest(
      `${urls.createRenewal}/${studentId}`,
      MethodsEnum.POST
    );
  }

  static async updateRenewal(renewalId: any, payload: any) {
    return await makeRequest(
      `${urls.updateRenewal}/${renewalId}`,
      MethodsEnum.PUT,
      payload
    );
  }

  static async deleteRenewal(renewalId: any) {
    return await makeRequest(
      `${urls.deleteRenewal}/${renewalId}`,
      MethodsEnum.DELETE
    );
  }

  static async getNotesEnums() {
    return await makeRequest(`${urls.getRenewalEnums}`, MethodsEnum.GET);
  }

  static async createInstructorFine(inputRecordFine: any) {
    return await makeRequest(
      urls.createInstructorFine,
      MethodsEnum.POST,
      inputRecordFine
    );
  }

  static async getAllIncentives(
    status: any,
    fromDate: any,
    toDate: any,
    instructorId: any
  ) {
    let params = makeParams([
      {
        index: "fromDate",
        value: moment(fromDate).format("YYYY-MM-DD"),
      },
      {
        index: "toDate",
        value: moment(toDate).format("YYYY-MM-DD"),
      },
      {
        index: "status",
        value: status,
      },
      {
        index: "instructorId",
        value: instructorId,
      },
    ]);
    return await makeRequest(
      urls.incentive.getAllIncentives + params,
      MethodsEnum.GET
    );
  }

  static async getAllBlockedEnrollments(
    pageNumber: any,
    pageSize: any,
    enrollmentId: any
  ) {
    const params = makeParams([
      {
        index: "pageNumber",
        value: pageNumber,
      },
      {
        index: "pageSize",
        value: pageSize,
      },
      {
        index: "enrollmentId",
        value: enrollmentId,
      },
    ]);
    return await makeRequest(
      urls.blockedEnrollments.getAllBlockedEnrollments + params,
      MethodsEnum.GET
    );
  }

  static async unblockEnrollment(enrollmentId: any, payload: any) {
    return await makeRequest(
      urls.blockedEnrollments.unblockEnrollment + "/" + enrollmentId,
      MethodsEnum.PUT,
      payload
    );
  }

  static async blockEnrollment(enrollmentId: any) {
    return await makeRequest(
      urls.blockedEnrollments.blockEnrollment + "/" + enrollmentId,
      MethodsEnum.PUT
    );
  }

  static async getRenewalById(renewalId: any) {
    return await makeRequest(
      urls.renewal.getById + "/" + renewalId,
      MethodsEnum.GET
    );
  }

  static async getRenewalSummary(renewalId: any) {
    return await makeRequest(
      urls.renewal.getSummary + "/" + renewalId,
      MethodsEnum.GET
    );
  }

  static async addNote(renewalId: any, note: string) {
    return await makeRequest(
      urls.renewal.addNote + "/" + renewalId,
      MethodsEnum.PUT,
      { note }
    );
  }

  static async getAllCallLogs(
    fromDate: any,
    toDate: any,
    studId?: any,
    instId?: any
  ) {
    const params = makeParams([
      {
        index: "fromDate",
        value: fromDate.toISOString(),
      },
      {
        index: "toDate",
        value: toDate.toISOString(),
      },
      {
        index: "student",
        value: studId,
      },
      {
        index: "instructor",
        value: instId,
      },
    ]);
    return await makeRequest(
      urls.callLogs.getAllCallLogs + params,
      MethodsEnum.GET
    );
  }

  static async getAllVersions() {
    return await makeRequest(urls.version.getAll, MethodsEnum.GET);
  }

  static async deleteVersion(versionId: any) {
    return await makeRequest(
      urls.version.deleteVersion + "/" + versionId,
      MethodsEnum.DELETE
    );
  }

  static async createVersion(payload: any) {
    return await makeRequest(
      `${urls.version.createVersion}`,
      MethodsEnum.POST,
      payload
    );
  }

  static async updateVersion(versionId: any, payload: any) {
    return await makeRequest(
      `${urls.version.updateVersion}/${versionId}`,
      MethodsEnum.PUT,
      payload
    );
  }
  static async editEnrollment(enrollmentId: any, payload: any) {
    return await makeRequest(
      urls.enrollments.edit + "/" + enrollmentId,
      MethodsEnum.PUT,
      payload
    );
  }

  static async getSessionDetailsById(sessionId: any) {
    return await makeRequest(
      url.getSessionDetailsById + "/" + sessionId,
      RequestMethods.GET
    );
  }

  static async getPaymentLink(paymentLinkId: any) {
    return await makeRequest(
      url.getPaymentLink + "/" + paymentLinkId,
      RequestMethods.GET
    );
  }

  static async markPaymentLinkCompleted(pay_id: any, classes: number, extraClasses: number) {
    return await makeRequest(
      url.markPaymentLinkCompleted + "/" + pay_id,
      RequestMethods.POST, {
        classes: classes,
        extraClasses : extraClasses
      }
    );
  }
  static async toggleBlock(toggle_id: any) {
    return await makeRequest(
      url.toggleBlock + "/" + toggle_id,
      RequestMethods.PATCH
    );
  }

  static async getCityByPincode(pinCode: number) {
    return await makeRequest(url.getCityByPincode + "/" + pinCode, RequestMethods.GET);
  }

  static async createSaleItem(payload: any) {
    return await makeRequest(url.sales.createSaleItem, RequestMethods.POST, payload);
  }

  static async getAllSalesItems(active?: boolean) {
    if (active !== undefined) return await makeRequest(url.sales.getAllSalesItems + '?active=' + active, RequestMethods.GET)
    return await makeRequest(url.sales.getAllSalesItems, RequestMethods.GET)
  }

  static async changeProductAvailability(payload: any) {
    return await makeRequest(url.sales.changeAvailability, RequestMethods.PATCH, payload)
  }

  static async updateSalesItem(payload: any) {
    return await makeRequest(url.sales.updateSalesItem + '/' + payload._id, RequestMethods.PUT, payload)
  }

  static async createLink(payload: any) {
    return await makeRequest(url.sales.createLink, RequestMethods.POST, payload);
  }

  static async getAllEnrollments(fromDate: any, toDate: any) {
    const params = makeParams([
      {
        index: "fromDate",
        value: fromDate,
      },
      {
        index: "toDate",
        value: toDate,
      },
    ]);
    return await makeRequest(`${urls.getAllEnrollmentsCsv + params}`, MethodsEnum.GET);
  }
}
