import { Badge } from "react-bootstrap"
import { RenewalStatusEnum } from "../../enum/renewal.enum"

interface IRenewalBadge {
    renewal: RenewalStatusEnum
}

export default function RenewalBadge (props: IRenewalBadge) {
    const renderBadge = () => {
        switch(props.renewal) {
            case RenewalStatusEnum.DNP:
                return <Badge bg="danger">DNP</Badge>
            case RenewalStatusEnum.NOT_INTERESTED:
                return <Badge bg="danger">Not right now</Badge>
            case RenewalStatusEnum.WILL_RENEW:
                return <Badge bg="warning">Renewal follow up</Badge>
            case RenewalStatusEnum.FOLLOW_UP:
                return <Badge bg="warning">Follow Up</Badge>
            case RenewalStatusEnum.LINK_SHARED:
                return <Badge bg="secondary">Link Shared</Badge>
            case RenewalStatusEnum.RENEWAL_DONE:
                return <Badge bg="success">Renewal Done</Badge>
            case RenewalStatusEnum.DID_NOT_RENEW:
                return <Badge bg="danger">Did Not Renew</Badge>
            case RenewalStatusEnum.OPEN:
                return <Badge bg="primary">Open</Badge>
            default:
                return <Badge bg="danger">---</Badge>
        }
    }
    return (
        <>
            {renderBadge()}
        </>
    )
}