import { Button, Col, Form, Row } from "react-bootstrap";
import CardBasic from "../Card/card.basic";
import { TfiBlackboard } from "react-icons/tfi";
import { RiSecurePaymentFill, RiShoppingCart2Line } from "react-icons/ri";
import { useEffect, useState } from "react";
import SideModal from "../SideModal/SideModal";
import CalenderInput from "../Modals/DatePicker";
import Select from "react-select";
import { AdminService } from "../../services/admin.service";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import AsyncSelect from "react-select/async";
import { useAppDispatch, useAppSelector } from "../../store/reduxHooks";
import { ArrayHelper } from "../../helpers/array.helper";
import { onFetchInstructorCourse } from "../../store/actions/InstructorCourse";
import { IcreateEnrollment } from "../../interfaces/createEnrollment";
import moment from "moment";

interface IControlbarStudent {
  studentId: string;
}

export default function ControlbarStudent({ studentId }: IControlbarStudent) {
  const [showSessionModal, setShowSessionModal] = useState<boolean>(false);
  const [showEnrollmentModal, setShowEnrollmentModal] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(moment().add("1","hours").startOf("hour").toDate());
  const [errors, setErrors] = useState(Array(3).fill(false));
  const [selectValue, setSelectValue] = useState<any>();
  const [enrollment, setEnrollment] = useState<any>({});

  // const [inputStudentValue, setInputStudentValue] = useState<any>();
  const [inputCourseValue, setInputCourseValue] = useState<any>();
  const [finalSaveState, setFinalSaveState] = useState<any>({
    coordinator: "",
    totalSession: 0,
    amountPaid: 0,
    dhunguruDiscount: 0
  });
  const [totalSessions, setTotalSessions] = useState<any>();
  const [pricePerClass, setPricePerClass] = useState<number>(0);

  const [instructorCourses, setInstructorCourses] = useState<any>({});
  const [selOption, setSelOption] = useState<any>({});

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const handelInstructorChanges = (obj: any) => {
    setSelOption(obj);
  };

  const id = selOption?.value || "";
  const courseDetail = useAppSelector(
    (state) => state.instructorCourses.courseDetail
  );

  const totalSession1 = [
    {
      num_classes: "1",
      discount: "0",
    },
    {
      num_classes: "2",
      discount: "0",
    },
    {
      num_classes: "3",
      discount: "0",
    },
    {
      num_classes: "4",
      discount: "0",
    },
    {
      num_classes: "5",
      discount: "0",
    },
    {
      num_classes: "6",
      discount: "0",
    },
    {
      num_classes: "7",
      discount: "0",
    },
  ];

  const totalSession2 = [
    {
      num_classes: "48",
      discount: "40",
    },
    {
      num_classes: "96",
      discount: "40",
    },
  ];

  useEffect(() => {
    if (inputCourseValue) {
      const current_course = courseDetail?.find(
        (course: any) => course?._id === inputCourseValue?.value
      );
      const newTotalSessions = totalSession1.concat(
        current_course?.discount,
        totalSession2
      );
      const uniqueElements = ArrayHelper.uniqueObjects(newTotalSessions);
      setPricePerClass(current_course?.price_per_class);
      setTotalSessions(
        uniqueElements?.map((data: any) => {
          return {
            value: Number(data?.num_classes),
            label:
              "Buy " +
              data?.num_classes +
              " classes and  get " +
              data?.discount +
              " % off",
          };
        })
      );
    }
  }, [inputCourseValue]);

  useEffect(() => {
    if(!id) return;
    dispatch(onFetchInstructorCourse({ id }));
  }, [id, dispatch]);

  const fetchInstructorData = (inputValue: any, callback: ([]) => void) => {
    if (!inputValue) {
      callback([]);
    } else {
      setTimeout(() => {
        AdminService.getAllInstructor(inputValue).then((res) => {
          if (res.status === 200) {
            const instructors = res.data.data.map((data: any) => {
              return {
                value: data._id,
                label: `${data.firstName}  ${data.lastName} - ${data.email}`,
              };
            });
            callback(instructors);
          }
        });
      }, 1000);
    }
  };

  const fetchCourseData = () => {
    if(!id) return;
    AdminService.getInstructorCourses({ id }).then((res) => {
      if (res.status === 200) {
        setInstructorCourses(
          res?.data?.data?.map((data: any) => {
            return {
              value: data?._id,
              label: `${data?.title}`,
            };
          })
        );
      }
    });
  };

  useEffect(() => {
    fetchCourseData();
  }, [id]);

  const fetchStudentData = (inputValue: any, callback: ([]) => void) => {
    if (!inputValue) {
      callback([]);
    } else {
      setTimeout(() => {
        AdminService.getAllStudents(inputValue).then((res) => {
          if (res.status === 200) {
            const students = res.data.data.map((data: any) => {
              return {
                value: data._id,
                label: `${data.firstName}  ${data.lastName} - ${data.email}`,
              };
            });
            callback(students);
          }
        });
      }, 1000);
    }
  };

  const handleChange = (e: any) => {
    setFinalSaveState({ ...finalSaveState, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (e: any, name: string) => {
    setFinalSaveState({ ...finalSaveState, [name]: e.value });
  };

  const allEnrollments = async () => {
    await AdminService.getStudentEnrollment({ id: studentId })
      .then((res) => {
        if (res.status === 200) {
          const paidEnrollments = res.data.filter(
            (enrollment: any) => enrollment.type == "paid"
          );
          setSelectValue(
            paidEnrollments.map((data: any) => {
              return {
                label:
                  data.instructor.firstName +
                  " " +
                  data.instructor.lastName +
                  " - " +
                  data.enrollmentId +
                  " - " +
                  data?.course?.instrument,
                value: data.enrollmentId,
              };
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createSchedule = async (e: any) => {
    setLoading(true)
    await AdminService.createSession({
      enrollmentId: enrollment.value,
      date: [date.toISOString()],
    })
      .then((res: any) => {
        if (res.status === 200) {
          toast.success("Sessions Booked");
        }
        setEnrollment("");
        setShowSessionModal(false);
      })
      .catch((err: any) => {
        toast.error(err.response.data.message || "Unable to create Session");
      });
      setLoading(false);
  };

  useEffect(() => {
    allEnrollments();
  }, []);

  const onSubmit = async () => {
    setLoading(true)
    const inputData: IcreateEnrollment = {
      instructorId: selOption?.value || " ",
      studentId: studentId,
      courseId: inputCourseValue?.value || " ",
      totalSession: finalSaveState?.totalSession || 0,
      pricePerClass: pricePerClass || 0,
      instructorDiscount: getInstructorDiscount() || 0,
      coordinator: finalSaveState?.coordinator || " ",
      amountPaid: finalSaveState?.amountPaid,
      dhunguruDiscount: finalSaveState?.dhunguruDiscount
    };

    await AdminService.createManualEnrollment(inputData)
      .then((res: any) => {
        if (res.status === 200) {
          setShowEnrollmentModal(false);
          setFinalSaveState({
            coordinator: "",
            totalSession: 0,
            amountPaid: 0,
            selOption:{},
            inputCourseValue:{},
          });
          toast.success("Success: Enrollment Created!");
        }
      })
      .then((e) => {
        console.error(e);
      });
    setLoading(false)

  };

  const getInstructorDiscount: any = () => {
    if (
      courseDetail &&
      courseDetail.length > 0 &&
      inputCourseValue &&
      inputCourseValue.value
    ) {
      const current_course = courseDetail.find(
        (course: any) => course._id == inputCourseValue.value
      );

      const newTotalSessions = totalSession1.concat(
        current_course?.discount,
        totalSession2
      );

      if (current_course && newTotalSessions) {
        const current_discount = newTotalSessions.find(
          (data: any) => data.num_classes == finalSaveState.totalSession
        );
        return current_discount ? current_discount.discount : 0;
      }
      return 0;
    }
    return 0;
  };

  const getPendingAmount = () => {
    if (finalSaveState.totalSession && pricePerClass) {
      const price_per_class =
        pricePerClass - (Number(getInstructorDiscount()) / 100) * pricePerClass;
      const pendingTotal = (price_per_class * finalSaveState.totalSession) -
        finalSaveState.amountPaid
         - finalSaveState.dhunguruDiscount;

      return pendingTotal;
    } else {
      return 0;
    }
  };

  const getInstructorDiscountPrice = () => {
    if (finalSaveState.totalSession && pricePerClass) {
      const total_temp =
        Number(pricePerClass) * Number(finalSaveState.totalSession);
      const instru_after_discount =
        total_temp - (Number(getInstructorDiscount()) / 100) * total_temp;
      return instru_after_discount;
    } else {
      return 0;
    }
  };

  const getPriceAfterDhunDiscount = () => {
    if (finalSaveState.totalSession && pricePerClass) {
      const instr_discount = getInstructorDiscountPrice();
      return instr_discount - finalSaveState.dhunguruDiscount;
    } else {
      return 0;
    }
  };


  const getFinalPrice = () => {
    if (finalSaveState.totalSession && pricePerClass) {
      const dhunguruDiscount_price = getPriceAfterDhunDiscount();
      const after_gst = dhunguruDiscount_price + 0.18 * dhunguruDiscount_price;
      return after_gst;
    } else {
      return 0;
    }
  };


  return (
    <>
      <CardBasic
        body={
          <>
            <div className="d-flex justify-content-end align-items-center">
              <div className="text-center mx-2 cursor-pointer">
                <RiSecurePaymentFill
                  onClick={() => {
                    navigate("/admin/razorpay");
                  }}
                />
                <div
                  className="fs-10 fw-bold"
                  onClick={() => {
                    navigate("/admin/razorpay");
                  }}
                >
                  Payment Link
                </div>
              </div>
              <div className="text-center mx-2 cursor-pointer"  onClick={() => setShowEnrollmentModal(true)}>
                <RiShoppingCart2Line
                />
                <div className="fs-10 fw-bold">New Enrollment</div>
              </div>
              <div className="text-center mx-2 cursor-pointer" onClick={() => setShowSessionModal(true)} >
                <TfiBlackboard />
                <div className="fs-10 fw-bold">New Session</div>
              </div>
            </div>
          </>
        }
      />

      <SideModal
        show={showSessionModal}
        handleClose={() => setShowSessionModal(false)}
        body={
          <div className="w-100  mt-1" style={{ height: "90vh" }}>
            <div className="form-group ">
              <label className="fw-bold text-secondary fs-12">
                Select Enrollment
              </label>
              <div className="selector">
                <Select
                  options={selectValue}
                  value={enrollment}
                  isSearchable={true}
                  placeholder="Select Enrollments"
                  onChange={(e: any) => {
                    setEnrollment(e);
                  }}
                  isClearable
                />
                {errors[0] && (
                  <div className="invalid-feedback d-block">
                    Please select a valid enrollment id
                  </div>
                )}
              </div>
            </div>

            <div className="form-group my-4">
              <label className="fw-bold text-secondary fs-12">Date</label>
              <CalenderInput
                setValue={(date: any) => {
                  setDate(date);
                }}
                selected={date}
              />
              {errors[1] && (
                <div className="invalid-feedback d-block">
                  Please select Date
                </div>
              )}
            </div>
            <button
              className="btn btn-primary btn-block mt-6"
              onClick={(e) => {
                createSchedule(e);
              }}
              disabled={loading}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm mr-2"></span>
              )}
              Create Schedule
            </button>
          </div>
        }
        title="Add Session"
      />

      <SideModal
        show={showEnrollmentModal}
        handleClose={() => setShowEnrollmentModal(false)}
        title="Add Enrollment"
        body={
          <>
            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">
                Select Guru
              </Form.Label>
              <AsyncSelect
                name="InstructorId"
                loadOptions={fetchInstructorData}
                onChange={(option) => handelInstructorChanges(option)}
                isClearable={true}
              />
            </Form.Group>
            {/* <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">
                Select the Student
              </Form.Label>

              <AsyncSelect
                name="studentId"
                loadOptions={fetchStudentData}
                onChange={setInputStudentValue}
                isClearable={true}
              />
            </Form.Group> */}
            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">
                Select the Course
              </Form.Label>
              <Select
                name="courseId"
                options={instructorCourses}
                isClearable={true}
                onChange={setInputCourseValue}
              />
            </Form.Group>
            {totalSessions && totalSessions.length > 0 && (
              <>
                <Form.Group className="mt-2">
                  <Form.Label className="fw-bold text-secondary fs-12">
                    Total Sessions
                  </Form.Label>

                  <Select
                    options={totalSessions}
                    onChange={(e: any) => handleSelectChange(e, "totalSession")}
                  />
                </Form.Group>
                <Form.Group className="mt-2">
                  <Form.Label className="fw-bold text-secondary fs-12">
                    Price per class
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="pricePerClass"
                    disabled={true}
                    value={pricePerClass}
                  />
                  <Form.Group className="mt-2">
                    <Form.Label className="fw-bold text-secondary fs-12">
                      Instructor Discount
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="instructorDiscount"
                      disabled={true}
                      value={getInstructorDiscount()}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label className="fw-bold text-secondary fs-12">
                      Dhunguru Discount
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="dhunguruDiscount"
                      value={finalSaveState.dhunguruDiscount}
                      defaultValue={finalSaveState.dhunguruDiscount}
                      min={0}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label className="fw-bold text-secondary fs-12">
                      Amount Paid (without GST)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="amountPaid"
                      onChange={handleChange}
                      value={finalSaveState.amountPaid}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label className="fw-bold text-secondary fs-12">
                      Pending Amount
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="pendingAMount"
                      disabled={true}
                      value={getPendingAmount()}
                    />
                  </Form.Group>
                </Form.Group>
              </>
            )}
            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">
                Coordinator
              </Form.Label>
              <Form.Control
                type="text"
                name="coordinator"
                onChange={handleChange}
              />
            </Form.Group>
            <div
              className="bg-muted rounded-3 mt-3 p-3 fs-12"
              style={{ backgroundColor: "#f1f1f1" }}
            >
              <div className="fw-bold text-secondary">
                <div>
                  Price per Class:{" "}
                  <span className="text-black">{pricePerClass}</span>
                </div>
                <div>
                  {" "}
                  Total Classes:{" "}
                  <span className="text-black">
                    {" "}
                    x {finalSaveState.totalSession}
                  </span>
                </div>
                <div>
                  {" "}
                  Total Amount:{" "}
                  <span className="text-secondary">
                    {" = "}
                    {finalSaveState.totalSession * pricePerClass}
                  </span>
                </div>
                <div>
                  {" "}
                  Instructor Discount:{" "}
                  <span className="text-danger">
                    {" "}
                    - {getInstructorDiscount()} %
                  </span>
                </div>
                <div>
                  {" "}
                  Price After Discount:{" "}
                  <span className="text-black">
                    {" "}
                    {getInstructorDiscountPrice()}
                  </span>
                </div>
                <div>
                  {" "}
                  Dhunguru Discount:{" "}
                  <span className="text-danger">
                    {" "}
                    - USD {finalSaveState.dhunguruDiscount}
                  </span>
                </div>
                <div>
                  {" "}
                  Price After Dhun. Discount:{" "}
                  <span className="text-black">
                    {" "}
                    {getPriceAfterDhunDiscount()}
                  </span>
                </div>
                <div>
                  {" "}
                  Total Price with GST:{" "}
                  <span className="text-success">
                    {" "}
                    {getFinalPrice() > 0
                      ? getFinalPrice()
                      : " -- All Fields Are Required -- "}
                  </span>
                </div>
              </div>
            </div>
            <Button className="mt-4" onClick={onSubmit} disabled={loading}>
            Create
          </Button>
          </>
        }
      />
    </>
  );
}
