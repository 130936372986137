import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import SideModal from "../SideModal/SideModal";
// import "./table.css";

interface IAllInvoiceByInstructor {
  showModal: any;
  hideModal: any;
  id: any;
  allInvoicesData: any
}

export default function AllInvoicesByInstructor({
  showModal,
  hideModal,
  id,
  allInvoicesData
}: IAllInvoiceByInstructor) {

  return (
    <>
      <SideModal
        show={showModal}
        handleClose={hideModal}
        title={"All Invoices"}
        body={
          <>
            <div className="">
              <div className="fw-bold text-muted">
                Total Earnings: <span className="text-primary">
                  {allInvoicesData && allInvoicesData.totalEarnings ? allInvoicesData.totalEarnings : null}
                </span></div>
              <Table striped borderless hover responsive size="sm" className="mt-4">
                <thead>
                  <tr className="dhun-table-header text-secondary">
                    <th>Month</th>
                    <th>Amount</th>
                    <th>Invoices</th>
                    <th>Fined Classes</th>
                    <th>Class Wise</th>
                  </tr>
                </thead>
                <tbody>
                  {allInvoicesData && allInvoicesData.invoicesData && allInvoicesData.invoicesData?.length > 0
                    ? allInvoicesData.invoicesData?.map((data: any, index: any) => {
                      return (
                        <tr >
                          <td className="fs-12 fw-bold">
                            {data.month}
                          </td>
                          <td>
                            {data.amount}
                          </td>
                          <td>
                            {data.invoices}
                          </td>
                          <td>
                            {data.fined_classes}
                          </td>
                          <td
                          >
                            {data.class_wise}
                          </td>
                        </tr>
                      );
                    })
                    : "No Data Available"}
                </tbody>
              </Table>
            </div>
          </>
        }
      />
    </>
  );
}
