import InstructorAvailability from "./InstructorAvailability";
import InstructorDetail from "./InstructorDetail";
import InstructorCourse from "./Courses";
import Finances from "../Finances";
import InstructorSessions from "./InstructorSessions";
import InstructorEnrollment from "./InstructorEnrollment";
import InstructorStatus from "./Status";
import { useParams } from "react-router";
import Breadcrumbs from "../../../../global/Breadcrumbs";
import { Col, Container, Row } from "react-bootstrap";
import ControlbarInstructor from "../../../../components/ControlBar/ControlBar.instructor";
import Approvals from "./Approvals";
import Instruments from "./Instruments.tsx";
import PaymentTable from "./payment/Payment.table";

export default function Instructor() {
  const params: any = useParams();

  return (
    <>
      <div className="m-4">
        <Container>
          <Breadcrumbs
            links={[
              {
                url: "/admin/search",
                name: "Home",
                active: false,
              },
              {
                url: "#",
                name: "Instructor",
                active: true,
              },
            ]}
          />
          <Row className="mt-4">
            <Col md={8}>
              <InstructorDetail id={params.id} />
            </Col>
            <Col md={4}>
              <InstructorAvailability id={params.id} />
              <div className="mt-4">
                <ControlbarInstructor instructorId={params.id} />
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={12}>
              <InstructorCourse id={params.id} />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <InstructorEnrollment id={params.id} />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <InstructorSessions id={params.id} />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Approvals
                instructor={""}
                courses={""}
                setCourses={""}
                setInstructor={""}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Instruments courses={""} />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <PaymentTable id={params.id} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
