import React, { useEffect, useState } from "react";
import { AdminService } from "../../../services/admin.service";
import moment from "moment";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import CardBasic from "../../../components/Card/card.basic";
import DatePicker from "react-datepicker";
import Select from "react-select";
import toast from "react-hot-toast";
// import IncentiveTable from "./IncentiveTable";
import { CSVLink } from "react-csv";
import CreateIncentiveLinkModal from "../../../components/Modals/CreateIncentive.modal";
import InstructorService from "../../../services/instructor.service";
import CreateVersionModal from "../../../components/Modals/CreateVersion.modal";
import VersionTable from "./VersionTable";

const Version = () => {
  const [showCreateVersion, setShowCreateVersion] = useState(false);

  const [versionData, setVersionData] = useState<any>();

  const getAllVersions = async () => {
    await AdminService.getAllVersions()
      .then((res) => {
        if (res.status === 200) {
          setVersionData(res.data);
          // setIncentiveCsvData(csvDataFormat);
        }
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  };
  
  useEffect(() => {
    getAllVersions();
  },[])


  return (
    <>
      <Container className="pt-3">


            <Row className="mt-2">
              <Col>
                <div className="d-flex justify-content-end">
                  <Button
                    className="mx-2 mt-3"
                    onClick={() => setShowCreateVersion(true)}
                  >
                    Create Version
                  </Button>
                </div>
                <CardBasic
                  className="mt-3"
                  body={
                    <>
                      <VersionTable
                        tableData={versionData}
                        reload={getAllVersions}
                      />
                    </>
                  }
                />
              </Col>
            </Row>
      </Container>
      <CreateVersionModal
        show={showCreateVersion}
        setShow={setShowCreateVersion}
        reload={getAllVersions}
      />
    </>
  );
};

export default Version;
