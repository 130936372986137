import makeRequest, { RequestMethods } from "../api/makeRequest";
import url from "../api/urls";
import { Method } from "axios";

export default class StudentService {
    static async getStudentDetails (studentId:string){
        return await makeRequest(url.student.getUserById+studentId, RequestMethods.GET);
    }
    static async getStudentStats (studentId:string){
        return await makeRequest(url.student.getStudentStats+studentId, RequestMethods.GET);
    }

    static async getPaymentDetail(id:string){
        return await makeRequest(url.student.getPaymentDetail+id, RequestMethods.GET);
    }
    static async getStudentsForSelect() {
        return await makeRequest(url.student.all, RequestMethods.GET);
    }

    static async all(){
        return await makeRequest(url.student.all, RequestMethods.GET);
    }

    static async all_paid(){
        return await makeRequest(url.student.all_paid, RequestMethods.GET);
    }
    
    static async renewalStudents(){
        return await makeRequest(url.renewal.renewalStudents, RequestMethods.GET);
    }
}