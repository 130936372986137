import { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { AdminService } from "../../services/admin.service";
import Select from "react-select";
import toast from "react-hot-toast";
import SideModal from "../SideModal/SideModal";
import SalesService from "../../services/sales.service";

interface ICreateSaleInvoiceModal {
  show: boolean;
  setShow: any;
  reloadData: any;
}

export default function CreateSaleInvoiceModal(props: ICreateSaleInvoiceModal) {
  const [loading, setLoading] = useState<boolean>(false);
  const [buyerEmail, setBuyerEmail] = useState<string>("");
  const [buyerPhone, setBuyerPhone] = useState<string>("");
  const [buyerName, setBuyerName] = useState<string>("");
  const [buyerAddress, setBuyerAddress] = useState<string>("");
  const [buyerCity, setBuyerCity] = useState<string>("");
  const [buyerState, setBuyerState] = useState<string>("");
  const [buyerCountry, setBuyerCountry] = useState<string>("India");
  const [buyerPincode, setBuyerPincode] = useState<string>("");
  const [discount, setDiscount] = useState(0);
  const [salesItems, setSalesItems] = useState<any>([]);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [itemQuantity, setItemQuantity] = useState(0);
  const [itemPrice, setItemPrice] = useState(0);

  const [gst, setGst] = useState({
    sgst: 0,
    cgst: 0,
    igst: 0,
  })
  async function createSale() {

    if (
      buyerEmail === "" ||
      buyerPhone === "" ||
      buyerName === "" ||
      buyerAddress === "" ||
      buyerCity === "" ||
      buyerState === "" ||
      buyerCountry === "" ||
      buyerPincode === "" ||
      !selectedItem.value

    ) {
      toast.error("Please fill all the fields");
      return;
    }

    const finalPrice = getFinalPrice();
    if (finalPrice <= 0) {
      toast.error("Final Price cannot be zero");
      return;
    }

    let payload = {
      buyerEmail,
      buyerName,
      buyerPhone,
      buyerAddress,
      buyerCity,
      buyerState,
      buyerCountry,
      buyerPincode,
      discount,
      itemId: selectedItem.value,
      quantity: itemQuantity,
    };
    setLoading(true);
    await SalesService.createSalesInvoice(payload)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data?.message);
          props.reloadData();
          onModalClose();
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  async function getInfoFromPinCode(pinCode: number) {
    await AdminService.getCityByPincode(pinCode)
      .then((res: any) => {
        if (res.status === 200) {
          if (res.data.state.length === 0 || res.data.city.length === 0) {
            toast.error("Invalid Pincode");
            return;
          }
          setBuyerCity(res.data.city[0]);
          setBuyerState(res.data.state[0]);
        }
      })
      .catch((err: any) => {
        console.log(err);
        toast.error(err.message);
      });
  }

  async function getAllSalesItems() {
    await AdminService.getAllSalesItems(true)
      .then((res) => {
        setSalesItems(res.data.data?.items);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function onModalClose() {
    setBuyerEmail("");
    setBuyerPhone("");
    setBuyerName("");
    setBuyerAddress("");
    setBuyerCity("");
    setBuyerState("");
    setBuyerPincode("");
    setDiscount(0);
    props.setShow(false);
  }
  function getFinalPrice() {
    let finalPrice = totalPrice();
    let gstPrice = {
      sgst: 0,
      cgst: 0,
      igst: 0,
    };
    finalPrice = finalPrice - discount;

    const state = buyerState.toUpperCase();
    if (state === "HARYANA") {
      const cgst = (finalPrice * gst.cgst) / 100;
      const sgst = (finalPrice * gst.sgst) / 100;


      gstPrice.cgst = cgst
      gstPrice.sgst = sgst
      finalPrice = finalPrice + cgst + sgst;

    } else {
      finalPrice = finalPrice + (finalPrice * gst.igst) / 100;
      const igst = (finalPrice * gst.igst) / 100;
      gstPrice.igst = igst;
    }
    return Number(finalPrice.toFixed(2));
  }

  function gstCalculation(gstType: "sgst" | "cgst" | "igst") {
    let gstCalculationAmount = totalPrice() - discount;
  
    const state = buyerState?.toUpperCase();
    switch (gstType) {
      case "sgst":
        return state === "HARYANA"
          ? (gstCalculationAmount * gst.sgst) / 100
          : 0;
      case "cgst":
        return state === "HARYANA"
          ? (gstCalculationAmount * gst.cgst) / 100
          : 0;
      case "igst":
        return state != "HARYANA" ? (gstCalculationAmount * gst.igst) / 100 : 0;
      default:
        return 0;
    }
  }

  const totalPrice = () => {
    let price = 0;
    if(selectedItem.value){
      const item = salesItems.find((item: any) => item._id === selectedItem.value);
      price = item.itemPrice * itemQuantity;
    }
    return price;
  }

  useEffect(() => {
    getAllSalesItems();
  }, []);

  useEffect(() => {
    if (buyerPincode.toString().length >= 6) {
      getInfoFromPinCode(parseInt(buyerPincode));
    }
  }, [buyerPincode]);

  useEffect(() => {
    if(selectedItem.value){
      setItemQuantity(1)
      const sale_item = salesItems.find((item: any) => item._id === selectedItem.value)
      setItemPrice(sale_item?.itemPrice);
      setGst({
        sgst: Number(sale_item?.gst?.sgst) || 0,
        cgst: Number(sale_item?.gst?.cgst) || 0,
        igst: Number(sale_item?.gst?.igst) || 0
      })
    }
  },[selectedItem])



  return (
    <SideModal
      title={"New Sale Invoice"}
      show={props.show}
      handleClose={onModalClose}
      body={
        <>
          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Buyer Name
            </Form.Label>
            <Form.Control
              type="text"
              value={buyerName}
              onChange={(e) => setBuyerName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Buyer Email
            </Form.Label>
            <Form.Control
              type="email"
              value={buyerEmail}
              onChange={(e) => setBuyerEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Buyer Phone
            </Form.Label>
            <Form.Control
              type="number"
              value={buyerPhone}
              onChange={(e) => setBuyerPhone(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Buyer Address
            </Form.Label>
            <Form.Control
              as="textarea"
              value={buyerAddress}
              onChange={(e) => setBuyerAddress(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Buyer Pincode
            </Form.Label>
            <Form.Control
              type="number"
              value={buyerPincode}
              onChange={(e) => setBuyerPincode(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Buyer City
            </Form.Label>
            <Form.Control type="text" value={buyerCity} disabled />
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Buyer State
            </Form.Label>
            <Form.Control type="text" value={buyerState} disabled />
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Buyer Country
            </Form.Label>
            <Form.Control type="text" value={buyerCountry} disabled />
          </Form.Group>
    

         <div>
            <div
              className="fw-bold text-secondary bg-muted rounded-3 mt-3 p-3 fs-12"
              style={{ backgroundColor: "#f1f1f1" }}
            >
              <Form.Group className="mt-2">
                <Form.Label className="fw-bold text-secondary fs-12">
                  Select Product
                </Form.Label>
                <Select
                  isClearable
                  options={salesItems.map((item: any) => {
                    return {
                      value: item._id,
                      label:
                        item.itemName +
                        " - " +
                        item.itemType +
                        " (" +
                        item.itemCode +
                        ")",
                    };
                  })}
                  value={selectedItem}
                  onChange={(e) => setSelectedItem(e)}
                />
              </Form.Group>
              <Form.Group className="mt-2">
                <Form.Label className="fw-bold text-secondary fs-12">
                  Product Price
                </Form.Label>
                <Form.Control
                  type="number"
                  value={itemPrice}
                  // onChange={(e) => setItemPrice(Number(e.target.value))}
                  disabled={true}
                />
              </Form.Group>
              <Form.Group className="mt-2">
                <Form.Label className="fw-bold text-secondary fs-12">
                  Product Quantity
                </Form.Label>
                <Form.Control
                  type="number"
                  value={itemQuantity}
                  onChange={(e) => setItemQuantity(Number(e.target.value))}
                  min={1}
                />
              </Form.Group>
            </div>
          </div>
          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Discount (in Rupees)
            </Form.Label>
            <Form.Control
              type="number"
              value={discount}
              onChange={(e) => setDiscount(Number(e.target.value))}
            />
          </Form.Group>

        
<div
            className="bg-muted rounded-3 mt-3 p-3 fs-12"
            style={{ backgroundColor: "#f1f1f1" }}
          >
            <div className="fw-bold text-secondary">
              <div>
                Total Price: <span className="text-black">{totalPrice()}</span>
              </div>
              <div>
                {" "}
                Discount: <span className="text-danger"> - {discount}</span>
              </div>
            
              <div>
                {" "}
                CGST:{" "}
                <span className="text-success">
                  {" "}
                  + {gstCalculation("cgst")}
                </span>
              </div>
              <div>
                {" "}
                SGST:{" "}
                <span className="text-success">
                  {" "}
                  + {gstCalculation("sgst")}
                </span>
              </div>
              <div>
                {" "}
                IGST:{" "}
                <span className="text-success">
                  {" "}
                  + {gstCalculation("igst")}
                </span>
              </div>

              <div>
                Final Price:{" "}
                <span className="text-black">{getFinalPrice()}</span>
              </div>
              <hr />
              <div className="h6 mt-2 fw-bold">
                Link Price:{" "}
                <span className="h5 text-success fw-bold">
                  USD {Math.ceil(getFinalPrice())}
                </span>
              </div>
            </div>
          </div>
          <Button
            className="mt-3 w-100"
            onClick={createSale}
            disabled={loading}
          >
            {loading ? (
              <span className="d-flex align-items-center justify-content-center gap-2">
                Creating <Spinner animation="border" />
              </span>
            ) : (
              "Create"
            )}
          </Button>
        </>
      }
    />
  );
}
