import { IAction } from "../../action";
import { IStudentState } from "./interface";
import * as actionTypes from "../../constants/student";

const initialState: IStudentState = {
  loading: false,
  studentDetail: {},
};

const StudentReducer = (state = initialState, action: IAction) => {
  if (action.type === actionTypes.FETCH_STUDENT_INITIAL) {
    return {
      ...state,
      loading: true,
      studentDetail: {},
    };
  }

  if (action.type === actionTypes.FETCH_STUDENT_SUCCESS) {
    return {
      ...state,
      loading: false,
      studentDetail: action.payload?.userData || {},
    };
  }

  if (action.type === actionTypes.FETCH_STUDENT_FAILURE) {
    return {
      ...state,
      loading: false,
      studentDetail: {},
    };
  }

  return state;
};

export default StudentReducer;
