import React from "react";
import { Form } from "react-bootstrap";

interface IFormInput {
  label: string;
  identifier: string;
  onChange: any;
  error?: boolean;
  value: string;
  errMessage?: string;
  classes?: any;
  readonly?: boolean;
  type?: string;
}

const FormInput = ({
  label,
  identifier,
  onChange,
  error,
  value,
  errMessage,
  classes,
  readonly,
  type,
}: IFormInput) => {
  return (
    <Form.Group className={classes}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        type={type}
        onChange={onChange}
        name={identifier}
        value={value || ""}
        isInvalid={error && true}
        readOnly={readonly}
      />
      {error && (
        <Form.Control.Feedback type="invalid">
          {errMessage}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default FormInput;
