import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import UserService from "../../services/user.service";
import { validateEmail, validatePhone } from "../../utilis/validations";
import SideModal from "../SideModal/SideModal";

interface ICreateAdmins {
  show: boolean;
  setShowModal: any;
  reloadData?: any;
}

const CreateAdminsModal = ({ show, setShowModal }: ICreateAdmins) => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
    countryCode: "",
  });
  const [errors, setErrors] = useState(Array(4).fill(false));

  useEffect(() => {
    setErrors(Array(4).fill(false));
  }, [user]);

  useEffect(() => {
    setUser({
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      password: "",
      countryCode: "",
    });
  }, [show]);

  const validate = () => {
    let new_errors = Array(4).fill(false);
    if (user.firstName.length < 3) new_errors[0] = true;
    if (user.lastName.length < 3) new_errors[0] = true;
    if (!validateEmail(user.email)) new_errors[1] = true;
    if (!validatePhone(user.phone)) new_errors[2] = true;
    if (user.password.length < 8) new_errors[3] = true;

    setErrors(new_errors);
    if (new_errors.includes(true)) return false;
    else return true;
  };

  const createAdmin = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (!validate()) return;
    await UserService.createAdmin(
      user.firstName,
      user.lastName,
      user.email,
      user.phone,
      user.password,
      user.countryCode
    ).then((res) => {
      if (res.status === 200) {
        setShowModal(false);
        toast.success("User Created");
      }
    });
    setLoading(false);

  };
  const handleClose = () => setShowModal(false);
  return (
    <SideModal
      show={show}
      handleClose={handleClose}
      title={"Create Admin"}
      body={
        <div className="w-100  mt-1">
          <form className="mt-3 ">
            <div className="form-group">
              <label className="fw-bold text-secondary fs-12">First Name</label>
              <input
                className={`form-control ${errors[0] && "is-invalid"} `}
                value={user.firstName}
                onChange={(e) =>
                  setUser((prev) => ({ ...prev, firstName: e.target.value }))
                }
              />
              <div className="invalid-feedback">
                Name should be min 3 characters
              </div>
            </div>

            <div className="form-group">
              <label className="fw-bold text-secondary fs-12">Last Name</label>
              <input
                className={`form-control ${errors[0] && "is-invalid"} `}
                value={user.lastName}
                onChange={(e) =>
                  setUser((prev) => ({ ...prev, lastName: e.target.value }))
                }
              />
              <div className="invalid-feedback">
                Name should be min 3 characters
              </div>
            </div>

            <div className="form-group">
              <label className="fw-bold text-secondary fs-12">Email</label>
              <input
                className={`form-control ${errors[1] && "is-invalid"} `}
                value={user.email}
                onChange={(e) =>
                  setUser((prev) => ({ ...prev, email: e.target.value }))
                }
                autoComplete="email"
              />
              <div className="invalid-feedback">Enter a right email</div>
            </div>
            <div className="form-group">
              <label className="fw-bold text-secondary fs-12">Country Code</label>
              <input
                className={`form-control`}
                value={user.countryCode}
                onChange={(e) =>
                  setUser((prev) => ({ ...prev, countryCode: e.target.value }))
                }
              />
              <div className="invalid-feedback">Phone is invalid</div>
            </div>

            <div className="form-group">
              <label className="fw-bold text-secondary fs-12">Phone</label>
              <input
                className={`form-control ${errors[2] && "is-invalid"} `}
                value={user.phone}
                onChange={(e) =>
                  setUser((prev) => ({ ...prev, phone: e.target.value }))
                }
                autoComplete="new-password"
              />
              <div className="invalid-feedback">Phone is invalid</div>
            </div>

            <div className="form-group">
              <label className="fw-bold text-secondary fs-12">Password</label>
              <input
                className={`form-control ${errors[3] && "is-invalid"
                  } `}
                value={user.password}
                onChange={(e) =>
                  setUser((prev) => ({ ...prev, password: e.target.value }))
                }

                placeholder="Password"
                type="password"
              />
              <div className="invalid-feedback">Please enter a password greater than 8 Characters</div>
            </div>

            <button
              className="btn btn-primary btn-block mt-6"
              onClick={(e) => createAdmin(e)}
              disabled={loading}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm mr-2"></span>
              )}
              Create Admin
            </button>
          </form>
        </div>
      }
    />
  );
};

export default CreateAdminsModal;
