import { useContext, useEffect, useState } from "react";
import SideModal from "../SideModal/SideModal";
import { Col, Form, Row } from 'react-bootstrap';
import { AdminService } from "../../services/admin.service";
import { IsLoading } from "../../App.context";
import toast from "react-hot-toast";

interface IEditEnrollment {
    enrollmentId: string | undefined;
    setEnrollmentId: any;
}

export default function EditEnrollment(props: IEditEnrollment) {


    const [changes, setChanges] = useState<any[]>([])
    const [enrollment, setEnrollment] = useState<any>({})

    const [comment, setComment] = useState('')
    const [cordinator, setCordinator] = useState('')

    const { loading, setLoading }: any = useContext(IsLoading);


    const validate = () => {
        const new_error = Array(10).fill(false)
        // console.log(new_error)
        if (comment.length === 0) new_error[0] = true;
        for (let i = 0; i < changes.length; i++) {
            const index = enrollment.payment_details.findIndex((item: any) => item.receipt === changes[i].receipt);
            const classes_left = enrollment.payment_details[index].num_classes - (enrollment.payment_details[index].classes_completed + enrollment.payment_details[index].classes_requested + enrollment.payment_details[index].classes_scheduled + enrollment.payment_details[index].classes_late_charged)
            if (!(changes[i].classes >= (-1 * classes_left))) new_error[2 * i + (1)] = true;
            if (changes[i].price_per_class < 50) new_error[2 * i + 2] = true;
        }
        if (new_error.includes(true)) return false;
        else return true;
    }

    const saveChanges = async () => {
        if (!validate()) {
            alert("Please make sure all fields are filled correctly");
            return
        }

        setLoading(true);

        const payload = { changes, comment, cordinator };

        await AdminService.editEnrollment(props.enrollmentId, payload).then((res) => {
            if(res.status == 200){
                toast.success("Enrollment Edited Successfully");
                props.setEnrollmentId(undefined);
                setChanges([]);
                setComment('');
                setCordinator('');
                setEnrollment({});

            }
        }).catch((e:any) => {
            console.log(e);
            toast.error("Unable to edit enrollment: Contact Admin");
        })
        setLoading(false);
    }

    const getEnrollmentById = async () => {
        await AdminService.getEnrollmentDetail({ id: props.enrollmentId }).then((res) => {
            if (res.status == 200) {
                const enrollment = res.data.enrollmentDetails.enrollment;
                setChanges(enrollment.payment_details.map((item: any) => ({ receipt: item.receipt, classes: 0, price_per_class: item.price, total_price: item.total_price })))
                setEnrollment(enrollment);
            }
        }).catch(e => {
            console.log(e);
        })
    }

    const editPayment = (value: any, type: any, receipt: any) => {
        const newChanges = [...changes];
        const index = newChanges.findIndex(item => item.receipt === receipt);
        newChanges[index][type] = value;
        setChanges(newChanges)
    }

    useEffect(() => {
        if (props.enrollmentId) {
            getEnrollmentById();
        }
    }, [props.enrollmentId]);

    console.log(changes, "changes")
    return (
        <SideModal
            show={props.enrollmentId ? true : false}
            handleClose={() => props.setEnrollmentId(undefined)}
            body={
                <>
                    {enrollment && enrollment.payment_details && enrollment.payment_details.length > 0 && enrollment.payment_details.filter((item: any) => item.status !== 'not-paid').map((item: any) =>
                        <>
                            <div className="bg-light p-3 rounded border border-1 mb-3">
                            <h6 className='mb-4 fw-bold'>Receipt Id: {item.receipt}</h6>
                            <div className='mb-3'>Total: <strong>{item.num_classes}</strong></div>
                            <div className='mb-3' >Left to be requested: <strong>{item.num_classes - (item.classes_completed + item.classes_requested + item.classes_scheduled + item.classes_late_charged)}</strong></div>

                            <Form.Group>
                                <Form.Label>Classes you want to add?</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={changes.filter(item2 => item2.receipt === item.receipt)[0].classes}
                                    onChange={e => editPayment(e.target.value, 'classes', item.receipt)}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Price per class?</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={changes.filter(item2 => item2.receipt === item.receipt)[0].price_per_class}
                                    onChange={e => editPayment(e.target.value, 'price_per_class', item.receipt)}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Total Price?</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={changes.filter(item2 => item2.receipt === item.receipt)[0].total_price}
                                    onChange={e => editPayment(e.target.value, 'total_price', item.receipt)}
                                />
                            </Form.Group>
                            </div>
                        </>
                    )
                    }

                    <div className='form-group mt-4'>
                        <label>Comments</label>
                        <input className='form-control' value={comment} onChange={e => setComment(e.target.value)} />
                    </div>

                    <div className='form-group mt-4'>
                        <label>Current Cordinator</label>
                        <input className='form-control' value={cordinator} onChange={(e: any) => setCordinator(e.target.value)} />
                    </div>

                    <div className="btn btn-primary btn-block mb-4" onClick={() => saveChanges()}>
                        Save Enrollment
                    </div>
                </>

            }
            title="Edit Enrollment"
        />
    );
}
