import React from "react";
interface IHeading {
  heading: string;
}
export default function Heading(props: IHeading) {
  return (
    <div className="fw-bold fs-grey fs-14 d-flex justify-content-start mb-1">
      {props.heading}
    </div>
  );
}
