interface IModalText {
  label: string;
  value: undefined | string;
}

export default function ModalText(props: IModalText) {
  return (
    <div className="d-flex justify-content-start align-items-center m-1">
      <span className="fw-bold text-secondary fs-14">{props.label}:</span>

      <span className="ms-1 text-primary fs-14">{props.value}</span>
    </div>
  );
}
