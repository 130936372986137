import React, { useEffect, useState } from "react";
import { Badge, Dropdown, Pagination, Table } from "react-bootstrap";
import { IoEye, IoFlag, IoTrashBinSharp } from "react-icons/io5";
import { FontBold } from "../Text/TableText.formats";
import "./table.css";
import moment from "moment";
import { BiDotsVertical } from "react-icons/bi";
import CustomToggle from "../Menu/CustomMenu";
import { AdminService } from "../../services/admin.service";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import FlaggedSessionModel from "../Modals/FlaggedSessionModel";
import ViewSessionModal from "../Modals/ViewSession.modal";
import { IoCopy } from "react-icons/io5";


interface IRazorPay {
  tableData: any;
  totalSessions: any;
  setTotalSessions: any;
  reloadData: any;
  status: any;
}

export default function AllSessionTable({
  tableData,
  totalSessions,
  setTotalSessions,
  reloadData,
  status,
}: IRazorPay) {
  const navigate = useNavigate();

  const [showFlaggedSessionModal, setShowFlaggedSessionModal] =
    useState<boolean>(false);
  const [bookingId, setBookingId] = useState<any>();
  const [index, setIndex] = useState<any>();

  const [showViewModal, setShowViewModal] = useState<any>(undefined);

  const handleSessionDelete = async (_id: any) => {
    await AdminService.deleteSession(_id)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Session Deleted Successfully");
          reloadData();
        }
      })
      .catch((e) => {
        console.error(e);
        toast.error(e.response.data);
      });
  };


  return (
    <>
      <Table striped borderless hover responsive size="sm">
        <thead>
          <tr className="dhun-table-header text-secondary">
            <th>#</th>
            <th>Booking Id</th>
            <th>Type</th>
            <th>Date Time</th>
            <th>Student Name</th>
            <th>Teacher Name</th>
            <th>Instrument</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {totalSessions && totalSessions?.length > 0
            ? totalSessions?.map((data: any, index: any) => {
              return (
                <tr key={data?._id}>
                  <td>
                    <FontBold value={index + 1} />
                  </td>
                  <td>
                    <span className="fw-bold fs-10 text-secondary">
                      {data.bookingId}
                    </span>
                  </td>
                  <td>
                    <span className="fw-bold fs-10 text-secondary">
                      {data.type == "paid" ? (
                        <Badge bg="success">Paid</Badge>
                      ) : (
                        <Badge bg="primary">Demo</Badge>
                      )}
                    </span>
                  </td>
                  <td>
                    <span className="font-monospace fs-12 text-secondary">
                      {moment(data.date_time).format("DD MM YYYY hh:mm a")}
                    </span>
                  </td>
                  <td
                    onClick={() => {
                      navigate(
                        "/admin/search/student/detail/" + data?.student?._id
                      );
                    }}
                  >
                    <u className="fw-bold fs-10 fst-italic text-primary cursor-pointer">
                      {data.student._id
                        ? data?.student?.firstName +
                        " " +
                        data?.student?.lastName
                        : "N.A."}
                    </u>
                  </td>
                  <td
                    onClick={() => {
                      navigate(
                        "/admin/search/student/detail/" +
                        data?.instructor?._id
                      );
                    }}
                  >
                    <u className="fw-bold fs-10 fst-italic text-primary cursor-pointer">
                      {data.instructor._id
                        ? data?.instructor?.firstName +
                        " " +
                        data?.instructor?.lastName
                        : "N.A."}
                    </u>
                  </td>

                  <td>
                    <FontBold
                      value={
                        data.course && data.course.instrument
                          ? data.course.instrument.toUpperCase()
                          : "N.A."
                      }
                    />
                  </td>

                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        as={CustomToggle}
                        id="dropdown-custom-components"
                      >
                        <BiDotsVertical className="cursor-pointer" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          className="text-secondary fs-12 align-items-center d-flex fw-bold"
                          onClick={() => setShowViewModal(data._id)}
                        >
                          <IoEye className="text-secondary me-1" />
                          View
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="text-secondary fs-12 align-items-center d-flex fw-bold"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              process.env.REACT_APP_DHUNGURU_MEET_URL + "/" + data.dhunguruMeetId
                            );
                            toast.success("Link Copied");
                          }}

                        >
                          <IoCopy className="text-secondary me-1" />
                          Copy Dhunguru Meet Link
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="text-secondary fs-12 align-items-center d-flex fw-bold"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              data.paymentDetails.join_url ||
                              data.paymentDetails.start_url
                            );
                            toast.success("Zoom Link Copied");
                          }}
                        >
                          <IoCopy className="text-secondary me-1" />
                          Copy Zoom Link
                        </Dropdown.Item>

                        <Dropdown.Item
                          onClick={() => {
                            handleSessionDelete(data._id);
                          }}
                        >
                          <IoTrashBinSharp className="text-danger" />{" "}
                          <span className="fw-bold text-danger fs-12">
                            Delete
                          </span>
                        </Dropdown.Item>
                        {status === "flagged" && (
                          <Dropdown.Item
                            onClick={() => {
                              setShowFlaggedSessionModal(true);
                              setBookingId(data?.bookingId);
                              setIndex(index);
                            }}
                          >
                            <IoFlag className="text-success" />{" "}
                            <span className="fw-bold text-success fs-12">
                              Resolve Flagged Session
                            </span>
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              );
            })
            : "No Data Available"}
        </tbody>
      </Table>
      <FlaggedSessionModel
        setShow={setShowFlaggedSessionModal}
        show={showFlaggedSessionModal}
        id={bookingId}
        data={tableData && tableData[index]}
      />
      <ViewSessionModal
        show={showViewModal}
        setShow={setShowViewModal}
      />
    </>
  );
}
