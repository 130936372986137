import React, { useEffect, useState } from "react";
import "./Modal.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import moment from "moment";

interface ICalendarInput {
  setValue: any;
  selected: Date;
  interval?: number;
}

const CalenderInput = (props: ICalendarInput) => {
  const filterPassedTime = (time: any) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };


  return (
    <DatePicker
      selected={props.selected}
      onChange={(date: Date) => props.setValue(date)}
      className="form-control"
      isClearable
      showTimeSelect
      filterTime={filterPassedTime}
      timeIntervals={props.interval || 60}
      dateFormat="MMM d, yy h:mm aa"
      minDate={new Date()}
    />
  );
};
export default CalenderInput;
