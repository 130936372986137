import moment from "moment";
import { useEffect, useState } from "react"
import CardBasic from "../../../../../components/Card/card.basic"
import JsonViewer from "../../../../../components/JsonViewer/Json.viewer";
import SideModal from "../../../../../components/SideModal/SideModal";
import { AdminService } from "../../../../../services/admin.service";
import InvoiceService from "../../../../../services/invoice.service";
import StudentService from "../../../../../services/student.service"

interface IStatsStudents {
    studentId: string
}

export default function StatsStudents(props: IStatsStudents) {

    const [stats, setStats] = useState<any>({});
    const [lastPayment, setLastPayment] = useState<any>([]);
    const [cronsData, setCronsData] = useState<any>();
    const [kylasIdData, setKylasIdData] = useState<any>();
    const [showCronsModal, setShowCronsModal] = useState<boolean>(false);

    const getStudentStats = async () => {
        await StudentService.getStudentStats(props.studentId).then(res => {
            if (res.status === 200) {
                setStats(res.data)
            }
        })
    }

    const getLastStudentPayment = async () => {
        await InvoiceService.getLastStudentPayment(props.studentId).then(res => {
            if (res.status === 200) {
                setLastPayment(res.data.data);
            }
        })
    }

    const getAllCrons = async () => {
        await AdminService.getAllCrons(props.studentId).then((res) => {
            if (res.status === 200) {
                setCronsData(res.data);
            }
        })
    }

    const getKyasId = async () => {
        await AdminService.getKylasId(props.studentId).then((res) => {
            if (res.status === 200) {
                setKylasIdData(res.data.data)
            }
        })
    }


    useEffect(() => {
        getStudentStats();
        getLastStudentPayment()
        getAllCrons();
        getKyasId();
    }, [])

    return (
        <>
            <CardBasic
                body={
                    <>
                        <div>
                            <div className="fw-bold fs-grey fs-12">
                                Next Session On: <span className="text-black">{stats.upcoming_session ? moment(stats.upcoming_session.date_time).format("Do MMM YY hh:mm a") : "No Session Scheduled"}</span>
                            </div>
                            <div className="fw-bold fs-grey fs-12">
                                Kylas Id: <span className="text-black">{kylasIdData && kylasIdData[0]?.kylasId ? kylasIdData[0]?.kylasId : "NA"}</span>
                            </div>
                            <div className="fw-bold fs-grey fs-12">
                                Cron(s) Active: <span className="fst-italic text-primary fs-12 fs-underline cursor-pointer" onClick={() => setShowCronsModal(true)}>{cronsData && cronsData.data && cronsData.data.filter((cron: any) => cron.completed === false).length}</span>
                            </div>
                            <div className="fw-bold fs-grey fs-12">
                                Last Purchase: <span className="text-black">{
                                    lastPayment && lastPayment.length > 0 ?
                                        <>INR {lastPayment[0].payment_details[lastPayment[0].payment_details.length-1].total_price} on <span> {moment(lastPayment[0].payment_details[lastPayment[0].payment_details.length-1].payment_date).format("DD MMM YY hh:mm a")} </span></>
                                        : <>
                                            ---
                                        </>
                                }</span>
                            </div>
                        </div>
                    </>
                }
            />
            <JsonViewer
                show={showCronsModal}
                setShow={setShowCronsModal}
                jsonData={cronsData && cronsData.data && cronsData.data.filter((cron: any) => cron.completed === false)}

            />
        </>
    )
}