import { IAction } from "../../action";
import { ICourseState } from "./interface";
import * as actionTypes from "../../constants/instructorCourse";

const initialState: ICourseState = {
  courseDetail: [],
  loading: false,
};

const instructorCourseReducer = (state = initialState, action: IAction) => {
  if (action.type === actionTypes.FETCH_INSTRUCTOR_COURSE_DETAIL_INITIAL) {
    return {
      ...state,
      courseDetail: [],
      loading: true,
    };
  }
  if (action.type === actionTypes.FETCH_INSTRUCTOR_COURSE_DETAIL_SUCCESS) {
    return {
      ...state,
      courseDetail: action.payload?.data || [],
      loading: false,
    };
  }

  if (action.type === actionTypes.FETCH_INSTRUCTOR_COURSE_DETAIL_FAILURE) {
    return {
      ...state,
      courseDetail: [],
      loading: false,
    };
  }

  return state;
};

export default instructorCourseReducer;
