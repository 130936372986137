import { IAction } from "../../action";
import { ILogState } from "./interface";
import * as actionTypes from "../../constants/logs";

const initialState: ILogState = {
  logDetail: {},
  loading: false,
};

const logReducer = (state = initialState, action: IAction) => {
  if (action.type === actionTypes.FETCH_LOG_DETAIL_INITIAL) {
    return {
      ...state,
      logDetail: {},
      loading: true,
    };
  }
  if (action.type === actionTypes.FETCH_LOG_DETAIL_SUCCESS) {
    return {
      ...state,
      logDetail: action.payload || {},
      loading: false,
    };
  }

  if (action.type === actionTypes.FETCH_LOG_DETAIL_INITIAL) {
    return {
      ...state,
      logDetail: {},
      loading: false,
    };
  }

  return state;
};

export default logReducer;
