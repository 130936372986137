import React, { useEffect, useState } from "react";
import { Badge, Button, Col, Row, Table } from "react-bootstrap";
import "./table.css";
import moment from "moment";
import { CSVLink } from "react-csv";
import toast from "react-hot-toast";
import InvoiceService from "../../services/invoice.service";
import AllInvoicesByInstructor from "../Modals/AllInvoicesByInstructor.modal";
import BooleanLabel from "../Labels/boolean.label";
import InvoiceBadge from "../Labels/invoice.label";

interface IInvoicesSummaryTable {
  pageCount: any;
  pageNumber: any;
  startDate: any;
  endDate: any;
  instructor: any;
}

export default function InvoicesSummaryTable({
  pageCount,
  pageNumber,
  startDate,
  endDate,
  instructor,
}: IInvoicesSummaryTable) {
  const [tableData, setTableData] = useState<any>();

  const getInvoices = async () => {
    if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
      return;
    }
    const fromDate = moment(startDate).format("YYYY-MM-DD");
    const toDate = moment(endDate).format("YYYY-MM-DD");

    await InvoiceService.getInvoices(
      pageCount,
      pageNumber,
      "invoices_summary",
      fromDate,
      toDate,
      instructor?.value
    )
      .then((res: any) => {
        if (res.status === 200) {
          setTableData(res.data.invoices);
        }
      })
      .catch((err: any) => {
        setTableData([]);
        // toast.error("Invoice Details not found.");
        console.log(err);
      });
  };

  console.log(tableData, "icent");

  const csvDataDownload =
    tableData && tableData?.length > 0
      ? tableData?.map((data: any) => {
          return {
            ...data,
          };
        })
      : "";

  useEffect(() => {
    getInvoices();
  }, [pageCount, startDate, endDate, instructor]);

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleTable = (index: any) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <>
      <Row>
        <Col className="my-4">
          <div className="d-flex justify-content-end">
            {}
            <CSVLink data={csvDataDownload} filename={"unpaid_invoices.csv"}>
              <Button size="sm" className="mx-2">
                Download CSV Data
              </Button>
            </CSVLink>
          </div>
        </Col>
      </Row>
      <Table striped borderless hover responsive size="sm">
        <thead>
          <tr
            className="dhun-table-header text-secondary"
            style={{ textAlign: "center" }}
          >
            <th>#</th>
            <th>Instructor Name</th>
            <th>Total Invoices</th>
            <th>Total Amount</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {tableData && tableData.length > 0
            ? tableData.map((data: any, index: any) => (
                <React.Fragment key={index}>
                  <tr style={{ textAlign: "center" }}>
                    <td>{index + 1}</td>
                    <td>{data?.instructorName}</td>
                    <td className="fw-bold">{data.invoices.length}</td>
                    <td>{data.totalAmount}</td>
                    <td>
                      <Button
                        variant="link"
                        size="sm"
                        onClick={() => toggleTable(index)}
                      >
                        {activeIndex === index ? "View Less" : "View More"}
                      </Button>
                    </td>
                  </tr>
                  {activeIndex === index && (
                    <tr>
                      <td colSpan={4}>
                        <h5 className="mb-3 text-muted fw-bold">Invoices</h5>
                        <Table striped bordered responsive>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Instructor</th>
                              <th>Enrollment Id</th>
                              <th>Booking Id</th>
                              <th>Type</th>
                              <th>Status</th>
                              <th>Amount</th>
                              <th>Commission</th>
                              <th>Recorded On</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.invoices.map(
                              (invoice: any, invoiceIndex: number) => (
                                <tr key={invoiceIndex}>
                                  <td>{invoiceIndex + 1}</td>
                                  <td>
                                    {invoice?.recipient?.firstName}{" "}
                                    {invoice?.recipient?.lastName}
                                  </td>
                                  <td className="fw-bold">
                                    {invoice.enrollment?.enrollmentId}
                                  </td>
                                  <td>{invoice.schedule?.bookingId}</td>
                                  <td>
                                    <InvoiceBadge type={invoice.type} />
                                  </td>
                                  <td>
                                    {invoice.status === "paid" ? (
                                      <Badge bg="success" className="ms-2">
                                        Paid
                                      </Badge>
                                    ) : (
                                      <Badge bg="danger" className="ms-2">
                                        Unpaid
                                      </Badge>
                                    )}
                                  </td>
                                  <td>{invoice.amount}</td>
                                  <td>
                                    {invoice.commission
                                      ? invoice.commission
                                      : "N/A"}
                                  </td>
                                  <td>
                                    {invoice.recordDate
                                      ? moment(invoice.recordDate).format(
                                          "DD-MM-YYYY hh:mm a"
                                        )
                                      : "N/A"}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                        <hr />
                        <div>
                          <h5 className="mt-2 mb-3 text-muted fw-bold">
                            Incentives
                          </h5>
                          <Table striped bordered responsive>
                            <tr
                              className="dhun-table-header text-secondary"
                              style={{ textAlign: "center" }}
                            >
                              <th>#</th>
                              <th>Guru</th>
                              <th>Student</th>
                              <th>Course</th>
                              <th>Enrollment ID</th>
                              <th>Amount</th>
                              <th>Date</th>
                              <th>Sessions</th>
                              <th>Status</th>
                            </tr>
                            <tbody>
                              {data &&
                                data.incentives.length > 0 &&
                                data.incentives.map(
                                  (data: any, index: number) => {
                                    return (
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>
                                          {data &&
                                            data.instructorId &&
                                            data.instructorId.firstName}
                                        </td>
                                        <td>
                                          {data &&
                                            data.studentId &&
                                            data.studentId.firstName}
                                        </td>
                                        <td>
                                          {data &&
                                            data.courseId &&
                                            data.courseId.instrument}
                                        </td>
                                        <td>
                                          {data &&
                                            data.enrollmentId &&
                                            data.enrollmentId.enrollmentId}
                                        </td>
                                        <td>{data && data.amount}</td>
                                        <td>
                                          {data &&
                                            moment(data.date).format(
                                              "DD MMM YYYY hh:mm"
                                            )}
                                        </td>
                                        <td>{data && data.sessionCount}</td>

                                        <td>
                                          <Badge bg="danger" className="ms-2">
                                            {data && data.transactionStatus}
                                          </Badge>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                            </tbody>
                          </Table>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            : "No Data Available"}
        </tbody>
      </Table>
    </>
  );
}
