import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { FaCheck, FaCross } from 'react-icons/fa';
import { ImCross } from "react-icons/im";
import { AdminService } from '../../services/admin.service';
import SideModal from '../SideModal/SideModal';

interface IFlaggesSession{
    id:string;
    setShow:any;
    show:boolean;
    data:any;
}

const labelsConfig = [
  "Student :",
  "Instructor :",
  "Enrollment Id :",
  "Instrument :",
  "Enrollment Tybe :",
  "Status :",
  "Price :",
  "Schedule Time :",
  "Zoom Join Link :",
  "Recording Allowed?",
  "License Type :"
];

const FlaggedSessionModel = ({id,setShow,show,data}:IFlaggesSession) => {
    
const [response,setResponse]=useState<any>();

const PostFlaggedResponse=async ()=>{
    const payload = {
      enrollmentId: data?.enrollment._id,
      response: response,
      scheduleId: data?._id,
    };
await AdminService.postFlaggedResponse(payload)
  .then((res) => {
    if (res.status == 200) {
     setShow(false);
     toast.success("Resolved Succesfully");
     window.location.reload();
    }
  })
  .catch((err) => {
    toast.error("Unable to Save the response");
  });
}




  return (
    <SideModal
      show={show}
      handleClose={() => setShow(false)}
      body={
        <div className="w-100  mt-1 px-3">
          <div className="form-group bg-mutedRed border rounded">
            <label className="fw-bold fs-6 text-pink px-3 py-3">
              This meeting is flagged due to different response from instructor
              and student
            </label>
          </div>
          <div className="form-group border rounded d-flex flex-column flex-start">
            <label className="fw-bold fs-6 text-dark px-3 py-3">
              Select whose response is correct
            </label>
            <div className="text-secondary px-3">
              <input
                type="radio"
                className="mr-1"
                onChange={(e) => {
                  setResponse(e.target.name);
                }}
                checked={response == "student" ? true : false}
                name="student"
              />
              <label>Student (instructor_no_show)</label>
            </div>
            <div className="mr-4 text-secondary px-3">
              <input
                type="radio"
                className="mr-1"
                onChange={(e) => setResponse(e.target.name)}
                name="instructor"
                checked={response == "instructor" ? true : false}
              />
              <label>Instructor (student_joined)</label>
            </div>
            <div className=" px-3 mb-3 mt-2">
              <Button
                variant="warning"
                className="align-self-start"
                size={"sm"}
                onClick={() => {
                  PostFlaggedResponse();
                }}
              >
                Resolve Flag
              </Button>
            </div>
          </div>
          <div className="d-flex flex-gap ml-3">
            <div className="d-flex flex-column flex-start gap-5 mt-3 fw-bold flexbox">
              {labelsConfig.map((label: string) => {
                return <div>{label}</div>;
              })}
            </div>
            <div className="d-flex flex-column flex-start gap-4-half mt-3">
              <div>
                {(data &&
                  data?.student?.firstName + " " + data?.student?.lastName) ||
                  "--"}
              </div>
              <div>
                {(data &&
                  data?.instructor.firstName +
                    " " +
                    data?.instructor.lastName) ||
                  "--"}
              </div>
              <div>{(data && data?.enrollment.enrollmentId) || "--"}</div>
              <div>{(data && data?.course.instrument) || "--"}</div>
              <div>{(data && data?.type) || "--"}</div>
              <div>{(data && data?.status) || "--"}</div>
              <div>{(data && data?.price) || "--"}</div>
              <div>
                {data?.date_time
                  ? moment(data?.date_time).format("DD MMM YYYY")
                  : "--"}
              </div>
              <div
                onClick={() => {
                  window.location.href = data?.zoom.join_url;
                }}
                className="text-warning cursor-pointer"
              >
                Link
              </div>
              <div>
                {(data && data?.isRecording === true ? (
                  <FaCheck className="text-success" />
                ) : (
                  <ImCross className="text-danger" />
                )) || "--"}
              </div>
              <div>{(data && data?.zoom?.licenseType) || "--"}</div>
            </div>
          </div>
        </div>
      }
      title={`Session-${id}`}
    />
  );
}
 

export default FlaggedSessionModel
