import moment from "moment";
import React from "react";
import { Badge, Dropdown, Table } from "react-bootstrap";
import { BiDotsVertical } from "react-icons/bi";
import { IoTrashBinSharp } from "react-icons/io5";
import CustomToggle from "../../../components/Menu/CustomMenu";
import { AdminService } from "../../../services/admin.service";

interface IIncentiveTable {
  tableData: any;
  reload: any;
}

const IncentiveTable = ({ tableData, reload }: IIncentiveTable) => {
  const handleIncentiveDelete = async (incentiveId: any) => {
    await AdminService.deleteIncentive(incentiveId).then((res) => {
      if (res.status === 200) {
        reload();
      }
    });
  };

  return (
    <>
      <Table striped borderless hover responsive size="sm">
        <thead>
          <tr className="dhun-table-header text-secondary">
            <th>#</th>
            <th>Enrollment Id</th>
            <th>Name</th>
            <th>Instructor</th>
            <th>Session Count</th>
            <th>Amount</th>
            <th>Date Time</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData && tableData.length > 0
            ? tableData.map((data: any, index: number) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      {data &&
                        data.enrollmentId &&
                        data.enrollmentId.enrollmentId}
                    </td>
                    <td>
                      {data &&
                        data.studentId &&
                        data.studentId.firstName +
                          " " +
                          data.studentId.lastName}
                    </td>
                    <td>
                      {data &&
                        data.instructorId &&
                        data.instructorId.firstName +
                          " " +
                          data.instructorId.lastName}
                    </td>
                    <td>{data.sessionCount}</td>
                    <td>{data.amount}</td>
                    <td>{moment(data.date).format("DD-MM-YYYY hh:mm a")}</td>
                    <td>
                      {
                        // <Badge bg="secondary">{data.transactionStatus}</Badge>
                        <Badge
                          bg={
                            data.transactionStatus === "pending"
                              ? "primary"
                              : data.transactionStatus === "transferred"
                              ? "secondary"
                              : data.transactionStatus === "adjusted"
                              ? "warning"
                              : "danger"
                          }
                        >
                          {data.transactionStatus === "pending"
                            ? "Pending"
                            : data.transactionStatus === "transferred"
                            ? "Transferred"
                            : data.transactionStatus === "adjusted"
                            ? "Adjusted"
                            : "Cancelled"}
                        </Badge>
                      }
                    </td>
                    <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            as={CustomToggle}
                            id="dropdown-custom-components"
                          >
                            <BiDotsVertical className="cursor-pointer" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                handleIncentiveDelete(data?._id);
                              }}
                            >
                              <IoTrashBinSharp className="text-danger" />{" "}
                              <span className="fw-bold text-danger fs-12">
                                Delete
                              </span>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                    </td>
                  </tr>
                );
              })
            : "No data found"}
        </tbody>
      </Table>
    </>
  );
};

export default IncentiveTable;
