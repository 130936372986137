import React, { useEffect, useState } from "react";
import { Button, FloatingLabel, Form, Modal, Tab, Tabs } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { AdminService } from "../../services/admin.service";
import toast from "react-hot-toast";
import SideModal from "../SideModal/SideModal";
import { IInputRecordFine } from "../../interfaces/createFine";

interface IRecordFine {
  show: boolean;
  setShow: any;
}


export default function RecordFine(
  props: IRecordFine
) {

  const [selOption, setSelOption] = useState<any>({});
  const [finalState, setFinalState] = useState({
    scheduleId: "",
    amount: "",
    comment: ""
  })
  const [loading, setLoading] = useState<boolean>(false);


  const fetchInstructorData = (inputValue: any, callback: ([]) => void) => {
    if (!inputValue) {
      callback([]);
    } else {
      setTimeout(() => {
        AdminService.getAllInstructor(inputValue).then((res) => {
          if (res.status === 200) {
            const instructors = res.data.data.map((data: any) => {
              return {
                value: data._id,
                label: `${data.firstName}  ${data.lastName} - ${data.email}`,
              };
            });
            callback(instructors);
          }
        });
      }, 1000);
    }
  };

  const handelInstructorChanges = (obj: any) => {
    setSelOption(obj);
  };

  const handleChange = (e: any) => {
    setFinalState({ ...finalState, [e.target.name]: e.target.value })
  }

  const onSubmit = async () => {
    if (!finalState.scheduleId || !finalState.amount ||!finalState.comment || !selOption) {
      toast.error("All Fields are required!");
      return false
    }

    setLoading(true)
    const inputRecordFine: IInputRecordFine = {
      instructorId: selOption?.value,
      bookingId: finalState.scheduleId,
      amount: finalState.amount, 
      comment: finalState.comment
    };

    await AdminService.createInstructorFine(inputRecordFine)
      .then((res: any) => {
        if (res.status === 200) {
          props.setShow(false);
          setFinalState({
            scheduleId: "",
            amount: "",
            comment: ""
          })
          setSelOption({})
          toast.success("Success: Invoice Created!");
        }
      })
      .then((e: any) => {
        setLoading(false)
        console.error(e);
      });

    setLoading(false)
  }

  return (
    <SideModal
      title={"Create Fine Invoice"}
      show={props.show}
      handleClose={() => props.setShow(false)}
      body={
        <>
          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Select Instructor
            </Form.Label>
            <AsyncSelect
              name="InstructorId"
              loadOptions={fetchInstructorData}
              onChange={(option) => handelInstructorChanges(option)}
              isClearable={true}
            />
          </Form.Group>

          <Form.Group className="mt-2 ">
            <Form.Label className="fw-bold text-secondary fs-12">
              Please enter the schedule Id
            </Form.Label>
            <Form.Control
              type="text"
              name="scheduleId"
              onChange={handleChange}
            />
          </Form.Group>

          
            <Form.Group className="mt-2 ">
              <Form.Label className="fw-bold text-secondary fs-12">
                Please enter a negative number if you want to create a fine. If added number is positive, it will reduce the overall fine imposed on teacher
              </Form.Label>
              <FloatingLabel controlId="floatingSelect" label="Please enter the amount">
                <Form.Control
                  type="text"
                  name="amount"
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mt-2 ">
            <Form.Label className="fw-bold text-secondary fs-12">
              Please add a comment
            </Form.Label>
            <Form.Control
              type="text"
              name="comment"
              onChange={handleChange}
            />
          </Form.Group>

          <Button className="mt-2" onClick={onSubmit} >
            Create Fine Invoice
          </Button> 
        </>
      }
    />
  );
}
