import React from 'react'
import { Button, Modal } from 'react-bootstrap'

interface IConfirmationModal {
    show: any,
    setShow: any,
    handleDelete: any
}

const ConfirmationModal = ({ show, setShow, handleDelete }: IConfirmationModal) => {

    const modalStyle = {
        width: "600px",
        height: "250px",
        margin: "20% 34%",
    };


    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="lg"
            style={{ ...modalStyle }}
        >
            <Modal.Header closeButton>
                <Modal.Title>Delete Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="alert alert-danger">
                    Do you really want to cancel this Invoice.
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default" onClick={() => setShow(false)}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handleDelete}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal >
    )
}

export default ConfirmationModal