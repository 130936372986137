import { configureStore } from "@reduxjs/toolkit";
import courseReducer from "./store/reducers/course";
import instructorReducer from "./store/reducers/instructor";
import logReducer from "./store/reducers/log";
import searchReducer from "./store/reducers/searchPage";
import StudentReducer from "./store/reducers/student";
import enrollmentReducer from "./store/reducers/enrollment";
import { authReducer } from "./store/reducers/auth";
import instructorCourseReducer from "./store/reducers/instructorCourses";
// ...

export const store = configureStore({
  reducer: {
    course: courseReducer,
    log: logReducer,
    instructor: instructorReducer,
    search: searchReducer,
    student: StudentReducer,
    enrollment: enrollmentReducer,
    auth: authReducer,
    instructorCourses: instructorCourseReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
