import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { IoTrashBinSharp } from "react-icons/io5";
import DatePicker from "react-datepicker";
import moment from "moment";

interface ISessionBookingDetail {
  selectedSessions: any[];
  setSelectedSessions: any;
  setSession: any;
  session: any[];
}

const SessionBookingDetail = (props: ISessionBookingDetail) => {
  const [showAddSession, setShowAddSession] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<any>(new Date());

  let curretSession: any = props.selectedSessions;
  const handleSessionDelete = (index: any) => {
    const clickedSession = curretSession.find((session: any, i: number) => {
      return (
        session?.toLocaleTimeString("en-US") ===
        curretSession[index]?.toLocaleTimeString("en-US")
      );
    });

    const remSession = curretSession.filter((session: any, i: number) => {
      if (
        curretSession[index]?.toLocaleTimeString("en-US") ===
        curretSession[index + 1]?.toLocaleTimeString("en-US")
      ) {
        return i !== index;
      } else if (
        curretSession[index]?.toLocaleTimeString("en-US") !==
        curretSession[index + 1]?.toLocaleTimeString("en-US")
      ) {
        return (
          session?.toLocaleTimeString("en-US") !==
          clickedSession?.toLocaleTimeString("en-US")
        );
      }
    });
    props.setSelectedSessions(remSession);
  };

  return (
    <div className="h-100">
      <Modal show={showAddSession} onHide={() => setShowAddSession(false)}>
        <Modal.Body>
          <div>
            <h6 className="fw-bold">Session Time</h6>
          </div>
          <div className="d-flex justify-content-between">
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              showTimeSelect
              timeFormat="p"
              dateFormat="MMM d, h:mm aa"
              timeIntervals={60}
            />
            <Button
              variant="secondary"
              size="sm"
              onClick={() => {
                props.setSelectedSessions([
                  ...props.selectedSessions,
                  selectedDate,
                ]);
                props.setSession([...props.session, selectedDate]);

                setShowAddSession(false);
              }}
            >
              {" "}
              Add{" "}
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <div className="dun-bulk-top-bar d-flex justify-content-end">
        <Button
          variant="primary"
          size="sm"
          onClick={() => setShowAddSession(true)}
        >
          Add New Session
        </Button>
      </div>
      <div className="m-3">
        {props.selectedSessions &&
          props.selectedSessions.length > 0 &&
          props.selectedSessions.map((data: any, index: any) => {
            return (
              <div
                key={data + index}
                className="border border-1 border-muted p-2 rounded-2 d-flex justify-content-between mb-2"
              >
                <div className="d-flex justify-content-start">
                  <div className="fw-bold">
                    <span className="text-muted">Time:</span>{" "}
                    {moment(data).format("hh:mm a")}
                  </div>
                  <div className="fw-bold ms-4">
                    <span className="text-muted">Date:</span>{" "}
                    {moment(data).format("Do MMM YYYY")}
                  </div>
                </div>
                <div>
                  <IoTrashBinSharp
                    className="text-danger"
                    onClick={() => {
                      handleSessionDelete(index);
                    }}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default SessionBookingDetail;
