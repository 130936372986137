import moment from "moment";
import React, { useState } from "react";
import { Badge, Dropdown, Pagination, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { BiDotsVertical } from "react-icons/bi";
import { IoPeople, IoTrashBinSharp, IoVideocam } from "react-icons/io5";
import { useNavigate } from "react-router";
import { AdminService } from "../../services/admin.service";
import CustomToggle from "../Menu/CustomMenu";
import DeleteConfirmation from "../Modals/DeleteConfirmation";
import EditCounsellorModel from "../Modals/EditCounsellor";
import { FontBold, NormalText } from "../Text/TableText.formats";
import "./table.css";

interface IDemoSessions {
  tableData: any;
  deleteAllowed: boolean;
  reloadData: any;
  zoomLinkShow: boolean;
}

export default function DemoSessionTable({
  tableData,
  deleteAllowed,
  reloadData,
  zoomLinkShow,
}: IDemoSessions) {
  const [demoSessionId, setDemoSessionId] = useState<any>();
  const [enrollmentId, setEnrollmentId] = useState<any>();
  const [type, setType] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [deleteMessage, setDeleteMessage] = useState<any>("");

  const [editCounsellorModelShow, setEditCounsellorModelShow] = useState<boolean>(false); //

  const navigate = useNavigate();

  const showDeleteModal = (type: any, id: any) => {
    setType(type);
    setDemoSessionId(id);
    if (type) {
      setDeleteMessage(
        `Are you sure you want to delete session with booking Id : ${type}`
      );
    }
    setDisplayConfirmationModal(true);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const submitDelete = async (type: any, _id: any) => {
    setDisplayConfirmationModal(false);
    if (displayConfirmationModal === true) {
      await AdminService.deleteSession(_id)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Session Deleted Successfully");
            reloadData();
          }
        })
        .catch((e) => {
          console.error(e);
          toast.error(e.response.data);
        });
    }
  };
console.log(tableData,'tableD')
  return (
    <>
      <Table striped borderless hover responsive size="sm">
        <thead>
          <tr className="dhun-table-header text-secondary">
            <th>#</th>
            <th>Booking Id</th>
            <th>Student Name</th>
            <th>Teacher Name</th>
            <th>Instrument</th>
            <th>Councellor</th>
            <th>Date Time</th>
            <th>Actual Time</th>
            <th>Coordinator</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tableData && tableData?.length > 0
            ? tableData?.map((data: any, index: any) => {
                return (
                  <tr>
                    <td>
                      <NormalText value={index + 1} />
                    </td>
                    <td>
                      <FontBold value={data.bookingId} />
                    </td>
                    <td
                      onClick={() => {
                        navigate(
                          "/admin/search/student/detail/" + data?.student?._id
                        );
                      }}
                    >
                      <u className="fst-italic text-primary fs-12 fs-underline cursor-pointer">
                        {data.student
                          ? data?.student?.firstName +
                            " " +
                            data?.student?.lastName
                          : "N.A."}
                      </u>
                    </td>
                    <td
                      onClick={() => {
                        navigate(
                          "/admin/search/student/detail/" +
                            data?.instructor?._id
                        );
                      }}
                    >
                      <u className="fst-italic text-primary fs-12 fs-underline cursor-pointer">
                        {data.instructor
                          ? data?.instructor?.firstName +
                            " " +
                            data?.instructor?.lastName
                          : "N.A."}
                      </u>
                    </td>
                    <td>
                      <div className="fs-12 cursor-pointer">
                        {data.course && data.course._id
                          ? data?.course?.instrument.toUpperCase()
                          : "N.A."}
                      </div>
                    </td>
                    <td>
                      <span className="font-monospace fs-12">
                        {data.enrollment &&
                          (data.enrollment.councellor || " -- ")}
                      </span>
                    </td>
                    <td>
                      <span className="font-monospace fs-12">
                        {moment(data.date_time).format("DD MMM YY hh:mm a")}
                      </span>
                    </td>
                    <td>
                      <span className="font-monospace fs-12">
                        {data.demo_time ? moment(data.demo_time).format("DD MMM YY hh:mm a") : "--"}
                      </span>
                    </td>
                    <td>
                      <div className="fs-12">
                        {data?.enrollment?.cordinator ? (
                          data?.enrollment?.cordinator
                        ) : (
                          <div className="fst-italic fs-12">---</div>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="fs-12 cursor-pointer">
                        {data?.status ? (
                          <Badge bg="primary">{data?.status}</Badge>
                        ) : (
                          "N.A."
                        )}
                        {data?.status === "cancelled" && data.isDeleted && (
                          <Badge bg="danger" className="ms-1">
                            By Admin
                          </Badge>
                        )}
                      </div>
                    </td>
                    <Dropdown>
                      <Dropdown.Toggle
                        as={CustomToggle}
                        id="dropdown-custom-components"
                      >
                        <BiDotsVertical />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {zoomLinkShow && (
                          <Dropdown.Item
                            className="text-secondary fs-12 align-items-center d-flex fw-bold"
                            onClick={() => {
                              navigator.clipboard.writeText(data.zoom.join_url);
                              toast.success("Link Copied");
                            }}
                          >
                            <IoVideocam className="text-primary me-1" /> Copy
                            Link
                          </Dropdown.Item>
                        )}
                        {deleteAllowed &&
                          (data.status === "scheduled" ||
                            data.status === "rescheduled") && (
                            <Dropdown.Item
                              className="text-danger fs-12 align-items-center d-flex fw-bold"
                              onClick={() => {
                                submitDelete(data.bookingId, data._id);
                                showDeleteModal(data.bookingId, data._id);
                              }}
                            >
                              <IoTrashBinSharp className="text-danger me-1" />{" "}
                              Delete Session
                            </Dropdown.Item>
                          )}
                        
                          <Dropdown.Item
                            className="text-secondary fs-12 align-items-center d-flex fw-bold"
                            onClick={() => {
                              setEditCounsellorModelShow(true);
                              setEnrollmentId(data?.enrollment?._id)
                            }}
                          >
                            <IoPeople className="text-primary me-1" /> Edit Councellor
                          </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </tr>
                );
              })
            : "No data found"}
        </tbody>
      </Table>
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        hideModal={hideConfirmationModal}
        confirmModal={submitDelete}
        id={demoSessionId}
        type={type}
        message={deleteMessage}
      />
      <EditCounsellorModel show={editCounsellorModelShow} setShow={setEditCounsellorModelShow} enrollmentId={enrollmentId} reload={()=>reloadData()}/>
    </>
  );
}
